import React from 'react'
import ReactJson from 'react-json-view'

import MappingRuleCopy from './rules/MappingRuleCopy'
import MappingRuleMenu from './rules/MappingRuleMenu'
import MappingRuleLiteral from './rules/MappingRuleLiteral'
import MappingRuleConvert from './rules/MappingRuleConvert'
import MappingRuleReplace from './rules/MappingRuleReplace'
import { DocumentType } from '../../declarations/SourceDocument'

const MAPPING_RULE_VIEWS = {
  menu: MappingRuleMenu,
  copy: MappingRuleCopy,
  literal: MappingRuleLiteral,
  convert: MappingRuleConvert,
  replace: MappingRuleReplace,
}

type Props = {
  defaultView?: string
  model?: any
  setModel: Function
  create?: boolean
  documentSchemaProperty?: string
  sourceDocumentAttributes?: string[]
  previewDocument?: DocumentType
  schemaAttributeType?: string
  close?: Function
  documentKey?: string
  setPreviewLoading?: Function
  canAddTransformRule?: boolean
  executeDelete?: Function
}

const MappingRuleSelector: React.FC<Props> = ({ defaultView = 'copy', ...rest }) => {
  const { model } = rest
  const [view, setView] = React.useState(defaultView)

  const View =
    MAPPING_RULE_VIEWS[view] ||
    (() => (
      <div css={styles.noView}>
        <ReactJson src={model} />
      </div>
    ))

  return (
    <>
      <View {...{ setView, ...rest }} />
    </>
  )
}

const styles: any = {
  noView: {
    display: 'flex',
    maxHeight: 500,
    overflowY: 'auto',
  },
}

export default MappingRuleSelector
