import React from 'react'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import toArray from 'lodash/toArray'
import isEmpty from 'lodash/isEmpty'
import isDefined from '@codewell/is-defined'

import List from '../../../components/List'
import DocumentRuleListItem from '../../../components/list/DocumentRuleListItem'

import useAPI, { getActionType } from '../../../hooks/useAPI'
import { DistributionPackage } from '../../../declarations'
import { useParams } from 'react-router-dom'

type Props = {
  release: any
  distributionPackage: DistributionPackage
}

const DocumentRulesTabPage: React.FC<Props> = ({ release, distributionPackage }) => {
  const { page = 0 } = useParams()
  const documentRules = useSelector(getQuerySelector({ type: getActionType('DOCUMENT_RULES') }))

  const pageLimit = 30
  const offset = page * pageLimit

  const documentRulesList = toArray(documentRules?.data).slice(offset, pageLimit)

  useAPI('DOCUMENT_RULES', `/releases/${release?.id}/document-rules?offset=${offset}&limit=${pageLimit}`, !isDefined(release?.id))

  const isLoading = documentRules?.pending > 0
  const isEmptyList = isEmpty(documentRulesList)

  return (
    <List feature="document_rules" loading={isLoading} empty={isEmptyList} gridGap="0">
      {documentRulesList &&
        documentRulesList.map((documentRule) => (
          <DocumentRuleListItem
            key={documentRule.id}
            documentRule={documentRule}
            to={`/distribution-packages/${distributionPackage?.id}/mapping-rules/edit#${documentRule.rule?.targetAttributeName}`}
          />
        ))}
    </List>
  )
}

export default DocumentRulesTabPage
