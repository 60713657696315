import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getQuerySelector, resetRequests } from '@redux-requests/core'
import toArray from 'lodash/toArray'
import groupBy from 'lodash/groupBy'
import filter from 'lodash/filter'
import isDefined from '@codewell/is-defined'

import { COLORS } from '../../../../theme'

import { DocumentRule, DocumentRules } from '../../../../declarations'

import Page from '../../../../components/layout/page/Page'
import CardList from '../../../../components/CardList'
import DistributionPackagesSubtitle from '../../../../components/layout/page/subtitle/DistributionPackagesSubtitle'
import SchemaAttributeOverview from '../../../../components/mapping/SchemaAttributeOverview'

import MappingForm from '../../../../components/mapping/MappingForm'

import useAPI, { getActionType } from '../../../../hooks/useAPI'
import { getSourceDocumentsAttributes } from '../../../../utils/functions'
import { setRules, clearRules } from '../../../../actions/mapping'
import { RootState } from '../../../../setup/storeConfig'
import PageAction from '../../../../components/layout/page/PageAction'

const DistributionPackageEditMappingRulesPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const dispatch = useDispatch()
  const [mounted, setMounted] = React.useState(false)
  const [schemaRequiredKeys, setSchemaRequiredKeys] = React.useState([])
  const [schemaNotRequiredKeys, setSchemaNotRequiredKeys] = React.useState<string[]>([])

  const mappingRules = useSelector((state: RootState) => state.mapping?.rules)

  const distributionPackage = useSelector(getQuerySelector({ type: getActionType('DISTRIBUTION_PACKAGE') }))
  const documentRules = useSelector(getQuerySelector({ type: getActionType('DOCUMENT_RULES') }))
  const sourceDocuments = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENTS') }))
  const sourceDocumentGroupSourceDocuments = useSelector(
    getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS') }),
  )
  const connection = useSelector(getQuerySelector({ type: getActionType('CONNECTION') }))
  const channel = useSelector(getQuerySelector({ type: getActionType('CHANNEL') }))
  const schema = useSelector(getQuerySelector({ type: getActionType('SCHEMA') }))
  const schemas = useSelector(getQuerySelector({ type: getActionType('SCHEMAS') }))
  const connections = useSelector(getQuerySelector({ type: getActionType('CONNECTIONS') }))
  const sourceDocumentSchemas = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMA') }))

  const [mainLoading, setMainLoading] = React.useState(false)

  const connectionId = distributionPackage?.data?.connectionId
  const channelKey = connection?.data?.key
  const channelTitle = channel?.data?.title
  const channelSchemaId = distributionPackage?.data?.channelSchemaId
  const baseSourceDocumentSchemaId = distributionPackage?.data?.baseSourceDocumentSchemaId
  const baseSourceDocumentGroupId = distributionPackage?.data?.baseSourceDocumentGroupId
  const schemaTitle = schema?.data?.title

  useAPI('DISTRIBUTION_PACKAGE', `/distribution-packages/${id}`)
  useAPI('DOCUMENT_RULES', `/distribution-packages/${id}/document-rules`)
  useAPI('SOURCE_DOCUMENTS', `/distribution-packages/${id}/source-documents`, false, 'GET', true)
  useAPI(
    'SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS',
    `/source-document-groups/${baseSourceDocumentGroupId}/source-documents`,
    isDefined(sourceDocumentGroupSourceDocuments?.data) || !isDefined(baseSourceDocumentGroupId),
    'GET',
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    [sourceDocumentGroupSourceDocuments?.data],
  )

  useAPI(
    'SOURCE_DOCUMENT_SCHEMA',
    `/source-document-schemas/${baseSourceDocumentSchemaId}/document-rules`,
    !isDefined(baseSourceDocumentSchemaId),
    'GET',
    false,
    'id',
    undefined,
    'documentRules',
  )
  useAPI('CONNECTION', `/connections/${connectionId}`, !isDefined(connectionId))
  useAPI('SCHEMAS', `/connections/${connectionId}/schemas`, !isDefined(connectionId))
  useAPI('SCHEMA', `/connections/${connectionId}/schemas/${channelSchemaId}`, !isDefined(connectionId) || !isDefined(channelSchemaId))
  useAPI('CHANNEL', `/channels/${channelKey}`, !isDefined(channelKey), 'GET', false, 'key')
  useAPI('CONNECTIONS', `/connections`)

  const isLoading =
    documentRules.pending > 0 ||
    distributionPackage.pending > 0 ||
    sourceDocuments.pending > 0 ||
    sourceDocumentGroupSourceDocuments.pending > 0 ||
    connection?.pending > 0 ||
    connections?.pending > 0 ||
    channel?.pending > 0 ||
    schemas?.pending > 0 ||
    sourceDocumentSchemas?.pending > 0 ||
    !mounted

  const sourceDocumentList = sourceDocuments?.data?._embedded?.sourceDocuments?.map((item) => item.document) || []
  const sourceDocumentGroupSourceDocumentList =
    sourceDocumentGroupSourceDocuments?.data?._embedded?.sourceDocuments?.map((item) => item.document) || []
  const documentRulesList = toArray<DocumentRules>(mappingRules)
  const documentRulesGrouped = groupBy(toArray<DocumentRules>(documentRulesList), (rule: DocumentRule) => rule.rule?.targetAttributeName)
  const sourceDocumentRulesGrouped = groupBy(sourceDocumentSchemas?.data, (rule) => rule.rule?.targetAttributeName)
  const sourceDocumentAttributes = Object.keys(getSourceDocumentsAttributes(sourceDocumentList))
  const sourceDocumentGroupSourceDocumentAttributes = Object.keys(getSourceDocumentsAttributes(sourceDocumentGroupSourceDocumentList))

  React.useEffect(() => {
    if (documentRules?.data) dispatch(setRules(documentRules?.data))
  }, [documentRules])

  React.useEffect(() => setMounted(true), [])

  React.useEffect(
    () => () => {
      dispatch(clearRules())
      dispatch(resetRequests([getActionType('SOURCE_DOCUMENTS'), getActionType('SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS')]))
    },
    [],
  )

  React.useEffect(() => {
    const properties = schema?.data?.definitions?.document?.properties
    const propertiesKeys = Object.keys(properties || [])
    const required = schema?.data?.definitions?.document?.required

    setSchemaRequiredKeys(required)
    setSchemaNotRequiredKeys(filter(propertiesKeys, (key) => !required?.includes(key)))
  }, [schema])

  const documentSchemaProperties = schema?.data?.definitions?.document?.properties
  const documentSchemaDefinitions = schema?.data?.definitions

  return (
    <Page
      sticky
      feature="distribution_packages_mapping_rules"
      title={distributionPackage?.data?.name}
      loading={isLoading}
      working={mainLoading}
      subtitle={
        <DistributionPackagesSubtitle
          allowCopy={true}
          id={distributionPackage?.data?.id}
          connectionId={connectionId}
          tag={distributionPackage?.data?.tag}
          lastUpdated={distributionPackage?.data?.lastUpdated}
          connectionTitle={channelTitle}
          schemaId={channelSchemaId}
          schemaTitle={schemaTitle}
        />
      }
      actions={
        <>
          <PageAction to={`/distribution-packages/${id}/general`} label="Back" icon="distributionPackages" />
          <SchemaAttributeOverview
            schemaRequiredKeys={schemaRequiredKeys}
            schemaNotRequiredKeys={schemaNotRequiredKeys}
            showNotRequired={true}
            documentSchemaProperties={documentSchemaProperties}
            documentSchemaDefinitions={documentSchemaDefinitions}
          />
        </>
      }
    >
      <CardList loading={isLoading} css={styles.content}>
        <MappingForm
          rules={mappingRules}
          schema={schema?.data}
          sourceDocumentAttributes={[...sourceDocumentAttributes, ...sourceDocumentGroupSourceDocumentAttributes]}
          sourceDocuments={[...sourceDocumentList, ...sourceDocumentGroupSourceDocumentList]}
          distributionPackageId={distributionPackage?.data?.id}
          documentKey={distributionPackage?.data?.documentKey}
          documentRulesGrouped={documentRulesGrouped}
          sourceDocumentRulesGrouped={sourceDocumentRulesGrouped}
          setMainLoading={setMainLoading}
        />
      </CardList>
    </Page>
  )
}

const styles: any = {
  button: {
    padding: '1rem 0.7rem',
    textTransform: 'uppercase',
    letterSpacing: '-1.4px',
    fontSize: 15,
    fontWeight: 'bold',
    borderRadius: 5,
    backgroundColor: COLORS.blue60,
    borderColor: COLORS.blue80,
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 2,
    border: 0,
  },

  content: {
    border: 0,
    boxShadow: 'none',
    // marginTop: 50,
    padding: 0,
    display: 'grid',
    gridGap: '2rem',
    position: 'relative',
    gridAutoColumns: 'auto 340px',
    gridAutoFlow: 'column',
  },
}

export default DistributionPackageEditMappingRulesPage
