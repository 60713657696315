import React from 'react'

import { COLORS } from '../../../../theme'

import PageSubtitle from '../PageSubtitle'
import Info from '../../../Info'
import InfoTag from '../../../InfoTag'

export type Props = {
  allowCopy?: boolean
  id?: string
  name?: string
  documentKey?: string
  tag?: string
  lastUpdated?: number
}

const SourceDocumentGroupSubtitle: React.FC<Props> = ({ allowCopy, id, name, documentKey, tag, lastUpdated }) => {
  return (
    <PageSubtitle allowCopy={allowCopy} id={id} lastUpdated={lastUpdated}>
      {documentKey && <Info label={documentKey} tooltip={'Document key'} color={COLORS.yellow80} icon="key" />}
      {tag && <InfoTag label={tag} />}
    </PageSubtitle>
  )
}

export default SourceDocumentGroupSubtitle
