import React from 'react'
import { __RouterContext as RouterContext } from 'react-router'
import { CircleSpinner } from 'react-spinners-kit'

import Icon from './Icon'
import Glyph from './Glyph'
import { COLORS } from '../theme'
import { Glyph as GlyphType, Icon as IconType } from '../declarations'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'

const isModifiedEvent = (event) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

type Props = {
  onClick?: Function
  target?: '_self' | '_blank'
  to?: URL | string
  href?: URL
  replace?: boolean
  label?: string | Element | EmotionJSX.Element
  icon?: IconType
  iconSize?: number
  glyph?: GlyphType
  glyphSize?: number
  glyphColor?: string
  loading?: boolean
  disabled?: boolean
}

const Button: React.FC<Props> = ({
  onClick,
  target,
  to,
  href,
  replace,
  label = '',
  icon,
  iconSize = 25,
  glyph,
  glyphSize = 25,
  glyphColor,
  loading,
  disabled,
  ...rest
}) => {
  const handleClick = (event, history) => {
    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      (!target || target === '_self') && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) && // ignore clicks with modifier keys
      to
    ) {
      event.preventDefault()

      // if already on the route, don't do anything
      if (history?.location?.pathname === to || (typeof to === 'object' && history?.location?.pathname === to.pathname)) {
        if (onClick) onClick(event)
        return
      }

      const method = replace ? history.replace : history.push

      method(to)
    }

    if (onClick) onClick(event)
  }

  return (
    <RouterContext.Consumer>
      {(context) => {
        return (
          <button css={styles.button} onClick={(event) => handleClick(event, context?.history)} disabled={disabled} {...rest}>
            {!loading && (
              <>
                {icon && <Icon icon={icon} size={iconSize} css={{ marginRight: '0.5rem' }} />}
                {glyph && <Glyph glyph={glyph} size={glyphSize} css={{ marginRight: '0.5rem' }} color={glyphColor} />}
                <div css={styles.label}>{label}</div>
              </>
            )}

            {loading && <CircleSpinner color={COLORS.purpleTitleLight} size={20} />}
          </button>
        )
      }}
    </RouterContext.Consumer>
  )
}

const styles: any = {
  button: {
    height: 37,
    backgroundColor: COLORS.purpleTitle,
    border: `2px solid ${COLORS.purpleTitleLight}`,
    color: COLORS.white,
    fontSize: 16,
    borderRadius: 4,
    fontFamily: 'Amiko',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:disabled': {
      opacity: 0.4,
      pointerEvents: 'none',
    },
  },

  label: {
    textAlign: 'start',
  },
}

export default Button
