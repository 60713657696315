import { store } from '../setup/store'
import { transformWebsocketMessage } from '../actions/mutations/websockets'
import { notify } from './notifications'

const wsUrl = process.env.REACT_APP_VENZEE_WS_BASE_URL

let connection: WebSocket | undefined
let timeout: NodeJS.Timeout

export const connect = (token) => {
  if (!connection) {
    try {
      connection = new WebSocket(`${wsUrl}?access_token=${token}`)

      // connection.addEventListener('open', () => console.log('ws connection is connected'))
      // connection.addEventListener('close', () => console.log('ws connection is closed'))
      // connection.addEventListener('error', (e) => console.error('ws connection is in error', e))

      connection.addEventListener('message', (e) => {
        const event = JSON.parse(e.data)
        const action = transformWebsocketMessage(event)

        if (action.type) store.dispatch(action)

        idleCheck()
      })

      idleCheck()
    } catch {
      notify(null, null, null, 'Server sync is off')
    }
  }
}

export const disconnect = () => {
  if (connection) {
    clearIdleCheck()

    connection.close()
    connection = undefined
  }
}

export const ping = () => {
  if (connection) {
    const payload = {
      action: 'ping',
    }

    connection.send(JSON.stringify(payload))
  }
}

const idleCheck = () => {
  clearIdleCheck()

  timeout = setTimeout(() => {
    ping()
  }, 60000)
}

const clearIdleCheck = () => {
  if (timeout) clearInterval(timeout)
}
