import React from 'react'

const PlusGlyph = ({ color, ...props }) => (
  <svg {...props}>
    <path
      d="M12 12H6M12 6V12V6ZM12 12V18V12ZM12 12H18H12Z"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export default PlusGlyph
