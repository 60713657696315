import React from 'react'

import ListItem from '../ListItem'

import { Connection } from '../../declarations'
import ConnectionsSubtitle from '../layout/page/subtitle/ConnectionsSubtitle'
import { FEATURES } from '../../theme'

export type Props = {
  connection: Connection
}

const ConnectionListItem: React.FC<Props> = ({ connection, ...rest }) => {
  if (!connection?.parameters) return null

  const { key, id, parameters, tag, lastUpdated, status } = connection
  const { key: paramKey } = parameters

  return (
    <ListItem label={key} icon={FEATURES.connections.icon} tag={tag} id={id} {...rest}>
      <ConnectionsSubtitle allowCopy={false} id={id} channelKey={paramKey} tag={tag} lastUpdated={lastUpdated} status={status} isList />
    </ListItem>
  )
}

export default ConnectionListItem
