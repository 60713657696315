import React from 'react'

import { BRAND } from '../theme'

// import { Icon as IconType } from '../declarations/types'

type Props = {
  icon?: string
  width?: number | string
  height?: number | string
} & React.HTMLAttributes<HTMLImageElement>

const Brand: React.FC<Props> = ({ icon = 'default', width = 24, height = 10, className }) => {
  let iconObj: any = {}

  if (typeof icon === 'string') iconObj = BRAND[icon]
  else if (typeof icon === 'object') iconObj = icon

  const styles: any = {
    width: width,
    height: height,
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    pointerEvents: 'none',
    flex: '0 0 auto',
  }

  return <img src={iconObj?.file} css={styles} alt={iconObj?.alt || 'Brand'} width={width} height={height} className={className} />
}

export default Brand
