import React from 'react'

const LightningGlyph = (props) => {
  const { color } = props
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5601 1.2551C13.8038 1.33171 14.0166 1.48404 14.1676 1.68992C14.3187 1.89581 14.4002 2.14453 14.4001 2.3999V8.3999H19.2001C19.4196 8.3998 19.635 8.45991 19.8227 8.57369C20.0104 8.68746 20.1633 8.85055 20.2648 9.04519C20.3662 9.23983 20.4124 9.45858 20.3982 9.67762C20.3839 9.89666 20.3099 10.1076 20.1841 10.2875L11.7841 22.2875C11.6379 22.4969 11.4287 22.6543 11.187 22.7366C10.9452 22.8189 10.6834 22.822 10.4398 22.7453C10.1961 22.6686 9.98335 22.5161 9.83233 22.3101C9.68132 22.1041 9.59998 21.8553 9.60014 21.5999V15.5999H4.80014C4.58064 15.6 4.36532 15.5399 4.1776 15.4261C3.98989 15.3123 3.83698 15.1492 3.73551 14.9546C3.63405 14.76 3.58791 14.5412 3.60213 14.3222C3.61635 14.1031 3.69037 13.8922 3.81614 13.7123L12.2161 1.7123C12.3626 1.50324 12.5718 1.34631 12.8135 1.26431C13.0552 1.18231 13.3167 1.1795 13.5601 1.2563V1.2551Z"
        fill={color}
      />
    </svg>
  )
}

export default LightningGlyph
