import { getActionType } from '../../hooks/useAPI'
import { normalize, parseDistributionPackagePayload } from './'
import { history } from '../../setup/store'

export const updateDistributionPackage = (distributionPackage) => {
  const { id } = distributionPackage

  const data = normalize(parseDistributionPackagePayload(distributionPackage))

  return {
    // We use PATCH here because is the type of the action
    type: getActionType('DISTRIBUTION_PACKAGE', 'PATCH'),
    request: {
      url: `/distribution-packages/${id}`,
      method: 'patch',
      data,
    },
    meta: {
      mutations: {
        // We use GET as method, because we want to affect the current
        // GET_DISTRIBUTION_PACKAGE bucket as a result of the mutation
        [getActionType('DISTRIBUTION_PACKAGE', 'GET')]: {
          updateData: (_, mutated) => {
            return mutated
          },
        },
      },
    },
  }
}

export const createDistributionPackage = (distributionPackage) => {
  return {
    type: getActionType('DISTRIBUTION_PACKAGE', 'POST'),
    request: {
      url: '/distribution-packages',
      method: 'post',
      data: distributionPackage,
    },
    meta: {
      mutations: {
        [getActionType('DISTRIBUTION_PACKAGES', 'GET')]: {
          updateData: (current, mutated) => {
            return { ...current, [mutated.id]: mutated }
          },
        },
        [getActionType('DISTRIBUTION_PACKAGE', 'POST')]: {
          updateData: (current, mutated) => {
            return { ...current, ...mutated }
          },
        },
      },

      onSuccess: (newDistributionPackage) => {
        history.push(`/distribution-packages/${newDistributionPackage?.data?.id}`)
        return newDistributionPackage
      },
    },
  }
}

export const deleteDistributionPackage = (distributionPackage) => {
  const { id } = distributionPackage

  return {
    type: getActionType('DISTRIBUTION_PACKAGE', 'DELETE'),
    request: {
      url: `/distribution-packages/${id}`,
      method: 'delete',
    },
    meta: {
      mutations: {
        [getActionType('DISTRIBUTION_PACKAGE', 'GET')]: () => null,
      },
    },
  }
}

export const deleteDistributionPackageSourceDocuments = (distributionPackage) => {
  const { id } = distributionPackage

  return {
    type: getActionType('DISTRIBUTION_PACKAGE_SOURCE_DOCUMENTS', 'DELETE'),
    request: {
      url: `/distribution-packages/${id}/source-documents`,
      method: 'delete',
    },
    meta: {},
  }
}

export const clearDistributionPackage = () => ({
  type: getActionType('DISTRIBUTION_PACKAGE', 'CLEAR'),
  meta: {
    mutations: {
      [getActionType('DISTRIBUTION_PACKAGE', 'GET')]: () => null,
    },
  },
})
