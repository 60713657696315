import React from 'react'

export const TabsContext = React.createContext({})

const withTabsContext = (Wrapped) => (props) => (
  <TabsContext.Consumer>
    {(context) => <Wrapped {...props} currentTab={context.currentTab} onSelect={context.onSelect} />}
  </TabsContext.Consumer>
)

export default withTabsContext
