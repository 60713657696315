import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import ListLoader from './ListLoader'

// This is due to an internal Infinite scroll component prop
declare type Fn = () => any

type Props = {
  next: Fn
  dataLength: number
  loader: React.FC
  hasMore: boolean
  scrollableTarget: string
}

const Pagination: React.FC<Props> = ({ next, dataLength, loader = <ListLoader />, hasMore, scrollableTarget = 'page', children }) => {
  return (
    <InfiniteScroll next={next} dataLength={dataLength} loader={loader} hasMore={hasMore} scrollableTarget={scrollableTarget}>
      {children}
    </InfiniteScroll>
  )
}

export default Pagination
