import React from 'react'
import { SourceDocumentSchema } from '../../declarations'

import ListItem from '../ListItem'
import SourceDocumentGroupSubtitle from '../layout/page/subtitle/SourceDocumentGroupSubtitle'

type Props = {
  sourceDocumentSchema: SourceDocumentSchema
  to?: string
}

const SourceDocumentSchemaListItem: React.FC<Props> = ({ sourceDocumentSchema, ...rest }) => {
  const { name, lastUpdated, tag } = sourceDocumentSchema

  return (
    <ListItem label={name} icon="sourceDocumentSchemas" tag={tag} {...rest}>
      <SourceDocumentGroupSubtitle lastUpdated={lastUpdated} />
    </ListItem>
  )
}
export default SourceDocumentSchemaListItem
