export const TOOLTIP_MAPPING_TYPES = {
  string: 'A string is made up of characters, and can include letters, words, phrases, or symbols',
  integer: 'Whole-valued positive or negative number or 0. The integers are generated from the set of counting numbers 1, 2, 3, etc.',
  default: '-',
}

export const TOOLTIP_MAPPING_RULES = {
  copy: 'The Copy rule copies the value of an attribute of the source document',
  literal: 'The Literal rule lets you define a fixed value',
  convert: 'The Convert rule can convert a value to a specific type',
  replace: 'The Replace rule can find and replace a given value',
}
