import React from 'react'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import isEmpty from 'lodash/isEmpty'
import isDefined from '@codewell/is-defined'
import toArray from 'lodash/toArray'

import List from '../../../components/List'

import useAPI, { getActionType } from '../../../hooks/useAPI'
import { DistributionPackage } from '../../../declarations'
import SourceDocumentListItem from '../../../components/list/SourceDocumentListItem'

type Props = {
  release: any
  distributionPackage: DistributionPackage
}

const ValidDocumentsTabPage: React.FC<Props> = ({ release, distributionPackage }) => {
  const validDocuments = useSelector(getQuerySelector({ type: getActionType('VALID_SOURCE_DOCUMENTS') }))

  const validDocumentsList = toArray(validDocuments?.data)

  useAPI(
    'VALID_SOURCE_DOCUMENTS',
    `/staged-releases/${release?.id}/results/valid-documents`,
    isDefined(validDocuments?.data),
    'GET',
    false,
    'key',
    null,
    'validDocuments',
  )

  const isLoading = validDocuments?.pending > 0
  const isEmptyList = isEmpty(validDocumentsList) || validDocuments?.data?.count === 0

  return (
    <List feature="valid_documents" loading={isLoading} empty={isEmptyList} gridGap="0">
      {validDocumentsList &&
        validDocumentsList.map((validDocument) => (
          <SourceDocumentListItem
            feature="valid_documents"
            action={validDocument?.action}
            key={validDocument?.id}
            documentKey={distributionPackage?.documentKey}
            sourceDocument={validDocument}
            to={`/staged-releases/${release?.id}/valid-documents/${validDocument?.key}`}
            label={validDocument?.key}
          />
        ))}
    </List>
  )
}

export default ValidDocumentsTabPage
