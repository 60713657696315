import React from 'react'

import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import reverse from 'lodash/reverse'

import Page from '../../components/layout/page/Page'
import PageAction from '../../components/layout/page/PageAction'
import CardSection from '../../components/CardSection'
import NewSourceDocumentGroup from '../../components/overlays/new/NewSourceDocumentGroup'
import SourceDocumentGroupListItem from '../../components/list/SourceDocumentGroupListItem'
import ListLoader from '../../components/ListLoader'

import useAPI, { getActionType } from '../../hooks/useAPI'

const SourceDocumentGroupsPage: React.FC = () => {
  const sourceDocumentGroups = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUPS') }))

  const sourceDocumentGroupsList = sourceDocumentGroups?.data

  useAPI('SOURCE_DOCUMENT_GROUPS', '/source-document-groups')

  const isLoading = sourceDocumentGroups?.pending > 0
  const isEmptyList = (isEmpty(sourceDocumentGroupsList) || sourceDocumentGroups?.data?.count === 0) && !isLoading

  return (
    <Page
      feature="source_document_groups"
      title="Source Document Groups"
      hideBackButton
      actions={<PageAction to="/source-document-groups/new" label="Create New" icon="sourceDocumentsGroup" />}
    >
      <CardSection
        feature="source_document_groups"
        empty={isEmptyList}
        loading={isLoading}
        emptyMessage="There are no source document groups created yet"
        customLoader={() => <ListLoader />}
      >
        {sourceDocumentGroupsList &&
          reverse(sortBy(sourceDocumentGroupsList, 'lastUpdated')).map((sourceDocumentGroup) => (
            <SourceDocumentGroupListItem
              key={sourceDocumentGroup.id}
              sourceDocumentGroup={sourceDocumentGroup}
              to={`/source-document-groups/${sourceDocumentGroup.id}`}
            />
          ))}
      </CardSection>
      <Switch>
        <Route exact path="/source-document-groups/new" component={NewSourceDocumentGroup} />
      </Switch>
    </Page>
  )
}

export default SourceDocumentGroupsPage
