import { history } from '../../setup/store'
import { notify } from '../../modules/notifications'

export const wsUpdateConnectionModel = (event) => {
  return {
    type: 'WS_UPDATE_CONNECTION_MODEL',
    request: {
      url: `/connections/${event.connectionId}`,
      method: 'GET',
    },
    meta: {
      mutations: {
        GET_CONNECTION: {
          updateData: (current, mutated) => ({ ...current, ...mutated }),
        },

        GET_CONNECTIONS: {
          updateData: (current, mutated) => ({ ...current, [mutated.id]: mutated }),
        },
      },
      onSuccess: (response) => {
        notify('CONNECTION', 'UPDATED', response.data, null, () => history.push(`/connections/${response.data.id}`))

        return response
      },
    },
  }
}

export const wsUpdateStagedReleaseModel = (event) => {
  return {
    type: 'WS_UPDATE_STAGED_RELEASE_MODEL',
    request: {
      url: `/staged-releases/${event._embedded.stagedRelease.id}`,
      method: 'GET',
    },
    meta: {
      mutations: {
        GET_STAGED_RELEASE: {
          updateData: (current, mutated) => ({ ...current, ...mutated }),
        },

        GET_STAGED_RELEASES: {
          updateData: (current, mutated) => ({ ...current, [mutated.id]: mutated }),
        },
      },
      onSuccess: (response) => {
        notify('STAGED_RELEASE', 'UPDATED', response.data, null, () => history.push(`/staged-releases/${response.data.id}`))

        return response
      },
    },
  }
}

export const wsUpdateReleaseModel = (event) => {
  return {
    type: 'WS_UPDATE_RELEASE_MODEL',
    request: {
      url: `/releases/${event._embedded.release.id}`,
      method: 'GET',
    },
    meta: {
      mutations: {
        GET_RELEASE: {
          updateData: (current, mutated) => ({ ...current, ...mutated }),
        },

        GET_RELEASES: {
          updateData: (current, mutated) => ({ ...current, [mutated.id]: mutated }),
        },
      },
      onSuccess: (response) => {
        notify('RELEASE', 'UPDATED', response.data, null, () => history.push(`/releases/${response.data.id}`))

        return response
      },
    },
  }
}

// We parse the ws event and set an action for it
export const transformWebsocketMessage = (event) => {
  if (event.connectionId) return wsUpdateConnectionModel(event)
  if (event.jobId) {
    if (event._embedded.release) return wsUpdateReleaseModel(event)
    if (event._embedded.stagedRelease) return wsUpdateStagedReleaseModel(event)
  }

  return event
}

// We can use the following method whenever the Mesh API embed the entity model within the websocket events
// Right now, we just have a hint that something changed within a certain entity, and there we call the
// Mesh API to retrieve the latest model. That's what wsUpdateConnectionModel is doing.

export const wsUpdateConnection = (connection) => {
  return {
    type: 'WS_UPDATE_CONNECTION',
    meta: {
      mutations: {
        GET_CONNECTION: {
          updateData: (current) => ({ ...current, ...connection }),
          local: true,
        },

        GET_CONNECTIONS: {
          updateData: (current) => ({ ...current, data: { ...current.data, [connection.connectionId]: connection } }),
          local: true,
        },
      },
    },
  }
}
