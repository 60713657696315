import React from 'react'

import { FEATURES } from '../../../../theme'

import PageSubtitle from '../PageSubtitle'
import InfoLink from '../../../InfoLink'

export type Props = {
  allowCopy?: boolean
  id: string
  connectionTitle?: string
  connectionId?: string
  schemaTitle?: string
  schemaId?: string
  tag?: string
  lastUpdated?: number
}

const DistributionPackagesSubtitle: React.FC<Props> = ({
  allowCopy,
  id,
  connectionTitle,
  connectionId,
  schemaTitle,
  schemaId,
  tag,
  lastUpdated,
}) => {
  return (
    <PageSubtitle allowCopy={allowCopy} lastUpdated={lastUpdated}>
      {connectionTitle && (
        <InfoLink
          icon="connections"
          to={`/connections/${connectionId}`}
          label={
            <div>
              <span css={{ opacity: 0.6 }}>Connected through </span>
              {connectionTitle}
            </div>
          }
          color={FEATURES.connections.color}
          tooltip="Connection"
        />
      )}
      {/* {allowCopy && schemaTitle && (
        <InfoCopyClipboard
          label={schemaCategoryEllipsis(schemaTitle, 8)}
          color={FEATURES.channel_schema.color}
          textToCopy={schemaId || ''}
          tooltip={schemaTitle}
        />
      )} */}
      {/* {!allowCopy && schemaTitle && (
        <Info
          icon={FEATURES.channel_schema.icon}
          label={idShorthand(schemaTitle)}
          color={FEATURES.channel_schema.color}
          tooltip="Channel Schema Id"
        />
      )} */}
      {/* {tag && <InfoTag label={tag} />} */}
    </PageSubtitle>
  )
}

export default DistributionPackagesSubtitle
