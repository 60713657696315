import React from 'react'

const CheckGlyph = ({ color, ...rest }) => (
  <svg viewBox="0 0 14 14" fill="none" {...rest}>
    <path
      d="M4.66665 4.08333H3.49998C3.19056 4.08333 2.89381 4.20625 2.67502 4.42504C2.45623 4.64383 2.33331 4.94058 2.33331 5.25V11.0833C2.33331 11.3928 2.45623 11.6895 2.67502 11.9083C2.89381 12.1271 3.19056 12.25 3.49998 12.25H8.16665C8.47607 12.25 8.77281 12.1271 8.9916 11.9083C9.2104 11.6895 9.33331 11.3928 9.33331 11.0833V9.91667M4.66665 4.08333V8.75C4.66665 9.05942 4.78956 9.35616 5.00836 9.57496C5.22715 9.79375 5.52389 9.91667 5.83331 9.91667H9.33331L4.66665 4.08333ZM4.66665 4.08333V2.91667C4.66665 2.60725 4.78956 2.3105 5.00836 2.09171C5.22715 1.87292 5.52389 1.75 5.83331 1.75H8.50848C8.66318 1.75003 8.81153 1.81151 8.9209 1.92092L11.4957 4.49575C11.6051 4.60512 11.6666 4.75347 11.6666 4.90817V8.75C11.6666 9.05942 11.5437 9.35616 11.3249 9.57496C11.1061 9.79375 10.8094 9.91667 10.5 9.91667H9.33331L4.66665 4.08333Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CheckGlyph
