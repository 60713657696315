import React from 'react'

const InputGroup: React.FC = ({ children }) => {
  return <div css={styles}>{children}</div>
}

const styles = {
  display: 'grid',
  gridGap: '1rem',

  padding: '1.5rem',
}

export default InputGroup
