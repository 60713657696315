import { lighten, transparentize, desaturate, darken } from 'polished'

const COLORS = {
  default: 'hotpink',

  purple: '#5B2C84',
  yellow: '#F7B106',
  blue: '#327DF5',
  red: '#F75445',
  green: '#4EC144',
  black: '#000',
  gray: '#828282',
  berryBlue: '#21025A',
  orchid: '#71115B',
  white: '#FFF',
  navbar: '#FDFCFD',

  blue80: '#6299E9',
  blue60: '#81AEEE',
  blue40: '#A1C2F2',
  blue20: '#C0D6F6',
  blueLight: '#E0EBFB',

  red80: '#F97664',
  red60: '#FA9183',
  red40: '#FCADA2',
  red20: '#FDC8C1',
  redLight: '#FEE4E0',

  green80: '#6CCB63',
  green60: '#89D682',
  green40: '#A6E0A1',
  green20: '#C4EAC1',
  greenLight: '#E2F5E0',

  black80: '#333333',
  black40: '#4F4F4F',

  gray80: '#BDBDBD',
  gray40: '#E0E0E0',
  grayLight: '#F2F2F2',

  purple80: '#783AAE',
  purple60: '#9358C7',
  purple40: '#AE82D5',
  purple20: '#C9ACE3',
  purpleLight: '#E4D5F1',

  purplePale: '#C0B6C8',
  purplePale80: '#CBC2D1',
  purplePale60: '#D5CEDA',
  purplePale40: '#E0DBE4',
  purplePale20: '#ECE8EE',
  purplePaleLight: '#F5F3F6',
  purplePaleLightest: lighten(0.005, '#F5F3F6'),
  purpleTitle: '#885D98',
  purpleTitleLight: '#A481B1',
  purpleTitleShaded: '#AC99B2',
  contentBackground: '#FAF9FB',

  orchid80: '#A81987',
  orchid60: '#A81987',
  orchid40: '#E65AC6',
  orchid20: '#EE91D9',
  orchidLight: '#F7C8EC',

  orchidPale: '#A37599',
  orchidPale80: '#B28CAA',
  orchidPale60: '#C2A3BB',
  orchidPale40: '#D1BACC',
  orchidPale20: '#E0D1DD',
  orchidPaleLight: '#F0E8EE',

  berryBlue80: '#3A049E',
  berryBlue60: '#5305E2',
  berryBlue40: '#7933FA',
  berryBlue20: '#A677FC',
  berryBlueLight: '#D2BBFD',

  yellow80: '#FABF2E',
  yellow60: '#FBCC58',
  yellow40: '#FCD882',
  yellow20: '#FDE5AB',
  yellowLight: '#FEF2D5',
  yellowLightest: '#FFFCF5',

  divider: 'gray',
  shadow: transparentize(0.5, desaturate(0.3, darken(0.3, '#C0B6C8'))),
  backdrop: transparentize(0.5, desaturate(0.3, darken(0.3, '#C0B6C8'))),
  transparent: 'rgba(0,0,0,0)',
  buttonShadow: '#F3EFF6',
}

export const NEW_COLORS = {
  purplePale: '#A794B8',
  purplePaleLight: '#F7F6F9',
  purplePaleLightest: '#FCFCFD',
}

export const getColor = (color) => {
  return COLORS[color] || COLORS.default
}

export default COLORS
