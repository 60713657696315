import React from 'react'
import { Connection } from '../declarations'

import EditableFieldInputSelect from './EditableFieldInputSelect'

type Props = {
  label?: string
  className?: string
  defaultValues?: object
  model: string
  setMutationData: Function
  disabled: boolean
  connection: Connection
  connections: Connection[]
  channel?: any
  loading?: boolean
} & React.ReactNode

const EditableConnectionsSelector: React.FC<Props> = ({ label = 'Connection', channel, connection, connections, ...rest }) => {
  const options = React.useMemo(
    () =>
      connections?.map?.((connection) => {
        const title = connection?._embedded?.channel?.title
        const tag = connection?.tag
        return (
          <option key={`connection_${connection.id}`} value={connection.id} hidden={connection?.status !== 'connection.established'}>
            {`${title}${tag ? ` - ${tag}` : ''}`}
          </option>
        )
      }),
    [connections],
  )

  return (
    <EditableFieldInputSelect
      label={label}
      labelValue={`${connection?._embedded?.channel?.title}${connection?.tag ? ` - ${connection?.tag}` : ''}`}
      options={options}
      {...rest}
    />
  )
}

export default EditableConnectionsSelector
