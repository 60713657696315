import React from 'react'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import isEmpty from 'lodash/isEmpty'
import isDefined from '@codewell/is-defined'

import List from '../../../components/List'
import Pagination from '../../../components/Pagination'
import ListLoader from '../../../components/ListLoader'

import useAPI, { getActionType } from '../../../hooks/useAPI'
import { DistributionPackage } from '../../../declarations'
import ErrorTranslatedDocumentListItem from '../../../components/list/ErrorTranslatedDocumentListItem'

type Props = {
  release: any
  distributionPackage: DistributionPackage
}

const ErrorDocumentsTabPage: React.FC<Props> = ({ release, distributionPackage }) => {
  const [getMoreState, setGetMoreState] = React.useState(false)
  const [errorDocumentsPages, setErrorDocumentsPages] = React.useState<DistributionPackage[]>([])
  const errorDocuments = useSelector(getQuerySelector({ type: getActionType('ERROR_SOURCE_DOCUMENTS') }))
  const errorDocumentsList = errorDocuments?.data?._embedded?.errors || []
  const errorDocumentsNextPage = useSelector(getQuerySelector({ type: getActionType('ERROR_SOURCE_DOCUMENTS_PAGES') }))
  const nextErrorDocumentsPage =
    (errorDocumentsNextPage?.data ? errorDocumentsNextPage?.data?._links?.next?.href : errorDocuments?.data?._links?.next?.href) || ''

  useAPI(
    'ERROR_SOURCE_DOCUMENTS',
    `/releases/${release?.id}/results/errors?offset=0&limit=20`,
    isDefined(errorDocuments?.data),
    'GET',
    true,
    'key',
    null,
    'errors',
  )
  useAPI(
    'ERROR_SOURCE_DOCUMENTS_PAGES',
    nextErrorDocumentsPage,
    !nextErrorDocumentsPage || !getMoreState || errorDocumentsNextPage?.pending > 0,
    'GET',
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    [getMoreState],
  )
  React.useEffect(() => {
    if (getMoreState) setGetMoreState(false)
  }, [getMoreState])

  React.useEffect(() => {
    if (errorDocumentsNextPage?.data)
      setErrorDocumentsPages([...errorDocumentsPages, ...(errorDocumentsNextPage?.data?._embedded?.errors || [])])
  }, [errorDocumentsNextPage?.data?._embedded?.errors])

  const isLoading = errorDocuments?.pending > 0
  const isEmptyList = isEmpty(errorDocumentsList) || errorDocuments?.data?.count === 0

  const completeErrorDocumentsList = [...errorDocumentsList, ...errorDocumentsPages]

  const items = completeErrorDocumentsList.map((errorDocument) => (
    <ErrorTranslatedDocumentListItem errorDocument={errorDocument} to={`/releases/${release?.id}/errors/${errorDocument?.key}`} />
  ))

  return (
    <List feature="error_documents" loading={isLoading} empty={isEmptyList} gridGap="0">
      {completeErrorDocumentsList && (
        <Pagination
          next={() => setGetMoreState(true)}
          dataLength={items.length}
          loader={<ListLoader />}
          hasMore={!!nextErrorDocumentsPage}
          scrollableTarget="page"
        >
          {items}
        </Pagination>
      )}
    </List>
  )
}

export default ErrorDocumentsTabPage
