import '@babel/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

// Removed until reimplementation
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'

import 'react-virtualized/styles.css'
import packageInfo from '../package.json'

// Import root app
import Entry from './scenes/index'

import { history, store } from './setup/store'
import WebFont from 'webfontloader'

WebFont.load({
  google: {
    families: ['Amiko:wght@400;600;700', 'sans-serif'],
  },
})

// Removed until reimplementation
// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://2b51ba49722540f3a6f59096894b3337@o926433.ingest.sentry.io/5875844',
//     integrations: [
//       new Integrations.BrowserTracing(),
//       // , new SentryRRWeb()
//     ],
//     tracesSampleRate: 1.0,
//     release: `${packageInfo.name}-${packageInfo.version} (${process.env.NODE_ENV})`,
//   })
// }

// App
const MOUNT_NODE = document.getElementById('app')
const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Entry />
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE,
  )
}

if (module.hot && process.env.NODE_ENV === 'development') {
  module.hot.accept(['./scenes'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render()
  })
}

render()
