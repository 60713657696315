import React from 'react'
import { lighten } from 'polished'

import { COLORS } from '../../theme'

import FormButton from '../FormButton'

type Props = {
  [key: string]: any
}

const DialogAction: React.FC<Props> = ({ ...rest }) => {
  return <FormButton css={styles.root} {...rest} />
}

const styles = {
  root: {
    background: 'transparent',
    borderRadius: 0,
    color: COLORS.purpleTitle,
    border: 0,
    borderRight: `1px solid ${COLORS.grayLight}`,
    textTransform: 'uppercase',
    height: '3rem',
    fontSize: '1.2rem',

    '&:first-child': {
      borderBottomLeftRadius: 4,
    },

    '&:last-child': {
      borderRight: 0,
      borderBottomRightRadius: 4,
    },

    '&:hover': {
      background: lighten(0.02, COLORS.grayLight),
    },
  },
}

export default DialogAction
