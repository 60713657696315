import { DateTime } from 'luxon'
import { request } from './axios'

export const SIGNED_IN = 'global/SIGNED_IN'
export const SIGN_OUT = 'global/SIGN_OUT'

const authParams = new URLSearchParams()
authParams.append('grant_type', 'client_credentials')

export const login = async (auth) => {
  try {
    const results = await request().post('/auth-tokens', authParams.toString(), {
      auth: {
        username: auth.username,
        password: auth.password,
      },
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    })

    // set the access token
    localStorage.setItem('vz.at', results.data.access_token)
    // set the expires at
    localStorage.setItem('vz.ea', DateTime.local().plus({ seconds: results.data.expires_in }).toISO())

    return results
  } catch (errors) {
    throw errors
  }
}

export const logout = async () => {
  localStorage.removeItem('vz.at') // access token
  localStorage.removeItem('vz.ea') // expires at
  localStorage.removeItem('vz.tn') // tenants
  localStorage.removeItem('vz.stn') // selected tenant
  localStorage.removeItem('vz.st') // state
  localStorage.removeItem('vz.imp') // impersonate
  localStorage.removeItem('vz.as') // login as

  return true
}
