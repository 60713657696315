import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { getQuerySelector } from '@redux-requests/core'
import isDefined from '@codewell/is-defined'

import { COLORS } from '../../theme'

import useAPI, { getActionType } from '../../hooks/useAPI'

import Overlay from '../Overlay'
import Portal from '../Portal'
import PageHeader from '../layout/page/PageHeader'
import Info from '../Info'
import InfoLink from '../InfoLink'
import Log from '../Log'

const ValidTranslatedDocumentOverlay = ({ distributionPackage, release, onClose, baseURL = '/releases' }) => {
  const history = useHistory()
  const { validDocumentKey } = useParams()

  const validDocuments = useSelector(getQuerySelector({ type: getActionType('VALID_SOURCE_DOCUMENTS') }))
  const validDocument = validDocuments?.data?.[validDocumentKey]

  useAPI(
    'VALID_SOURCE_DOCUMENTS',
    `${baseURL}/${release?.id}/results/errors`,
    isDefined(validDocuments?.data),
    'GET',
    false,
    'key',
    null,
    'validDocuments',
  )

  if (!validDocument) return null

  const onCloseDefault = () => history.push(`${baseURL}/${release?.id}/valid-documents`)

  return (
    <Portal type="overlay">
      <Overlay onClose={onClose || onCloseDefault} css={styles.root}>
        <PageHeader title={validDocumentKey} icon="sourceDocuments" small>
          <Info label={`Action: ${validDocument.action}`} color={COLORS.green60} tooltip="Document Key" />
          <InfoLink label="Source Document" icon="sourceDocuments" to={`${baseURL}/${release?.id}/source-documents/${validDocumentKey}`} />
        </PageHeader>

        <div css={styles.content}>
          <Log src={validDocument} />
        </div>
      </Overlay>
    </Portal>
  )
}

const styles = {
  root: {
    flexShrink: 0,

    '& .dialog': {
      maxWidth: '60rem',
    },
  },
  content: {
    padding: '1.5rem',
    display: 'grid',
    gridGap: '1rem',
    overflowY: 'auto',
  },
  value: {
    borderRadius: 2,
    fontSize: '1.3rem',
    color: COLORS.purpleTitle,
  },
}

export default ValidTranslatedDocumentOverlay
