import React from 'react'
import ReactDOM from 'react-dom'
import { transparentize } from 'polished'
import { usePopper } from 'react-popper'
import { Placement } from '@popperjs/core'

import { COLORS } from '../theme'

type Props = {
  placement?: Placement
  offset?: [number | null | undefined, number | null | undefined]
  className?: string
  tooltip?: React.ReactNode
  message: string
  portal?: string
}

const DefaultTooltip: React.FC<Props> = ({ message }) => {
  return <div css={customStyles.root}>{message}</div>
}

const Tooltip: React.FC<Props> = ({
  children,
  message,
  tooltip,
  portal = '#portal-tooltip',
  placement = 'bottom',
  offset = [5, 5],
  className,
}) => {
  const [open, setOpen] = React.useState(false)
  const [referenceElement, setReferenceElement] = React.useState(null)
  const [popperElement, setPopperElement] = React.useState(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
  })

  const portalContainer = document.querySelector(portal)

  return (
    <div>
      <div ref={setReferenceElement} className={className} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        {children}
      </div>
      {open &&
        portalContainer &&
        (tooltip || message) &&
        ReactDOM.createPortal(
          <div ref={setPopperElement} css={{ ...styles.popper, ...customStyles.container }} {...attributes.popper}>
            {tooltip && tooltip}
            {!tooltip && <DefaultTooltip message={message} />}
          </div>,
          portalContainer,
        )}
    </div>
  )
}

const customStyles: any = {
  root: {
    fontSize: 14,
    letterSpacing: '-0.4px',
    textTransform: 'uppercase',
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.orchidPaleLight}`,
    padding: '0.4rem 0.5rem 0.35rem 0.5rem',
    lineHeight: '140%',
    borderRadius: 4,

    boxShadow: `
      0px 1px 1px ${transparentize(0.5, COLORS.purplePale40)},
      0px 2px 2px ${transparentize(0.6, COLORS.purplePale40)}
    `,

    width: 'auto',
    maxWidth: 320,
  },

  container: {
    zIndex: 10000,
  },
}

export default Tooltip
