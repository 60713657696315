import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'

import Page from '../../components/layout/page/Page'
import CardSection from '../../components/CardSection'
import Log from '../../components/Log'
import LogGroup from '../../components/LogGroup'
import InfoCopyClipboard from '../../components/InfoCopyClipboard'

import useAPI, { getActionType } from '../../hooks/useAPI'

const ConnectionPage: React.FC = () => {
  const { id } = useParams()

  const channel = useSelector(getQuerySelector({ type: getActionType('CHANNEL') }))

  useAPI('CHANNEL', `/channels/${id}`)

  const isLoading = channel?.pending > 0

  return (
    <Page
      feature="channel"
      title={`${channel?.data?.title || channel?.data?.key || ''}`}
      subtitle={<InfoCopyClipboard label={channel?.data?.key} textToCopy={channel?.data?.key} />}
      loading={isLoading}
      actions={<></>}
    >
      <CardSection feature="channel" notFound={channel?.error?.response?.status === 404} loading={isLoading}>
        <LogGroup>
          <Log label="channel data" src={channel} />
        </LogGroup>
      </CardSection>
    </Page>
  )
}

export default ConnectionPage
