import COLORS from './definitions/colors'

export const GLOBAL_STYLES = {
  'html, body': {
    fontFamily: 'Amiko, sans-serif',
    fontSize: 15,
    lineHeight: 1.4,
    color: COLORS.orchidPale,
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    background: COLORS.white,
    wordBreak: 'normal',

    width: '100%',
    height: '100%',
    overflow: 'hidden',

    scrollBehavior: 'smooth',
  },

  '#app': {
    // '@media (min-width: 600px)': {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    // },
  },

  'svg:not([fill])': {
    fill: 'auto',
  },

  '*, *:before, *:after': {
    boxSizing: 'inherit',
  },

  'audio, canvas, iframe, img, svg, video': {
    verticalAlign: 'inherit',
  },

  '.is-hidden': {
    display: 'none',
  },

  '.portals': {
    top: 0,
    left: 0,
    zIndex: 100,
    position: 'absolute',

    width: '100%',
    height: '100%',

    pointerEvents: 'none',

    '& > *': {
      position: 'relative',
    },
  },

  '#portal-sheet': {
    zIndex: 80,
    pointerEvents: 'all',
  },

  '#portal-dropdown': {
    zIndex: 80,
    position: 'static',
    pointerEvents: 'all',

    '> *': {
      zIndex: 101,
    },
  },

  '#portal-default': {
    zIndex: 100,
    pointerEvents: 'all',
  },

  '#portal-overlay': {
    zIndex: 100,
    pointerEvents: 'all',
  },

  '#portal-selector': {
    zIndex: 101,
    pointerEvents: 'all',
  },

  '#portal-dialog': {
    zIndex: 101,
    pointerEvents: 'all',
  },

  '#portal-iframe': {
    zIndex: 110,
    pointerEvents: 'all',
  },

  '#portal-tooltip': {
    zIndex: 110,
    pointerEvents: 'all',
  },

  '#portal-modals': {
    zIndex: 100,
    pointerEvents: 'all',
  },

  '#portal-avatar': {
    zIndex: 120,
    pointerEvents: 'none',
  },
}

export { default as COLORS, NEW_COLORS, getColor } from './definitions/colors'
export { default as ICONS, getIcon } from './definitions/icons'
export { default as GLYPHS, getGlyph } from './definitions/glyphs'
export { default as BRAND, getBrand } from './definitions/brand'
export { default as FEATURES, getFeature } from './definitions/features'
export { TOOLTIP_MAPPING_TYPES } from './definitions/tooltips'
export { SHADOW, HARD_SHADOW } from './definitions/shadow'
export { shadow } from './definitions/styles'
