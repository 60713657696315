import React, { ReactChild } from 'react'

import Animated from './Animated'
import Portal from './Portal'

type Props = {
  isDisabled?: string
  isOpen?: boolean
  children?: ReactChild[]
  overlay: ReactChild
  onOpen?: Function
  onClose?: Function
  portalType?: string
}

const SummonOverlay: React.FC<Props> = ({ isDisabled, isOpen, children, overlay, onOpen, onClose, portalType = 'overlay' }) => {
  const [open, setOpen] = React.useState(isOpen)

  React.useEffect(() => {
    isOpen ? setOpen(true) : setOpen(false)
  }, [isOpen])

  const handleOpen = (e) => {
    if (isDisabled) return
    if (onOpen) onOpen(e)

    setOpen(true)
    e.stopPropagation()
  }

  const close = () => {
    setOpen(false)
    if (onClose) onClose(false)
  }

  return (
    <>
      {React.Children.map(children, (child) => React.cloneElement(child, { onClick: handleOpen }))}

      <Portal type={portalType}>
        <Animated show={open} animation="fade">
          {React.cloneElement(overlay, { onClose: close, type: 'summon' })}
        </Animated>
      </Portal>
    </>
  )
}

export default SummonOverlay
