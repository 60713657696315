import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import clsx from 'clsx'
import { transparentize } from 'polished'

import { COLORS } from '../theme'

type Props = {
  loading?: boolean
  className?: string
  customLoader?: any
}

const Card: React.FC<Props> = ({ children, className, loading = false, customLoader }) => {
  const classNames = clsx({
    [className]: className,
    'is-loading': loading,
  })

  return (
    <div css={styles.root} className={classNames}>
      {!loading && <>{children}</>}

      {loading && !customLoader && (
        <div css={styles.cardSpinnerContainer}>
          <CircleSpinner size={34} color={COLORS.purplePale60} />
        </div>
      )}

      {loading && customLoader && <>{customLoader()}</>}
    </div>
  )
}

const styles = {
  root: {
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.purplePale60}`,
    borderRadius: 8,
    width: '100%',

    boxShadow: `0 3px 0 ${transparentize(0.8, COLORS.purpleTitleShaded)}`,

    overflow: 'hidden',

    '& .is-loading': {
      minHeight: 250,
    },
  },

  cardSpinnerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    height: 500,
  },
}

export default Card
