import React from 'react'

import { COLORS } from '../../theme'
import { isVenzeeUndefined } from '../../utils/functions'

import Info from '../Info'
import ListItem from '../ListItem'

import { DistributionPackage } from '../../declarations'
import DistributionPackagesSubtitle from '../layout/page/subtitle/DistributionPackagesSubtitle'

type distributionPackageExtendedType = {
  channelTitle: string
}

type Props = {
  distributionPackage: DistributionPackage & distributionPackageExtendedType
}

const DistributionPackageListItem: React.FC<Props> = ({ distributionPackage, ...rest }) => {
  const { id, name, connectionId, channelSchemaId, channelTitle, lastUpdated, tag } = distributionPackage
  const hasNoConnection = !connectionId || isVenzeeUndefined(connectionId)
  const hasNoChannelSchema = !channelSchemaId || isVenzeeUndefined(channelSchemaId)
  const hasMissingAttributes = hasNoConnection || hasNoChannelSchema

  return (
    <ListItem label={name || id} id={id} tag={tag} {...rest}>
      {hasMissingAttributes && <Info label="Missing Attributes" color={COLORS.yellow80} />}

      {!hasMissingAttributes && (
        <DistributionPackagesSubtitle
          allowCopy={false}
          id={id}
          connectionId={connectionId}
          tag={tag}
          lastUpdated={lastUpdated}
          connectionTitle={channelTitle}
          schemaId={channelSchemaId}
          schemaTitle={channelSchemaId}
        />
      )}
    </ListItem>
  )
}

export default DistributionPackageListItem
