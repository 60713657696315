import React from 'react'
import { keyframes } from '@emotion/react'

import Icon from './Icon'
import { COLORS } from '../theme'

const NotificationToast = ({ message, options, style, close }) => {
  return (
    <div css={[styles.root, { justifyContent: options?.icon ? 'flex-start' : 'center' }]} onClick={options?.onClick}>
      {options?.icon && <Icon icon={options.icon} spin={options?.spin} size={25} css={{ marginRight: '0.75rem' }} />}
      <div css={{ flex: 2, textAlign: options?.icon ? 'left' : 'center' }}>{message}</div>
      <button onClick={close} css={styles.button}>
        x
      </button>
    </div>
  )
}

const slide = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
`

const styles = {
  root: {
    height: 'auto',
    color: COLORS.purpleTitle,
    textTransform: 'uppercase',
    fontSize: 17,
    padding: '1rem 1.5rem',
    borderRadius: 9,

    display: 'flex',
    // justifyContent: 'flex-start',
    alignItems: 'center',

    width: 'fit-content',
    maxWidth: 500,

    border: `1px solid ${COLORS.purpleTitleShaded}`,
    background: COLORS.yellowLightest,
    boxShadow: `8px 8px 0 ${COLORS.buttonShadow}`,

    marginBottom: 40,
    animation: `${slide} .5s ease`,
  },

  button: {
    marginLeft: '20px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: '#FFFFFF',
  },
}

export default NotificationToast
