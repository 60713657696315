import React from 'react'

import { COLORS } from '../../../../theme'

const MappingRulePillDefault = ({ rule, className }) => (
  <div css={styles.root} className={className}>
    <div css={styles.pillHeader} className={className}>
      Rule View Not Available: {rule?.rule?.key}
    </div>
  </div>
)

const styles = {
  root: {
    cursor: 'pointer',
    backgroundColor: COLORS.blue40,
    borderRadius: 3,
    height: 30,
    marginRight: '0.4rem',
    color: COLORS.white,
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${COLORS.blue80}`,
    borderBottomWidth: 3,
    marginTop: '0.4rem',
  },

  pillHeader: {
    backgroundColor: COLORS.blue60,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: '0.1rem 0.5rem 0 0.5rem',

    '&.is-open': {
      backgroundColor: COLORS.yellow40,
    },
  },

  pillContent: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    fontFamily: 'Courier New',
    padding: '0 0.5rem',
  },
}

export default MappingRulePillDefault
