export const SET_USER = 'ui/common/SET_USER'

export const SET_SPREADSHEET = 'ui/common/SET_SPREADSHEET'
export const SET_SOURCE_DOCUMENTS_CURRENT_UPLOAD = 'ui/common/SET_SOURCE_DOCUMENTS_CURRENT_UPLOAD'
export const UPDATE_SOURCE_DOCUMENTS_CURRENT_UPLOAD = 'ui/common/UPDATE_SOURCE_DOCUMENTS_CURRENT_UPLOAD'
export const ERROR_SOURCE_DOCUMENTS_CURRENT_UPLOAD = 'ui/common/ERROR_SOURCE_DOCUMENTS_CURRENT_UPLOAD'
export const RESET_SOURCE_DOCUMENTS_CURRENT_UPLOAD = 'ui/common/RESET_SOURCE_DOCUMENTS_CURRENT_UPLOAD'
export const SET_SOURCE_DOCUMENTS_LOADER_SHOW = 'ui/common/SET_SOURCE_DOCUMENTS_LOADER_SHOW'
export const SET_SOURCE_DOCUMENTS_LOADER_UPLOAD = 'ui/common/SET_SOURCE_DOCUMENTS_LOADER_UPLOAD'
export const SET_NEW_CONNECTION = 'ui/common/SET_NEW_CONNECTION'
export const CLEAR_NEW_CONNECTION = 'ui/common/CLEAR_NEW_CONNECTION'
export const SET_DELETED_CONNECTION = 'ui/common/SET_DELETED_CONNECTION'
export const CLEAR_DELETED_CONNECTION = 'ui/common/CLEAR_DELETED_CONNECTION'
export const SET_NEW_WEBHOOK = 'ui/common/SET_NEW_WEBHOOK'
export const CLEAR_NEW_WEBHOOK = 'ui/common/CLEAR_NEW_WEBHOOK'
export const SET_NEW_SOURCE_DOCUMENT_GROUP = 'ui/common/SET_NEW_SOURCE_DOCUMENT_GROUP'
export const CLEAR_NEW_SOURCE_DOCUMENT_GROUP = 'ui/common/CLEAR_NEW_SOURCE_DOCUMENT_GROUP'
export const SET_NEW_SOURCE_DOCUMENT_SCHEMA = 'ui/common/SET_NEW_SOURCE_DOCUMENT_SCHEMA'
export const CLEAR_NEW_SOURCE_DOCUMENT_SCHEMA = 'ui/common/CLEAR_NEW_SOURCE_DOCUMENT_SCHEMA'
export const ADD_NOTIFICATION = 'ui/common/ADD_NOTIFICATION'

// SET USER
export const setUser = (user) => ({ type: SET_USER, user })

// SET UPLOADER SPREADSHEET
export const setSpreadsheet = (meta) => ({ type: SET_SPREADSHEET, meta })
export const setSourceDocumentsCurrentUpload = (sourceDocuments, id, feature) => ({
  type: SET_SOURCE_DOCUMENTS_CURRENT_UPLOAD,
  sourceDocuments,
  id,
  feature,
})

export const updateSourceDocumentsCurrentUpload = (sourceDocuments) => ({
  type: UPDATE_SOURCE_DOCUMENTS_CURRENT_UPLOAD,
  sourceDocuments,
})
export const errorSourceDocumentsCurrentUpload = (error) => ({
  type: ERROR_SOURCE_DOCUMENTS_CURRENT_UPLOAD,
  error,
})
export const resetSourceDocumentsCurrentUpload = () => ({
  type: RESET_SOURCE_DOCUMENTS_CURRENT_UPLOAD,
})
export const setSourceDocumentsLoaderShow = (show) => ({
  type: SET_SOURCE_DOCUMENTS_LOADER_SHOW,
  show,
})
export const setSourceDocumentsLoaderUpload = (upload) => ({
  type: SET_SOURCE_DOCUMENTS_LOADER_UPLOAD,
  upload,
})

export const setNewConnection = (connection) => ({
  type: SET_NEW_CONNECTION,
  connection,
})

export const setDeletedConnection = (deletedConnection) => ({
  type: SET_DELETED_CONNECTION,
  deletedConnection,
})

export const clearDeletedConnection = () => ({
  type: CLEAR_DELETED_CONNECTION,
})

export const clearNewConnection = () => ({
  type: CLEAR_NEW_CONNECTION,
})

export const setNewWebhook = (webhook) => ({
  type: SET_NEW_WEBHOOK,
  webhook,
})

export const clearNewWebhook = () => ({
  type: CLEAR_NEW_WEBHOOK,
})

export const setNewSourceDocumentGroup = (sourceDocumentGroup) => ({
  type: SET_NEW_SOURCE_DOCUMENT_GROUP,
  sourceDocumentGroup,
})

export const clearNewSourceDocumentGroup = () => ({
  type: CLEAR_NEW_SOURCE_DOCUMENT_GROUP,
})

export const setNewSourceDocumentSchema = (sourceDocumentSchema) => ({
  type: SET_NEW_SOURCE_DOCUMENT_SCHEMA,
  sourceDocumentSchema,
})

export const clearNewSourceDocumentSchema = () => ({
  type: CLEAR_NEW_SOURCE_DOCUMENT_SCHEMA,
})

export const addNotification = (notification) => ({
  type: ADD_NOTIFICATION,
  notification,
})
