import React from 'react'

import { COLORS, FEATURES } from '../../../../theme'

import PageSubtitle from '../PageSubtitle'
import Info from '../../../Info'

export type Props = {
  allowCopy?: boolean
  id?: string
  status?: string
  lastUpdated?: number
}
export enum WebhookStatus {
  ConnectionRequiresInput = 'connection.requiresInput',
  ConnectionEstablished = 'connection.established',
  DistributionPackageReleased = 'distributionPackage.released',
  DistributionPackageStaged = 'distributionPackage.staged',
}

export const webhookStatus = {
  'connection.requiresInput': {
    color: FEATURES.connections.color,
    glyph: 'exclamation',
  },
  'connection.established': {
    color: FEATURES.connections.color,
    glyph: 'check',
  },
  'distributionPackage.released': {
    color: FEATURES.distribution_packages.color,
    glyph: 'check',
  },
  'distributionPackage.staged': {
    color: FEATURES.distribution_packages.color,
    glyph: 'check',
  },
}

const WebhooksSubtitle: React.FC<Props> = ({ allowCopy, id, status = '', lastUpdated }) => {
  return (
    <PageSubtitle allowCopy={allowCopy} id={id} lastUpdated={lastUpdated}>
      {status && (
        <Info
          label={status}
          color={webhookStatus[status]?.color || COLORS.purpleTitle}
          afterGlyph={webhookStatus[status]?.glyph}
          glyphSize={15}
          glyphColor={COLORS.white}
        />
      )}
    </PageSubtitle>
  )
}

export default WebhooksSubtitle
