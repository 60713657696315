import React from 'react'

const EditableFields: React.FC = ({ children }) => <div css={styles.root}>{children}</div>

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: '1rem',
    gap: '1rem',
  },
}

export default EditableFields
