import React from 'react'
import { lighten, desaturate, getLuminance } from 'polished'

import { COLORS } from '../theme'

import Icon from './Icon'
import Glyph from './Glyph'
import Tooltip from './Tooltip'
import Ellipsis from './typography/Ellipsis'

export type Props = {
  label?: string | React.FC | React.FunctionComponentElement<React.HTMLAttributes<HTMLElement>>
  className?: string
  color?: string
  icon?: string
  glyph?: string
  afterIcon?: string
  afterGlyph?: string
  iconSize?: number | string
  glyphSize?: number | string
  glyphColor?: any
  tooltipPlacement?: string
  tooltip?: string | null
  seal?: boolean
  spin?: boolean
}

const Info: React.FC<Props> = ({
  color = COLORS.blue80,
  icon,
  glyph,
  afterIcon,
  afterGlyph,
  iconSize = 20,
  glyphSize,
  glyphColor,
  label = '',
  tooltipPlacement = 'top',
  tooltip,
  className,
  ignoreAdjustments,
  seal,
  spin,
}) => (
  <Tooltip message={tooltip} placement={tooltipPlacement}>
    <div css={styles.root({ color, ignoreAdjustments, seal })} className={className}>
      {icon && <Icon icon={icon} size={seal ? iconSize * 1.2 : iconSize} css={{ marginRight: '0.2rem' }} spin={spin} />}
      {glyph && <Glyph glyph={glyph} size={seal ? glyphSize * 1.2 : glyphSize} color={glyphColor} spin={spin} />}
      <Ellipsis css={{ margin: '0 0.25rem' }}>{label}</Ellipsis>
      {afterIcon && <Icon icon={afterIcon} size={seal ? iconSize * 1.2 : iconSize} css={{ marginLeft: '0.2rem' }} />}
      {afterGlyph && <Glyph glyph={afterGlyph} size={seal ? glyphSize * 1.2 : glyphSize} color={glyphColor} />}
    </div>
  </Tooltip>
)

const styles = {
  root: ({ color, ignoreAdjustments, seal }) => {
    const desaturatedColor = desaturate(0.3, lighten(0.25, color))
    const luminance = getLuminance(ignoreAdjustments ? color : desaturatedColor)

    return {
      borderRadius: 8,
      padding: 10,
      color: luminance > 0.5 ? COLORS.purpleTitle : COLORS.white,
      width: 'fit-content',
      backgroundColor: ignoreAdjustments ? color : desaturatedColor,

      fontSize: seal ? 17 : 14,
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',

      fontWeight: 500,
      height: seal ? 'fit-content' : 33,
    }
  },
}

export default Info
