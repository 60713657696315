import React from 'react'
import { Route, Redirect, useParams, useRouteMatch, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getQuerySelector, resetRequests } from '@redux-requests/core'
import isDefined from '@codewell/is-defined'
import { stringToColor } from '../../utils/functions'

import useAPI, { getActionType } from '../../hooks/useAPI'

import Page from '../../components/layout/page/Page'
import PageAction from '../../components/layout/page/PageAction'
import CardSection from '../../components/CardSection'
import EditableSection from '../../components/EditableSection'
import EditableSectionContent from '../../components/EditableSectionContent'
import EditableFieldGroup from '../../components/EditableFieldGroup'
import EditableFieldGroupHeader from '../../components/EditableFieldGroupHeader'
import CopyableField from '../../components/CopyableField'
import Tabs from '../../components/tabs/Tabs'
import Tab from '../../components/tabs/Tab'
import TabList from '../../components/tabs/TabList'
import ReleaseSubtitle from '../../components/layout/page/subtitle/ReleaseSubtitle'
import ValidDocumentsTabPage from './tabs/valid-documents'
import SourceDocumentsTabPage from './tabs/source-documents'
import ErrorDocumentsTabPage from './tabs/error-documents'
import DocumentRulesTabPage from './tabs/document-rules'
import Icon from '../../components/Icon'
import InfoReleaseStatus from '../../components/InfoReleaseStatus'

import ValidTranslatedDocumentOverlay from '../../components/overlays/ValidTranslatedDocumentOverlay'
import ErrorTranslatedDocumentOverlay from '../../components/overlays/ErrorTranslatedDocumentOverlay'
import SourceDocumentOverlay from '../../components/overlays/SourceDocumentOverlay'

import { FEATURES } from '../../theme'

const StagedReleasePage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { url } = useRouteMatch()
  const dispatch = useDispatch()
  const history = useHistory()

  const stagedRelease = useSelector(getQuerySelector({ type: getActionType('STAGED_RELEASE') }))
  const connection = useSelector(getQuerySelector({ type: getActionType('CONNECTION') }))
  const schemas = useSelector(getQuerySelector({ type: getActionType('SCHEMAS') }))
  const schema = useSelector(getQuerySelector({ type: getActionType('SCHEMA') }))
  const distributionPackage = useSelector(getQuerySelector({ type: getActionType('DISTRIBUTION_PACKAGE') }))
  const sourceDocumentSchema = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMA') }))
  const sourceDocumentGroup = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUP') }))

  const connectionId = stagedRelease?.data?._embedded?.distributionPackage?.connectionId
  const channelSchemaId = stagedRelease?.data?._embedded?.distributionPackage?.channelSchemaId
  const baseSourceDocumentSchemaId = distributionPackage?.data?.baseSourceDocumentSchemaId
  const baseSourceDocumentGroupId = distributionPackage?.data?.baseSourceDocumentGroupId

  useAPI('STAGED_RELEASE', `/staged-releases/${id}`, false, 'GET', true)
  useAPI('CONNECTION', `/connections/${connectionId}`, !isDefined(connectionId))
  useAPI('SCHEMAS', `/connections/${connectionId}/schemas/${channelSchemaId}`, !isDefined(connectionId) || !isDefined(channelSchemaId))
  useAPI(
    'SCHEMA',
    `/connections/${connectionId}/schemas/${distributionPackage?.data?.channelSchemaId}`,
    !isDefined(connectionId) || !isDefined(distributionPackage?.data?.channelSchemaId),
  )
  useAPI(
    'DISTRIBUTION_PACKAGE',
    `/distribution-packages/${stagedRelease?.data?.distributionPackageId}`,
    !stagedRelease?.data?.distributionPackageId,
  )
  useAPI(
    'SOURCE_DOCUMENT_GROUP',
    `/source-document-groups/${baseSourceDocumentGroupId}/`,
    isDefined(sourceDocumentGroup?.data) || !baseSourceDocumentGroupId,
  )
  useAPI(
    'SOURCE_DOCUMENT_SCHEMA',
    `/source-document-schemas/${baseSourceDocumentSchemaId}/`,
    isDefined(sourceDocumentSchema?.data) || !baseSourceDocumentSchemaId,
  )

  const isLoading = stagedRelease?.pending > 0 || connection?.pending > 0 || schemas?.pending > 0 || distributionPackage?.pending > 0
  const hasDpkg = !!distributionPackage?.data

  React.useEffect(() => {
    dispatch(
      resetRequests([getActionType('VALID_SOURCE_DOCUMENTS'), getActionType('ERROR_SOURCE_DOCUMENTS'), getActionType('SOURCE_DOCUMENTS')]),
    )
  }, [])

  return (
    <Page
      feature="staged_releases"
      title={stagedRelease.data?.id || ''}
      loading={isLoading}
      notFound={stagedRelease?.error?.response?.status === 404}
      subtitle={
        <ReleaseSubtitle
          allowCopy
          distributionPackageId={stagedRelease?.data?.distributionPackageId}
          lastUpdated={stagedRelease?.data?.lastUpdated}
          distributionPackageTitle={stagedRelease?.data?._embedded?.distributionPackage?.name}
          status={stagedRelease?.data?.status}
        />
      }
      actions={
        <>
          <PageAction
            label="View Distribution Package"
            to={`/distribution-packages/${stagedRelease?.data?.distributionPackageId}`}
            icon="distributionPackages"
            disabled={!hasDpkg}
          />
          <PageAction label="View Connection" to={`/connections/${distributionPackage?.data?.connectionId}`} icon="connection" />
          {stagedRelease?.data?.baseSourceDocumentSchemaId && (
            <PageAction
              label="View Source Document Schema"
              to={`/source-document-schemas/${distributionPackage?.data?.baseSourceDocumentSchemaId}`}
              icon="connection"
            />
          )}
        </>
      }
      status={<InfoReleaseStatus status={stagedRelease?.data?.status} seal spin={stagedRelease?.data?.status === 'staging'} />}
    >
      <CardSection feature="distribution_packages" loading={isLoading}>
        <Tabs>
          <TabList>
            <Tab label="General" to={`${url}/general`} feature="general" />
            <Tab label="Valid documents" to={`${url}/valid-documents`} feature="valid_documents" />
            <Tab label="Errors" to={`${url}/errors`} feature="error_documents" />
            <Tab label="Source documents" to={`${url}/source-documents`} feature="source_documents" />
            <Tab label="Document rules" to={`${url}/document-rules`} feature="document_rules" />
          </TabList>

          <Route
            path="/staged-releases/:id/general"
            component={() => (
              <>
                <EditableSection css={{ gridTemplateColumns: 'initial' }}>
                  <EditableSectionContent>
                    <EditableFieldGroup>
                      <CopyableField label="Release ID" defaultValues={stagedRelease?.data} model="id" allowCopy shortened />

                      <CopyableField label="Last Updated" defaultValues={stagedRelease?.data} model="lastUpdated" isDate />
                    </EditableFieldGroup>
                  </EditableSectionContent>
                </EditableSection>
                {stagedRelease?.data?.id && (
                  <>
                    {hasDpkg && (
                      <EditableSection css={{ gridTemplateColumns: 'initial', width: '100%' }}>
                        <EditableSectionContent>
                          <EditableFieldGroupHeader title="Distribution Package" feature="distribution_packages" />
                          <EditableFieldGroup>
                            <CopyableField
                              label="Name"
                              defaultValues={hasDpkg ? distributionPackage?.data : stagedRelease?.data}
                              model={hasDpkg ? 'name' : 'distributionPackageId'}
                              allowCopy={!hasDpkg}
                              shortened={!hasDpkg}
                            />
                            <CopyableField
                              label="Distribution Package ID"
                              defaultValues={distributionPackage?.data}
                              model="id"
                              shortened
                              allowCopy
                            />
                            <CopyableField label="Document Key" defaultValues={distributionPackage?.data} model="documentKey" allowCopy />

                            <CopyableField
                              label="Tag"
                              defaultValues={distributionPackage?.data}
                              model="tag"
                              colorIndicator={stringToColor(distributionPackage?.data?.tag)}
                            />

                            <CopyableField label="Last Updated" defaultValues={distributionPackage?.data} model="lastUpdated" isDate />
                          </EditableFieldGroup>

                          <EditableFieldGroupHeader title="Source Document Schemas & Groups" feature="schemas" />

                          <EditableFieldGroup>
                            <CopyableField label="Source Document Schema" defaultValues={sourceDocumentSchema?.data} model="name" />
                            <CopyableField label="Source Document Group" defaultValues={sourceDocumentGroup?.data} model="name" />
                          </EditableFieldGroup>

                          <EditableFieldGroupHeader title="Connection" feature="connection" />

                          <EditableFieldGroup>
                            <CopyableField label="Name" defaultValues={connection?.data} model="key" />

                            <CopyableField
                              label="Connection Id"
                              defaultValues={distributionPackage?.data}
                              model="connectionId"
                              shortened
                              allowCopy
                            />

                            <CopyableField label="Channel Schema" defaultValues={schema?.data} model="title" css={{ gridColumn: '1/-1' }} />
                          </EditableFieldGroup>
                        </EditableSectionContent>
                      </EditableSection>
                    )}
                    {!hasDpkg && (
                      <div css={styles.emptyMessage}>
                        <Icon icon={FEATURES.distribution_packages.icon} size={50} css={{ marginBottom: '1rem' }} />
                        <div css={styles.header}>This Distribution Package has been deleted</div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          />
          <Route
            path="/staged-releases/:id/valid-documents"
            component={() => <ValidDocumentsTabPage release={stagedRelease?.data} distributionPackage={distributionPackage?.data} />}
          />
          <Route
            path="/staged-releases/:id/errors"
            component={() => <ErrorDocumentsTabPage release={stagedRelease?.data} distributionPackage={distributionPackage?.data} />}
          />
          <Route
            path="/staged-releases/:id/source-documents"
            component={() => <SourceDocumentsTabPage release={stagedRelease?.data} distributionPackage={distributionPackage?.data} />}
          />
          <Route
            path="/staged-releases/:id/document-rules"
            component={() => <DocumentRulesTabPage release={stagedRelease?.data} distributionPackage={distributionPackage?.data} />}
          />
          <Route
            path="/staged-releases/:id/errors/:translatedErrorKey"
            component={() => (
              <ErrorTranslatedDocumentOverlay
                distributionPackage={distributionPackage?.data}
                release={stagedRelease?.data}
                onClose={() => history.push(`/staged-releases/${stagedRelease?.data?.id}/errors`)}
                baseURL="/staged-releases"
              />
            )}
          />
          <Route
            path="/staged-releases/:id/valid-documents/:validDocumentKey"
            component={() => (
              <ValidTranslatedDocumentOverlay
                distributionPackage={distributionPackage?.data}
                release={stagedRelease?.data}
                onClose={() => history.push(`/staged-releases/${stagedRelease?.data?.id}/valid-documents`)}
                baseURL="/staged-releases"
              />
            )}
          />
          <Route
            path="/staged-releases/:id/source-documents/:sourceDocumentId"
            component={() => (
              <SourceDocumentOverlay
                distributionPackage={distributionPackage?.data}
                onClose={() => history.push(`/staged-releases/${stagedRelease?.data?.id}/source-documents`)}
              />
            )}
          />
          <Redirect to={`${url}/general`} />
        </Tabs>
      </CardSection>
    </Page>
  )
}

const styles: any = {
  dpkgContainer: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    fontSize: 17,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  emptyMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: 300,
    textAlign: 'center',
  },
}

export default StagedReleasePage
