import React from 'react'
import { transparentize, darken } from 'polished'
import clsx from 'clsx'

import { COLORS } from '../theme'

import Label from './Label'
import FormContext from '../context/FormContext'

type Props = {
  label?: string
  error?: any
  placeholder?: string
  model: string
  register?: Function
  unregister?: Function
  onChange?: Function
  hideEmptyOption?: boolean
  disabled?: boolean
}

const InputSelect: React.FC<Props> = ({ model, label, placeholder, children, hideEmptyOption, ...rest }) => {
  return (
    <FormContext.Consumer>
      {({ register, formState, disabled }) => {
        const error = formState?.errors?.[model]

        const classNames = clsx({
          'is-disabled': disabled,
        })

        return (
          <div css={styles.root} className={classNames}>
            {label && <Label for={model} label={label} />}
            <select name={model} type="text" {...register(model)} css={styles.input} {...rest}>
              {!hideEmptyOption && <option />}
              {children}
            </select>
            {error && <div css={styles.error}>{error.message}</div>}
          </div>
        )
      }}
    </FormContext.Consumer>
  )
}

const styles = {
  root: {
    display: 'grid',
    gap: '0.5rem',

    '&.is-disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },

  error: {
    border: `1px solid ${COLORS.red}`,
    backgroundColor: COLORS.red60,
    color: COLORS.white,
    padding: '0.5rem',
    textAlign: 'center',
    borderRadius: 4,
    fontFamily: 'Amiko',
    textTransform: 'uppercase',
    fontSize: '.75rem',
    fontWeight: 'bold',
  },

  input: {
    height: 40,
    outlineColor: COLORS.blue40,
    fontSize: 15,
    fontFamily: 'Amiko',
    width: '100%',
    color: darken(0.1, COLORS.purplePale),
    border: `1px solid ${COLORS.purplePale40}`,
    borderRadius: 4,
    padding: '0.5rem 1rem',
    boxShadow: `
      0px 1px 1px ${transparentize(0.5, COLORS.purplePale40)},
      0px 2px 2px ${transparentize(0.6, COLORS.purplePale40)}
    `,

    '&:focus': {},
  },

  placeholder: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    lineHeight: '100%',
  },
}

export default InputSelect
