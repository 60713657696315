import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getQuerySelector, resetRequests } from '@redux-requests/core'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import reverse from 'lodash/reverse'

import Page from '../../components/layout/page/Page'
import CardSection from '../../components/CardSection'

import ReleaseListItem from '../../components/list/ReleaseListItem'
import ReleaseSubtitle from '../../components/layout/page/subtitle/ReleaseSubtitle'
import PageAction from '../../components/layout/page/PageAction'
import ListLoader from '../../components/ListLoader'
import Pagination from '../../components/Pagination'

import NewStagedRelease from '../../components/overlays/new/NewStagedRelease'

import useAPI, { getActionType } from '../../hooks/useAPI'
import { Release } from '../../declarations/Release'

const ReleasesPage: React.FC = () => {
  const dispatch = useDispatch()
  const releases = useSelector(getQuerySelector({ type: getActionType('STAGED_RELEASES') }))
  const distributionPackages = useSelector(getQuerySelector({ type: getActionType('DISTRIBUTION_PACKAGES') }))

  const releasesList = releases?.data?._embedded?.stagedReleases || []

  const [getMoreState, setGetMoreState] = React.useState(false)
  const [stagedReleasesPages, setReleasesPages] = React.useState<Release[]>([])
  const stagedReleaseNextPage = useSelector(getQuerySelector({ type: getActionType('STAGED_RELEASES_PAGES') }))
  const nextStagedReleasePage =
    (stagedReleaseNextPage?.data ? stagedReleaseNextPage?.data?._links?.next?.href : releases?.data?._links?.next.href) || ''

  useAPI('STAGED_RELEASES', '/staged-releases?offset=0&limit=20', false, 'GET', true)
  useAPI('DISTRIBUTION_PACKAGES', '/distribution-packages')
  useAPI(
    'STAGED_RELEASES_PAGES',
    nextStagedReleasePage,
    !nextStagedReleasePage || !getMoreState || stagedReleaseNextPage?.pending > 0,
    'GET',
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    [getMoreState],
  )
  React.useEffect(
    () => () => {
      dispatch(resetRequests([getActionType('STAGED_RELEASES'), getActionType('DISTRIBUTION_PACKAGES')]))
    },
    [],
  )

  React.useEffect(() => {
    if (getMoreState) setGetMoreState(false)
  }, [getMoreState])

  React.useEffect(() => {
    if (stagedReleaseNextPage?.data)
      setReleasesPages([...stagedReleasesPages, ...(stagedReleaseNextPage?.data?._embedded?.stagedReleases || [])])
  }, [stagedReleaseNextPage?.data?._embedded?.stagedReleases])

  const isLoading = releases?.pending > 0 || distributionPackages?.pending > 0
  const isEmptyList = (isEmpty(releasesList) || releases?.data?.count === 0) && !isLoading

  const completeStagedReleaseList = [...releasesList, ...stagedReleasesPages]

  const items = reverse(sortBy(completeStagedReleaseList, 'lastUpdated')).map((release) => (
    <ReleaseListItem
      key={release.id}
      id={release?.id}
      release={release}
      to={`/staged-releases/${release.id}`}
      tag={distributionPackages?.data?.[release?.distributionPackageId]?.tag}
      loading={release?.status === 'staging'}
    >
      <ReleaseSubtitle
        allowCopy={false}
        distributionPackageId={release?.distributionPackageId}
        lastUpdated={release?.lastUpdated}
        distributionPackageTitle={distributionPackages?.data?.[release?.distributionPackageId]?.name}
        status={release?.status}
        isList
      />
    </ReleaseListItem>
  ))

  return (
    <Page
      feature="staged_releases"
      title="Staged Releases"
      actions={<PageAction to="/staged-releases/new" label="Create New" icon="releases" />}
    >
      <CardSection
        loading={isLoading}
        empty={isEmptyList}
        feature="staged_releases"
        emptyMessage="There are no Releases created yet"
        customLoader={() => <ListLoader />}
      >
        {releasesList && (
          <Pagination
            next={() => setGetMoreState(true)}
            dataLength={items.length}
            loader={<ListLoader />}
            hasMore={!!nextStagedReleasePage}
            scrollableTarget="page"
          >
            {items}
          </Pagination>
        )}
      </CardSection>

      <Switch>
        <Route exact path="/staged-releases/new" component={NewStagedRelease} />
      </Switch>
    </Page>
  )
}

export default ReleasesPage
