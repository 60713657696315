import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Global } from '@emotion/react'

import 'sanitize.css/sanitize.css'

import Login from './access/Login'
import Logout from './access/Logout'
import VenzeeStudioEntry from './VenzeeStudioEntry'

import { GLOBAL_STYLES } from '../theme'

const Entry = () => {
  // React.useEffect(() => {
  //   Network.start()

  //   return () => {
  //     Network.stop()
  //   }
  // }, [])

  return (
    <>
      <Global styles={GLOBAL_STYLES} />
      {/* <ErrorBoundary> */}
      {/* <DevStats /> */}
      {/* <NetworkStatus /> */}

      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />

        <Route path="/*" component={VenzeeStudioEntry} />

        <Redirect to="/login" />
      </Switch>
      {/* </ErrorBoundary> */}
    </>
  )
}

export default Entry
