import React from 'react'

import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import toArray from 'lodash/toArray'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import reverse from 'lodash/reverse'

import Page from '../../components/layout/page/Page'
import PageAction from '../../components/layout/page/PageAction'
import CardSection from '../../components/CardSection'
import NewWebhook from '../../components/overlays/new/NewWebhook'
import ListLoader from '../../components/ListLoader'

import WebhookListItem from '../../components/list/WebhookListItem'

import useAPI, { getActionType } from '../../hooks/useAPI'

const WebhooksPage: React.FC = () => {
  const webhooks = useSelector(getQuerySelector({ type: getActionType('WEBHOOKS') }))

  const webhooksList = toArray(webhooks?.data)

  useAPI('WEBHOOKS', '/web-hooks', false, 'GET', false, 'id', null, 'webHooks')

  const isLoading = webhooks?.pending > 0
  const isEmptyList = (isEmpty(webhooksList) || webhooks?.data?.count === 0) && !isLoading

  return (
    <Page feature="webhooks" title="Webhooks" actions={<PageAction to="/webhooks/new" label="Create New" icon="webhook" />}>
      <CardSection
        feature="webhooks"
        empty={isEmptyList}
        loading={isLoading}
        emptyMessage="There are no Webhooks created yet"
        customLoader={() => <ListLoader />}
      >
        {webhooksList &&
          reverse(sortBy(webhooksList, 'lastUpdated')).map((webhook) => (
            <WebhookListItem key={webhook.id} webhook={webhook} to={`/webhooks/${webhook.id}`} />
          ))}
      </CardSection>
      <Switch>
        <Route exact path="/webhooks/new" component={NewWebhook} />
      </Switch>
    </Page>
  )
}

export default WebhooksPage
