import React from 'react'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'

import { COLORS } from '../theme'

const ErrorList = ({ errors, className = '' }) => {
  const isAString = isString(errors)
  const isAnArray = isArray(errors)
  const isAnObject = isObject(errors)

  if (isAString) return <div css={styles.error}>{errors}</div>
  if (isAnArray)
    return (
      <div css={styles.root} className={className}>
        {errors?.map(({ message }) => (
          <div css={styles.error}>{message}</div>
        ))}
      </div>
    )
  if (isAnObject) return <div css={styles.error}>{errors?.message}</div>
  return null
}

const styles: any = {
  root: {
    display: 'grid',
    gridGap: '1rem',
  },

  error: {
    border: `1px solid ${COLORS.red}`,
    backgroundColor: COLORS.red60,
    color: COLORS.white,
    padding: '0.5rem',
    textAlign: 'center',
    borderRadius: 4,
    fontFamily: 'Amiko',
    textTransform: 'uppercase',
    fontSize: '.75rem',
    fontWeight: 'bold',
  },
}

export default ErrorList
