import React from 'react'
import clsx from 'clsx'
import PlaceholderLoading from 'react-placeholder-loading'

import { COLORS, getFeature } from '../../../theme'

import Container from '../../Container'
import Icon from '../../Icon'
import InfoTag from '../../InfoTag'
import { Feature } from '../../../declarations'

type Props = {
  title: string
  feature: Feature
  actions?: any
  hideBackButton?: boolean
  fullWidth?: boolean
  loading?: boolean
  className?: string
  tag?: string
  small?: boolean
  status?: any
}

const PageHeader: React.FC<Props> = ({
  title,
  feature,
  icon,
  loading,
  working,
  hideBackButton,
  tag,
  children,
  small,
  status,
  className,
  ...rest
}) => {
  const foundFeature = getFeature(feature)

  const classNames = clsx({
    [className]: className,
    'is-small': small,
  })

  return (
    <div css={styles.root} className={classNames}>
      <Container fullWidth css={{ height: '100%' }} {...rest}>
        <div css={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <div css={styles.title}>
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <Icon icon={icon || foundFeature?.icon} size={small ? 26 : 30} css={{ marginRight: '.5rem' }} loading={working} />

              {!loading && (
                <div css={styles.titleContainer}>
                  <div css={{ height: 29 }}>{title}</div>
                  {tag && <InfoTag label={tag} css={{ marginLeft: '1rem' }} />}
                </div>
              )}

              {loading && (
                <>
                  <PlaceholderLoading width={320} height={33} shape="rect" />
                </>
              )}
            </div>

            {status && !loading && status}
          </div>

          {children && (
            <div css={{ display: 'grid', gridAutoFlow: 'column', gridGap: '0.5rem', width: 'max-content' }}>
              {!loading && children}

              {loading && (
                <>
                  <PlaceholderLoading width={110} height={33} shape="rect" />
                  <PlaceholderLoading width={60} height={33} shape="rect" />
                  <PlaceholderLoading width={50} height={33} shape="rect" />
                </>
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

const styles: any = {
  root: {
    display: 'grid',
    gridTemplateColumns: '12fr 5fr',
    gridGap: '1rem',

    zIndex: 3,
    textTransform: 'uppercase',
    letterSpacing: '-1px',
    marginBottom: '2rem',
    fontSize: 25,

    '&.is-small': {
      fontSize: 20,

      padding: '1.5rem 4rem 0 1.5rem',
    },
  },

  title: {
    width: '100%',

    color: COLORS.purpleTitle,

    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },

  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  backbutton: {
    height: '100%',
    width: 60,
    // borderRight: `1px solid ${COLORS.orchidPaleLight}`,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '1rem',

    cursor: 'pointer',
  },

  actions: {},
}

export default PageHeader
