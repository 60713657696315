import React from 'react'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import reverse from 'lodash/reverse'
import isDefined from '@codewell/is-defined'
import toArray from 'lodash/toArray'

import List from '../../../components/List'
import ReleaseListItem from '../../../components/list/ReleaseListItem'
import ReleaseSubtitle from '../../../components/layout/page/subtitle/ReleaseSubtitle'

import useAPI, { getActionType } from '../../../hooks/useAPI'
import { DistributionPackage } from '../../../declarations'

type Props = {
  distributionPackage: DistributionPackage
}

const ReleasesTabPage: React.FC<Props> = ({ distributionPackage }) => {
  const releases = useSelector(getQuerySelector({ type: getActionType('RELEASES') }))

  const releasesList = toArray(releases?.data)

  useAPI('RELEASES', `/releases?distributionPackageId=${distributionPackage?.id}`, !distributionPackage || isDefined(releases?.data), 'GET')

  const isLoading = releases?.pending > 0
  const isEmptyList = isEmpty(releasesList) || releases?.data?.count === 0

  return (
    <List feature="releases" loading={isLoading} empty={isEmptyList} gridGap="0">
      {releasesList &&
        reverse(sortBy(releasesList, 'lastUpdated')).map((release) => (
          <ReleaseListItem key={release.id} release={release} to={`/releases/${release.id}`} loading={release.status === 'releasing'}>
            <ReleaseSubtitle
              allowCopy={false}
              id={release?.id}
              distributionPackageId={release?.distributionPackageId}
              lastUpdated={release?.lastUpdated}
              distributionPackageTitle={distributionPackage?.name}
              status={release?.status}
              isList
            />
          </ReleaseListItem>
        ))}
    </List>
  )
}

export default ReleasesTabPage
