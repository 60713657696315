import React from 'react'

import EditableField from './EditableField'
import InputSelect from './InputSelect'

type Props = {
  className?: string
  label?: string
  defaultValues?: object
  model: any
  id?: string
  disabled?: boolean
  resolver?: any
  colorIndicator: string
  icon: string
  iconSize: number
  glyph: string
  glyphColor: string
  glyphSize: number
  options: Array<any>
}

const EditableFieldInputSelect: React.FC<Props> = ({ model, options, ...rest }) => {
  return <EditableField model={model} id={`id_${model}`} editComponent={<InputSelect css={styles.root}>{options}</InputSelect>} {...rest} />
}

const styles = {
  root: {
    maxHeight: 'unset',
    outline: 0,
  },
}

export default EditableFieldInputSelect
