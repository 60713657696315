import { Placement } from '@popperjs/core'
import React from 'react'
import { Icon } from '../declarations'

import { TOOLTIP_MAPPING_TYPES } from '../theme'

import InfoTooltip from './InfoTooltip'

type Props = {
  type: string
  icon?: Icon
  placement?: Placement
}

const getMessageByType = (type) => (TOOLTIP_MAPPING_TYPES[type] ? TOOLTIP_MAPPING_TYPES[type] : TOOLTIP_MAPPING_TYPES.default)

const InfoMappingTypeTooltip: React.FC<Props> = ({ type = 'default', ...rest }) => {
  return <InfoTooltip message={getMessageByType(type)} {...rest} />
}

export default InfoMappingTypeTooltip
