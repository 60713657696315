import React from 'react'
import { lighten } from 'polished'
import clsx from 'clsx'

import { COLORS } from '../theme'

import Icon from './Icon'

type Props = {
  label: string
  icon?: string
  to?: string
  className?: string
  disabled?: boolean
} & React.ReactNode

const ListItemGroup: React.FC<Props> = ({ className, label, icon, children, disabled = false }) => {
  const [open, setOpen] = React.useState(false)

  const classNames = clsx({
    [className]: className,
    'is-disabled': disabled,
    'is-opened': open,
  })

  return (
    <>
      <div css={styles.root} className={classNames} onClick={() => setOpen(!open)}>
        <div>{icon && <Icon icon={icon} size={30} css={{ height: '100%' }} />}</div>
        <div>
          <div css={styles.content}>
            <div css={styles.label}>{label}</div>
          </div>
        </div>
        <Icon icon="chevron" size={30} css={{ height: '100%' }} className="icon" />
      </div>

      {open && children}
    </>
  )
}

const styles = {
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '40px auto 40px',

    padding: '0 1rem 0 2rem',

    height: 76,
    borderBottom: `1px solid ${COLORS.orchidPaleLight}`,
    textDecoration: 'none',

    cursor: 'pointer',

    color: COLORS.purpleTitle,

    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },

    '&:hover': {
      backgroundColor: lighten(0.025, COLORS.purplePaleLightest),
    },

    '&.is-disabled': {
      pointerEvents: 'none',
      opacity: 0.6,
    },

    '&.is-opened': {
      boxShadow: `0 -1px 3px #e3dde3`,
      '& .icon': {
        transform: 'rotate(90deg)',
      },
    },
  },

  content: {
    display: 'grid',
    gridGap: 2,
  },

  label: {
    textTransform: 'uppercase',
    letterSpacing: '-1px',
  },

  pills: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '1rem',
  },
}

export default ListItemGroup
