import { getActionType } from '../../hooks/useAPI'
import { normalize, parseJsonPatch, parseJsonPatchResponse } from './'

export const updateConnection = (connection) => {
  if (!connection) return {}

  const { id } = connection

  let data = parseJsonPatch(normalize(connection))

  return {
    // We use PATCH here because is the type of the action
    type: getActionType('CONNECTION', 'PATCH'),
    request: {
      url: `/connections/${id}`,
      method: 'patch',
      data,
    },
    meta: {
      mutations: {
        // We use GET as method, because we want to affect the current
        // GET_CONNECTION bucket as a result of the mutation
        [getActionType('CONNECTION', 'GET')]: {
          updateData: (current, mutated) => {
            const parsedMutatedValues = parseJsonPatchResponse(mutated)
            return { ...current, ...parsedMutatedValues }
          },
        },
      },
    },
  }
}

export const createConnection = (connection, onSuccess) => {
  return {
    type: getActionType('CONNECTION', 'POST'),
    request: {
      url: '/connections',
      method: 'post',
      data: connection,
    },
    meta: {
      mutations: {
        [getActionType('CONNECTION', 'NEW')]: (data) => data,
        [getActionType('CONNECTIONS', 'GET')]: {
          updateData: (current, mutated) => {
            return { ...current, [mutated?.id]: mutated }
          },
        },
      },

      ...(onSuccess && { onSuccess }),
    },
  }
}

export const deleteConnection = (connection) => {
  const id = connection?.id

  return {
    // We use DELETE here because is the type of the action
    type: getActionType('CONNECTION', 'DELETE'),
    request: {
      url: `/connections/${id}`,
      method: 'delete',
    },
    meta: {},
  }
}

export const clearConnection = () => ({
  type: getActionType('CONNECTION', 'CLEAR'),
  meta: {
    mutations: {
      [getActionType('CONNECTION', 'GET')]: () => null,
    },
  },
})
