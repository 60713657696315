import React from 'react'
import isDefined from '@codewell/is-defined'

import { COLORS } from '../theme'

import Info, { Props as InfoProps } from './Info'

export type Props = {
  onClick?: Function
  status?: string
} & InfoProps

const states = {
  released: {
    label: 'Released',
    color: COLORS.greenLight,
    icon: 'success',
  },
  releasing: {
    label: 'Releasing',
    color: COLORS.yellowLight,
    icon: 'cog',
  },

  staged: {
    label: 'Staged',
    color: COLORS.greenLight,
    icon: 'success',
  },
  staging: {
    label: 'Staging',
    color: COLORS.yellowLight,
    icon: 'cog',
  },
}

const getStateStatus = (status) => states[status]

const InfoReleaseStatus: React.FC<Props> = ({ color, status, spin, ...rest }) => {
  const stateStatus = getStateStatus(status)

  const label = stateStatus?.label || status
  return (
    <Info
      color={color || stateStatus?.color}
      label={label}
      icon={stateStatus?.icon}
      css={styles.root}
      ignoreAdjustments={isDefined(stateStatus)}
      spin={stateStatus?.spin || spin}
      {...rest}
    />
  )
}

const styles = {
  root: {
    position: 'relative',

    margin: 0,

    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
}

export default InfoReleaseStatus
