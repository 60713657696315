import React, { DetailedHTMLProps, HTMLAttributes } from 'react'

import { getGlyph } from '../theme'
import { Glyph as TGlyph } from '../declarations'

type Props = {
  size?: number
  color?: string
  glyph: TGlyph
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>

const Glyph: React.FC<Props> = ({ size = 24, color, glyph, style, ...svgProps }) => {
  const { component, defaultColor } = getGlyph(glyph)
  const styledColor = color || defaultColor

  const svgExtraProps: any = {
    width: `${size}px`,
    height: `${size}px`,
    style: {
      color: styledColor,
      ...style,
    },
  }

  const GlyphComp: React.ElementType = component
  return <GlyphComp color={styledColor} {...svgProps} {...svgExtraProps} />
}

export default Glyph
