import React from 'react'
import { useDispatch } from 'react-redux'
import useAxios from 'axios-hooks'
import { transparentize, darken } from 'polished'
import clsx from 'clsx'
import produce from 'immer'
import { CircleSpinner } from 'react-spinners-kit'
import remove from 'lodash/remove'

import { COLORS, FEATURES } from '../../theme'
import { stripTags, getInputSchema } from '../../utils/functions'
import { createRule } from '../../actions/mapping'

import SummonModal from '../SummonModal'
import InfoMappingTypeTooltip from '../InfoMappingTypeTooltip'
import Info from '../Info'
import Icon from '../Icon'
import Card from '../Card'
import MappingRuleSelector from './MappingRuleSelector'
import MappingRuleCloud from './MappingRuleCloud'
import MappingRulePreview from './MappingRulePreview'
import Tooltip from '../Tooltip'
import { Dictionary } from 'lodash'
import { DocumentType } from '../../declarations/SourceDocument'
import Glyph from '../Glyph'

type Props = {
  documentSchema?: { [key: string]: object }
  documentSchemaDefinitions?: { [key: string]: object }
  documentRulesGrouped?: Dictionary<any[]>
  sourceDocumentRulesGrouped?: Dictionary<any[]>
  documentSchemaProperty?: string
  distributionPackageId?: string
  sourceDocumentSchemaId?: string
  sourceDocumentAttributes?: string[]
  previewDocument?: DocumentType
  documentKey?: string
  currentDocumentPreview?: DocumentType
  setCurrentDocumentPreview?: Function
  disabled?: boolean
  required?: boolean
  feature?: string
  className?: string
}

const SchemaAttributeMappingInput: React.FC<Props> = ({
  documentSchema,
  documentSchemaDefinitions,
  documentRulesGrouped,
  sourceDocumentRulesGrouped,
  documentSchemaProperty = '',
  distributionPackageId,
  sourceDocumentAttributes,
  previewDocument,
  documentKey = '',
  currentDocumentPreview, //TODO: @jbraccini pls change this name
  setCurrentDocumentPreview = () => {},
  disabled,
  required,
  className,
  feature = 'distribution_package',
  sourceDocumentSchemaId,
  ...rest
}) => {
  const dispatch = useDispatch()
  const [model, setModel] = React.useState(documentRulesGrouped?.[documentSchemaProperty] || [])
  const [sourceDocumentRules] = React.useState(sourceDocumentRulesGrouped?.[documentSchemaProperty] || [])
  const [open, setOpen] = React.useState(false)
  const [defaultMenu, setDefaultMenu] = React.useState<string>()
  const [opensFromTop, setOpensFromTop] = React.useState(false)
  const [previewValue, setPreviewValue] = React.useState(currentDocumentPreview?.[documentSchemaProperty])
  const [previewLoading, setPreviewLoading] = React.useState(false)

  const featureId = feature === 'distribution_package' ? distributionPackageId : sourceDocumentSchemaId

  const [{ data: documentPreview, loading: documentPreviewLoading }, getDocumentPreview] = useAxios(
    {
      url: `/distribution-packages/${distributionPackageId}/attribute-previews/${previewDocument?.[documentKey]}`,
      method: 'GET',
    },
    { manual: true },
  )

  React.useEffect(() => {
    if (documentPreview) setCurrentDocumentPreview(documentPreview.document)
  }, [documentPreview])

  //eslint-disable-next-line
  const [{ data, loading }, executePost] = useAxios(
    {
      url: `${FEATURES[feature].parentpath}/${featureId}/document-rules`,
      method: 'POST',
    },
    { manual: true },
  )

  React.useEffect(() => {
    setPreviewLoading(documentPreviewLoading)
  }, [documentPreviewLoading])

  React.useEffect(() => setPreviewValue(currentDocumentPreview?.[documentSchemaProperty]), [currentDocumentPreview])

  React.useEffect(() => {
    const newRules = data?._embedded?.documentRules
    if (newRules) {
      dispatch(createRule(newRules))
      setModel(produce(model, (draft) => [...draft, ...newRules]))
      getDocumentPreview()
    }
  }, [data])

  const removeRuleById = (id) => {
    const newModel = produce(model, (draft) => {
      remove(draft, { id: id })
    })

    setModel(newModel)
    getDocumentPreview()
  }

  const setModelExternal = (rule) => {
    executePost({ data: [rule?.rule] })
  }

  const close = () => setOpen(false)

  const openSection = (section = 'copy') => {
    if (open) {
      setOpen(false)
      return
    }

    setDefaultMenu(section)
    setOpen(true)
  }

  const isInitiatorRule = (rule) => rule?.rule?.key === 'copy' || rule?.rule?.key === 'literal'

  const canAddTransformRule = isInitiatorRule(sourceDocumentRules?.[0]) || isInitiatorRule(model?.[0])

  const inputSchema = getInputSchema(documentSchemaProperty, documentSchema, documentSchemaDefinitions)

  const isValid = model.length > 0 || sourceDocumentRules.length > 0

  const inputClassName = clsx({
    'is-open': open,
    'is-open-from-top': opensFromTop,
    'is-required': required,
    'is-loading': loading,
    'is-valid': isValid,
    'is-previewing': previewValue,
  })

  return (
    <form css={styles.root} className={className}>
      <div>
        <div css={styles.anchor} id={documentSchemaProperty?.substr(0, 7)}></div>
        <div css={styles.header}>
          <div css={styles.label}>
            {isValid && <Icon icon="valid" size={20} css={{ marginRight: '0.3rem' }} />}
            {required && <Info label="Required" color={COLORS.purpleTitleLight} css={{ marginRight: '0.5rem' }} />}
            {inputSchema?.referenced && <Info label="Referenced" color={COLORS.red40} css={{ marginRight: '0.5rem' }} />}

            <div>{inputSchema?.title || inputSchema?.$ref} </div>
          </div>
          <div css={styles.type}>
            <div css={{ marginRight: '0.4rem' }}>{inputSchema?.type}</div>
            <InfoMappingTypeTooltip type={inputSchema?.type} icon="infoLight" placement="right" />
          </div>
        </div>

        <SummonModal
          open={open}
          setOpen={setOpen}
          setOpensFromTop={setOpensFromTop}
          disableDefaultClickBehavior
          cta={
            <div css={styles.input} className={inputClassName}>
              <MappingRuleCloud
                model={model}
                setModel={setModelExternal}
                sourceDocumentRules={sourceDocumentRules}
                distributionPackageId={distributionPackageId}
                documentSchemaProperty={documentSchemaProperty}
                sourceDocumentAttributes={sourceDocumentAttributes}
                previewDocument={previewDocument}
                inputSchemaType={inputSchema?.type}
                close={close}
                openSection={openSection}
                currentDocumentPreview={currentDocumentPreview}
                setCurrentDocumentPreview={setCurrentDocumentPreview}
                documentKey={documentKey}
                setPreviewLoading={setPreviewLoading}
                removeRuleById={removeRuleById}
                canAddTransformRule={canAddTransformRule}
                feature={feature}
                sourceDocumentSchemaId={sourceDocumentSchemaId}
                enums={inputSchema?.enum}
                {...rest}
              />

              <div>
                {!loading && (
                  <Tooltip message={!open ? 'Create a New Rule' : 'Close'} placement="right">
                    <div onClick={() => openSection('menu')} css={{ cursor: 'pointer' }}>
                      {!open && <Icon icon="ruleAdd" size={25} css={{ marginBottom: '0.1rem' }} />}
                      {open && <Glyph glyph="times" size={25} css={{ marginBottom: '0.1rem' }} />}
                    </div>
                  </Tooltip>
                )}

                {loading && <CircleSpinner size={20} color={COLORS.purplePale60} />}
              </div>
            </div>
          }
          offset={[0, -3]}
        >
          <Card css={styles.modal} className={inputClassName}>
            <MappingRuleSelector
              create
              documentSchemaProperty={documentSchemaProperty}
              sourceDocumentAttributes={sourceDocumentAttributes}
              previewDocument={previewDocument}
              schemaAttributeType={inputSchema?.type}
              setModel={setModelExternal}
              close={close}
              defaultView={defaultMenu}
              documentKey={documentKey}
              setPreviewLoading={setPreviewLoading}
              canAddTransformRule={canAddTransformRule}
              enums={inputSchema?.enum}
              {...rest}
            />
          </Card>
        </SummonModal>

        {(previewValue || previewLoading) && (
          <MappingRulePreview title={inputSchema?.title} preview={previewValue} loading={previewLoading} />
        )}

        <div css={styles.description}>{stripTags(inputSchema?.description)}</div>

        {/* <Log src={inputSchema} collapsed /> */}
      </div>
    </form>
  )
}

const styles: any = {
  root: {
    maxWidth: '80%',
    userSelect: 'none',
    marginTop: '1rem',
  },

  anchor: {
    position: 'relative',
    top: -150,
    visibility: 'hidden',
  },
  pills: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    textTransform: 'uppercase',
    color: COLORS.orchidPale,
    marginBottom: '0.5rem',
  },
  type: {
    display: 'flex',

    textTransform: 'capitalize',
    color: COLORS.purplePale,
    marginBottom: '0.5rem',
  },
  description: {
    color: COLORS.purplePale,
    textTransform: 'uppercase',
    fontSize: '0.85rem',
    letterSpacing: '-0.5px',
    marginBottom: '1rem',
    marginTop: '1rem',
    lineHeight: '150%',
  },
  input: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginBottom: '1rem',
    minHeight: 40,

    outlineColor: COLORS.blue40,
    fontSize: 15,
    fontFamily: 'Amiko',
    color: darken(0.1, COLORS.purplePale),
    border: `1px solid ${COLORS.purplePale40}`,
    borderRadius: 4,
    padding: '0.25rem',
    boxShadow: `
      0px 1px 1px ${transparentize(0.5, COLORS.purplePale40)},
      0px 2px 2px ${transparentize(0.6, COLORS.purplePale40)}
    `,

    '&:focus': {},

    '&.is-valid': {
      borderColor: COLORS.green60,
      borderWidth: 2,
      padding: '0.2rem',
    },

    '&.is-open': {
      borderColor: COLORS.yellow60,
      borderWidth: 2,
      padding: '0.2rem',
    },

    '&.is-loading': {
      opacity: 0.7,
      minHeight: 'auto',
    },

    '&.is-previewing': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  modal: {
    width: 600,
    minHeight: 'auto',
    borderRadius: 4,
    borderTop: 'none',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderColor: COLORS.yellow60,
    borderWidth: 2,
    boxShadow: `
      0px 1px 1px ${transparentize(0.5, COLORS.purplePale40)},
      0px 2px 2px ${transparentize(0.6, COLORS.purplePale40)}
    `,

    zIndex: 1000,

    '&.is-open-from-top': {
      borderBottom: `1px solid ${COLORS.orchidPaleLight}`,
      borderTopWidth: 2,
      borderTopStyle: 'solid',

      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,

      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,

      boxShadow: `
        0px -1px 1px ${transparentize(0.5, COLORS.purplePale40)},
        0px -2px 2px ${transparentize(0.6, COLORS.purplePale40)}
      `,
    },
  },
}

export default SchemaAttributeMappingInput
