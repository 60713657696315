import React from 'react'
import clsx from 'clsx'
import { darken } from 'polished'
import { CircleSpinner } from 'react-spinners-kit'

import { COLORS } from '../../theme'

import Glyph from '../Glyph'
import Tooltip from '../Tooltip'

type Props = { title: string; preview: string | number; loading?: boolean; className?: string }

const MappingRulePreview: React.FC<Props> = ({ title, preview, loading, className = '' }) => {
  const classNames = clsx({
    [className]: className,
    'is-loading': loading,
  })

  return (
    <Tooltip message={`${title} Preview`} placement="left" offset={[0, 10]}>
      <div css={styles.root} className={classNames}>
        <div css={styles.icon}>
          {loading && <CircleSpinner size={18} color={darken(0.1, COLORS.purplePale)} />}
          {!loading && <Glyph glyph="lightning" size={18} color={darken(0.1, COLORS.purplePale)} />}
        </div>
        <div css={styles.content}>{preview && String(preview)}</div>
      </div>
    </Tooltip>
  )
}

const styles: any = {
  root: {
    position: 'relative',

    backgroundColor: COLORS.purplePaleLight,
    // border: `1px solid ${COLORS.purplePale40}`,
    color: darken(0.1, COLORS.purplePale),
    borderRadius: 5,
    padding: '0.5rem',
    fontWeight: 400,

    display: 'flex',

    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,

    maxHeight: 110,
    overflowY: 'auto',

    '&.is-loading': {
      opacity: 0.75,
      minHeight: 'auto',
    },
  },
  icon: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',

    width: '1.5rem',

    flexShrink: 0,
  },

  content: {
    padding: '0 1rem 0 0.5rem',
  },
}

export default MappingRulePreview
