import React from 'react'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getMutationSelector, getQuerySelector, resetRequests } from '@redux-requests/core'
import { produce } from 'immer'
import isDefined from '@codewell/is-defined'
import toArray from 'lodash/toArray'
import sortBy from 'lodash/sortBy'

import Overlay from '../../Overlay'
import Portal from '../../Portal'
import PageHeader from '../../layout/page/PageHeader'
import Form from '../../Form'
import InputGroup from '../../InputGroup'
import ErrorList from '../../ErrorList'
import InputSelect from '../../InputSelect'

import DialogActions from '../../dialog/DialogActions'
import DialogAction from '../../dialog/DialogAction'

import useAPI, { useAPIMutation, getActionType } from '../../../hooks/useAPI'
import { notify } from '../../../modules/notifications'

const normalize = (data) => {
  if (!data) return

  return produce(data, (draft) => {
    return draft
  })
}

const NewStagedRelease: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [release, setRelease] = React.useState()

  const mutation = useSelector(getMutationSelector({ type: getActionType('STAGED_RELEASE', 'POST') }))
  const distributionPackages = useSelector(getQuerySelector({ type: getActionType('DISTRIBUTION_PACKAGES') }))

  const distributionPackagesList = sortBy(toArray(distributionPackages?.data), (distributionPackage) => distributionPackage?.name)

  const validation = React.useMemo(
    () =>
      Yup.object().shape({
        distributionPackageId: Yup.string().required(),
        skipRequiredAttributeValidation: Yup.boolean(),
      }),
    [],
  )

  const handleStagedReleaseSuccess = (response) => {
    notify('STAGED_RELEASE', 'CREATED', response.data)

    history.push(`/staged-releases/${response?.data?.id}/general`)

    return response
  }

  useAPI('DISTRIBUTION_PACKAGES', '/distribution-packages', isDefined(distributionPackages?.data))

  useAPIMutation('STAGED_RELEASE', release, !isDefined(release), 'POST', handleStagedReleaseSuccess)

  const isLoading = mutation?.pending > 0 || distributionPackages?.pending > 0

  React.useEffect(
    () => () => {
      dispatch(resetRequests([getActionType('STAGED_RELEASE', 'POST')]))
    },
    [],
  )

  return (
    <Portal type="overlay">
      <Form
        onSubmit={(data) => setRelease(normalize(data))}
        defaultValues={{ skipRequiredAttributeValidation: false }}
        resolver={validation}
        disabled={isLoading}
      >
        <Overlay
          onClose={() => history.push('/staged-releases')}
          css={styles.root}
          position="right"
          fullheight
          showBackdrop
          closeOnBackdrop
          disabled={isLoading}
          isLoading={isLoading}
        >
          <PageHeader title="New Staged Release" feature="releases" small />

          <div css={styles.content}>
            <InputGroup>
              <InputSelect
                model="distributionPackageId"
                label="Distribution Package"
                disabled={!!distributionPackages?.pending || !distributionPackagesList?.length}
              >
                {distributionPackagesList.map((distributionPackage) => (
                  <option key={distributionPackage?.id} value={distributionPackage?.id}>
                    {distributionPackage?.name}
                  </option>
                ))}
              </InputSelect>
            </InputGroup>

            {mutation?.error?.response && (
              <InputGroup>
                <ErrorList errors={mutation?.error?.response?.data} />
              </InputGroup>
            )}
          </div>

          <DialogActions>
            <DialogAction label="Create" />
            <DialogAction label="Cancel" to="/staged-releases" />
          </DialogActions>
        </Overlay>
      </Form>
    </Portal>
  )
}

const styles: any = {
  root: {
    flexShrink: 0,
    display: 'flex',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
  },
}

export default NewStagedRelease
