import React, { ReactChild } from 'react'
import { Icon as TIcon } from '../declarations'

import { COLORS } from '../theme'

import Icon from './Icon'

type Props = {
  title?: string
  subtitle?: string
  icon?: TIcon
  iconSize?: number
  aside?: React.Component | ReactChild | React.FC
  before?: React.Component | ReactChild | React.FC
  className?: string
}

const Header: React.FC<Props> = ({ title, subtitle, icon, iconSize, aside, before, className }) => {
  return (
    <div css={styles.root} className={className}>
      <div css={styles.headerToolbar}>
        {before && before}
        {icon && <Icon icon={icon} size={iconSize} css={{ marginRight: '0.5rem' }} />}
        <div css={styles.title}>
          <div>{title}</div>
          {subtitle && <div css={styles.subtitle}>{subtitle}</div>}
        </div>
      </div>

      {aside && <div css={styles.headerToolbar}>{aside}</div>}
    </div>
  )
}

const styles: any = {
  root: {
    display: 'flex',
    alignItems: 'center',

    justifyContent: 'space-between',

    background: COLORS.white,
    padding: '0.25rem 1rem',

    borderBottom: `1px solid ${COLORS.purplePale40}`,
    borderTop: `1px solid ${COLORS.purplePale40}`,

    height: 46,
  },

  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: COLORS.purpleTitle,
  },
  subtitle: {},

  headerToolbar: {
    display: 'flex',
    alignItems: 'center',
  },
}

export default Header
