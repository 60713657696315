import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { getQuerySelector } from '@redux-requests/core'
import { useDispatch, useSelector } from 'react-redux'
import { resetRequests } from '@redux-requests/core'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import toArray from 'lodash/toArray'
import reverse from 'lodash/reverse'

import { isVenzeeUndefined } from '../../utils/functions'

import Page from '../../components/layout/page/Page'
import CardSection from '../../components/CardSection'
import NewDistributionPackage from '../../components/overlays/new/NewDistributionPackage'
import PageAction from '../../components/layout/page/PageAction'
import ListLoader from '../../components/ListLoader'

import DistributionPackageListItem from '../../components/list/DistributionPackageListItem'

import useAPI, { getActionType } from '../../hooks/useAPI'

const checkUndefinedAttributes = ({ connectionId, channelSchemaId }) =>
  isVenzeeUndefined(connectionId) || isVenzeeUndefined(channelSchemaId)

const DistributionPackagesPage: React.FC = () => {
  const dispatch = useDispatch()
  const distributionPackages = useSelector(getQuerySelector({ type: getActionType('DISTRIBUTION_PACKAGES') }))
  const connections = useSelector(getQuerySelector({ type: getActionType('CONNECTIONS') }))

  useAPI('DISTRIBUTION_PACKAGES', '/distribution-packages')
  useAPI('CONNECTIONS', '/connections/?includeChannel=true')

  const distributionPackagesList = toArray(distributionPackages?.data)

  const fulfilledDistributionPackages = distributionPackagesList?.map((distributionPackage) => {
    const connectionId = distributionPackage?.connectionId
    const channelTitle = connections?.data?.[connectionId]?._embedded?.channel?.title
    return { ...distributionPackage, channelTitle }
  })

  const notAvailableDistributionPackages = fulfilledDistributionPackages?.filter((pack) => checkUndefinedAttributes(pack))
  const availableDistributionPackages = fulfilledDistributionPackages?.filter((pack) => !checkUndefinedAttributes(pack))

  const isLoading = distributionPackages.pending > 0 || connections.pending > 0
  const isEmptyList = (isEmpty(distributionPackagesList) || distributionPackages.data?.count === 0) && !isLoading

  React.useEffect(
    () => () => {
      dispatch(resetRequests([getActionType('DISTRIBUTION_PACKAGES')]))
    },
    [],
  )

  return (
    <Page
      feature="distribution_packages"
      title="Distribution Packages"
      actions={<PageAction to="/distribution-packages/new" label="Create New" icon="distributionPackages" />}
    >
      <CardSection
        loading={isLoading}
        empty={isEmptyList}
        emptyMessage="There are no distribution packages created yet"
        feature="distribution_packages"
        customLoader={() => <ListLoader />}
      >
        {availableDistributionPackages &&
          reverse(sortBy(availableDistributionPackages, 'lastUpdated')).map((distributionPackage) => (
            <DistributionPackageListItem
              key={distributionPackage.id}
              icon="products"
              distributionPackage={distributionPackage}
              to={`/distribution-packages/${distributionPackage.id}`}
            />
          ))}

        {notAvailableDistributionPackages &&
          sortBy(notAvailableDistributionPackages, 'name').map((distributionPackage) => (
            <DistributionPackageListItem
              key={distributionPackage.id}
              icon="exclamation"
              distributionPackage={distributionPackage}
              to={`/distribution-packages/${distributionPackage.id}`}
            />
          ))}
      </CardSection>
      <Switch>
        <Route exact path="/distribution-packages/new" component={NewDistributionPackage} />
      </Switch>
    </Page>
  )
}

export default DistributionPackagesPage
