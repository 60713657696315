import React from 'react'
import { transparentize } from 'polished'

import Tooltip from './Tooltip'
import Icon from './Icon'
import { Placement } from '@popperjs/core'
import { COLORS } from '../theme'

type Props = {
  message: string
}

type IconProps = {
  icon?: string
  size?: string | number
  placement?: Placement
} & Props

const InfoTooltipComponent: React.FC<Props> = ({ message }) => {
  return <div css={styles.root}>{message}</div>
}

const InfoTooltip: React.FC<IconProps> = ({ icon = 'info', size = 16, placement, ...rest }) => {
  return (
    <Tooltip tooltip={<InfoTooltipComponent {...rest} />} placement={placement} offset={[0, 5]}>
      <Icon icon={icon} size={size} />
    </Tooltip>
  )
}

const styles = {
  root: {
    fontSize: '0.8rem',
    letterSpacing: '-0.4px',
    textTransform: 'uppercase',
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.orchidPaleLight}`,
    padding: '0.4rem 0.5rem 0.35rem 0.5rem',
    lineHeight: '140%',
    borderRadius: 4,

    boxShadow: `
      0px 1px 1px ${transparentize(0.5, COLORS.purplePale40)},
      0px 2px 2px ${transparentize(0.6, COLORS.purplePale40)}
    `,

    width: 'auto',
    maxWidth: 320,
  },
}

export default InfoTooltip
