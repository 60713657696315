import React from 'react'
import ReactDOM from 'react-dom'
import { transparentize } from 'polished'
import { usePopper } from 'react-popper'
import { Offsets, Placement } from '@popperjs/core'

import { COLORS } from '../theme'
import usePortal from '../hooks/usePortal'

import Dialog from './dialog/Dialog'
import DialogAction from './dialog/DialogAction'
import DialogActions from './dialog/DialogActions'
import DialogContent from './dialog/DialogContent'

type Props = {
  placement?: Placement
  offset?: Offsets
  portal?: string
  onYes?: Function
  onNo?: Function
  disabled?: boolean
  className?: string
  message?: string
}

const DEFAULT_MESSAGE = 'Are you sure you want to do this?'

const Confirm: React.FC<Props> = ({
  children,
  message = DEFAULT_MESSAGE,
  portal = 'dialog',
  placement = 'top',
  offset,
  onYes,
  onNo,
  disabled,
  className,
}) => {
  const [open, setOpen] = React.useState(false)
  const [referenceElement, setReferenceElement] = React.useState(null)
  const [popperElement, setPopperElement] = React.useState(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
  })

  const handleAccept = (e) => {
    if (onYes) onYes(e)
    setOpen(false)
  }

  const handleCancel = (e) => {
    if (onNo) onNo(e)
    setOpen(false)
  }

  React.useEffect(() => {
    if (!popperElement) return

    const handleOutsideClick = (e) => {
      if (popperElement?.contains?.(e.target)) return
      handleCancel()
    }

    document.addEventListener('click', handleOutsideClick)

    return () => document.removeEventListener('click', handleOutsideClick)
  }, [popperElement])

  const portalContainer = usePortal(portal)

  return (
    <div>
      <div ref={setReferenceElement} className={className} onClick={() => (!disabled ? setOpen(true) : null)}>
        {children}
      </div>
      {open &&
        portalContainer &&
        ReactDOM.createPortal(
          <div ref={setPopperElement} css={{ ...styles.popper, ...customStyles.container }} {...attributes.popper}>
            <Dialog css={{ width: '25rem' }}>
              <DialogContent>{message}</DialogContent>

              <DialogActions>
                <DialogAction label="Yes" glyph="check" glyphSize="25" onClick={handleAccept} />
                <DialogAction label="No" glyph="times" glyphSize="23" onClick={handleCancel} />
              </DialogActions>
            </Dialog>
          </div>,
          portalContainer,
        )}
    </div>
  )
}

const customStyles = {
  root: {
    fontSize: '0.8rem',
    letterSpacing: '-0.4px',
    textTransform: 'uppercase',
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.orchidPaleLight}`,
    padding: '0.4rem 0.5rem 0.35rem 0.5rem',
    lineHeight: '140%',
    borderRadius: 4,

    boxShadow: `
      0px 1px 1px ${transparentize(0.5, COLORS.purplePale40)},
      0px 2px 2px ${transparentize(0.6, COLORS.purplePale40)}
    `,

    width: 'auto',
    maxWidth: 320,
  },

  container: {
    zIndex: 100,
  },
}

export default Confirm
