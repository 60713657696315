import React from 'react'
import clsx from 'clsx'
import { CircleSpinner } from 'react-spinners-kit'

import { COLORS } from '../theme'

import Glyph from './Glyph'

type BackdropProps = {
  isTransparent: boolean
}

export type OverlayProps = {
  children?: React.ReactNode
  className?: string
  closeOnBackdrop?: boolean
  closeWrapper?: any
  content?: any
  footer?: any
  fullheight?: boolean
  header?: any
  history?: any
  innerRef?: any
  isLoading?: boolean
  location?: any
  maxWidth?: number | string
  minHeight?: number | string
  onClose?: any
  position?: string
  showBackdrop?: boolean
  stopPropagation?: boolean
  transparentBackdrop?: boolean
  hideClose?: boolean
  disabled?: boolean
}

const closeElement = (
  <div className="close">
    <Glyph glyph="times" size={18} />
  </div>
)

const BackdropElement = ({ isTransparent }: BackdropProps) => {
  const classNames = clsx('backdrop', { 'is-transparent': isTransparent })

  return <div className={classNames} />
}

const Overlay = ({
  children,
  className,
  closeOnBackdrop = false,
  closeWrapper,
  content,
  fullheight,
  innerRef,
  isLoading = false,
  maxWidth = 36,
  minHeight,
  onClose,
  position = 'right',
  showBackdrop,
  stopPropagation = false,
  transparentBackdrop,
  hideClose,
  disabled,
}: OverlayProps) => {
  const onStopPropagation = (event) => {
    if (!stopPropagation) return

    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  const closeOverlay = () => {
    if (onClose) return onClose(false)
  }

  if (innerRef) innerRef(this.ref && this.ref.current)

  const rootClasses = clsx({
    [`pos-${position}`]: position,
    [className]: className,
  })

  const dialogClasses = clsx('dialog', `pos-${position}`, { fullheight: fullheight })

  return (
    <div css={styles} onClick={onStopPropagation} className={rootClasses}>
      <div
        className={dialogClasses}
        css={{
          ...(minHeight && {
            height: '100% !important',
            maxHeight: `${minHeight}rem !important`,
          }),
          maxWidth: maxWidth ? `${maxWidth}rem` : '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {isLoading && (
          <div css={styles.loadingBackdrop}>
            <CircleSpinner size={30} color={COLORS.purplePale40} />
          </div>
        )}
        {<>{content || children}</>}

        {/* CLOSE */}
        {closeWrapper && !hideClose && closeWrapper(closeElement, closeOverlay)}
        {!closeWrapper && !hideClose && <div onClick={closeOverlay}>{closeElement}</div>}
      </div>

      {showBackdrop && (
        <>
          {closeWrapper && closeWrapper(<BackdropElement isTransparent={transparentBackdrop} />, closeOnBackdrop ? closeOverlay : null)}
          {!closeWrapper && (
            <div onClick={closeOnBackdrop && !disabled ? closeOverlay : null}>
              <BackdropElement isTransparent={transparentBackdrop} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

const styles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'fixed',
  top: 0,
  right: 0,
  zIndex: 100,
  pointerEvents: 'none',

  '&.pos-right': {
    justifyContent: 'flex-end',
  },

  '&.pos-center': {
    padding: '0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '&.pos-top': {
    padding: '0.5rem',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },

  '&.pos-bottom': {
    padding: '3rem',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },

  '.close': {
    position: 'absolute',
    top: 0,
    right: 0,

    width: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    height: 50,

    zIndex: 3,
    transition: 'all 120ms cubic-bezier(0.39, 0.575, 0.565, 1)',

    svg: {
      transition: 'all 120ms cubic-bezier(0.39, 0.575, 0.565, 1)',
    },

    cursor: 'pointer',
    '&:hover': {
      svg: {
        fill: COLORS.blue,
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
    },
  },

  '.backdrop': {
    background: COLORS.backdrop,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    pointerEvents: 'all',

    willChange: 'opacity',

    '&.is-transparent': {
      cursor: 'default',
      background: COLORS.transparent,
    },
  },

  '.dialog': {
    width: '100%',
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 100,
    pointerEvents: 'all',
    background: COLORS.white,
    overflow: 'hidden',

    transition: `max-width 120ms easeOutCubic`,
    willChange: 'transform, opacity, max-width',

    border: `1px solid ${COLORS.gray40}`,

    '&.pos-right': {
      boxShadow: '-6px 0 8px 4px rgba(12,38,106,0.04), -16px 0 24px 6px rgba(12,38,106,0.03), -24px 0 32px 8px rgba(12,38,106,0.02)',
    },

    '&.pos-center': {
      height: 'min-content',
      maxHeight: '100%',
      borderRadius: 6,

      boxShadow: '0 6px 8px 4px rgba(12,38,106,0.08), 0 16px 24px 6px rgba(12,38,106,0.06), 0 24px 32px 8px rgba(12,38,106,0.04)',
    },

    '&.pos-top': {
      height: 'min-content',
      maxHeight: '100%',
      borderRadius: 6,

      boxShadow: '0 6px 8px 4px rgba(12,38,106,0.08), 0 16px 24px 6px rgba(12,38,106,0.06), 0 24px 32px 8px rgba(12,38,106,0.04)',
    },

    '&.pos-bottom': {
      overflow: 'initial',
      justifyContent: 'flex-end',
      zIndex: 1,
      border: 0,
    },

    '&.fullheight': { height: '100% !important' },
  },

  loadingBackdrop: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    opacity: 0.75,
  },
}

export default Overlay
