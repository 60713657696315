import React from 'react'

import ListItem from '../ListItem'

type Props = {
  schema: any
}

const SchemaListItem: React.FC<Props> = ({ schema, ...rest }) => {
  return <ListItem label={schema?.title} icon="sourceDocumentSchemas" {...rest} />
}
export default SchemaListItem
