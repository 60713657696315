import { getActionType } from '../../hooks/useAPI'
import { store } from '../../setup/store'

import { setNewWebhook } from '../common'

export const deleteWebhook = (webhook = {}) => {
  const { id } = webhook

  return {
    // We use DELETE here because is the type of the action
    type: getActionType('WEBHOOK', 'DELETE'),
    request: {
      url: `/web-hooks/${id}`,
      method: 'delete',
    },
    meta: {
      mutations: {},
    },
  }
}

export const createWebhook = (webhook) => {
  return {
    type: getActionType('WEBHOOK', 'POST'),
    request: {
      url: '/web-hooks',
      method: 'post',
      data: webhook,
    },
    meta: {
      mutations: {
        [getActionType('WEBHOOKS', 'GET')]: {
          updateData: (current, mutated) => {
            return { ...current, [mutated.id]: mutated }
          },
        },
        [getActionType('WEBHOOK', 'POST')]: {
          updateData: (current, mutated) => {
            return { ...current, ...mutated }
          },
        },
      },

      onSuccess: (newWebhook) => {
        // history.push(`/webhooks/${newWebhook?.data?.id}`)
        store.dispatch(setNewWebhook(newWebhook))
        return newWebhook
      },
    },
  }
}
