import React from 'react'
import isDefined from '@codewell/is-defined'

import { COLORS } from '../theme'

import Info, { Props as InfoProps } from './Info'

export type Props = {
  onClick?: Function
} & InfoProps

const states = {
  'connection.established': {
    label: 'Established',
    color: COLORS.greenLight,
    icon: 'success',
  },
  'connection.requiresInput': {
    label: 'Requires Input',
    color: COLORS.redLight,
    icon: 'input',
  },
  'channelConnection.requiresManualInput': {
    label: 'Requires Manual Input',
    color: COLORS.redLight,
    icon: 'input',
  },
  'channelConnection.requested': {
    label: 'Requested',
    color: COLORS.yellowLight,
    icon: 'requested',
  },
}

const getStateStatus = (status) => states[status]

const InfoConnectionStatus: React.FC<Props> = ({ color, status, ...rest }) => {
  const stateStatus = getStateStatus(status)

  const label = stateStatus?.label || status
  return (
    <Info
      color={color || stateStatus?.color}
      label={label}
      icon={stateStatus?.icon}
      css={styles.root}
      ignoreAdjustments={isDefined(stateStatus)}
      {...rest}
    />
  )
}

const styles = {
  root: {
    position: 'relative',

    margin: 0,

    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
}

export default InfoConnectionStatus
