import React from 'react'

import { COLORS } from '../theme'
import { stringToColor } from '../utils/functions'

import Info, { Props as InfoProps } from './Info'

export type Props = {
  onClick?: Function
} & InfoProps

const InfoTag: React.FC<Props> = ({ color, label, tooltip = 'TAG', ...rest }) => {
  return <Info color={color || stringToColor(label)} label={label} {...rest} tooltip={tooltip} css={styles.root} />
}

const styles = {
  root: {
    position: 'relative',
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    paddingLeft: 18,
    paddingRight: 5,
    paddingBottom: 7,
    margin: 0,

    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',

    height: 22,

    '::before': {
      position: 'absolute',
      content: '""',
      background: COLORS.white,
      width: 8,
      height: 8,
      borderRadius: 10,

      left: 8,
      top: 7,
    },
  },
}

export default InfoTag
