import React from 'react'

const ExclamationGlyph = ({ color, ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.90834 3.71863C10.8263 2.08663 13.1747 2.08663 14.0915 3.71863L20.7875 15.6226C21.6875 17.2234 20.5319 19.1986 18.6971 19.1986H5.30394C3.46794 19.1986 2.31234 17.2234 3.21234 15.6226L9.90834 3.71863ZM13.1999 15.5998C13.1999 15.9181 13.0735 16.2233 12.8485 16.4484C12.6234 16.6734 12.3182 16.7998 11.9999 16.7998C11.6817 16.7998 11.3765 16.6734 11.1514 16.4484C10.9264 16.2233 10.7999 15.9181 10.7999 15.5998C10.7999 15.2816 10.9264 14.9763 11.1514 14.7513C11.3765 14.5263 11.6817 14.3998 11.9999 14.3998C12.3182 14.3998 12.6234 14.5263 12.8485 14.7513C13.0735 14.9763 13.1999 15.2816 13.1999 15.5998V15.5998ZM11.9999 5.99983C11.6817 5.99983 11.3765 6.12626 11.1514 6.3513C10.9264 6.57634 10.7999 6.88157 10.7999 7.19983V10.7998C10.7999 11.1181 10.9264 11.4233 11.1514 11.6484C11.3765 11.8734 11.6817 11.9998 11.9999 11.9998C12.3182 11.9998 12.6234 11.8734 12.8485 11.6484C13.0735 11.4233 13.1999 11.1181 13.1999 10.7998V7.19983C13.1999 6.88157 13.0735 6.57634 12.8485 6.3513C12.6234 6.12626 12.3182 5.99983 11.9999 5.99983Z"
      fill={color}
    />
  </svg>
)

export default ExclamationGlyph
