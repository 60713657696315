import axios from 'axios'
import get from 'lodash/get'

import { URL } from '../utils/globals'
import { isPast } from '../utils/functions'

export const getToken = () => {
  const token = localStorage.getItem('vz.at')

  if (!token) return null
  if (isPast(localStorage.getItem('vz.ea'))) return null

  return token
}

export const buildBaseUrl = async () => {
  const protocol = process.env.REACT_APP_VENZEE_API_BASE_PROTOCOL
  const appBaseUrl = process.env.REACT_APP_VENZEE_APP_BASE_URL
  const apiBaseUrl = process.env.REACT_APP_VENZEE_API_BASE_URL
  const version = process.env.REACT_APP_VENZEE_API_VERSION

  URL.api = `${protocol}://${apiBaseUrl}/${version}`
  URL.app = `${protocol}://${appBaseUrl}`

  axios.defaults.baseURL = URL.api

  return true
}

export const setHeaders = async () => {
  const token = await getToken()
  if (!token) return false

  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

  return true
}

export const startRequestInterceptors = (_store, history) => {
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      // This is just a mocked example of how we could take on HTTP responses. We should iterate over the cases
      // and create different files for each case, so we can use it in a more flexible way, and less if-dependant.
      if (get(error, 'response.data.type') === 'authentication_error') {
        history.push('/logout', {
          type: 'session_expired',
          message: 'Your Session has expired for security purposes. Please log back in to continue',
          from: history.location.pathname,
        })
      } else if (get(error, 'response.data.type') === 'trial_expired') {
        history.push('/logout', {
          type: 'trial_expired',
          message: 'Your Trial has expired. Please get in touch with us or the Owner of your Facility',
          from: history.location.pathname,
        })
      } else if (get(error, 'response.data.type') === 'user_blocked') {
        history.push('/logout', {
          type: 'user_blocked',
          message: 'Your access has been blocked. Please get in touch with the Owner of your Facility',
          from: history.location.pathname,
        })
      } else if (get(error, 'response.data.type') === 'account_blocked') {
        history.push('/logout', {
          type: 'account_blocked',
          message: 'Your account has been blocked. Please get in touch with us if you think this is in error',
          from: history.location.pathname,
        })
      } else if (get(error, 'response.data.type') === 'no_tenant_access') {
        history.push('/logout', {
          type: 'no_tenant_access',
          message:
            'You do not have access to this facility. Please check that the URL is correct and get in touch with the person that invited you to join',
          from: history.location.pathname,
        })
      }

      // Throw errr again (may be need for some other catch)
      return Promise.reject(error.response)
    },
  )
}

export const request = () => axios
