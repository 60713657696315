import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'

import { COLORS } from '../../../../theme'

const MappingRulePillConvert = ({ rule, loading, onClick, className }) => (
  <div css={styles.root} onClick={onClick} className={className}>
    <div css={styles.pillHeader} className={className}>
      {loading && (
        <div css={{ marginRight: '0.3rem' }}>
          <CircleSpinner size={14} />
        </div>
      )}
      <div css={{ height: 16 }}>{rule?.rule?.key}</div>
    </div>
    <div css={styles.pillContent}>{rule?.rule?.options?.type}</div>
  </div>
)

const styles = {
  root: {
    cursor: 'pointer',
    backgroundColor: COLORS.purple40,
    borderRadius: 3,
    height: 30,
    marginRight: '0.4rem',
    color: COLORS.white,
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${COLORS.purple80}`,
    borderBottomWidth: 3,
    marginTop: '0.4rem',

    '&.is-open': {
      border: `2px solid ${COLORS.yellow60}`,
      backgroundColor: COLORS.yellow20,
    },

    '&.is-source-document-rule': {
      backgroundColor: COLORS.orchidPaleLight,
      border: `2px dashed ${COLORS.orchidPale80}`,
      cursor: 'auto',
      color: COLORS.orchidPale,
    },
  },

  pillHeader: {
    backgroundColor: COLORS.purple60,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: '0.1rem 0.5rem 0 0.5rem',

    '&.is-open': {
      backgroundColor: COLORS.yellow40,
    },

    '&.is-source-document-rule': {
      backgroundColor: COLORS.orchidPale40,
      color: COLORS.white,
    },
  },

  pillContent: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    fontFamily: 'Courier New',
    padding: '0 0.5rem',
  },
}

export default MappingRulePillConvert
