import React from 'react'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import isEmpty from 'lodash/isEmpty'
import isDefined from '@codewell/is-defined'

import List from '../../../components/List'
import SourceDocumentListItem from '../../../components/list/SourceDocumentListItem'

import useAPI, { getActionType } from '../../../hooks/useAPI'
import { DistributionPackage } from '../../../declarations'

type Props = {
  release: any
  distributionPackage: DistributionPackage
}

const SourceDocumentsTabPage: React.FC<Props> = ({ release, distributionPackage }) => {
  const sourceDocuments = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENTS') }))

  const sourceDocumentsList = sourceDocuments?.data?._embedded?.sourceDocuments?.map((item) => item.document)

  useAPI('SOURCE_DOCUMENTS', `/releases/${release?.id}/source-documents`, isDefined(sourceDocuments?.data), 'GET', true)

  const isLoading = sourceDocuments?.pending > 0
  const isEmptyList = isEmpty(sourceDocumentsList) || sourceDocuments?.data?.count === 0

  return (
    <List feature="source_documents" loading={isLoading} empty={isEmptyList} gridGap="0" css={{ overflowX: 'auto' }}>
      {sourceDocumentsList &&
        sourceDocumentsList.map((sourceDocument) => (
          <SourceDocumentListItem
            action={sourceDocument?.action}
            key={sourceDocument?.key}
            documentKey={distributionPackage?.documentKey}
            sourceDocument={sourceDocument}
            to={`/releases/${release?.id}/source-documents/${sourceDocument?.[distributionPackage?.documentKey]}`}
          />
        ))}
    </List>
  )
}

export default SourceDocumentsTabPage
