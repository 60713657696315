import { history, store } from '../../setup/store'
import { getActionType } from '../../hooks/useAPI'
import { SourceDocumentSchema } from '../../declarations'
import { setNewSourceDocumentSchema } from '../common'
import { normalize } from './'

export const updateSourceDocumentSchema = (sourceDocumentSchema) => {
  const { id } = sourceDocumentSchema

  const data = normalize(sourceDocumentSchema)

  return {
    type: getActionType('SOURCE_DOCUMENT_SCHEMA', 'PATCH'),
    request: {
      url: `/source-document-schemas/${id}`,
      method: 'patch',
      data,
    },
    meta: {
      mutations: {
        [getActionType('SOURCE_DOCUMENT_SCHEMA', 'GET')]: {
          updateData: (_, mutated) => {
            return mutated
          },
        },
      },
    },
  }
}

export const deleteSourceDocumentSchema = (sourceDocumentSchema) => {
  const id = sourceDocumentSchema?.id

  return {
    // We use DELETE here because is the type of the action
    type: getActionType('SOURCE_DOCUMENT_SCHEMA', 'DELETE'),
    request: {
      url: `/source-document-schemas/${id}`,
      method: 'delete',
    },
    meta: {
      onSuccess: (data) => {
        history.push('/source-document-schemas')
        return data
      },
    },
  }
}

export const createSourceDocumentSchema = (sourceDocumentSchema: SourceDocumentSchema) => {
  return {
    type: getActionType('SOURCE_DOCUMENT_SCHEMA', 'POST'),
    request: {
      url: '/source-document-schemas',
      method: 'post',
      data: sourceDocumentSchema,
    },
    meta: {
      mutations: {
        [getActionType('SOURCE_DOCUMENT_SCHEMA', 'GET')]: {
          updateData: (current, mutated) => {
            return { ...current, [mutated.id]: mutated }
          },
        },
        [getActionType('SOURCE_DOCUMENT_SCHEMA', 'POST')]: {
          updateData: (current, mutated) => {
            return { ...current, ...mutated }
          },
        },
      },

      onSuccess: (sourceDocumentSchema) => {
        store.dispatch(setNewSourceDocumentSchema(sourceDocumentSchema))
        return sourceDocumentSchema
      },
    },
  }
}

export const createSourceDocumentSchemaRule = ({ id, rules }) => {
  return {
    type: getActionType('SOURCE_DOCUMENT_SCHEMA_RULE', 'POST'),
    request: {
      url: `/source-document-schemas/${id}/document-rules`,
      method: 'post',
      data: rules,
    },
    meta: {},
  }
}
