import React from 'react'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import toArray from 'lodash/toArray'
import isEmpty from 'lodash/isEmpty'
import isDefined from '@codewell/is-defined'

import List from '../../../components/List'
import SchemaListItem from '../../../components/list/SchemaListItem'
import SchemaSubtitle from '../../../components/layout/page/subtitle/SchemaSubtitle'
import StatusEmpty from '../../../components/StatusEmpty'

import useAPI, { getActionType } from '../../../hooks/useAPI'
import { Connection } from '../../../declarations'

type Props = {
  connection: Connection
}

const SchemasTabPage: React.FC<Props> = ({ connection }) => {
  const schemas = useSelector(getQuerySelector({ type: getActionType('SCHEMAS') }))

  const schemasList = toArray(schemas?.data).slice(0, 50)

  const isConnectionEstablished = connection?.status === 'connection.established'
  const skipApiCall = !isConnectionEstablished || isDefined(schemas?.data)

  const schemasToRender = React.useMemo(() => {
    return schemasList.map((schema) => (
      <SchemaListItem key={schema.id} schema={schema} to={`/connections/${connection?.id}/schemas/${schema.id}`}>
        <SchemaSubtitle
          allowCopy={false}
          id={schema?.id}
          attributesAvailable={schema?.attributesAvailable}
          lastUpdated={schema?.lastUpdated}
          title={schema?.title}
        />
      </SchemaListItem>
    ))
  }, [schemas?.data])

  useAPI('SCHEMAS', `/connections/${connection?.id}/schemas`, skipApiCall)

  const isLoading = schemas?.pending > 0
  const isEmptyList = isEmpty(schemasList)

  return (
    <div css={{ display: 'flex', justifyContent: 'center', height: isConnectionEstablished ? 'fit-content' : 500 }}>
      {isConnectionEstablished && (
        <List feature="schemas" loading={isLoading} empty={isEmptyList} gridGap="0">
          {schemasToRender}
        </List>
      )}
      {!isConnectionEstablished && <StatusEmpty feature="schemas" message="Establish connection to access channel schemas" />}
    </div>
  )
}

export default SchemasTabPage
