import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../../theme'
import { TOOLTIP_MAPPING_RULES } from '../../../theme/definitions/tooltips'

import Header from '../../Header'

type Props = {
  setView: Function
}

const MappingRuleMenunItem = ({ view, activeRule, disabled, setView, setActiveRule, className }) => {
  const classNames = clsx({
    [className]: className,
    'is-active': activeRule === view,
    'is-disabled': disabled,
  })

  return (
    <div css={styles.ruleItem} onClick={() => setView(view)} onMouseEnter={() => setActiveRule(view)} className={classNames}>
      {view}
    </div>
  )
}

const MappingRuleMenu: React.FC<Props> = ({ setView, canAddTransformRule }) => {
  const [activeRule, setActiveRule] = React.useState()

  return (
    <div css={styles.root}>
      <Header title="Create a new Rule" css={{ borderTop: 0 }} />
      <div css={styles.content}>
        <div css={styles.rules}>
          <MappingRuleMenunItem view="copy" activeRule={activeRule} setView={setView} setActiveRule={setActiveRule} />
          <MappingRuleMenunItem view="literal" activeRule={activeRule} setView={setView} setActiveRule={setActiveRule} />
          <MappingRuleMenunItem
            view="convert"
            activeRule={activeRule}
            setView={setView}
            setActiveRule={setActiveRule}
            disabled={!canAddTransformRule}
          />
          <MappingRuleMenunItem
            view="replace"
            activeRule={activeRule}
            setView={setView}
            setActiveRule={setActiveRule}
            disabled={!canAddTransformRule}
          />
        </div>
        <div css={styles.ruleDescription}>
          {activeRule && (
            <>
              <Header title={activeRule} icon="rules" css={{ borderTop: 0 }} />
              <div css={{ padding: '1rem' }}>{TOOLTIP_MAPPING_RULES[activeRule]}</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const styles = {
  root: {
    minHeight: 250,
    borderTop: `1px solid ${COLORS.gray40}`,
  },

  content: {
    display: 'grid',
    gridTemplateColumns: '200px auto',
    minHeight: 250,
  },

  rules: {
    display: 'flex',
    flexDirection: 'column',

    borderRight: `1px solid ${COLORS.gray40}`,
    padding: '0.5rem',
    height: 'auto',
  },

  ruleDescription: {},

  ruleItem: {
    textTransform: 'uppercase',
    fontSize: '0.9rem',
    padding: '0.25rem 0.5rem',
    borderRadius: 3,
    cursor: 'pointer',

    '&.is-active': {
      backgroundColor: COLORS.blue40,
      color: COLORS.white,
    },

    '&.is-disabled': {
      opacity: 0.75,
      pointerEvents: 'none',
    },
  },
}

export default MappingRuleMenu
