import React from 'react'

import PageSubtitle from '../PageSubtitle'
import InfoTag from '../../../InfoTag'

export type Props = {
  allowCopy?: boolean
  id?: string
  tag?: string
  lastUpdated?: number
}

const SourceDocumentSchemaSubtitle: React.FC<Props> = ({ allowCopy, id = '', tag, lastUpdated }) => {
  return (
    <PageSubtitle allowCopy={allowCopy} id={id} lastUpdated={lastUpdated}>
      {tag && <InfoTag label={tag} />}
    </PageSubtitle>
  )
}

export default SourceDocumentSchemaSubtitle
