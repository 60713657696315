import React from 'react'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import toArray from 'lodash/toArray'
import isEmpty from 'lodash/isEmpty'
import isDefined from '@codewell/is-defined'

import List from '../../../components/List'
import DocumentRuleListItem from '../../../components/list/DocumentRuleListItem'

import useAPI, { getActionType } from '../../../hooks/useAPI'

type Props = {
  distributionPackage: any
}

const ReleasesTabPage: React.FC<Props> = ({ distributionPackage }) => {
  const documentRules = useSelector(getQuerySelector({ type: getActionType('DOCUMENT_RULES') }))

  const documentRulesList = toArray(documentRules?.data)

  useAPI(
    'DOCUMENT_RULES',
    `/distribution-packages/${distributionPackage?.id}/document-rules`,
    !isDefined(distributionPackage?.id) || documentRulesList?.length > 0,
  )

  const isLoading = documentRules?.pending > 0
  const isEmptyList = isEmpty(documentRulesList)

  return (
    <List feature="document_rules" loading={isLoading} empty={isEmptyList} gridGap="0">
      {documentRulesList &&
        documentRulesList.map((documentRule) => (
          <DocumentRuleListItem
            key={documentRule.id}
            documentRule={documentRule}
            to={`/distribution-packages/${distributionPackage?.id}/document-rules/${documentRule?.id}`}
          />
        ))}
    </List>
  )
}

export default ReleasesTabPage
