import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { matchPath, useHistory } from 'react-router'
import { produce } from 'immer'
import { lighten } from 'polished'
import { CircleSpinner } from 'react-spinners-kit'

import Animated from './Animated'
import Portal from './Portal'
import Overlay from './Overlay'
import ProgressBar from './ProgressBar'
import Button from './Button'
import Glyph from './Glyph'
import SummonOverlay from './SummonOverlay'

import UploadSourceDocumentsErrorsOverlay from './overlays/UploadSourceDocumentsErrorsOverlay'

import { COLORS, FEATURES, SHADOW } from '../theme'
import { request } from '../modules/axios'

import {
  updateSourceDocumentsCurrentUpload,
  errorSourceDocumentsCurrentUpload,
  setSourceDocumentsLoaderUpload,
  resetSourceDocumentsCurrentUpload,
} from '../actions/common'

const uploadSourceDocuments = async (sourceDocuments, featureId, feature, dispatch) => {
  const url = `${FEATURES[feature]?.parentpath}/${featureId}/source-documents`
  try {
    const documents = sourceDocuments.map((document) => ({ document: document, action: 'upsert' }))
    return await request().put(url, documents)
  } catch (errors) {
    dispatch(errorSourceDocumentsCurrentUpload(errors))
  }
}

const SourceDocumentsUploader: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const totalChunks = useSelector((state) => state.common?.uploader?.totalChunks)
  const sourceDocumentsCurrentUpload = useSelector((state) => state.common?.uploader?.sourceDocumentsCurrentUpload)
  const uploading = useSelector((state) => state.common?.uploader?.upload)
  const show = useSelector((state) => state.common?.uploader?.show)
  const errors = useSelector((state) => state.common?.uploader?.errors)
  const [showErrors, setShowErrors] = React.useState(false)

  const featureId = useSelector((state) => state.common?.uploader?.id)
  const feature = useSelector((state) => state.common?.uploader?.feature)

  const [chunksUpdated, setChunksUpdated] = React.useState(0)

  React.useEffect(() => {
    if (sourceDocumentsCurrentUpload?.length === 0) dispatch(setSourceDocumentsLoaderUpload(false))
  }, [sourceDocumentsCurrentUpload])

  const featureUrl = `${FEATURES[feature]?.parentpath}/${featureId}`

  const isActive = !!matchPath(history?.location?.pathname, featureUrl)

  const handleDismiss = () => {
    if (isActive) dispatch(resetSourceDocumentsCurrentUpload())
  }

  React.useEffect(() => {
    const update = async () => {
      if (sourceDocumentsCurrentUpload?.length > 0) {
        const [currentChunk, updatedSourceDocuments] = produce(sourceDocumentsCurrentUpload, (draft) => {
          const updatedDocuments = draft.concat()
          const currentChunk = updatedDocuments.shift()

          return [currentChunk, updatedDocuments]
        })

        if (currentChunk) {
          await uploadSourceDocuments(currentChunk, featureId, feature, dispatch)

          dispatch(updateSourceDocumentsCurrentUpload(updatedSourceDocuments))
        }
      }
    }

    update()
    setChunksUpdated(totalChunks - sourceDocumentsCurrentUpload?.length)
  }, [sourceDocumentsCurrentUpload])

  return (
    <Portal type="uploader">
      <Animated show={show} animation="fade">
        <Overlay hideClose onClose={() => setShow(false)} minHeight="8" maxWidth="30" position="bottom">
          <div css={styles.root}>
            {/* uploader yeaaa {chunksUpdated} / {totalChunks}{' '} */}
            <ProgressBar percent={(chunksUpdated * 100) / totalChunks} color={uploading ? 'purple' : 'green'} size={8} />
            <div css={{ display: 'flex', height: '100%', flexGrow: '1', padding: '0.5rem' }}>
              <div css={{ display: 'flex', width: '4rem', alignItems: 'center', justifyContent: 'flex-end' }}>
                {uploading && <CircleSpinner size={25} color={COLORS.orchidPaleLight} />}
                {!uploading && <Glyph glyph="check" size={35} color={COLORS.green} />}
              </div>
              <div css={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: '1' }}>
                {uploading && (
                  <div
                    css={{
                      textTransform: 'uppercase',
                      fontSize: '1.1rem',
                      letterSpacing: '-0.6px',
                      textAlign: 'center',
                    }}
                  >
                    Importing your source documents
                  </div>
                )}
                {!uploading && (
                  <div css={{ paddingRight: '1rem' }}>
                    <div
                      css={{
                        textTransform: 'uppercase',
                        fontSize: '1.1rem',
                        letterSpacing: '-0.6px',
                        textAlign: 'center',
                      }}
                    >
                      Source Documents Updated!
                    </div>
                    <div css={{ display: 'flex', justifyContent: 'center', marginTop: '0.5rem' }}>
                      <SummonOverlay
                        isOpen={showErrors}
                        onClose={() => setShowErrors(false)}
                        overlay={<UploadSourceDocumentsErrorsOverlay />}
                      />
                      {errors.length > 0 && (
                        <Button
                          label={`Errors (${errors.length})`}
                          css={[{ marginRight: '1rem' }, styles.button]}
                          onClick={() => setShowErrors(true)}
                        />
                      )}
                      <Button
                        label={isActive ? 'Dismiss' : `Go to ${FEATURES[feature]?.title}`}
                        to={isActive ? '' : `${featureUrl}/source-documents`}
                        onClick={handleDismiss}
                        css={styles.button}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Overlay>
      </Animated>
    </Portal>
  )
}

const styles = {
  root: {
    background: 'white',
    height: '100%',
    border: `1px solid ${COLORS.gray40}`,
    borderRadius: 6,
    boxShadow: SHADOW(3, lighten(0.025, COLORS.gray40)),

    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  button: {
    textTransform: 'uppercase',
    padding: '0.25rem 0.75rem',
    height: 'auto',
    fontSize: '0.9rem',
    letterSpacing: '-0.6px',
  },
}

export default SourceDocumentsUploader
