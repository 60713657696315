export const SET_RULES = 'ui/mapping/SET_RULES'
export const UPDATE_RULE = 'ui/mapping/UPDATE_RULE'
export const CREATE_RULE = 'ui/mapping/CREATE_RULE'
export const DELETE_RULE = 'ui/mapping/DELETE_RULE'
export const CLEAR_RULES = 'ui/mapping/CLEAR_RULES'

export const setRules = (rules) => ({ type: SET_RULES, rules })
export const updateRule = (rule) => ({ type: UPDATE_RULE, rule })
export const createRule = (rules) => ({ type: CREATE_RULE, rules })
export const deleteRule = (rule) => ({ type: DELETE_RULE, rule })
export const clearRules = () => ({ type: CLEAR_RULES })
