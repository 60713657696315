import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import toArray from 'lodash/toArray'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import reverse from 'lodash/reverse'

import Page from '../../components/layout/page/Page'
import PageAction from '../../components/layout/page/PageAction'
import NewSourceDocumentSchema from '../../components/overlays/new/NewSourceDocumentSchema'
import CardSection from '../../components/CardSection'
import ListLoader from '../../components/ListLoader'

import SourceDocumentSchemaListItem from '../../components/list/SourceDocumentSchemaListItem'

import useAPI, { getActionType } from '../../hooks/useAPI'

const SourceDocumentSchemas: React.FC = () => {
  const sourceDocumentSchemas = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMAS') }))

  const sourceDocumentSchemasList = toArray(sourceDocumentSchemas?.data)

  useAPI('SOURCE_DOCUMENT_SCHEMAS', '/source-document-schemas')

  const isLoading = sourceDocumentSchemas?.pending > 0
  const isEmptyList = (isEmpty(sourceDocumentSchemasList) || sourceDocumentSchemas?.data?.count === 0) && !isLoading

  return (
    <Page
      feature="source_document_schemas"
      title="Source Document Schemas"
      hideBackButton
      actions={<PageAction to="/source-document-schemas/new" label="Create New" icon="sourceDocumentSchemas" />}
    >
      <CardSection
        loading={isLoading}
        empty={isEmptyList}
        feature="source_document_schemas"
        emptyMessage="There are no Source Document Schemas"
        customLoader={() => <ListLoader />}
      >
        {sourceDocumentSchemasList &&
          reverse(sortBy(sourceDocumentSchemasList, 'lastUpdated')).map((sourceDocumentSchema) => (
            <SourceDocumentSchemaListItem
              key={sourceDocumentSchema.id}
              sourceDocumentSchema={sourceDocumentSchema}
              to={`/source-document-schemas/${sourceDocumentSchema.id}`}
              lastUpdated={sourceDocumentSchema.lastUpdated}
            />
          ))}
      </CardSection>
      <Switch>
        <Route exact path="/source-document-schemas/new" component={NewSourceDocumentSchema} />
      </Switch>
    </Page>
  )
}

export default SourceDocumentSchemas
