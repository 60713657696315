export const METHOD = {
  GET: 'GET',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  POST: 'POST',
  CLEAR: 'CLEAR',
  NEW: 'NEW',
}

export const ENTITY = {
  DISTRIBUTION_PACKAGES: 'DISTRIBUTION_PACKAGES',
  DISTRIBUTION_PACKAGE: 'DISTRIBUTION_PACKAGE',
  DISTRIBUTION_PACKAGE_SOURCE_DOCUMENTS: 'DISTRIBUTION_PACKAGE_SOURCE_DOCUMENTS',
  CONNECTIONS: 'CONNECTIONS',
  CONNECTIONS_PAGES: 'CONNECTIONS_PAGES',
  CONNECTION: 'CONNECTION',
  CHANNELS: 'CHANNELS',
  CHANNEL: 'CHANNEL',
  SOURCE_DOCUMENTS: 'SOURCE_DOCUMENTS',
  SOURCE_DOCUMENTS_PAGES: 'SOURCE_DOCUMENTS_PAGES',
  SOURCE_DOCUMENT: 'SOURCE_DOCUMENT',
  VALID_SOURCE_DOCUMENTS: 'VALID_SOURCE_DOCUMENTS',
  VALID_SOURCE_DOCUMENT: 'VALID_SOURCE_DOCUMENT',
  ERROR_SOURCE_DOCUMENTS: 'ERROR_SOURCE_DOCUMENTS',
  ERROR_SOURCE_DOCUMENTS_PAGES: 'ERROR_SOURCE_DOCUMENTS_PAGES',
  ERROR_SOURCE_DOCUMENT: 'ERROR_SOURCE_DOCUMENT',
  SOURCE_DOCUMENT_SCHEMAS: 'SOURCE_DOCUMENT_SCHEMAS',
  SOURCE_DOCUMENT_SCHEMA: 'SOURCE_DOCUMENT_SCHEMA',
  SOURCE_DOCUMENT_SCHEMA_RULE: 'SOURCE_DOCUMENT_SCHEMA_RULE',
  SOURCE_DOCUMENT_GROUPS: 'SOURCE_DOCUMENT_GROUPS',
  SOURCE_DOCUMENT_GROUP: 'SOURCE_DOCUMENT_GROUP',
  SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS: 'SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS',
  SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS_PAGES: 'SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS_PAGES',
  SOURCE_DOCUMENT_GROUPS_PAGES: 'SOURCE_DOCUMENT_GROUPS_PAGES',
  RELEASES: 'RELEASES',
  RELEASES_PAGES: 'RELEASES_PAGES',
  RELEASE: 'RELEASE',
  STAGED_RELEASES: 'STAGED_RELEASES',
  STAGED_RELEASES_PAGES: 'STAGED_RELEASES_PAGES',
  STAGED_RELEASE: 'STAGED_RELEASE',
  WEBHOOKS: 'WEBHOOKS',
  WEBHOOK: 'WEBHOOK',
  DOCUMENT_RULES: 'DOCUMENT_RULES',
  DOCUMENT_RULE: 'DOCUMENT_RULE',
  SCHEMAS: 'SCHEMAS',
  SCHEMA: 'SCHEMA',
  HIERARCHY_RULES: 'HIERARCHY_RULES',
  HIERARCHY_RULE: 'HIERARCHY_RULE',
}

export type ApiMethod = keyof typeof METHOD
export type ApiEntity = keyof typeof ENTITY
