import { SourceDocumentGroup } from '../../declarations'
import { getActionType } from '../../hooks/useAPI'
import { store } from '../../setup/store'

import { setNewSourceDocumentGroup } from '../common'
import { normalize } from './'

export const updateSourceDocumentGroup = (sourceDocumentGroup) => {
  const { id } = sourceDocumentGroup

  const data = normalize(sourceDocumentGroup)

  return {
    // We use PATCH here because is the type of the action
    type: getActionType('SOURCE_DOCUMENT_GROUP', 'PATCH'),
    request: {
      url: `/source-document-groups/${id}`,
      method: 'patch',
      data,
    },
    meta: {
      mutations: {
        [getActionType('SOURCE_DOCUMENT_GROUP', 'GET')]: {
          updateData: (_, mutated) => {
            return mutated
          },
        },
      },
    },
  }
}

export const deleteSourceDocumentGroup = (sourceDocumentGroup: SourceDocumentGroup) => {
  const { id } = sourceDocumentGroup

  return {
    // We use DELETE here because is the type of the action
    type: getActionType('SOURCE_DOCUMENT_GROUP', 'DELETE'),
    request: {
      url: `/source-document-groups/${id}`,
      method: 'delete',
    },
    meta: {
      mutations: {},
    },
  }
}

export const createSourceDocumentGroup = (sourceDocumentGroup: SourceDocumentGroup) => {
  return {
    type: getActionType('SOURCE_DOCUMENT_GROUP', 'POST'),
    request: {
      url: '/source-document-groups',
      method: 'post',
      data: sourceDocumentGroup,
    },
    meta: {
      mutations: {
        [getActionType('SOURCE_DOCUMENT_GROUP', 'GET')]: {
          updateData: (current, mutated) => {
            return { ...current, [mutated.id]: mutated }
          },
        },
        [getActionType('SOURCE_DOCUMENT_GROUP', 'POST')]: {
          updateData: (current, mutated) => {
            return { ...current, ...mutated }
          },
        },
      },

      onSuccess: (sourceDocumentGroup) => {
        store.dispatch(setNewSourceDocumentGroup(sourceDocumentGroup))
        return sourceDocumentGroup
      },
    },
  }
}

export const deleteSourceDocumentGroupSourceDocuments = (sourceDocumentGroup: SourceDocumentGroup) => {
  const { id } = sourceDocumentGroup

  return {
    type: getActionType('SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS', 'DELETE'),
    request: {
      url: `/source-document-groups/${id}/source-documents`,
      method: 'delete',
    },
    meta: {},
  }
}
