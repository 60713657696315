import React from 'react'
import clsx from 'clsx'
import { CircleSpinner } from 'react-spinners-kit'
import { useDetectClickOutside } from 'react-detect-click-outside'
import isDefined from '@codewell/is-defined'
import isEmpty from 'lodash/isEmpty'

import get from 'lodash/get'

import Form from './Form'
import Button from './Button'
import Icon from './Icon'
import Glyph from './Glyph'

import { COLORS } from '../theme'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'
import { Glyph as GlyphType, Icon as IconType } from '../declarations'

type Props = {
  className?: string
  label?: string | Element | EmotionJSX.Element
  labelValue?: string | Element | EmotionJSX.Element
  defaultValues?: object
  editComponent: React.FC<any> | React.ReactElement
  resolver?: any
  model?: string
  id?: string
  disabled?: boolean
  colorIndicator?: string
  icon?: IconType
  iconSize?: number
  glyph?: GlyphType
  glyphColor?: string
  glyphSize?: number
  setMutationData?: Function
  loading?: boolean
  escapedIds?: string[]
} & React.ReactNode

const EditableField: React.FC<Props> = ({
  label,
  labelValue,
  editComponent,
  defaultValues,
  resolver,
  model,
  id,
  disabled,
  colorIndicator,
  icon,
  iconSize,
  glyph,
  glyphColor,
  glyphSize,
  setMutationData,
  loading,
  className,
  escapedIds = [],
}) => {
  const [editMode, setEditMode] = React.useState(false)

  const handleBlur = (e) => {
    let isPrevented = false
    if (e?.type === 'click') {
      isPrevented = !!e.path?.find?.((current) => escapedIds.includes(current.id))
    }
    if (!isPrevented) setEditMode(false)
  }

  const handleSubmit = (data) => {
    setEditMode(false)
    setMutationData?.(data)
  }

  const handleEdit = () => {
    if (!editMode && !disabled) setEditMode(true)
  }

  const onKeyDown = (event) => {
    if (event.keyCode === 27) setEditMode(false)
  }

  const ref = useDetectClickOutside({ onTriggered: handleBlur })

  React.useEffect(() => {
    if (editMode && id) document.getElementById(id)?.focus?.()
  }, [editMode])

  const childrenWithProps = React.useMemo(() => {
    return React.cloneElement(editComponent, {
      ...editComponent?.props,
      onKeyDown: onKeyDown,
      model,
      css: { ...styles.editComponent, ...editComponent?.props?.css },
    })
  }, [defaultValues, model, editComponent])

  const rootClassNames = clsx({
    editableFieldContainer: true,
    [className]: className,
    'is-disabled': disabled || loading,
    'is-editing': editMode,
  })

  const classNames = clsx({
    'is-disabled': disabled || loading,
  })

  const value = get(defaultValues, model)
  const hasValue = isDefined(value) && !isEmpty(value)

  return (
    <div css={styles.root} className={rootClassNames} ref={ref} onClick={handleEdit}>
      <section css={styles.dataContainer}>
        <label css={styles.label} className={classNames}>
          {label}
        </label>
        <div css={styles.data}>
          {icon && <Icon icon={icon} size={iconSize} css={{ marginRight: '0.5rem' }} />}
          {glyph && <Glyph glyph={glyph} size={glyphSize} color={glyphColor} css={{ marginRight: '0.5rem' }} />}
          {colorIndicator && hasValue && <div css={styles.colorIndicator({ color: colorIndicator })} className={classNames} />}

          {!editMode && (
            <span css={styles.idle} className={classNames}>
              {labelValue && labelValue}
              {!labelValue && <>{hasValue ? value : '-'}</>}
            </span>
          )}

          {editMode && (
            <>
              <Form onSubmit={handleSubmit} defaultValues={defaultValues} resolver={resolver} css={styles.form}>
                {childrenWithProps}

                <Button glyph="check" css={styles.submit} />
              </Form>
            </>
          )}
        </div>
      </section>

      {!loading && !editMode && (
        <Button role="button" glyph="edit" glyphSize={24} css={styles.edit} disabled={disabled} className={classNames} />
      )}

      {loading && (
        <div css={{ marginRight: '.5rem', width: 33, display: 'flex', justifyContent: 'center' }}>
          <CircleSpinner size={20} color={COLORS.purplePale40} />
        </div>
      )}
    </div>
  )
}

const styles = {
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem ',
    minWidth: '330px',
    cursor: 'pointer',
    width: '100%',

    '&:hover': {
      outline: `1px solid ${COLORS.orchidPaleLight}`,
      borderRadius: '4px',
      boxShadow: '0px 3px 0px rgb(229 229 229 / 18%)',
    },

    '&.is-editing': {
      outline: `2px solid ${COLORS.yellow40}`,
      borderRadius: '4px',
      boxShadow: '0px 3px 0px rgb(229 229 229 / 18%)',
      padding: '0.5rem 0.75rem 0.75rem 0.75rem',
    },

    '&.is-disabled': {
      cursor: 'auto',
      borderColor: COLORS.gray80,

      '&:hover': {
        outline: 0,
        borderRadius: 0,
        boxShadow: 'none',
      },
    },
  },

  form: {
    gridTemplateColumns: 'auto 40px',
    width: '100%',
  },

  label: {
    color: COLORS.orchidPale,
    fontSize: '0.9rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.01em',
    lineHeight: '30px',
    textTransform: 'uppercase',
    pointerEvents: 'none',

    '&.is-disabled': {
      color: COLORS.gray80,
    },
  },

  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  data: {
    display: 'flex',
    alignItems: 'center',
  },

  colorIndicator: ({ color }) => {
    return {
      backgroundColor: color,
      borderRadius: '50%',
      marginRight: '0.5rem',
      height: 15,
      minWidth: 15,

      '&.is-disabled': {
        backgroundColor: COLORS.gray80,
      },
    }
  },

  idle: {
    alignItems: 'center',
    color: COLORS.orchid,
    display: 'flex',
    fontFamily: 'Amiko',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.01em',
    lineHeight: '27px',
    pointerEvents: 'none',
    marginTop: 5,
    marginBottom: 5,

    '&.is-disabled': {
      color: COLORS.gray80,
    },
  },
  edit: {
    background: 'none',
    border: 'none',
    visibility: 'hidden',
    pointerEvents: 'none',

    '.editableFieldContainer:hover &': {
      visibility: 'visible',
    },

    '&.is-disabled': {
      '.editableFieldContainer:hover &': {
        visibility: 'hidden',
      },
    },
  },
  submit: {
    background: 'none',
    border: 'none',
    // height: 20,
  },
  editComponent: {
    maxHeight: '27px',
  },
}

export default EditableField
