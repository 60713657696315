import React from 'react'
import { Route, Redirect, useParams, useRouteMatch, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getQuerySelector, getMutationSelector, resetRequests } from '@redux-requests/core'
import isDefined from '@codewell/is-defined'
import toArray from 'lodash/toArray'
import * as Yup from 'yup'

import { stringToColor, readSpreadsheet } from '../../utils/functions'

import Page from '../../components/layout/page/Page'
import DistributionPackagesSubtitle from '../../components/layout/page/subtitle/DistributionPackagesSubtitle'
import CardSection from '../../components/CardSection'
import Tabs from '../../components/tabs/Tabs'
import Tab from '../../components/tabs/Tab'
import TabList from '../../components/tabs/TabList'
import Separator from '../../components/Separator'
import Confirm from '../../components/Confirm'

import SummonOverlay from '../../components/SummonOverlay'
import SourceDocumentOverlay from '../../components/overlays/SourceDocumentOverlay'
import UploadSourceDocumentsOverlay from '../../components/overlays/UploadSourceDocumentsOverlay'
import DocumentRuleOverlay from '../../components/overlays/DocumentRuleOverlay'

import CopyableField from '../../components/CopyableField'
import EditableFieldInputText from '../../components/EditableFieldInputText'
import EditableConnectionsSelector from '../../components/EditableConnectionsSelector'
import EditableRawSchemaSelector from '../../components/EditableRawSchemaSelector'
import EditableSourceDocumentSchemaSelector from '../../components/EditableSourceDocumentSchemaSelector'
import EditableFieldGroup from '../../components/EditableFieldGroup'
import EditableSection from '../../components/EditableSection'
import EditableSectionContent from '../../components/EditableSectionContent'
import EditableFieldGroupHeader from '../../components/EditableFieldGroupHeader'
import PageAction from '../../components/layout/page/PageAction'

import ReleasesTabPage from './tabs/releases'
import StagedReleasesTabPage from './tabs/staged-releases'
import DocumentRulesTabPage from './tabs/document-rules'
import SourceDocumentsTabPage from './tabs/source-documents'

import { setSpreadsheet } from '../../actions/common'
import useAPI, { useAPIMutation, getActionType } from '../../hooks/useAPI'
import EditableSourceDocumentGroupsSelector from '../../components/EditableSourceDocumentGroupSelector'
import { RootState } from '../../setup/storeConfig'
import { DistributionPackage, SourceDocumentGroup } from '../../declarations'

const DistributionPackagePage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { url } = useRouteMatch()
  const dispatch = useDispatch()
  const history = useHistory()
  const fileRef: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null)

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        connectionId: Yup.string().required(),
        channelSchemaId: Yup.string().nullable().required('Please select a valid schema'),
      }),
    [],
  )

  const [modalOpen, setModalOpen] = React.useState(false)
  const [mutationData, setMutationDataState] = React.useState<DistributionPackage>()
  const [selectedConnection, setSelectedConnection] = React.useState()
  const [deleteState, setDeleteState] = React.useState(false)
  const [deleteSourceDocumentsState, setDeleteSourceDocumentsState] = React.useState(false)
  const [getMoreState, setGetMoreState] = React.useState(false)
  const [sourceDocumentGroupsPages, setSourceDocumentGroupsPages] = React.useState<SourceDocumentGroup[]>([])

  const mutation = useSelector(getMutationSelector({ type: getActionType('DISTRIBUTION_PACKAGE', 'PATCH') }))
  const deleteMutation = useSelector(getMutationSelector({ type: getActionType('DISTRIBUTION_PACKAGE', 'DELETE') }))
  const deleteSourceDocumentsMutation = useSelector(
    getMutationSelector({ type: getActionType('DISTRIBUTION_PACKAGE_SOURCE_DOCUMENTS', 'DELETE') }),
  )

  const distributionPackage = useSelector(getQuerySelector({ type: getActionType('DISTRIBUTION_PACKAGE') }))
  const sourceDocuments = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENTS') }))
  const sourceDocumentGroupSourceDocuments = useSelector(
    getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS') }),
  )
  const connection = useSelector(getQuerySelector({ type: getActionType('CONNECTION') }))
  const schema = useSelector(getQuerySelector({ type: getActionType('SCHEMA') }))
  const schemas = useSelector(getQuerySelector({ type: getActionType('SCHEMAS') }))
  const connections = useSelector(getQuerySelector({ type: getActionType('CONNECTIONS') }))
  const sourceDocumentSchemas = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMAS') }))
  const sourceDocumentSchema = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMA') }))
  const sourceDocumentGroups = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUPS') }))
  const sourceDocumentGroup = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUP') }))
  const sourceDocumentGroupsNextPage = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUPS_PAGES') }))

  const uploading = useSelector((state: RootState) => state.common?.uploader?.upload)

  const connectionId = selectedConnection || distributionPackage?.data?.connectionId
  const schemaId = distributionPackage?.data?.channelSchemaId
  const baseSourceDocumentSchemaId = distributionPackage?.data?.baseSourceDocumentSchemaId
  const baseSourceDocumentGroupId = distributionPackage?.data?.baseSourceDocumentGroupId

  const connectionsList = toArray(connections?.data)
  const schemasList = schemas?.data?._embedded?.schemas || []
  const sourceDocumentSchemasList = toArray(sourceDocumentSchemas?.data)
  const sourceDocumentGroupsList = sourceDocumentGroups?.data?._embedded?.sourceDocumentGroups || []

  const nextSDGPage =
    (sourceDocumentGroupsNextPage?.data
      ? sourceDocumentGroupsNextPage?.data?._links?.next?.href
      : sourceDocumentGroups?.data?._links?.next?.href) || ''

  const isMutating = mutation?.pending > 0

  const isLoading =
    distributionPackage.pending > 0 ||
    (!selectedConnection && connection?.pending > 0) ||
    connections?.pending > 0 ||
    (!selectedConnection && schemas?.pending > 0) ||
    sourceDocumentSchemas?.pending > 0 ||
    deleteMutation?.pending > 0

  const hasNoSourceDocuments =
    !isLoading &&
    (sourceDocuments?.data?.count === 0 || !sourceDocuments?.data) &&
    (sourceDocumentGroupSourceDocuments?.data?.count === 0 || !sourceDocumentGroupSourceDocuments.data)

  useAPI('DISTRIBUTION_PACKAGE', `/distribution-packages/${id}`)
  useAPI('DOCUMENT_RULES', `/distribution-packages/${id}/document-rules`)
  useAPI(
    'SOURCE_DOCUMENTS',
    `/distribution-packages/${id}/source-documents?offset=0&limit=10`,
    uploading,
    'GET',
    true,
    undefined,
    null,
    undefined,
    undefined,
    [uploading],
  )
  useAPI(
    'SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS',
    `/source-document-groups/${baseSourceDocumentGroupId}/source-documents?offset=0&limit=10`,
    isDefined(sourceDocumentGroupSourceDocuments?.data) || !baseSourceDocumentGroupId || sourceDocumentGroupSourceDocuments?.pending > 0,
    'GET',
    true,
  )
  useAPI('SOURCE_DOCUMENT_SCHEMAS', `/source-document-schemas`)
  useAPI('SOURCE_DOCUMENT_SCHEMA', `/source-document-schemas/${baseSourceDocumentSchemaId}`, !isDefined(baseSourceDocumentSchemaId))
  useAPI('CONNECTION', `/connections/${connectionId}?includeChannel=true`, !isDefined(connectionId))
  useAPI(
    'SCHEMAS',
    `/connections/${connectionId}/schemas?getRootCategories=true`,
    !isDefined(connectionId),
    'GET',
    true,
    undefined,
    null,
    undefined,
    undefined,
    [selectedConnection],
  )
  useAPI('SCHEMA', `/connections/${connectionId}/schemas/${schemaId}`, !isDefined(connectionId) || !isDefined(schemaId))
  useAPI('CONNECTIONS', `/connections?includeChannel=true`, isDefined(connections?.data))
  useAPI(
    'SOURCE_DOCUMENT_GROUP',
    `/source-document-groups/${baseSourceDocumentGroupId}/`,
    isDefined(sourceDocumentGroup?.data) || !baseSourceDocumentGroupId,
  )
  useAPI('SOURCE_DOCUMENT_GROUPS', '/source-document-groups?offset=0&limit=10', false, 'GET', true)
  useAPI(
    'SOURCE_DOCUMENT_GROUPS_PAGES',
    nextSDGPage,
    !nextSDGPage || !getMoreState,
    'GET',
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    [getMoreState],
  )

  if (selectedConnection && mutationData) mutationData.connectionId = selectedConnection
  if (selectedConnection) distributionPackage.data.connectionId = selectedConnection

  useAPIMutation('DISTRIBUTION_PACKAGE', mutationData, !mutationData)
  useAPIMutation('DISTRIBUTION_PACKAGE', distributionPackage?.data, !deleteState || !isDefined(distributionPackage?.data), 'DELETE')
  useAPIMutation('DISTRIBUTION_PACKAGE_SOURCE_DOCUMENTS', distributionPackage?.data, !deleteSourceDocumentsState, 'DELETE')

  React.useEffect(() => {
    if (deleteState) {
      if (!isDefined(distributionPackage?.data)) {
        history.push('/distribution-packages')
      }
    }
  }, [deleteMutation])

  React.useEffect(() => {
    if (deleteSourceDocumentsState && deleteSourceDocumentsMutation?.pending === 0) {
      setDeleteSourceDocumentsState(false)
      dispatch(setSpreadsheet(null))
      dispatch(resetRequests([getActionType('SOURCE_DOCUMENTS')]))
    }
  }, [deleteSourceDocumentsMutation?.pending])

  const setMutationData = (data) => {
    // We are going to do things here
    setMutationDataState(data)
  }

  const handleDelete = () => setDeleteState(true)

  const updateSourceDocuments = (e) => {
    e.preventDefault()

    fileRef.current?.click()
  }

  const handleFileChange = (e) =>
    e.target.files.length > 0 &&
    readSpreadsheet(e.target.files[0]).then((data) => {
      dispatch(setSpreadsheet(data))
      setModalOpen(true)
      if (fileRef.current) fileRef.current.value = ''
    })

  const handleConnectionSelect = (data) => {
    dispatch(resetRequests([getActionType('SCHEMA'), getActionType('SCHEMAS')]))
    setSelectedConnection(data?.connectionId)
  }

  React.useEffect(
    () => () => {
      dispatch(
        resetRequests([
          getActionType('DISTRIBUTION_PACKAGE'),
          getActionType('STAGED_RELEASES'),
          getActionType('RELEASES'),
          getActionType('DOCUMENT_RULES'),
          getActionType('SOURCE_DOCUMENT_GROUPS'),
          getActionType('SOURCE_DOCUMENT_GROUP'),
          getActionType('SOURCE_DOCUMENT_GROUPS_PAGES'),
          getActionType('SOURCE_DOCUMENTS'),
          getActionType('SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS'),
        ]),
      )
    },
    [],
  )

  React.useEffect(() => {
    if (getMoreState) setGetMoreState(false)
  }, [getMoreState])

  React.useEffect(() => {
    if (sourceDocumentGroupsNextPage?.data)
      setSourceDocumentGroupsPages([
        ...sourceDocumentGroupsPages,
        ...(sourceDocumentGroupsNextPage?.data?._embedded?.sourceDocumentGroups || []),
      ])
  }, [sourceDocumentGroupsNextPage?.data?._embedded?.sourceDocumentGroups])

  const handleDeleteSourceDocuments = () => setDeleteSourceDocumentsState(true)

  return (
    <Page
      feature="distribution_packages"
      title={distributionPackage?.data?.name || distributionPackage?.data?.id}
      tag={distributionPackage?.data?.tag}
      loading={isLoading}
      working={isMutating}
      notFound={distributionPackage?.error?.response?.status === 404}
      subtitle={
        <DistributionPackagesSubtitle
          allowCopy
          id={distributionPackage?.data?.id}
          connectionTitle={connection?.data?._embedded?.channel?.title}
          connectionId={connectionId}
          schemaId={schemaId}
          schemaTitle={schema?.data?.title}
          tag={distributionPackage?.data?.tag}
          lastUpdated={distributionPackage?.data?.lastUpdated}
        />
      }
      actions={
        <>
          <PageAction
            label="Import Source Documents"
            description={uploading && 'Already uploading source documents'}
            onClick={updateSourceDocuments}
            icon="sourceDocuments"
            disabled={uploading}
          />
          <PageAction
            label="Edit Mapping Rules"
            description={hasNoSourceDocuments && 'Has No Source Documents'}
            to={`/distribution-packages/${id}/mapping-rules/edit`}
            icon="rules"
            disabled={hasNoSourceDocuments}
          />
          <input type="file" accept=".xls,.xlsx,.csv" ref={fileRef} onChangeCapture={handleFileChange} hidden />

          <PageAction
            label="Stage a release"
            // to={`/distribution-packages/${id}/mapping-rules/edit`}
            icon="releases"
            disabled
          />

          <PageAction
            label="Set a new release"
            description={hasNoSourceDocuments && 'Has No Source Documents'}
            to={`/distribution-packages/${id}/mapping-rules/edit`}
            icon="releases"
            disabled
          />

          <Confirm
            onYes={handleDeleteSourceDocuments}
            message={`Are you sure you want to delete all source documents?`}
            disabled={hasNoSourceDocuments || deleteSourceDocumentsState}
          >
            <PageAction
              label="Delete Source Documents"
              description={deleteSourceDocumentsState && 'Already deleting source documents'}
              icon="deleteDocuments"
              disabled={hasNoSourceDocuments || deleteSourceDocumentsState}
            />
          </Confirm>

          <Confirm onYes={handleDelete} message={`Are you sure you want to delete ${distributionPackage?.data?.name}?`}>
            <PageAction label="Delete" icon="thrash" reverse />
          </Confirm>
        </>
      }
    >
      <CardSection feature="distribution_packages" loading={isLoading}>
        <Tabs>
          <TabList>
            <Tab label="General" name="general" to={`${url}/general`} feature="general" />
            <Tab label="Source Documents" name="source_documents" to={`${url}/source-documents`} feature="source_documents" />
            <Tab label="Document Rules" name="document_rules" to={`${url}/document-rules`} feature="document_rules" />
            <Tab label="Staged Releases" name="staged_releases" to={`${url}/staged-releases`} feature="staged_releases" />
            <Tab label="Releases" name="releases" to={`${url}/releases`} feature="releases" />
          </TabList>

          <SummonOverlay
            isOpen={modalOpen}
            onClose={setModalOpen}
            overlay={
              <UploadSourceDocumentsOverlay
                id={distributionPackage?.data?.id}
                feature={'distribution_package'}
                documentKey={distributionPackage?.data?.documentKey}
                showBackdrop
              />
            }
          />

          <Route
            path="/distribution-packages/:id/general"
            component={() => (
              <EditableSection>
                <EditableSectionContent>
                  <EditableFieldGroup>
                    <EditableFieldInputText
                      label=" Name"
                      defaultValues={distributionPackage?.data}
                      model="name"
                      setMutationData={setMutationData}
                      disabled={isMutating}
                    />

                    <EditableFieldInputText
                      label="Tag"
                      labelValue={distributionPackage?.data?.tag?.toUpperCase()}
                      defaultValues={distributionPackage?.data}
                      model="tag"
                      colorIndicator={stringToColor(distributionPackage?.data?.tag)}
                      setMutationData={setMutationData}
                      disabled={isMutating}
                    />

                    <CopyableField
                      label="Document Key"
                      defaultValues={distributionPackage?.data}
                      model="documentKey"
                      disabled={isMutating}
                      allowCopy
                    />

                    <CopyableField
                      label="Last Updated"
                      defaultValues={distributionPackage?.data}
                      model="lastUpdated"
                      disabled={isMutating}
                      isDate
                    />
                  </EditableFieldGroup>
                  <Separator />

                  <EditableFieldGroupHeader title="Source Document Schemas & Groups" feature="schemas" />

                  <EditableFieldGroup>
                    <EditableSourceDocumentSchemaSelector
                      defaultValues={distributionPackage?.data}
                      model="baseSourceDocumentSchemaId"
                      disabled={isMutating || sourceDocumentSchemas?.data?.count === 0}
                      sourceDocumentSchemas={sourceDocumentSchemasList}
                      sourceDocumentSchema={sourceDocumentSchema?.data}
                      setMutationData={setMutationData}
                      loading={sourceDocumentSchema?.pending > 0}
                    />
                    <EditableSourceDocumentGroupsSelector
                      defaultValues={distributionPackage?.data}
                      model="baseSourceDocumentGroupId"
                      label="Source Document Group"
                      labelValue={sourceDocumentGroup?.data?.name}
                      sourceDocumentGroups={sourceDocumentGroupsList}
                      documentKey={distributionPackage?.data?.documentKey}
                      setMutationData={setMutationData}
                      disabled={
                        isMutating ||
                        sourceDocumentGroups?.pending > 0 ||
                        !sourceDocumentGroupsList?.length ||
                        sourceDocumentGroups?.data?.count === 0 ||
                        !distributionPackage?.data?.documentKey
                      }
                    />
                  </EditableFieldGroup>

                  <EditableFieldGroupHeader title="Connection Information" feature="connection" />

                  <EditableFieldGroup>
                    <EditableConnectionsSelector
                      defaultValues={distributionPackage?.data}
                      model="connectionId"
                      disabled={isMutating}
                      connection={connection?.data}
                      connections={connectionsList}
                      setMutationData={handleConnectionSelect}
                      loading={connection?.pending > 0}
                    />

                    <CopyableField
                      label="Connection Id"
                      defaultValues={distributionPackage?.data}
                      model="connectionId"
                      shortened
                      allowCopy
                      disabled={isMutating}
                    />

                    <EditableRawSchemaSelector
                      label="Channel Schema"
                      schemas={schemasList}
                      schema={schema?.data}
                      defaultValues={distributionPackage?.data}
                      loading={schemas?.pending > 0 || schema?.pending > 0}
                      connectionId={connectionId}
                      model="channelSchemaId"
                      forceDisabled={!connectionId || !!schemas?.pending || !schemasList || schemasList?.length === 0}
                      setMutationData={setMutationData}
                      disabled={isMutating}
                      css={{ gridColumn: '1/-1' }}
                      resolver={validationSchema}
                    />
                  </EditableFieldGroup>
                </EditableSectionContent>
              </EditableSection>
            )}
          />

          <Route
            path="/distribution-packages/:id/releases"
            component={() => <ReleasesTabPage distributionPackage={distributionPackage?.data} />}
          />
          <Route
            path="/distribution-packages/:id/staged-releases"
            component={() => <StagedReleasesTabPage distributionPackage={distributionPackage?.data} />}
          />
          <Route
            path="/distribution-packages/:id/document-rules"
            component={() => <DocumentRulesTabPage distributionPackage={distributionPackage?.data} />}
          />
          <Route
            path="/distribution-packages/:id/source-documents"
            component={() => <SourceDocumentsTabPage distributionPackage={distributionPackage?.data} />}
          />

          <Route path="/distribution-packages/:id/source-documents/:sourceDocumentId" component={() => <SourceDocumentOverlay />} />

          <Route
            path="/distribution-packages/:id/document-rules/:documentRuleId"
            component={() => (
              <DocumentRuleOverlay
                onClose={() => history.push(`/distribution-packages/${distributionPackage?.data?.id}/document-rules`)}
                editPath={`/distribution-packages/${distributionPackage?.data?.id}/mapping-rules/edit#`}
                disableEdit={hasNoSourceDocuments}
              />
            )}
          />

          <Redirect from={`/distribution-packages/${id}`} to={`/distribution-packages/${id}/general`} />
        </Tabs>
      </CardSection>
    </Page>
  )
}
export default DistributionPackagePage
