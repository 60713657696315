import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../theme'

import Label from './Label'
import FormContext from '../context/FormContext'
import SearchableDropdown, { Option, Props as SearchableDropdownProps } from './SearchableDropdown'
import { get } from 'lodash'

type Props = {
  label?: string
  error?: any
  placeholder?: string
  model: string
  hideEmptyOption?: boolean
  getMoreOptions?: Function
  hasMoreOptions?: boolean
  options?: Option[]
} & SearchableDropdownProps

const InputSearchableSelect: React.FC<Props> = ({
  model,
  label,
  placeholder,
  children,
  hideEmptyOption,
  getMoreOptions,
  hasMoreOptions,
  options,
  ...rest
}) => {
  return (
    <FormContext.Consumer>
      {({ formState, disabled, getValues, setValue }) => {
        const error = formState?.errors?.[model]
        const classNames = clsx({
          'is-disabled': disabled,
        })

        return (
          <>
            <div css={styles.root} className={classNames}>
              {label && <Label for={model} label={label} />}
              <SearchableDropdown
                getMoreOptions={getMoreOptions}
                hasMoreOptions={hasMoreOptions}
                options={options}
                onChange={(value) => setValue(model, value)}
                defaultValue={get(getValues?.(), model)}
                {...rest}
              />
              {error && <div css={styles.error}>{error.message}</div>}
            </div>
          </>
        )
      }}
    </FormContext.Consumer>
  )
}

const styles = {
  root: {
    display: 'grid',
    gap: '0.5rem',

    '&.is-disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },

  error: {
    border: `1px solid ${COLORS.red}`,
    backgroundColor: COLORS.red60,
    color: COLORS.white,
    padding: '0.5rem',
    textAlign: 'center',
    borderRadius: 4,
    fontFamily: 'Amiko',
    textTransform: 'uppercase',
    fontSize: '.75rem',
    fontWeight: 'bold',
  },
}

export default InputSearchableSelect
