import React from 'react'
import useAxios from 'axios-hooks'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getMutationSelector } from '@redux-requests/core'
import { produce } from 'immer'
import isDefined from '@codewell/is-defined'

import Overlay from '../../Overlay'
import Portal from '../../Portal'
import PageHeader from '../../layout/page/PageHeader'
import Form from '../../Form'
import InputGroup from '../../InputGroup'
import InputText from '../../InputText'

import DialogActions from '../../dialog/DialogActions'
import DialogAction from '../../dialog/DialogAction'

import { useAPIMutation, getActionType } from '../../../hooks/useAPI'

import { clearNewSourceDocumentGroup } from '../../../actions/common'
import { RootState } from '../../../setup/storeConfig'

const normalize = (data) => {
  if (!data) return

  return produce(data, (draft) => {
    return draft
  })
}

const NewSourceDocumentGroup: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [sourceDocumentGroup, setSourceDocumentGroup] = React.useState()
  const [sourceDocumentGroupLoading, setSourceDocumentGroupLoading] = React.useState(false)

  const mutation = useSelector(getMutationSelector({ type: getActionType('SOURCE_DOCUMENT_GROUP', 'POST') }))
  const newSourceDocumentGroup = useSelector((state: RootState) => state?.common?.sourceDocumentGroup)

  const validation = React.useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(),
        documentKey: Yup.string().required(),
        tag: Yup.string(),
      }),
    [],
  )

  useAPIMutation('SOURCE_DOCUMENT_GROUP', sourceDocumentGroup, !isDefined(sourceDocumentGroup), 'POST')

  const [{ data: sourceDocumentGroupCreated, error }, getSourceDocumentGroupCreated] = useAxios(
    {
      url: `/source-document-groups/${newSourceDocumentGroup?.data?.id}`,
      method: 'GET',
    },
    { manual: true },
  )

  React.useEffect(() => {
    if (error) {
      try {
        getSourceDocumentGroupCreated()
      } catch (error) {}
    }
  }, [error])

  React.useEffect(() => {
    if (sourceDocumentGroupCreated?.id) {
      setSourceDocumentGroupLoading(false)
      history.push(`/source-document-groups/${sourceDocumentGroupCreated?.id}`)
    }
  }, [sourceDocumentGroupCreated])

  React.useEffect(() => {
    if (newSourceDocumentGroup?.data?.id) {
      setSourceDocumentGroupLoading(true)
      getSourceDocumentGroupCreated()
    }
  }, [newSourceDocumentGroup])

  React.useEffect(
    () => () => {
      dispatch(clearNewSourceDocumentGroup())
    },
    [],
  )

  const isLoading = mutation?.pending > 0 || sourceDocumentGroupLoading

  return (
    <Portal type="overlay">
      <Form onSubmit={(data) => setSourceDocumentGroup(normalize(data))} resolver={validation} disabled={isLoading}>
        <Overlay
          onClose={() => history.push('/source-document-groups')}
          css={styles.root}
          position="right"
          fullheight
          showBackdrop
          closeOnBackdrop
          disabled={isLoading}
          isLoading={isLoading}
        >
          <PageHeader title="New Source Document Group" feature="source_document_groups" small />

          <div css={styles.content}>
            <InputGroup>
              <InputText model="name" label="Name" />
              <InputText model="documentKey" label="Document Key" />
              <InputText model="tag" label="Tag" />
            </InputGroup>
          </div>

          <DialogActions>
            <DialogAction label="Create" />
            <DialogAction label="Cancel" to="/source-document-groups" />
          </DialogActions>
        </Overlay>
      </Form>
    </Portal>
  )
}

const styles: any = {
  root: {
    flexShrink: 0,
    display: 'flex',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
  },
}

export default NewSourceDocumentGroup
