import React from 'react'

const EditGlyph = ({ color, ...props }) => (
  <svg viewBox="0 0 19 20" {...props}>
    <path
      d="M16.5433 2.95672C16.187 2.60052 15.7038 2.40042 15.2 2.40042C14.6962 2.40042 14.213 2.60052 13.8567 2.95672L6.65002 10.1634V12.85H9.33663L16.5433 5.64332C16.8995 5.28701 17.0996 4.80383 17.0996 4.30002C17.0996 3.7962 16.8995 3.31302 16.5433 2.95672Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.90002 6.19999C1.90002 5.69608 2.1002 5.2128 2.45652 4.85648C2.81284 4.50017 3.29611 4.29999 3.80002 4.29999H7.60002C7.85198 4.29999 8.09362 4.40008 8.27177 4.57824C8.44993 4.7564 8.55002 4.99803 8.55002 5.24999C8.55002 5.50194 8.44993 5.74358 8.27177 5.92174C8.09362 6.0999 7.85198 6.19999 7.60002 6.19999H3.80002V15.7H13.3V11.9C13.3 11.648 13.4001 11.4064 13.5783 11.2282C13.7564 11.0501 13.9981 10.95 14.25 10.95C14.502 10.95 14.7436 11.0501 14.9218 11.2282C15.0999 11.4064 15.2 11.648 15.2 11.9V15.7C15.2 16.2039 14.9998 16.6872 14.6435 17.0435C14.2872 17.3998 13.8039 17.6 13.3 17.6H3.80002C3.29611 17.6 2.81284 17.3998 2.45652 17.0435C2.1002 16.6872 1.90002 16.2039 1.90002 15.7V6.19999Z"
      fill={color}
    />
  </svg>
)

export default EditGlyph
