import React from 'react'

import moment from 'moment'

export type Props = {
  date: number | string
  format?: DATE_FORMATS | string
  className?: string
}

export enum DATE_FORMATS {
  short = 'MM/DD/YY',
  long = 'MM/DD/YYYY',
  full = 'LLLL',
  relative = 'relative',
  readable = 'MMM Do YY',
  system = 'MMM Do YY (hh:mm)',
}

const FormatDate: React.FC<Props> = ({ date, format = DATE_FORMATS.long, className }) => {
  const parsedDate = moment(date)
  const formatedDate = format === DATE_FORMATS.relative ? parsedDate.fromNow() : parsedDate.format(format)

  return (
    <span css={styles.root} className={className}>
      {formatedDate}
    </span>
  )
}

const styles = {
  root: {},
}

export default FormatDate
