import React from 'react'
import { useDispatch } from 'react-redux'
import { camelCase } from 'change-case'

import { METHOD, ApiEntity, ApiMethod } from '../actions/data'
import { getMutation } from '../actions/mutations'

type APIRequest = {
  entity: ApiEntity
  url: string
  skip: boolean
  method: ApiMethod
  raw: boolean
  mapKey?: string
  data?: any
  key?: string
  requestKey?: string
  dependencies: any[]
  onSuccess: any
}

export const getActionType = (entity: ApiEntity, method: ApiMethod = METHOD.GET as ApiMethod) => `${method}_${entity}`

export const getAction = (
  entity: ApiEntity,
  url: string,
  key: string,
  method: ApiMethod = METHOD.GET as ApiMethod,
  raw: boolean = false,
  mapKey?: string,
  data?: any,
  requestKey?: string,
  onSuccess?: any,
) => {
  return {
    type: getActionType(entity, method),
    key,
    mapKey,
    raw,
    request: {
      url,
      method,
      data,
    },
    meta: {
      requestKey,
      ...(onSuccess && { onSuccess }),
    },
  }
}

const useAPI = (
  entity?: ApiEntity,
  url?: string,
  skip?: boolean = false,
  method?: ApiMethod = METHOD.GET as ApiMethod,
  raw?: boolean = false,
  mapKey?: string,
  data?: any,
  key?: string,
  requestKey?: string,
  dependencies?: any[] = [],
  onSuccess?: any,
) => {
  const dispatch = useDispatch()
  // This is the key we use to identify inner _embedded responses.
  const resultKey = key || camelCase(entity)

  return React.useEffect(() => {
    if (!skip) dispatch(getAction(entity, url, resultKey, method, raw, mapKey, data, requestKey))

    //eslint-disable-next-line
  }, [dispatch, entity, url, key, method, ...dependencies])
}

export const useAPIObject = (request: APIRequest) => {
  const {
    entity,
    url,
    key,
    method = METHOD.GET as ApiMethod,
    raw = false,
    skip = false,
    mapKey,
    data,
    requestKey,
    dependencies = [],
    onSuccess,
  } = request

  const dispatch = useDispatch()
  const resultKey = key || camelCase(entity)

  return React.useEffect(() => {
    if (!skip) dispatch(getAction(entity, url, resultKey, method, raw, mapKey, data, requestKey, onSuccess))

    //eslint-disable-next-line
  }, [dispatch, entity, url, key, method, ...dependencies])
}

export const useAPIMutation = (entity: ApiEntity, data: any, skip: boolean = false, method: ApiMethod = 'PATCH', onSuccess?: any) => {
  const dispatch = useDispatch()
  const mutationAction = getMutation(entity, method)

  return React.useEffect(() => {
    if (!skip) {
      let action = mutationAction(data)
      action = { ...action, meta: { ...action?.meta, ...(onSuccess && { onSuccess }) } }
      dispatch(action)
    }
  }, [dispatch, entity, data, method, skip])
}

export const useAPICustomMutation = (mutationAction: any, skip: boolean = false) => {
  const dispatch = useDispatch()

  return React.useEffect(() => {
    if (!skip) dispatch(mutationAction)
  })
}

export default useAPI
