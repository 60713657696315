import React from 'react'
import { darken, desaturate } from 'polished'

import { COLORS } from '../../theme'

import ListItem from '../ListItem'

type Props = {
  channel: any
}

const ChannelListItem: React.FC<Props> = ({ channel, ...rest }) => {
  const { title } = channel

  return <ListItem label={title} icon="channels" {...rest} css={styles.root} />
}

const styles = {
  root: {
    backgroundColor: darken(0.02, desaturate(0.15, COLORS.navbar)),
    paddingLeft: '4.7rem',

    '&:hover': {
      backgroundColor: darken(0.03, desaturate(0.15, COLORS.navbar)),
    },
  },
}

export default ChannelListItem
