import React from 'react'

import ListItem from '../ListItem'

import Info from '../Info'

import { COLORS, FEATURES } from '../../theme'

export type Props = {
  documentKey: string
  sourceDocument: any
  action: string
  feature: string
  label: string
}

const SourceDocumentListItem: React.FC<Props> = ({ documentKey, sourceDocument, feature = 'source_documents', label, action, ...rest }) => {
  return (
    <ListItem label={label || sourceDocument?.[documentKey]} icon={FEATURES[feature].icon} {...rest} css={styles.root}>
      {action && <Info label={action} color={COLORS.orchidPale} tooltip="Action" />}
      {documentKey && <Info label={documentKey} color={COLORS.green60} tooltip="Document Key" icon="key" />}
    </ListItem>
  )
}

const styles = {
  root: {
    '&.active': {
      background: COLORS.grayLight,
    },
  },
}

export default SourceDocumentListItem
