import React from 'react'
import useAxios from 'axios-hooks'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getMutationSelector } from '@redux-requests/core'
import { produce } from 'immer'
import isDefined from '@codewell/is-defined'

import Overlay from '../../Overlay'
import Portal from '../../Portal'
import PageHeader from '../../layout/page/PageHeader'
import Form from '../../Form'
import InputGroup from '../../InputGroup'
import InputText from '../../InputText'
import InputSelect from '../../InputSelect'

import DialogActions from '../../dialog/DialogActions'
import DialogAction from '../../dialog/DialogAction'

import { useAPIMutation, getActionType } from '../../../hooks/useAPI'

import { clearNewWebhook } from '../../../actions/common'

const normalize = (data) => {
  if (!data) return

  return produce(data, (draft) => {
    return draft
  })
}

const NewWebhook: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [webhook, setWebhook] = React.useState()
  const [webhookLoading, setWebhookLoading] = React.useState(false)

  const mutation = useSelector(getMutationSelector({ type: getActionType('WEBHOOK', 'POST') }))
  const newWebhook = useSelector((state) => state?.common?.webhook)

  const validation = React.useMemo(
    () =>
      Yup.object().shape({
        key: Yup.string().required(),
        url: Yup.string().url().required(),
      }),
    [],
  )

  useAPIMutation('WEBHOOK', webhook, !isDefined(webhook), 'POST')

  const [{ data: webhookCreated, error }, getWebhookCreated] = useAxios(
    {
      url: `/web-hooks/${newWebhook?.data?.id}`,
      method: 'GET',
    },
    { manual: true },
  )

  React.useEffect(() => {
    if (error) {
      try {
        getWebhookCreated()
      } catch (error) {}
    }
  }, [error])

  React.useEffect(() => {
    if (webhookCreated?.id) {
      setWebhookLoading(false)
      history.push(`/webhooks/${webhookCreated?.id}`)
    }
  }, [webhookCreated])

  React.useEffect(() => {
    if (newWebhook?.data?.id) {
      setWebhookLoading(true)
      getWebhookCreated()
    }
  }, [newWebhook])

  React.useEffect(() => () => dispatch(clearNewWebhook()), [])

  const isLoading = mutation?.pending > 0 || webhookLoading

  return (
    <Portal type="overlay">
      <Form onSubmit={(data) => setWebhook(normalize(data))} resolver={validation} disabled={isLoading}>
        <Overlay
          onClose={() => history.push('/webhooks')}
          css={styles.root}
          position="right"
          fullheight
          showBackdrop
          closeOnBackdrop
          disabled={isLoading}
          isLoading={isLoading}
        >
          <PageHeader title="New Webhook" feature="webhooks" small />

          <div css={styles.content}>
            <InputGroup>
              <InputSelect model="key" label="Key">
                <option value="connection.requiresInput">connection.requiresInput</option>
                <option value="connection.established">connection.established</option>
                <option value="distributionPackage.staged">distributionPackage.staged</option>
                <option value="distributionPackage.released">distributionPackage.released</option>
                <option value="subAccount.dataDeleted">subAccount.dataDeleted</option>
              </InputSelect>

              <InputText model="url" label="URL" />
            </InputGroup>
          </div>

          <DialogActions>
            <DialogAction label="Create" />
            <DialogAction label="Cancel" to="/webhooks" />
          </DialogActions>
        </Overlay>
      </Form>
    </Portal>
  )
}

const styles = {
  root: {
    flexShrink: 0,
    display: 'flex',
    // flexDirection: 'column',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
  },
}

export default NewWebhook
