import React from 'react'
import get from 'lodash/get'

import FormContext from '../context/FormContext'

const withTabsContext = (Wrapped) => (props) => (
  <FormContext.Consumer>
    {(context) => {
      const error = context?.formState?.errors ? get(context?.formState?.errors, props?.model) : null
      return <Wrapped {...props} {...context} error={error} />
    }}
  </FormContext.Consumer>
)

export default withTabsContext
