import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { getQuerySelector } from '@redux-requests/core'
import isDefined from '@codewell/is-defined'

import { COLORS } from '../../theme'

import useAPI, { getActionType } from '../../hooks/useAPI'

import Overlay from '../Overlay'
import Portal from '../Portal'
import PageHeader from '../layout/page/PageHeader'
import Info from '../Info'
import InfoLink from '../InfoLink'
import Log from '../Log'

const ErrorTranslatedDocumentOverlay = ({ distributionPackage, release, onClose, baseURL = '/releases' }) => {
  const history = useHistory()
  const { translatedErrorKey } = useParams()

  const errorDocuments = useSelector(getQuerySelector({ type: getActionType('ERROR_SOURCE_DOCUMENTS') }))
  const errorDocument = errorDocuments?.data?._embedded?.errors?.find?.((error) => error.key === translatedErrorKey)

  useAPI(
    'ERROR_SOURCE_DOCUMENTS',
    `/releases/${release?.id}/results/errors`,
    isDefined(errorDocuments?.data),
    'GET',
    false,
    'key',
    null,
    'errors',
  )

  if (!errorDocument) return null

  const onCloseDefault = () => history.push(`/releases/${release?.id}/errors`)

  return (
    <Portal type="overlay">
      <Overlay onClose={onClose || onCloseDefault} css={styles.root}>
        <PageHeader title={translatedErrorKey} icon="documentError" small>
          <Info label={`Action: ${errorDocument.action}`} color={COLORS.green60} tooltip="Document Key" />
          <InfoLink
            label="Source Document"
            icon="sourceDocuments"
            color={COLORS.blue60}
            to={`${baseURL}/${release?.id}/source-documents/${translatedErrorKey}`}
          />
          <InfoLink
            label="Edit Mapping Rules"
            icon="rules"
            color={COLORS.yellow60}
            to={`/distribution-packages/${distributionPackage?.id}/mapping-rules/edit`}
            disabled={!distributionPackage?.id}
            tooltipPlacement="bottom"
            tooltip={!distributionPackage?.id ? 'This distribution package has been deleted' : null}
          />
        </PageHeader>

        <div css={styles.content}>
          <Log src={errorDocument} />
        </div>
      </Overlay>
    </Portal>
  )
}

const styles = {
  root: {
    flexShrink: 0,

    '& .dialog': {
      maxWidth: '60rem',
    },
  },
  content: {
    padding: '1.5rem',
    display: 'grid',
    gridGap: '1rem',
    overflowY: 'auto',
  },
  value: {
    borderRadius: 2,
    fontSize: '1.3rem',
    color: COLORS.purpleTitle,
  },
}

export default ErrorTranslatedDocumentOverlay
