import React from 'react'
import { transparentize } from 'polished'
import clsx from 'clsx'
import { useDetectClickOutside } from 'react-detect-click-outside'

import SummonModal from '../SummonModal'
import Card from '../Card'

import { COLORS } from '../../theme'
import withFormContext from '../../hocs/withFormContext'
import ExpandableSchemaItem from './ExpandableSchemaItem'
import { Schema } from '../../declarations'
import Icon from '../Icon'

type Props = {
  model: string
  schemas: Schema[]
  label: string
  register: Function
  disabled: boolean
  forceDisabled: boolean
  error: object
  connectionId: string | number
  labelValue?: string
}

const RawSchemaSelector: React.FC<Props> = ({
  model,
  schemas,
  label,
  register,
  disabled,
  forceDisabled,
  error,
  connectionId,
  labelValue,
}) => {
  const [open, setOpen] = React.useState(false)
  const [expandedRows, setExpandedRows] = React.useState({})
  const [valueLabel, setValueLabel] = React.useState('')
  const [selectedId, setSelectedId] = React.useState('')
  const [selectedPath, setSelectedPath] = React.useState('')
  const [isWorking, setIsWorking] = React.useState(false)

  const classNames = clsx({
    'is-disabled': forceDisabled || disabled,
  })

  const handleBlur = (e) => {
    let isPrevented = false
    if (e?.type === 'click') {
      isPrevented = !!e.path?.find?.((current) => current.id === 'RawSchemaSelectorList')
    }
    if (!isPrevented) setOpen(false)
  }

  const registered = register(model)
  const ref = useDetectClickOutside({ onTriggered: handleBlur })

  const clearExpandedRows = (keepOpen = null) => {
    const rows = {}
    schemas.forEach((current) => {
      if (current.title !== keepOpen) {
        rows[current?.title] = { [current?.title]: false }
      }
    })
    return rows
  }

  React.useEffect(() => {
    if (schemas) {
      setExpandedRows(clearExpandedRows())
    }
    handleSelect({ value: null, label: '' })
  }, [schemas])

  const handleExpand = (data = {}) => {
    const result = {
      ...clearExpandedRows(data.rootCategory),
      [data.rootCategory]: { ...expandedRows[data.rootCategory], [data.id]: data.isOpen },
    }

    result[data.rootCategory] = Object.fromEntries(Object.entries(result[data.rootCategory]).filter(([key]) => data.id.includes(key)))

    setTimeout(() => {
      if (ref && data.ref) {
        ref.current.scrollTop = data.ref?.current?.offsetTop
      }
    }, 300)
    return setExpandedRows(result)
  }

  const handleSelect = ({ value, label, path }) => {
    setValueLabel(label)
    setSelectedId(value)
    setSelectedPath(path)
    registered.onChange({ target: { value, name: model } })
    setOpen(false)
  }
  const handleOpen = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(!open)
  }

  return (
    <div css={styles.root} className={classNames} key={model} onClick={(e) => e.stopPropagation()} ref={ref}>
      {label && (
        <label for-={model} css={styles.label}>
          {label}
        </label>
      )}

      <SummonModal
        open={open}
        setOpen={setOpen}
        placement="bottom-start"
        cta={
          <div onClick={handleOpen} css={styles.input(!(valueLabel || labelValue) && !selectedPath)} className={classNames}>
            <span>
              {selectedPath && <div css={styles.pathLabel}>{selectedPath}</div>}
              {valueLabel || labelValue}
            </span>
            <Icon icon={'chevron'} size="16" css={styles.chevron(open)} />
          </div>
        }
      >
        <Card css={styles.modal}>
          <div css={styles.listContainer({ width: ref?.current?.offsetWidth })} id="RawSchemaSelectorList">
            {schemas?.map?.((current) => (
              <ExpandableSchemaItem
                key={`SchemaSelector_Category_${current.title}`}
                label={current?.title}
                connectionId={connectionId}
                requestPath={current?.title}
                onSelect={handleSelect}
                handleIsExpanded={handleExpand}
                rootCategory={current?.title}
                expandedTree={expandedRows[current?.title]}
                selectedId={selectedId}
                level={0}
                handleIsWorking={setIsWorking}
                working={isWorking}
              />
            ))}
          </div>
        </Card>
      </SummonModal>

      {error && <div css={styles.error}>{error.message}</div>}
    </div>
  )
}

const styles = {
  root: {
    display: 'grid',
    gap: '0.5rem',

    '&.is-disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },

  list: {
    width: '100%',
  },

  modal: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: `
      0px 1px 1px ${transparentize(0.5, COLORS.purplePale40)},
      0px 2px 2px ${transparentize(0.6, COLORS.purplePale40)}
    `,
    height: 300,
  },

  listContainer: ({ width }) => ({
    height: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: width || 493,
  }),

  error: {
    border: `1px solid ${COLORS.red}`,
    backgroundColor: COLORS.red60,
    color: COLORS.white,
    padding: '0.5rem',
    textAlign: 'center',
    borderRadius: 4,
    fontFamily: 'Amiko',
    textTransform: 'uppercase',
    fontSize: '.75rem',
    fontWeight: 'bold',
  },

  label: {
    color: COLORS.purple,
    fontSize: 12,
    letterSpacing: '-0.6px',
    textTransform: 'uppercase',
  },

  input: (iddle) => ({
    alignItems: 'center',
    border: `1px solid ${COLORS.purplePale40}`,
    borderRadius: 4,
    boxShadow: `
      0px 1px 1px ${transparentize(0.5, COLORS.purplePale40)},
      0px 2px 2px ${transparentize(0.6, COLORS.purplePale40)}
    `,
    color: COLORS.purple,
    display: 'flex',
    fontFamily: 'Amiko',
    fontSize: '18px',
    height: iddle ? 40 : 65,
    justifyContent: 'space-between',
    lineHeight: '24,01px',
    outlineColor: COLORS.blue40,
    padding: '0.5rem 10px 0.5rem 1rem',

    '&:focus': {},

    '&.is-disabled': {},
  }),

  pathLabel: {
    color: COLORS.orchidPale80,
    textTransform: 'uppercase',
    fontSize: 11,
    lineHeight: '14,67px',
  },

  chevron: (open) => {
    return {
      transition: 'transform .3s ease',
      transform: `rotate(${open ? '90' : '0'}deg)`,
    }
  },

  placeholder: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    lineHeight: '100%',
  },
}

export default withFormContext(RawSchemaSelector)
