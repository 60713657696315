import keyBy from 'lodash/keyBy'
import produce from 'immer'

import { SET_RULES, UPDATE_RULE, CREATE_RULE, DELETE_RULE, CLEAR_RULES } from '../actions/mapping'

export const INITIAL_STATE = {
  rules: {},
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_RULES:
      return {
        ...state,
        rules: action.rules,
      }

    case UPDATE_RULE: {
      return {
        ...state,
        rules: {
          ...state.rules,
          [action.rule.id]: action.rule,
        },
      }
    }

    case CREATE_RULE: {
      const rulesById = keyBy(action.rules, 'id')

      return {
        ...state,
        rules: {
          ...state.rules,
          ...rulesById,
        },
      }
    }

    case DELETE_RULE: {
      const newState = produce(state, (draft) => {
        delete draft.rules[action.rule.id]
      })
      return {
        ...newState,
      }
    }

    case CLEAR_RULES: {
      return {
        ...INITIAL_STATE,
      }
    }

    default:
      return state
  }
}

export default reducer
