import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../theme'

type Props = {
  className?: string
  vertical?: boolean
}

const Separator: React.FC<Props> = ({ vertical, className = '' }) => {
  const classNames = clsx({
    [className]: className,
    'is-vertical': vertical,
  })

  return <div css={styles.root} className={classNames}></div>
}

const styles = {
  root: {
    borderBottom: `1px solid ${COLORS.grayLight}`,

    '&.is-vertical': {
      borderBottom: 'none',
      borderRight: `1px solid ${COLORS.grayLight}`,
    },
  },
}

export default Separator
