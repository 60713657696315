import React from 'react'
import { Route, Redirect, useParams, useRouteMatch, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getQuerySelector, getMutationSelector, resetRequests } from '@redux-requests/core'

import isDefined from '@codewell/is-defined'

import Page from '../../components/layout/page/Page'

import Tabs from '../../components/tabs/Tabs'
import Tab from '../../components/tabs/Tab'
import TabList from '../../components/tabs/TabList'

import CardSection from '../../components/CardSection'
import EditableSection from '../../components/EditableSection'
import EditableSectionContent from '../../components/EditableSectionContent'
import EditableFieldGroup from '../../components/EditableFieldGroup'
import PageAction from '../../components/layout/page/PageAction'
import CopyableField from '../../components/CopyableField'

import useAPI, { getActionType, useAPIMutation } from '../../hooks/useAPI'
import SourceDocumentGroupSubtitle from '../../components/layout/page/subtitle/SourceDocumentGroupSubtitle'
import Confirm from '../../components/Confirm'
import EditableFieldInputText from '../../components/EditableFieldInputText'
import { stringToColor, readSpreadsheet } from '../../utils/functions'

import SourceDocumentsTabPage from './tabs/source-documents'
import DistributionPackagesTabPage from './tabs/distribution-Packages'
import SourceDocumentOverlay from '../../components/overlays/SourceDocumentOverlay'

import { setSpreadsheet } from '../../actions/common'
import UploadSourceDocumentsOverlay from '../../components/overlays/UploadSourceDocumentsOverlay'
import SummonOverlay from '../../components/SummonOverlay'

const SourceDocumentGroupPage: React.FC = () => {
  const { id } = useParams()
  const history = useHistory()
  const { url } = useRouteMatch()
  const dispatch = useDispatch()
  const fileRef = React.useRef()
  const [deleteState, setDeleteState] = React.useState(false)
  const [isDeleteStarted, setIsDeleteStarted] = React.useState(false)
  const [mutationData, setMutationDataState] = React.useState()
  const [modalOpen, setModalOpen] = React.useState(false)
  const [deleteSourceDocumentsState, setDeleteSourceDocumentsState] = React.useState(false)

  const sourceDocumentGroup = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUP') }))
  const sourceDocuments = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENTS') }))
  const deleteMutation = useSelector(getMutationSelector({ type: getActionType('SOURCE_DOCUMENT_GROUP', 'DELETE') }))
  const mutation = useSelector(getMutationSelector({ type: getActionType('SOURCE_DOCUMENT_GROUP', 'PATCH') }))
  const deleteSourceDocumentsMutation = useSelector(
    getMutationSelector({ type: getActionType('SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS', 'DELETE') }),
  )

  const uploading = useSelector((state) => state.common?.uploader?.upload)

  const isLoading = sourceDocumentGroup.pending > 0
  const isDeleting = deleteMutation?.pending > 0
  const isMutating = mutation?.pending > 0

  const hasNoSourceDocuments = !isLoading && (sourceDocuments?.data?.count === 0 || !sourceDocuments?.data)

  useAPI('SOURCE_DOCUMENT_GROUP', `/source-document-groups/${id}`)
  useAPI(
    'SOURCE_DOCUMENTS',
    `/source-document-groups/${id}/source-documents`,
    uploading,
    'GET',
    true,
    undefined,
    null,
    undefined,
    undefined,
    [uploading],
  )

  const handleDelete = () => setDeleteState(true)
  useAPIMutation('SOURCE_DOCUMENT_GROUP', sourceDocumentGroup?.data, !deleteState, 'DELETE')
  useAPIMutation('SOURCE_DOCUMENT_GROUP', mutationData, !isDefined(mutationData))
  useAPIMutation('SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS', sourceDocumentGroup?.data, !deleteSourceDocumentsState, 'DELETE')

  React.useEffect(() => {
    if (deleteState && isDeleting) setIsDeleteStarted(true)
    if (isDeleteStarted && !isDeleting && !deleteMutation?.error) history.push('/source-document-groups')
  }, [deleteState, isDeleting])

  React.useEffect(
    () => () => {
      dispatch(resetRequests([getActionType('DISTRIBUTION_PACKAGES'), getActionType('CONNECTIONS'), getActionType('SOURCE_DOCUMENTS')]))
    },
    [],
  )

  React.useEffect(() => {
    if (deleteSourceDocumentsState && deleteSourceDocumentsMutation?.pending === 0) {
      setDeleteSourceDocumentsState(false)
      dispatch(setSpreadsheet(null))
      dispatch(resetRequests([getActionType('SOURCE_DOCUMENTS')]))
    }
  }, [deleteSourceDocumentsMutation?.pending])

  const setMutationData = (data) => {
    setMutationDataState(data)
  }

  const updateSourceDocuments = (e) => {
    e.preventDefault()

    fileRef.current?.click()
  }

  const handleFileChange = (e) =>
    e.target.files.length > 0 &&
    readSpreadsheet(e.target.files[0]).then((data) => {
      dispatch(setSpreadsheet(data))
      setModalOpen(true)
      fileRef.current.value = null
    })

  const handleDeleteSourceDocuments = () => setDeleteSourceDocumentsState(true)

  return (
    <Page
      feature="source_document_groups"
      title={sourceDocumentGroup?.data?.name}
      tag={sourceDocumentGroup?.data?.tag}
      loading={isLoading}
      working={isMutating || isDeleting}
      notFound={sourceDocumentGroup?.error?.response?.status === 404}
      subtitle={
        <SourceDocumentGroupSubtitle
          allowCopy
          name={sourceDocumentGroup?.data?.name}
          documentKey={sourceDocumentGroup?.data?.documentKey}
          lastUpdated={sourceDocumentGroup?.data?.lastUpdated}
        />
      }
      actions={
        <>
          <input type="file" accept=".xls,.xlsx,.csv" ref={fileRef} onChangeCapture={handleFileChange} hidden />

          <PageAction
            label="Import Source Documents"
            description={uploading && 'Already uploading source documents'}
            onClick={updateSourceDocuments}
            icon="sourceDocuments"
            disabled={uploading}
          />
          <Confirm
            onYes={handleDeleteSourceDocuments}
            message={`Are you sure you want to delete all source documents?`}
            disabled={hasNoSourceDocuments || deleteSourceDocumentsState}
          >
            <PageAction
              label="Delete Source Documents"
              description={deleteSourceDocumentsState && 'Already deleting source documents'}
              icon="deleteDocuments"
              disabled={hasNoSourceDocuments || deleteSourceDocumentsState}
            />
          </Confirm>

          <Confirm onYes={handleDelete} message={`Are you sure you want to delete this source document group?`}>
            <PageAction label="Delete" icon="thrash" reverse />
          </Confirm>
        </>
      }
    >
      <CardSection feature="source_document_groups" loading={isLoading}>
        <Tabs>
          <TabList>
            <Tab label="General" to={`${url}/general`} feature="general" />
            <Tab label="Source Documents" to={`${url}/source-documents`} feature="source_documents" />
            <Tab label="Distribution Packages" to={`${url}/distribution-packages`} feature="distribution_packages" />
          </TabList>

          <SummonOverlay
            isOpen={modalOpen}
            onClose={setModalOpen}
            overlay={
              <UploadSourceDocumentsOverlay
                id={sourceDocumentGroup?.data?.id}
                feature={'source_document_group'}
                documentKey={sourceDocumentGroup?.data?.documentKey}
                showBackdrop
              />
            }
          />

          <Route
            path="/source-document-groups/:id/general"
            component={() => (
              <EditableSection>
                <EditableSectionContent>
                  <EditableFieldGroup>
                    <EditableFieldInputText
                      label="Source Document Group Name"
                      defaultValues={sourceDocumentGroup?.data}
                      model="name"
                      setMutationData={setMutationData}
                      disabled={isMutating}
                    />

                    <EditableFieldInputText
                      label="Tag"
                      labelValue={sourceDocumentGroup?.data?.tag?.toUpperCase()}
                      defaultValues={sourceDocumentGroup?.data}
                      model="tag"
                      colorIndicator={stringToColor(sourceDocumentGroup?.data?.tag)}
                      setMutationData={setMutationData}
                      disabled={isMutating}
                    />

                    <CopyableField
                      label="Document Key"
                      defaultValues={sourceDocumentGroup?.data}
                      model="documentKey"
                      disabled={isMutating}
                      allowCopy
                    />

                    <CopyableField label="ID" defaultValues={sourceDocumentGroup?.data} model="id" shortened allowCopy />

                    <CopyableField
                      label="Last Updated"
                      defaultValues={sourceDocumentGroup?.data}
                      model="lastUpdated"
                      disabled={isMutating}
                      isDate
                    />
                  </EditableFieldGroup>
                </EditableSectionContent>
              </EditableSection>
            )}
          />

          <Route
            path="/source-document-groups/:id/source-documents"
            component={() => <SourceDocumentsTabPage sourceDocumentGroup={sourceDocumentGroup?.data} />}
          />

          <Route
            path="/source-document-groups/:id/distribution-packages"
            component={() => <DistributionPackagesTabPage sourceDocumentGroup={sourceDocumentGroup?.data} />}
          />

          <Route
            path="/source-document-groups/:id/source-documents/:sourceDocumentId"
            component={() => (
              <SourceDocumentOverlay
                entityType={'SOURCE_DOCUMENT_GROUP'}
                onClose={() => history.push(`/source-document-groups/${sourceDocumentGroup?.data?.id}/source-documents`)}
              />
            )}
          />

          <Redirect from={`/source-document-groups/${id}`} to={`/source-document-groups/${id}/general`} />
        </Tabs>
      </CardSection>
    </Page>
  )
}

export default SourceDocumentGroupPage
