import React from 'react'
import random from 'lodash/random'
import PlaceholderLoading from 'react-placeholder-loading'

import { COLORS } from '../theme'

const ListLoaderItem = ({ subtitles = 3 }) => {
  return (
    <div css={listLoaderStyles.item}>
      <div>
        <PlaceholderLoading width={random(120, 280)} height={33} shape="rect" />
      </div>
      <div>
        {[...Array(subtitles)].map((x, i) => (
          <PlaceholderLoading key={i} width={random(30, 80)} height={25} shape="rect" />
        ))}
      </div>
    </div>
  )
}

const ListLoader = () => (
  <div css={listLoaderStyles.root}>
    <ListLoaderItem subtitles={random(3, 7)} />
    <ListLoaderItem subtitles={random(3, 7)} />
    <ListLoaderItem subtitles={random(3, 7)} />
    <ListLoaderItem subtitles={random(3, 7)} />
    <ListLoaderItem subtitles={random(3, 7)} />
    <ListLoaderItem subtitles={random(3, 7)} />
    <ListLoaderItem subtitles={random(3, 7)} />
    <ListLoaderItem subtitles={random(3, 7)} />
    <ListLoaderItem subtitles={random(3, 7)} />
    <ListLoaderItem subtitles={random(3, 7)} />
  </div>
)

const listLoaderStyles = {
  root: {
    display: 'grid',
    // gridAutoFlow: 'row',
  },

  item: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: '1rem',
    padding: ' 15px 20px 15px 20px',
    borderBottom: `1px solid ${COLORS.orchidPaleLight}`,
    textDecoration: 'none',

    color: COLORS.purpleTitle,
    backgroundColor: COLORS.white,

    '& > *': {
      display: 'grid',
      gridAutoFlow: 'column',
      gridGap: '1rem',
      width: 'fit-content',
    },

    '&:last-child': {
      borderBottom: 0,
    },
  },
}

export default ListLoader
