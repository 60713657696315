import React from 'react'

const LogGroup: React.FC = ({ children, ...rest }) => {
  return <div css={{ ...styles.root, ...rest }}>{children}</div>
}

const styles = {
  root: {
    padding: '2rem',
    display: 'grid',
    gridGap: '2rem',
  },
}

export default LogGroup
