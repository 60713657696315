import React from 'react'

import { FEATURES } from '../../../../theme'

import { idShorthand } from '../../../../utils/functions'

import PageSubtitle from '../PageSubtitle'
import InfoLink from '../../../InfoLink'
import InfoReleaseStatus from '../../../InfoReleaseStatus'

export type Props = {
  allowCopy?: boolean
  id: string
  distributionPackageId: string
  lastUpdated?: number
  distributionPackageTitle?: string
  status?: string
  isList?: boolean
}

const ReleaseSubtitle: React.FC<Props> = ({
  allowCopy,
  id,
  distributionPackageId,
  lastUpdated,
  distributionPackageTitle,
  status,
  isList,
}) => {
  const distributionPackageLabel = distributionPackageTitle || (distributionPackageId && idShorthand(distributionPackageId))
  return (
    <>
      <PageSubtitle allowCopy={allowCopy} lastUpdated={lastUpdated}>
        {distributionPackageLabel && (
          <InfoLink
            to={`/distribution-packages/${distributionPackageId}`}
            label={distributionPackageLabel}
            icon={FEATURES.distribution_packages.icon}
            color={FEATURES.distribution_packages.color}
            tooltip="Distribution Package"
          />
        )}
        {status && isList && <InfoReleaseStatus status={status} />}
      </PageSubtitle>
    </>
  )
}

export default ReleaseSubtitle
