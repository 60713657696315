import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { getQuerySelector, resetRequests } from '@redux-requests/core'

import { COLORS } from '../../theme'

import useAPI, { getActionType } from '../../hooks/useAPI'

import Overlay from '../Overlay'
import Portal from '../Portal'
import PageHeader from '../layout/page/PageHeader'
import InfoCopyClipboard from '../InfoCopyClipboard'
import Log from '../Log'

const SchemaOverlay = ({ connection, onClose }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { schemaId } = useParams()

  const schemas = useSelector(getQuerySelector({ type: getActionType('SCHEMAS') }))
  const schema = useSelector(getQuerySelector({ type: getActionType('SCHEMA') }))

  const connectionSchema = schemas?.data?.[schemaId]

  useAPI('SCHEMA', `/connections/${connection?.id}/schemas/${schemaId}`)

  React.useEffect(() => () => dispatch(resetRequests([getActionType('SCHEMA')])), [])

  const onCloseDefault = () => history.push(`/connections/${connection?.id}/schemas`)
  const isLoading = schema?.pending > 0

  return (
    <Portal type="overlay">
      <Overlay onClose={onClose || onCloseDefault} css={styles.root} isLoading={isLoading}>
        <PageHeader
          title={schema?.data?.title || connectionSchema?.title}
          icon="rules"
          hideBackButton
          css={{ top: 0, flexShrink: 0, padding: '1rem 2rem 0 2rem' }}
          fullWidth
        >
          <InfoCopyClipboard label="Copy Schema" icon="rules" textToCopy={JSON.stringify(schema?.data)} />
        </PageHeader>

        {schema?.data && (
          <div css={styles.content}>
            <Log src={schema?.data} shouldCollapse={(entry) => entry.name === '_links'} />
          </div>
        )}
      </Overlay>
    </Portal>
  )
}

const styles = {
  root: {
    flexShrink: 0,

    '& .dialog': {
      maxWidth: '60rem',
    },
  },
  content: {
    padding: '1.5rem',
    display: 'grid',
    gridGap: '1rem',
    overflowY: 'auto',
  },
  value: {
    borderRadius: 2,
    fontSize: '1.3rem',
    color: COLORS.purpleTitle,
  },
}

export default SchemaOverlay
