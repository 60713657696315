import React from 'react'

const DialogContent: React.FC = ({ children }) => {
  return <div css={styles.root}>{children}</div>
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
    textAlign: 'center',
    fontSize: '1.25rem',
  },
}

export default DialogContent
