import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { getQuerySelector } from '@redux-requests/core'
import keyBy from 'lodash/keyBy'

import { COLORS } from '../../theme'

import { getActionType } from '../../hooks/useAPI'

import Overlay from '../Overlay'
import Portal from '../Portal'
import Label from '../Label'
import PageHeader from '../layout/page/PageHeader'
import Info from '../Info'
import { ApiEntity } from '../../actions/data'

type Props = {
  onClose?: Function
  entityType?: ApiEntity
}

const SourceDocumentOverlay: React.FC<Props> = ({ onClose, entityType = 'DISTRIBUTION_PACKAGE' }) => {
  const history = useHistory()
  const { sourceDocumentId } = useParams<{ sourceDocumentId: string }>()

  const entity = useSelector(getQuerySelector({ type: getActionType(entityType) }))
  const sourceDocuments = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENTS') }))
  const sourceDocumentGroupSourceDocuments = useSelector(
    getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS') }),
  )

  const documents = {
    ...keyBy(sourceDocuments?.data?._embedded?.sourceDocuments, `document.${entity?.data?.documentKey}`),
    ...keyBy(sourceDocumentGroupSourceDocuments?.data?._embedded?.sourceDocuments, `document.${entity?.data?.documentKey}`),
  }
  const document = documents?.[sourceDocumentId]?.document

  if (!document) return null

  const onCloseDefault = () => history.push(`/distribution-packages/${entity?.data?.id}/source-documents`)

  return (
    <Portal type="overlay">
      <Overlay onClose={onClose || onCloseDefault} css={{ flexShrink: 0 }}>
        <PageHeader title={document?.[entity?.data?.documentKey]} feature="source_documents" small>
          <Info label={entity?.data?.documentKey} color={COLORS.green60} tooltip="Document Key" icon="key" />
        </PageHeader>

        <div css={styles.root}>
          {Object.keys(document).map((propertyName) => {
            const value = document[propertyName]

            return (
              <div key={`Source_document_${propertyName}`}>
                <Label label={propertyName} css={{ color: COLORS.purplePale, fontSize: '0.9rem' }} />
                <div css={styles.value}>{String(value) || '-'}</div>
              </div>
            )
          })}
        </div>
      </Overlay>
    </Portal>
  )
}

const styles: any = {
  root: {
    padding: '1.5rem',
    display: 'grid',
    gridGap: '1rem',
    overflowY: 'auto',
  },
  value: {
    borderRadius: 2,
    fontSize: '1.3rem',
    color: COLORS.purpleTitle,
  },
}

export default SourceDocumentOverlay
