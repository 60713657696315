import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Global } from '@emotion/react'
import { parse } from 'query-string'
import * as Yup from 'yup'

import { login } from '../../modules/auth'
import { getToken, buildBaseUrl, setHeaders } from '../../modules/axios'
import { COLORS } from '../../theme'

import Form from '../../components/Form'
import InputText from '../../components/InputText'
import Button from '../../components/Button'
import Footer from '../../components/Footer'
import CleanTopBar from '../../components/CleanTopBar'

const Login: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = React.useState()

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        username: Yup.string().min(8),
        password: Yup.string().min(8),
      }),
    [],
  )

  React.useEffect(() => {
    buildBaseUrl()
    setHeaders()

    const token = getToken()
    if (token) {
      let params = parse(location.search)
      if (Object.keys(params).length && params.goto) {
        history.push(params.goto)
      } else {
        const tenantURL = localStorage.getItem('vz.stn')
        if (tenantURL) history.push('/')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      await login(data)

      // store.dispatch(openWebsocket())

      history.push('/')
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
      if (err) setErrors(err?.message)
    } finally {
      setLoading(false)
    }
  }

  const defaultValues = {}

  return (
    <>
      <Global styles={globalStyles} />

      <div css={styles.root}>
        <CleanTopBar />

        <div css={styles.container}>
          <div css={{ display: 'grid', gridGap: '1rem' }}>
            {errors && <div css={styles.error}>Wrong credentials</div>}

            <Form onSubmit={onSubmit} defaultValues={defaultValues} resolver={validationSchema} css={styles.form}>
              <InputText model="username" label="Client ID" />
              <InputText model="password" label="Client Secret" type="password" />

              <Button label="Sign In" loading={loading} />
            </Form>
          </div>
        </div>

        <Footer />
      </div>
    </>
  )
}

const globalStyles = {
  'html, body': {
    background: COLORS.purplePaleLight,
  },
}

const styles = {
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '95px auto 80px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: 300,
  },
  error: {
    border: `1px solid ${COLORS.red}`,
    backgroundColor: COLORS.red60,
    color: COLORS.white,
    padding: '0.5rem',
    textAlign: 'center',
    borderRadius: 4,
    fontFamily: 'Amiko',
    textTransform: 'uppercase',
    fontSize: '.75rem',
    fontWeight: 'bold',
  },
}

export default Login
