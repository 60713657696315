import React from 'react'
import useAxios from 'axios-hooks'
import { Route, Redirect, useParams, useRouteMatch, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getQuerySelector, getMutationSelector, resetRequests } from '@redux-requests/core'
import isDefined from '@codewell/is-defined'

import useAPI, { useAPIMutation, getActionType } from '../../hooks/useAPI'
import { stringToColor } from '../../utils/functions'
import { clearDeletedConnection } from '../../actions/common'
import { COLORS } from '../../theme'

import Page from '../../components/layout/page/Page'
import PageAction from '../../components/layout/page/PageAction'
import ConnectionsSubtitle from '../../components/layout/page/subtitle/ConnectionsSubtitle'
import CardSection from '../../components/CardSection'
import Tooltip from '../../components/Tooltip'
import EditableFieldInputText from '../../components/EditableFieldInputText'
import EditableSection from '../../components/EditableSection'
import EditableSectionContent from '../../components/EditableSectionContent'
import EditableFieldGroup from '../../components/EditableFieldGroup'
import CopyableField from '../../components/CopyableField'
import Confirm from '../../components/Confirm'
import Tabs from '../../components/tabs/Tabs'
import Tab from '../../components/tabs/Tab'
import TabList from '../../components/tabs/TabList'
import SchemaOverlay from '../../components/overlays/SchemaOverlay'
import InfoConnectionStatus from '../../components/InfoConnectionStatus'

import SchemasTabPage from './tabs/schemas'
import DistributionPackagesTabPage from './tabs/distribution-Packages'

const ConnectionPage: React.FC = () => {
  const { id } = useParams()
  const { url } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const connection = useSelector(getQuerySelector({ type: getActionType('CONNECTION') }))
  const deleteMutation = useSelector(getMutationSelector({ type: getActionType('WEBHOOK', 'DELETE') }))
  const channel = connection?.data?._embedded?.channel

  const [mutationData, setMutationDataState] = React.useState()
  const [establishingConnection, setEstablishingConnection] = React.useState(false)
  const [established, setEstablished] = React.useState(false)
  const mutation = useSelector(getMutationSelector({ type: getActionType('CONNECTION', 'PATCH') }))

  const [deleteState, setDeleteState] = React.useState(false)

  const key = connection?.data?.parameters?.key || ''
  const tag = connection?.data?.tag
  const status = connection?.data?.status
  const lastUpdated = connection?.data?.lastUpdated

  const handleDelete = () => setDeleteState(true)

  useAPI('CONNECTION', `/connections/${id}/?includeChannel=true`, false, 'GET', false, undefined, null, undefined, undefined, [established])

  useAPIMutation('CONNECTION', mutationData, !isDefined(mutationData))
  useAPIMutation('CONNECTION', connection?.data, !deleteState, 'DELETE')

  const [{ data: connectionEstablished, error }, getEstablishedConnection] = useAxios(
    {
      url: `/connections/${id}`,
      method: 'GET',
    },
    { manual: true },
  )

  React.useEffect(() => {
    if (deleteState && !error) {
      try {
        getEstablishedConnection()
      } catch (error) {}
    }
  }, [deleteState, connectionEstablished])

  React.useEffect(() => {
    if (error) {
      history.push('/connections')
    }
  }, [error])

  React.useEffect(() => {
    if (connectionEstablished) {
      if (connectionEstablished?.status !== 'connection.established') {
        getEstablishedConnection()
      } else {
        setEstablishingConnection(false)
        setEstablished(true)
      }
    }
  }, [connectionEstablished])

  React.useEffect(() => {
    if (establishingConnection) getEstablishedConnection()
  }, [establishingConnection])

  React.useEffect(() => {
    dispatch(resetRequests([getActionType('SCHEMAS'), getActionType('DISTRIBUTION_PACKAGES')]))
    dispatch(clearDeletedConnection())
  }, [])

  const isLoading = connection?.pending > 0 && !established
  const isDeleting = deleteMutation?.pending > 0 || deleteState
  const isMutating = mutation?.pending > 0 || establishingConnection

  const setMutationData = (data) => {
    setMutationDataState(data)
  }

  const setMutationDataAndWaitForEstablishedConnection = (data) => {
    setMutationDataState(data)
    setEstablishingConnection(true)
  }

  const canCreateDistributionPackage = status === 'connection.established'

  return (
    <Page
      feature="connections"
      title={key}
      loading={isLoading}
      notFound={connection?.error?.response?.status === 404}
      subtitle={<ConnectionsSubtitle allowCopy id={id} channelKey={key} tag={tag} lastUpdated={lastUpdated} status={status} />}
      status={<InfoConnectionStatus status={status} seal />}
      actions={
        <>
          <Tooltip message="Coming soon" placement="left">
            <PageAction
              label="Create distribution package"
              description={!canCreateDistributionPackage && 'Establish this connection'}
              to={`/distribution-packages/new?connectionId=${id}`}
              icon="distributionPackages"
              disabled
            />
          </Tooltip>

          <Confirm onYes={handleDelete} message="Are you sure you want to delete this Connection?">
            <PageAction label="Delete" icon="thrash" reverse />
          </Confirm>
        </>
      }
    >
      <CardSection feature="connections" loading={isLoading || isDeleting}>
        <Tabs>
          <TabList>
            <Tab label="General" to={`${url}/general`} feature="general" />
            <Tab label="Schemas" to={`${url}/schemas`} feature="schemas" />
            <Tab label="Distribution Packages" to={`${url}/distribution-packages`} feature="distribution_packages" />
          </TabList>

          <Route
            path="/connections/:id/general"
            component={() => (
              <>
                <EditableSection>
                  <EditableSectionContent>
                    <EditableFieldGroup>
                      <CopyableField
                        label="Channel"
                        defaultValues={{ channelTitle: channel?.title }}
                        model="channelTitle"
                        disabled={isMutating || isDeleting}
                      />

                      <EditableFieldInputText
                        label="Tag"
                        labelValue={connection?.data?.tag?.toUpperCase()}
                        defaultValues={connection?.data}
                        model="tag"
                        colorIndicator={stringToColor(connection?.data?.tag)}
                        setMutationData={setMutationData}
                        disabled={isMutating || isDeleting}
                      />

                      <CopyableField
                        label="Connection ID"
                        defaultValues={connection?.data}
                        model="id"
                        disabled={isMutating || isDeleting}
                        allowCopy
                        shortened
                      />

                      <CopyableField
                        label="Last Updated"
                        defaultValues={connection?.data}
                        model="lastUpdated"
                        disabled={isMutating || isDeleting}
                        isDate
                      />
                    </EditableFieldGroup>

                    {connection?.data?.status === 'connection.requiresInput' && (
                      <EditableFieldGroup>
                        {channel?.key === 'amazonSellerCentral' && (
                          <EditableFieldInputText
                            label="Access Token"
                            labelValue="Complete to establish the connection"
                            defaultValues={connection?.data}
                            model="accessToken"
                            setMutationData={setMutationDataAndWaitForEstablishedConnection}
                            disabled={isMutating}
                            css={{ gridColumn: '1/-1' }}
                          />
                        )}

                        {channel?.key === 'shopify' && (
                          <div css={{ gridColumn: '1/-1', display: 'flex' }}>
                            <a href={connection?.data?._links?.installStoreApp?.href} target="_blank" css={styles.link} rel="noreferrer">
                              Establish this connection
                            </a>
                          </div>
                        )}
                      </EditableFieldGroup>
                    )}
                  </EditableSectionContent>
                </EditableSection>
              </>
            )}
          />
          <Route path="/connections/:id/schemas" component={() => <SchemasTabPage connection={connection?.data} />} />
          <Route
            path="/connections/:id/distribution-packages"
            component={() => <DistributionPackagesTabPage connection={connection?.data} channelTitle={channel?.title} />}
          />
          <Route
            path="/connections/:id/schemas/:schemaId"
            component={() => (
              <SchemaOverlay connection={connection?.data} onClose={() => history.push(`/connections/${connection?.data?.id}/schemas`)} />
            )}
          />
          <Redirect to={`${url}/general`} />
        </Tabs>
      </CardSection>
    </Page>
  )
}

const styles = {
  button: {
    border: 0,
    padding: '1rem 0.7rem',
    textTransform: 'uppercase',
    letterSpacing: '-1.4px',
    fontSize: 15,
    fontWeight: 'bold',
    borderRadius: 5,
    backgroundColor: COLORS.blue60,
    borderColor: COLORS.blue80,
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 2,
  },

  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderLeft: `1px solid ${COLORS.grayLight}`,
  },

  link: {
    textAlign: 'center',
    backgroundColor: COLORS.blue,
    color: COLORS.white,
    textTransform: 'uppercase',
    flex: 1,
    padding: '0.5rem 1rem',
    borderRadius: 4,
    textDecoration: 'none',
  },
}

export default ConnectionPage
