import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { getQuerySelector } from '@redux-requests/core'

import { COLORS, ICONS } from '../../theme'

import { getActionType } from '../../hooks/useAPI'

import Overlay from '../Overlay'
import Portal from '../Portal'
import PageHeader from '../layout/page/PageHeader'
import InfoCopyClipboard from '../InfoCopyClipboard'
import Log from '../Log'
import Info from '../Info'
import InfoId from '../InfoId'
import { COLOR_TYPES } from '../list/DocumentRuleListItem'
import InfoLink from '../InfoLink'

const DocumentRuleOverlay = ({ onClose, disableEdit, editPath = '/' }) => {
  const history = useHistory()
  const { documentRuleId } = useParams()

  const documentRules = useSelector(getQuerySelector({ type: getActionType('DOCUMENT_RULES') }))
  const documentRule = documentRules?.data?.[documentRuleId]

  const onCloseDefault = () => history.goBack()

  const isLoading = documentRules?.pending > 0

  return (
    <Portal type="overlay">
      <Overlay onClose={onClose || onCloseDefault} css={styles.root} isLoading={isLoading}>
        <PageHeader title={documentRule?.rule?.targetAttributeName} icon="rules" small>
          <InfoId id={documentRule?.id} tooltip="Id" color={COLORS.orchidPale} />

          <InfoCopyClipboard label="Copy Document Rule" icon="rules" textToCopy={JSON.stringify(documentRule)} />

          {documentRule?.rule?.key === 'copy' && (
            <Info label={documentRule?.rule?.options?.source?.attributeName} color={COLOR_TYPES.copy} tooltip="Source Document Attribute" />
          )}
          {documentRule?.rule?.key === 'literal' && (
            <Info label={documentRule?.rule?.options?.value} color={COLOR_TYPES.literal} tooltip="Literal Value" />
          )}

          {documentRule?.rule?.key === 'convert' && (
            <Info label={documentRule?.rule?.options?.type} color={COLOR_TYPES.convert} tooltip="Convert To" />
          )}
          <Info
            icon={ICONS.sourceDocumentSchemas}
            label={documentRule?.rule?.key}
            color={COLOR_TYPES[documentRule?.rule?.key] || COLORS.default}
            tooltip="Rule"
          />

          <InfoLink
            label="Edit Mapping Rules"
            icon="rules"
            color={COLORS.yellow60}
            to={`${editPath}${documentRule?.rule?.targetAttributeName}`}
            disabled={disableEdit}
          />
        </PageHeader>

        {documentRule && (
          <div css={styles.content}>
            <Log src={documentRule} shouldCollapse={(entry) => entry.name === '_links'} />
          </div>
        )}
      </Overlay>
    </Portal>
  )
}

const styles = {
  root: {
    flexShrink: 0,
    '& .dialog': {
      maxWidth: 'fit-content',
    },
  },
  content: {
    padding: '1.5rem',
    display: 'grid',
    gridGap: '1rem',
    overflowY: 'auto',
  },
  value: {
    borderRadius: 2,
    fontSize: '1.3rem',
    color: COLORS.purpleTitle,
  },
  button: {
    border: 0,
    padding: '1rem 0.7rem',
    textTransform: 'uppercase',
    letterSpacing: '-1.4px',
    fontSize: 15,
    fontWeight: 'bold',
    borderRadius: 5,
    backgroundColor: COLORS.blue60,
    borderColor: COLORS.blue80,
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 2,
    marginLeft: '1rem',
    marginRight: '3rem',
  },
}

export default DocumentRuleOverlay
