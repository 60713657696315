import React from 'react'
import clsx from 'clsx'

import MappingRulePillCopy from './MappingRulePillCopy'
import MappingRulePillLiteral from './MappingRulePillLiteral'
import MappingRulePillDefault from './MappingRulePillDefault'
import MappingRulePillConvert from './MappingRulePillConvert'
import MappingRulePillReplace from './MappingRulePillReplace'

const MAPPING_RULE_PILLS = {
  copy: MappingRulePillCopy,
  literal: MappingRulePillLiteral,
  convert: MappingRulePillConvert,
  replace: MappingRulePillReplace,
  default: MappingRulePillDefault,
}

const MappingRulePill = ({ className, ...rest }) => {
  const { rule } = rest

  const classNames = clsx({
    [className]: className,
    'is-source-document-rule': rest?.isSourceDocument,
  })

  const View = MAPPING_RULE_PILLS[rule?.rule?.key] || MAPPING_RULE_PILLS.default

  return <View {...rest} className={classNames} />
}

export default MappingRulePill
