import React from 'react'
import { useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import clsx from 'clsx'

const ApplicationWrapper: React.FC = ({ children }) => {
  const ref = React.useRef()
  const alert = useAlert()
  const notifications = useSelector((state) => state?.common?.notifications)

  const [y, setY] = React.useState(ref.current?.scrollTop)
  const [compact, setCompact] = React.useState(false)

  const classNames = clsx({
    'is-compact': y > 100,
  })

  const handleNavigation = React.useCallback(() => {
    setY(ref.current?.scrollTop)
  }, [])

  React.useEffect(() => setCompact(y > 100), [y])

  React.useEffect(() => {
    const el = ref.current
    setY(el?.scrollTop)
    el?.addEventListener('scroll', handleNavigation)

    return () => {
      el?.removeEventListener('scroll', handleNavigation)
    }
  }, [handleNavigation])

  React.useEffect(() => {
    if (notifications.length > 0) {
      alert.show(notifications[0]?.message, notifications[0])
    }
  }, [notifications])

  const childrenWithProps = React.Children.map(children, (child: any) => React.cloneElement(child, { compact }))

  return (
    <div ref={ref} css={style} className={classNames} id="page">
      {childrenWithProps}
    </div>
  )
}

const style = {
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  WebkitOverflowScrolling: 'touch',
  scrollBehavior: 'smooth',

  display: 'grid',
  gridTemplateColumns: '30rem auto',

  '@media (max-width: 1895px)': {
    gridTemplateColumns: '26rem 4fr',
  },
}

export default ApplicationWrapper
