import React from 'react'
import { useMedia } from 'use-media'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'

import clsx from 'clsx'

import { COLORS } from '../theme'

import Brand from './Brand'
import Icon from './Icon'
import Ellipsis from './typography/Ellipsis'

const MainNavigationVertical = ({ compact = false }) => {
  const small = useMedia({ maxWidth: 1895 })

  const navClassNames = clsx({
    'is-small': small,
  })

  const iconSize = small ? 24 : 26

  return (
    <div css={styles.root}>
      <div css={styles.brand}>
        <Brand width={184} height={39} />
      </div>

      <div css={styles.flexNav}>
        <div css={styles.navGrid} className={navClassNames}>
          <NavLink css={styles.navItem} activeClassName="active" to="/connections" className={navClassNames}>
            <Icon icon="connections" size={iconSize} className="icon" css={{ marginRight: '0.5rem' }} />
            <Ellipsis className="label">Connections</Ellipsis>
          </NavLink>
          <NavLink css={styles.navItem} activeClassName="active" to="/distribution-packages" className={navClassNames}>
            <Icon icon="products" size={29} className="icon" css={{ marginRight: '0.5rem' }} />
            <Ellipsis className="label">Distribution Packages</Ellipsis>
          </NavLink>
          <NavLink css={styles.navItem} activeClassName="active" to="/source-document-schemas" className={navClassNames}>
            <Icon icon="sourceDocumentSchemas" size={iconSize} className="icon" css={{ marginRight: '0.5rem' }} />
            <Ellipsis className="label">Source Document Schemas</Ellipsis>
          </NavLink>
          <NavLink css={styles.navItem} activeClassName="active" to="/source-document-groups" className={navClassNames}>
            <Icon icon="sourceDocumentsGroup" size={iconSize} className="icon" css={{ marginRight: '0.5rem' }} />
            <Ellipsis className="label">Source Document Groups</Ellipsis>
          </NavLink>
          <NavLink css={styles.navItem} activeClassName="active" to="/staged-releases" className={navClassNames}>
            <Icon icon="releases" size={28} className="icon" css={{ marginRight: '0.5rem' }} />
            <Ellipsis className="label">Staged Releases</Ellipsis>
          </NavLink>
          <NavLink css={styles.navItem} activeClassName="active" to="/releases" className={navClassNames}>
            <Icon icon="releases" size={28} className="icon" css={{ marginRight: '0.5rem' }} />
            <Ellipsis className="label">Releases</Ellipsis>
          </NavLink>
        </div>

        <div css={(styles.navGrid, { opacity: 0.7 })}>
          <NavLink css={styles.navItem} activeClassName="active" to="/channels" className={navClassNames}>
            <Icon icon="channels" size={iconSize} className="icon" css={{ marginRight: '0.5rem' }} />
            <Ellipsis className="label">Channels</Ellipsis>
          </NavLink>
          <NavLink css={styles.navItem} activeClassName="active" to="/webhooks" className={navClassNames}>
            <Icon icon="webhook" size={iconSize} className="icon" css={{ marginRight: '0.5rem' }} />
            <Ellipsis className="label">Webhooks</Ellipsis>
          </NavLink>
          <NavLink css={{ ...styles.navItem, ...{ marginTop: '2rem' } }} activeClassName="active" to="/logout">
            Sign Out
          </NavLink>
        </div>
      </div>
    </div>
  )
}

const styles = {
  root: {
    position: 'fixed',
    padding: '1rem 4rem 2rem 4rem',
    height: '100vh',

    '@media (max-width: 1895px)': {
      padding: '1rem 0rem 2rem 2rem',
    },
  },

  flexNav: {
    height: 'calc(100% - 7rem)',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  brand: {
    height: '7rem',
    padding: '2rem 0',
  },

  navGrid: {
    padding: '2rem 0',
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: '0.25rem',

    '&.is-mobile': {
      // gridGap: '1rem',
    },
  },

  navItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    textTransform: 'uppercase',
    letterSpacing: -0.5,
    textDecoration: 'none',
    color: COLORS.purple,
    fontWeight: 500,
    padding: '10px 18px',
    borderRadius: 8,

    '& .icon': {
      // display: 'none',
    },

    '&.is-mobile': {
      width: 30,

      '& .label': {
        display: 'none',
      },

      // '& .icon': {
      //   display: 'block',
      //   marginRight: 0,
      // },
    },

    '&:hover, &.active': {
      backgroundColor: darken(0.025, COLORS.contentBackground),
    },

    '@media (max-width: 1895px)': {
      fontSize: 16,
    },
  },
}

export default MainNavigationVertical
