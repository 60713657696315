import React from 'react'
import { Route, Redirect, useParams, useRouteMatch, useHistory, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getQuerySelector, getMutationSelector, resetRequests } from '@redux-requests/core'
import isDefined from '@codewell/is-defined'

import Page from '../../components/layout/page/Page'
import CardSection from '../../components/CardSection'

import EditableSection from '../../components/EditableSection'
import EditableSectionContent from '../../components/EditableSectionContent'
import EditableFieldGroup from '../../components/EditableFieldGroup'
import PageAction from '../../components/layout/page/PageAction'
import CopyableField from '../../components/CopyableField'
import Confirm from '../../components/Confirm'

import Tabs from '../../components/tabs/Tabs'
import Tab from '../../components/tabs/Tab'
import TabList from '../../components/tabs/TabList'
import DocumentRuleOverlay from '../../components/overlays/DocumentRuleOverlay'

import DocumentRulesTabPage from './tabs/document-rules'

import useAPI, { useAPIMutation, getActionType } from '../../hooks/useAPI'
import EditableFieldInputText from '../../components/EditableFieldInputText'
import { stringToColor } from '../../utils/functions'
import SourceDocumentSchemaSubtitle from '../../components/layout/page/subtitle/SourceDocumentSchemaSubtitle'
import NewSourceDocumentSchemaRule from '../../components/overlays/new/NewSourceDocumentSchemaRule'

const SourceDocumentSchemaPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { url } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const [deleteState, setDeleteState] = React.useState(false)
  const [isDeleteStarted, setIsDeleteStarted] = React.useState(false)
  const [mutationData, setMutationData] = React.useState()

  const deleteMutation = useSelector(getMutationSelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMA', 'DELETE') }))
  const mutation = useSelector(getMutationSelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMA', 'PATCH') }))

  const sourceDocumentSchema = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMA') }))

  useAPI('SOURCE_DOCUMENT_SCHEMA', `/source-document-schemas/${id}`)

  useAPIMutation('SOURCE_DOCUMENT_SCHEMA', mutationData, !isDefined(mutationData))
  useAPIMutation('SOURCE_DOCUMENT_SCHEMA', sourceDocumentSchema?.data, !deleteState, 'DELETE')

  const isLoading = sourceDocumentSchema?.pending > 0
  const isDeleting = deleteMutation?.pending > 0 || deleteState
  const isMutating = mutation?.pending > 0

  const handleDelete = () => setDeleteState(true)

  React.useEffect(() => {
    if (deleteState && isDeleting) setIsDeleteStarted(true)
    if (isDeleteStarted && !isDeleting) history.push('/webhooks')
  }, [deleteState, isDeleting])

  React.useEffect(
    () => () => {
      dispatch(resetRequests([getActionType('DOCUMENT_RULES')]))
    },
    [],
  )

  return (
    <Page
      feature="source_document_schema"
      title={sourceDocumentSchema?.data?.name}
      subtitle={
        <SourceDocumentSchemaSubtitle
          id={sourceDocumentSchema.data?.id}
          tag={sourceDocumentSchema.data?.tag}
          lastUpdated={sourceDocumentSchema.data?.lastUpdated}
          allowCopy
        />
      }
      loading={isLoading}
      working={isDeleting}
      notFound={sourceDocumentSchema?.error?.response?.status === 404}
      actions={
        <>
          <PageAction
            label="New Mapping Rule"
            to={`/source-document-schemas/${sourceDocumentSchema?.data?.id}/create-rule`}
            icon="sourceDocumentSchemas"
          />

          <PageAction
            label="Edit Mapping Rules"
            to={`/source-document-schemas/${sourceDocumentSchema?.data?.id}/mapping-rules/edit`}
            icon="sourceDocumentSchemas"
          />

          <Confirm onYes={handleDelete} message={`Are you sure you want to delete this source document schema?`}>
            <PageAction label="Delete" icon="thrash" reverse />
          </Confirm>
        </>
      }
    >
      <CardSection feature="source_document_schemas" loading={isLoading}>
        <Tabs>
          <TabList>
            <Tab label="General" to={`${url}/general`} feature="general" />
            <Tab label="Document Rules" to={`${url}/document-rules`} feature="document_rules" />
          </TabList>

          <Route
            path="/source-document-schemas/:id/general"
            component={() => (
              <EditableSection>
                <EditableSectionContent>
                  <EditableFieldGroup>
                    <EditableFieldInputText
                      css={{ wordBreak: 'break-word' }}
                      label="Name"
                      labelValue={sourceDocumentSchema?.data?.name?.toUpperCase()}
                      defaultValues={sourceDocumentSchema?.data}
                      model="name"
                      setMutationData={setMutationData}
                      disabled={isMutating || isDeleting}
                    />

                    <EditableFieldInputText
                      label="Tag"
                      labelValue={sourceDocumentSchema?.data?.tag?.toUpperCase()}
                      defaultValues={sourceDocumentSchema?.data}
                      model="tag"
                      colorIndicator={stringToColor(sourceDocumentSchema?.data?.tag)}
                      setMutationData={setMutationData}
                      disabled={isMutating || isDeleting}
                    />

                    <CopyableField
                      label="ID"
                      defaultValues={sourceDocumentSchema?.data}
                      model="id"
                      disabled={isMutating || isDeleting}
                      shortened
                      allowCopy
                    />

                    <CopyableField
                      label="Last Updated"
                      defaultValues={sourceDocumentSchema?.data}
                      model="lastUpdated"
                      disabled={isMutating || isDeleting}
                      isDate
                    />
                  </EditableFieldGroup>
                </EditableSectionContent>
              </EditableSection>
            )}
          />

          <Route
            path="/source-document-schemas/:id/(document-rules|create-rule)"
            component={() => <DocumentRulesTabPage sourceDocumentSchema={sourceDocumentSchema?.data} />}
          />
          <Route
            path="/source-document-schemas/:id/document-rules/:documentRuleId"
            component={() => (
              <DocumentRuleOverlay
                onClose={() => history.push(`/source-document-schemas/${sourceDocumentSchema?.data?.id}/document-rules`)}
                editPath={`/source-document-schemas/${sourceDocumentSchema?.data?.id}/document-rules/edit#`}
                disableEdit
              />
            )}
          />
          <Redirect to={`${url}/general`} />
        </Tabs>
        <Switch>
          <Route path="/source-document-schemas/:id/create-rule" component={() => <NewSourceDocumentSchemaRule />} />
        </Switch>
      </CardSection>
    </Page>
  )
}

export default SourceDocumentSchemaPage
