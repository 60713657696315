import React from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import { Placement } from '@popperjs/core'
import isUndefined from 'lodash/isUndefined'

type Props = {
  placement?: Placement
  offset?: [number | null | undefined, number | null | undefined]
  className?: string
  cta: React.ReactNode
  disableDefaultClickBehavior?: boolean
  open?: boolean
  setOpen?: Function
  setOpensFromTop?: Function
}

const PortalModal: React.FC = ({ children, styles, attributes, setPopperElement, popperElement, setOpen }) => {
  // const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) })

  return ReactDOM.createPortal(
    <div ref={setPopperElement} css={{ ...styles.popper, ...customStyles.root }} {...attributes.popper}>
      {/* <div ref={ref}>{children}</div> */}
      <div>{children}</div>
    </div>,
    document.querySelector('#portal-modals')!,
  )
}

const SummonModal: React.FC<Props> = ({
  children,
  cta,
  placement = 'bottom',
  offset = [0, 0],
  open,
  setOpen,
  setOpensFromTop,
  disableDefaultClickBehavior,
  className,
}) => {
  const [internalOpen, setInternalOpen] = React.useState(false)
  const [referenceElement, setReferenceElement] = React.useState(null)
  const [popperElement, setPopperElement] = React.useState(null)

  const resultOpen = isUndefined(open) ? internalOpen : open
  const resultSetOpen = isUndefined(setOpen) ? setInternalOpen : setOpen

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
  })

  if (setOpensFromTop) setOpensFromTop(attributes?.popper?.['data-popper-placement'] === 'top')

  return (
    <div>
      {cta && (
        <div
          ref={setReferenceElement}
          className={className}
          onClick={() => {
            if (!disableDefaultClickBehavior) resultSetOpen(!resultOpen)
          }}
        >
          {cta}
        </div>
      )}

      {resultOpen && (
        <PortalModal
          styles={styles}
          attributes={attributes}
          setOpen={resultSetOpen}
          setPopperElement={setPopperElement}
          popperElement={popperElement}
        >
          {children}
        </PortalModal>
      )}
    </div>
  )
}

const customStyles = {
  root: {
    zIndex: 1000,
  },
}

export default SummonModal
