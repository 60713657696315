import React from 'react'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import isDefined from '@codewell/is-defined'

import { isVenzeeUndefined } from '../../../utils/functions'

import List from '../../../components/List'
import DistributionPackageListItem from '../../../components/list/DistributionPackageListItem'

import useAPI, { getActionType } from '../../../hooks/useAPI'
import { SourceDocumentGroup } from '../../../declarations'

type Props = {
  sourceDocumentGroup: SourceDocumentGroup
}

const DistributionPackagesTabPage: React.FC<Props> = ({ sourceDocumentGroup }) => {
  const distributionPackages = useSelector(getQuerySelector({ type: getActionType('DISTRIBUTION_PACKAGES') }))
  const connections = useSelector(getQuerySelector({ type: getActionType('CONNECTIONS') }))

  const distributionPackagesList = distributionPackages?.data?._embedded?.distributionPackages
  useAPI(
    'DISTRIBUTION_PACKAGES',
    `/distribution-packages/?baseSourceDocumentGroupId=${sourceDocumentGroup?.id}`,
    !sourceDocumentGroup?.id || isDefined(distributionPackages?.data),
    'GET',
    true,
  )
  useAPI('CONNECTIONS', `/connections?includeChannel=true`, isDefined(connections?.data))

  const isLoading = distributionPackages?.pending > 0 || connections?.pending > 0
  const isEmptyList = isEmpty(distributionPackagesList)

  const checkUndefinedAttributes = ({ connectionId, channelSchemaId }) =>
    isVenzeeUndefined(connectionId) || isVenzeeUndefined(channelSchemaId)

  const fulfilledDistributionPackages = distributionPackagesList?.map((distributionPackage) => {
    return { ...distributionPackage, channelTitle: connections?.data?.[distributionPackage?.connectionId]?._embedded?.channel?.title }
  })

  const notAvailableDistributionPackages = fulfilledDistributionPackages?.filter((pack) => checkUndefinedAttributes(pack))
  const availableDistributionPackages = fulfilledDistributionPackages?.filter((pack) => !checkUndefinedAttributes(pack))

  return (
    <List feature="distribution_packages" loading={isLoading} empty={isEmptyList} gridGap="0">
      <>
        {availableDistributionPackages &&
          reverse(sortBy(availableDistributionPackages, 'lastUpdated')).map((distributionPackage) => (
            <DistributionPackageListItem
              key={distributionPackage.id}
              icon="products"
              distributionPackage={distributionPackage}
              to={`/distribution-packages/${distributionPackage.id}`}
            />
          ))}

        {notAvailableDistributionPackages &&
          reverse(sortBy(notAvailableDistributionPackages, 'lastUpdated')).map((distributionPackage) => (
            <DistributionPackageListItem
              key={distributionPackage.id}
              icon="exclamation"
              distributionPackage={distributionPackage}
              to={`/distribution-packages/${distributionPackage.id}`}
            />
          ))}
      </>
    </List>
  )
}

export default DistributionPackagesTabPage
