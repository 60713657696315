import React, { Component } from 'react'
import { resetRequests } from '@redux-requests/core'
import { withRouter } from 'react-router-dom'

import { resetSourceDocumentsCurrentUpload } from '../../actions/common'

import { logout } from '../../modules/auth'
import { store } from '../../setup/store'
import { disconnect } from '../../modules/websocket'

class Logout extends Component {
  componentDidMount = async () => {
    const isLoggedOut = await logout()
    let logoutPath = '/login'
    if (this.props.location.state) logoutPath += `?goto=${this.props.location.state.from}`
    if (isLoggedOut) this.props.history.push(logoutPath, this.props.location.state)

    disconnect()

    store.dispatch(resetRequests())
    store.dispatch(resetSourceDocumentsCurrentUpload())
  }

  render() {
    return (
      <div className="Logout">
        <span>Logging out...</span>
      </div>
    )
  }
}

export default withRouter(Logout)
