import React from 'react'
import useClipboard from 'react-use-clipboard'

import { COLORS } from '../theme'

import Info, { Props as InfoProps } from './Info'

export type Props = {
  textToCopy: string
  tooltip?: string
  successMessage?: string
} & InfoProps

const InfoCopyClipboard: React.FC<Props> = ({
  glyph = 'clipboard',
  glyphColor = COLORS.purpleTitle,
  icon,
  textToCopy,
  tooltip,
  successMessage = 'Copied!',
  ...rest
}) => {
  const [copied, setCopied] = useClipboard(textToCopy, { successDuration: 2000 })

  const message = copied ? successMessage : tooltip

  return (
    <div onClick={setCopied}>
      <Info
        icon={icon}
        afterGlyph={copied ? 'check' : glyph}
        glyphSize={17}
        glyphColor={glyphColor}
        css={styles.root}
        tooltip={message}
        {...rest}
      />
    </div>
  )
}

const styles = {
  root: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.75,
    },
  },
}

export default InfoCopyClipboard
