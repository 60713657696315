import { COLORS } from '..'
import { Feature as TFeature } from '../../declarations'

type Feature = {
  icon?: string
  title?: string
  parentpath?: string
}

const FEATURES = {
  default: {
    icon: 'error',
    title: 'FEATURE NOT FOUND',
  },
  general: {
    icon: 'general',
    title: 'General',
  },
  connections: {
    icon: 'connections',
    title: 'Connections',
    color: COLORS.green60,
    parentpath: '/connections',
  },
  connection: {
    icon: 'connections',
    title: 'Connection',
    parentpath: '/connections',
  },
  distribution_packages: {
    icon: 'distributionPackages',
    title: 'Distribution Packages',
    color: COLORS.yellow80,
    parentpath: '/distribution-packages',
  },
  distribution_package: {
    icon: 'distributionPackages',
    title: 'Distribution Package',
    color: COLORS.yellow80,
    parentpath: '/distribution-packages',
  },
  distribution_packages_mapping_rules: {
    icon: 'mappingRules',
    title: 'Mapping Rules',
    parentpath: '/distribution-packages/id',
  },
  source_document_schemas: {
    icon: 'sourceDocumentSchemas',
    title: 'Source Document Schemas',
    parentpath: '/source-document-schemas',
  },
  source_document_schema: {
    icon: 'sourceDocumentSchemas',
    title: 'Source Document Schema',
    parentpath: '/source-document-schemas',
  },
  source_document_schemas_mapping_rules: {
    icon: 'mappingRules',
    title: 'Mapping Rules',
    parentpath: '/source-document-schemas/id',
  },
  source_document_groups: {
    icon: 'sourceDocumentsGroup',
    title: 'Source Document Groups',
    parentpath: '/source-document-groups',
    color: COLORS.berryBlue40,
  },
  source_document_group: {
    icon: 'sourceDocumentsGroup',
    title: 'Source Document Group',
    parentpath: '/source-document-groups',
    color: COLORS.berryBlue40,
  },
  document_rules: {
    icon: 'sourceDocumentSchemas',
    title: 'Document Rules',
  },
  channel: {
    icon: 'channels',
    title: 'Channel',
    color: COLORS.blue80,
    parentpath: '/channels',
  },
  channels: {
    icon: 'channels',
    title: 'Channels',
    color: COLORS.purple60,
    parentpath: '/channels',
  },
  schemas: {
    icon: 'sourceDocumentSchemas',
    title: 'Channel schemas',
    parentpath: '/channels',
  },
  webhooks: {
    icon: 'webhook',
    title: 'Webhook',
    parentpath: '/webhooks',
  },
  releases: {
    icon: 'releases',
    title: 'Releases',
    parentpath: '/releases',
  },
  staged_releases: {
    icon: 'releases',
    title: 'Staged Releases',
    parentpath: '/staged-releases',
  },
  channel_schema: {
    icon: 'sourceDocumentSchemas',
    title: 'Channel Schema',
    color: COLORS.orchid40,
  },
  source_documents: {
    icon: 'sourceDocuments',
    title: 'Source Documents',
    color: COLORS.orchid40,
  },
  valid_documents: {
    icon: 'documentValid',
    title: 'Valid Documents',
    color: COLORS.orchid40,
  },
  error_documents: {
    icon: 'documentError',
    title: 'Errored Documents',
    color: COLORS.orchid40,
  },
  working: {
    icon: 'cog',
  },
}

export const getFeature = (feature: TFeature): Feature => {
  return FEATURES[feature] || FEATURES.default
}

export default FEATURES
