import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'

import FormContext from '../context/FormContext'

type Props = {
  defaultValues?: object
  onSubmit?: Function
  resolver?: any
  shouldReset?: boolean
  saveValues?: object
  resetCallback?: Function
  className?: string
  onChange?: Function
  disabled?: boolean
}

const Form: React.FC<Props> = ({
  children,
  disabled,
  defaultValues = {},
  onChange,
  resolver,
  onSubmit = () => null,
  className,
  shouldReset,
  saveValues,
  resetCallback,
}) => {
  const { register, unregister, handleSubmit, watch, formState, reset, getValues, setValue } = useForm({
    defaultValues,
    resolver: resolver ? yupResolver(resolver) : undefined,
  })

  const watchInputs = watch()

  const classNames = clsx({
    [className]: className,
    'is-disabled': disabled,
  })

  React.useEffect(() => onChange && onChange(watchInputs), [watchInputs])

  React.useEffect(() => {
    shouldReset && reset(saveValues)
    shouldReset && resetCallback?.()
  }, [shouldReset])

  return (
    <FormContext.Provider value={{ register, unregister, handleSubmit, watch, formState, disabled, getValues, setValue }}>
      <form css={styles.root} className={classNames} onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormContext.Provider>
  )
}

const styles = {
  root: {
    display: 'grid',
    gap: '1rem',

    '&.is-disabled': {},
  },
}

export default Form
