import React from 'react'

import { TabsContext } from '../../hocs/withTabsContext'

type Props = {
  defaultTab?: string | number
  children?: any[]
  onChange?: Function
  className?: string
}

const Tabs: React.FC<Props> = ({ defaultTab, children, onChange, className }) => {
  const [selected, setSelected] = React.useState(defaultTab)

  const onSelect = (name) => {
    setSelected(name)

    if (onChange) onChange(name)
  }

  return (
    <TabsContext.Provider value={{ currentTab: selected, onSelect: onSelect }}>
      <div className={className} css={styles.root}>
        {children}
      </div>
    </TabsContext.Provider>
  )
}

const styles = {
  root: {
    position: 'relative',
    zIndex: 1,
  },
}

export default Tabs
