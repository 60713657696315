import React from 'react'
import clsx from 'clsx'

import Card from '../components/Card'
import Icon from '../components/Icon'

import { COLORS } from '../theme'

type Props = {
  empty?: boolean
  loading?: boolean
  className?: string
  emptyMessage?: string
  feature?: string
}

const CardSection: React.FC<Props> = ({
  children,
  feature,
  empty = false,
  emptyMessage = 'There are no items yet',
  className = '',
  ...rest
}) => {
  const classNames = clsx({
    [className]: className,
    'is-empty': empty,
  })

  return (
    <Card css={styles.root} className={classNames} {...rest}>
      {empty && (
        <div css={styles.empty.content}>
          <Icon feature={feature} size={60} css={{ marginBottom: '1.5rem' }} />
          <div css={styles.empty.header}>{emptyMessage}</div>
        </div>
      )}
      {!empty && children}
    </Card>
  )
}

const styles: any = {
  root: {
    backgroundColor: COLORS.contentBackground,
    '&.is-empty': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      minHeight: '25rem',
    },
  },

  header: {
    padding: '1rem',
    backgroundColor: COLORS.purplePaleLight,
    borderBottom: `1px solid ${COLORS.purplePale60}`,
    height: 50,
  },

  empty: {
    header: {
      fontSize: 17,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: 300,
      textAlign: 'center',
    },
  },
}

export default CardSection
