import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getQuerySelector, resetRequests } from '@redux-requests/core'
import toArray from 'lodash/toArray'
import groupBy from 'lodash/groupBy'
import filter from 'lodash/filter'
import isDefined from '@codewell/is-defined'

import { COLORS } from '../../../../theme'

import { DocumentRule, DocumentRules } from '../../../../declarations'

import Page from '../../../../components/layout/page/Page'
import CardList from '../../../../components/CardList'

import MappingForm from '../../../../components/mapping/MappingForm'

import useAPI, { getActionType } from '../../../../hooks/useAPI'
import { getSourceDocumentsAttributes } from '../../../../utils/functions'
import { setRules, clearRules } from '../../../../actions/mapping'
import { RootState } from '../../../../setup/storeConfig'
import SearchableDropdown from '../../../../components/SearchableDropdown'
import Label from '../../../../components/Label'
import SourceDocumentSchemaSubtitle from '../../../../components/layout/page/subtitle/SourceDocumentSchemaSubtitle'
import SchemaAttributeOverview from '../../../../components/mapping/SchemaAttributeOverview'
import Warning from '../../../../components/Warning'
import PageAction from '../../../../components/layout/page/PageAction'

const SourceDocumentSchemasEditMappingRulesPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const dispatch = useDispatch()
  const [mounted, setMounted] = React.useState(false)
  const [schemaRequiredKeys, setSchemaRequiredKeys] = React.useState([])
  const [schemaNotRequiredKeys, setSchemaNotRequiredKeys] = React.useState<string[]>([])

  const mappingRules = useSelector((state: RootState) => state.mapping?.rules)

  const distributionPackage = useSelector(getQuerySelector({ type: getActionType('DISTRIBUTION_PACKAGE') }))
  const distributionPackages = useSelector(getQuerySelector({ type: getActionType('DISTRIBUTION_PACKAGES') }))
  const documentRules = useSelector(getQuerySelector({ type: getActionType('DOCUMENT_RULES') }))
  const sourceDocuments = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENTS') }))
  const schema = useSelector(getQuerySelector({ type: getActionType('SCHEMA') }))
  const schemas = useSelector(getQuerySelector({ type: getActionType('SCHEMAS') }))
  const sourceDocumentSchema = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMA') }))
  const sourceDocumentSchemas = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMA') }))

  const [mainLoading, setMainLoading] = React.useState(false)
  const [dpkgId, setDpkgId] = React.useState(null)

  const connectionId = distributionPackage?.data?.connectionId
  const channelSchemaId = distributionPackage?.data?.channelSchemaId

  useAPI('DISTRIBUTION_PACKAGE', `/distribution-packages/${dpkgId}`)
  useAPI('DISTRIBUTION_PACKAGES', '/distribution-packages')
  useAPI('DOCUMENT_RULES', `/source-document-schemas/${id}/document-rules`)
  useAPI('SOURCE_DOCUMENTS', `/distribution-packages/${dpkgId}/source-documents`, false, 'GET', true)
  useAPI('SOURCE_DOCUMENT_SCHEMA', `/source-document-schemas/${id}`)

  useAPI('SCHEMAS', `/connections/${connectionId}/schemas`, !isDefined(connectionId))
  useAPI('SCHEMA', `/connections/${connectionId}/schemas/${channelSchemaId}`, !isDefined(connectionId) || !isDefined(channelSchemaId))

  const isLoading =
    documentRules.pending > 0 ||
    distributionPackage.pending > 0 ||
    sourceDocuments.pending > 0 ||
    schemas?.pending > 0 ||
    sourceDocumentSchemas?.pending > 0 ||
    !mounted

  const sourceDocumentList = sourceDocuments?.data?._embedded?.sourceDocuments?.map((item) => item.document) || []
  const documentRulesList = toArray<DocumentRules>(mappingRules)
  const documentRulesGrouped = groupBy(toArray<DocumentRules>(documentRulesList), (rule: DocumentRule) => rule.rule?.targetAttributeName)
  const sourceDocumentRulesGrouped = groupBy(sourceDocumentSchemas?.data, (rule) => rule.rule?.targetAttributeName)
  const sourceDocumentAttributes = Object.keys(getSourceDocumentsAttributes(sourceDocumentList))

  const distributionPackagesList = toArray(distributionPackages?.data)

  const dkpgOptions = distributionPackagesList.map((dpkg) => ({ label: `${dpkg.name}${dpkg.tag ? ` - ${dpkg.tag}` : ''}`, value: dpkg.id }))

  React.useEffect(() => {
    if (documentRules?.data) dispatch(setRules(documentRules?.data))
  }, [documentRules])

  React.useEffect(() => setMounted(true), [])

  React.useEffect(
    () => () => {
      dispatch(clearRules())
    },
    [],
  )

  React.useEffect(
    () => () => {
      dispatch(resetRequests([getActionType('DISTRIBUTION_PACKAGE'), getActionType('DISTRIBUTION_PACKAGES')]))
    },
    [],
  )

  React.useEffect(() => {
    const properties = schema?.data?.definitions?.document?.properties
    const propertiesKeys = Object.keys(properties || [])
    const required = schema?.data?.definitions?.document?.required

    setSchemaRequiredKeys(required)
    setSchemaNotRequiredKeys(filter(propertiesKeys, (key) => !required?.includes(key)))
  }, [schema])

  const documentSchemaProperties = schema?.data?.definitions?.document?.properties
  const documentSchemaDefinitions = schema?.data?.definitions

  return (
    <Page
      sticky
      feature="source_document_schemas_mapping_rules"
      title={sourceDocumentSchema?.data?.name}
      loading={mainLoading}
      subtitle={
        <SourceDocumentSchemaSubtitle
          id={sourceDocumentSchema.data?.id}
          tag={sourceDocumentSchema.data?.tag}
          lastUpdated={sourceDocumentSchema.data?.lastUpdated}
          allowCopy
        />
      }
      actions={
        <>
          <PageAction to={`/source-document-schemas/${id}/general`} label="Back" icon="sourceDocumentSchemas" />
          <SchemaAttributeOverview
            schemaRequiredKeys={schemaRequiredKeys}
            schemaNotRequiredKeys={schemaNotRequiredKeys}
            showNotRequired={true}
            documentSchemaProperties={documentSchemaProperties}
            documentSchemaDefinitions={documentSchemaDefinitions}
          />
        </>
      }
    >
      <CardList css={styles.dpkgSelector}>
        <Label label={'Please select a distribution package'} />
        <SearchableDropdown options={dkpgOptions} onChange={setDpkgId} disabled={isLoading} defaultValue={distributionPackage.data?.id} />
        <Warning css={{ marginTop: '1rem' }} icon="info" label="Select a Distribution Package to use as a mapping reference" />
      </CardList>
      {dpkgId && (
        <CardList loading={isLoading} css={styles.content}>
          <MappingForm
            rules={mappingRules}
            schema={schema?.data}
            sourceDocumentAttributes={sourceDocumentAttributes}
            sourceDocuments={sourceDocumentList}
            distributionPackageId={distributionPackage?.data?.id}
            sourceDocumentSchemaId={sourceDocumentSchema?.data?.id}
            documentKey={distributionPackage?.data?.documentKey}
            documentRulesGrouped={documentRulesGrouped}
            sourceDocumentRulesGrouped={sourceDocumentRulesGrouped}
            setMainLoading={setMainLoading}
            feature={'source_document_schemas'}
          />
        </CardList>
      )}
    </Page>
  )
}

const styles: any = {
  button: {
    border: 0,
    padding: '1rem 0.7rem',
    textTransform: 'uppercase',
    letterSpacing: '-1.4px',
    fontSize: 15,
    fontWeight: 'bold',
    borderRadius: 5,
    backgroundColor: COLORS.blue60,
    borderColor: COLORS.blue80,
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomWidth: 2,
  },

  dpkgSelector: {
    padding: '1rem 0.7rem',
    maxWidth: '80%',
  },

  content: {
    border: 0,
    boxShadow: 'none',
    marginTop: 50,
    padding: 0,
    display: 'grid',
    gridGap: '2rem',
    position: 'relative',
    gridAutoColumns: 'auto 340px',
    gridAutoFlow: 'column',
  },
}

export default SourceDocumentSchemasEditMappingRulesPage
