import React from 'react'

import ListItem from '../ListItem'
import WebhooksSubtitle from '../layout/page/subtitle/WebhooksSubtitle'

type Props = {
  webhook: any
}

const WebhookListItem: React.FC<Props> = ({ webhook, ...rest }) => {
  const { url, id, key } = webhook

  return (
    <ListItem label={url} icon="webhook" {...rest}>
      <WebhooksSubtitle allowCopy={false} id={id} status={key} />
    </ListItem>
  )
}

export default WebhookListItem
