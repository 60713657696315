import React from 'react'

import { FEATURES } from '../../../../theme'

import PageSubtitle from '../PageSubtitle'
import InfoLink from '../../../InfoLink'
import InfoConnectionStatus from '../../../InfoConnectionStatus'

export type Props = {
  allowCopy?: boolean
  id: string
  channelKey?: string
  tag?: string
  lastUpdated?: number
  status?: string
  isList?: boolean
}

const ConnectionsSubtitle: React.FC<Props> = ({ allowCopy, channelKey, lastUpdated, status, isList }) => {
  return (
    <PageSubtitle allowCopy={allowCopy} lastUpdated={lastUpdated}>
      {channelKey && (
        <InfoLink
          to={`/channels/${channelKey}`}
          label={channelKey}
          icon={FEATURES.channel.icon}
          color={FEATURES.channel.color}
          tooltip="Channel"
        />
      )}
      {status && isList && <InfoConnectionStatus status={status} />}
      {/* {tag && <InfoTag label={tag} />} */}
    </PageSubtitle>
  )
}

export default ConnectionsSubtitle
