import React from 'react'

import { idShorthand } from '../../../utils/functions'

import InfoDate from '../../InfoDate'
import Info from '../../Info'
import InfoId from '../../InfoId'
import { DATE_FORMATS } from '../../FormatDate'

export type Props = {
  allowCopy?: boolean
  id?: string
  lastUpdated?: number
}

const PageSubtitle: React.FC<Props> = ({ allowCopy, id = '', lastUpdated, children }) => {
  return (
    <section css={styles.container}>
      {id && allowCopy && <InfoId id={id} />}
      {id && !allowCopy && <Info label={idShorthand(id)} />}
      {children}
      {lastUpdated && <InfoDate label={'Updated '} date={lastUpdated} format={DATE_FORMATS.relative} />}
    </section>
  )
}

const styles = {
  container: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '1rem',
    width: 'min-content',
  },
}

export default PageSubtitle
