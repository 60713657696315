import React from 'react'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getQuerySelector, getMutationSelector } from '@redux-requests/core'
import { resetRequests } from '@redux-requests/core'
import { produce } from 'immer'
import isDefined from '@codewell/is-defined'
import toArray from 'lodash/toArray'

import Overlay from '../../Overlay'
import Portal from '../../Portal'
import PageHeader from '../../layout/page/PageHeader'
import Form from '../../Form'
import InputGroup from '../../InputGroup'
import InputText from '../../InputText'
import InputSelect from '../../InputSelect'
import Separator from '../../Separator'
import RawSchemaSelector from '../../selectors/RawSchemaSelector'

import DialogActions from '../../dialog/DialogActions'
import DialogAction from '../../dialog/DialogAction'

import useAPI, { getActionType, useAPIMutation } from '../../../hooks/useAPI'
import InputSearchableSelect from '../../InputSearchableSelect'
import { RootState } from '../../../setup/storeConfig'
import { Option as SearchableDropdownOption } from '../../SearchableDropdown'
import { SourceDocumentGroup } from '../../../declarations'

const normalize = (data) => {
  if (!data) return
  return produce(data, (draft) => {
    if (draft.tag === '') delete draft.tag
    if (draft.baseSourceDocumentSchemaId === '') delete draft.baseSourceDocumentSchemaId
    if (draft.baseSourceDocumentGroupId === '') delete draft.baseSourceDocumentGroupId
  })
}

const NewDistributionPackage: React.FC = () => {
  const history = useHistory()
  const queryConnectionId = useSelector((state: RootState) => state?.router?.location?.query?.connectionId)
  const dispatch = useDispatch()

  const [distributionPackage, setDistributionPackage] = React.useState()
  const [selectedConnectionId, setSelectedConnectionId] = React.useState(queryConnectionId)
  const [selectedDocumentKey, setSelectedDocumentKey] = React.useState()
  const [getMoreState, setGetMoreState] = React.useState(false)
  const [sourceDocumentGroupsPages, setSourceDocumentGroupsPages] = React.useState<SourceDocumentGroup[]>([])

  const mutation = useSelector(getMutationSelector({ type: getActionType('DISTRIBUTION_PACKAGE', 'POST') }))

  const connections = useSelector(getQuerySelector({ type: getActionType('CONNECTIONS') }))
  const schemas = useSelector(getQuerySelector({ type: getActionType('SCHEMAS') }))
  const sourceDocumentSchemas = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMAS') }))
  const channels = useSelector(getQuerySelector({ type: getActionType('CHANNELS') }))
  const sourceDocumentGroups = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUPS') }))
  const sourceDocumentGroupsNextPage = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUPS_PAGES') }))

  const connectionsList = toArray(connections?.data)
  const schemasList = schemas?.data?._embedded?.schemas || []
  const sourceDocumentGroupsList = sourceDocumentGroups?.data?._embedded?.sourceDocumentGroups || []
  const sourceDocumentSchemasList = toArray(sourceDocumentSchemas?.data)

  const nextSDGPage =
    (sourceDocumentGroupsNextPage?.data
      ? sourceDocumentGroupsNextPage?.data?._links?.next?.href
      : sourceDocumentGroups?.data?._links?.next?.href) || ''

  const validation = React.useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().min(3),
        connectionId: Yup.string().required(),
        channelSchemaId: Yup.string().required(),
        documentKey: Yup.string().required().min(1),
      }),
    [],
  )

  useAPI('CONNECTIONS', '/connections', isDefined(connections?.data))
  useAPI('SCHEMAS', `/connections/${selectedConnectionId}/schemas?getRootCategories=true`, !isDefined(selectedConnectionId), 'GET', true)
  useAPI('SOURCE_DOCUMENT_SCHEMAS', '/source-document-schemas')
  useAPI('CHANNELS', '/channels', isDefined(channels?.data), 'GET', false, 'key')
  useAPI('SOURCE_DOCUMENT_GROUPS', '/source-document-groups?offset=0&limit=10', false, 'GET', true)
  useAPI(
    'SOURCE_DOCUMENT_GROUPS_PAGES',
    nextSDGPage,
    !nextSDGPage || !getMoreState,
    'GET',
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    [getMoreState],
  )

  useAPIMutation('DISTRIBUTION_PACKAGE', distributionPackage, !isDefined(distributionPackage), 'POST')

  React.useEffect(
    () => () => {
      dispatch(
        resetRequests([getActionType('SCHEMAS'), getActionType('SOURCE_DOCUMENT_GROUPS_PAGES'), getActionType('SOURCE_DOCUMENT_GROUPS')]),
      )
    },
    [],
  )

  React.useEffect(() => {
    if (getMoreState) setGetMoreState(false)
  }, [getMoreState])

  React.useEffect(() => {
    if (sourceDocumentGroupsNextPage?.data)
      setSourceDocumentGroupsPages([
        ...sourceDocumentGroupsPages,
        ...(sourceDocumentGroupsNextPage?.data?._embedded?.sourceDocumentGroups || []),
      ])
  }, [sourceDocumentGroupsNextPage?.data?._embedded?.sourceDocumentGroups])

  const handleFormChange = (formData) => {
    if (formData?.connectionId) setSelectedConnectionId(formData?.connectionId)
    if (formData?.documentKey) setSelectedDocumentKey(formData?.documentKey)
  }

  const isLoading = mutation?.pending > 0 || connections?.pending > 0 || schemas?.pending > 0 || sourceDocumentSchemas?.pending > 0

  const defaultValues: { [key: string]: any } = {}

  if (queryConnectionId) {
    defaultValues.connectionId = queryConnectionId
  }

  const completeSDGList = [...sourceDocumentGroupsList, ...sourceDocumentGroupsPages]

  return (
    <Portal type="overlay">
      <Form
        onSubmit={(data) => setDistributionPackage(normalize(data))}
        onChange={handleFormChange}
        resolver={validation}
        disabled={isLoading}
        defaultValues={defaultValues}
      >
        <Overlay
          onClose={() => history.push('/distribution-packages')}
          css={styles.root}
          position="right"
          fullheight
          showBackdrop
          closeOnBackdrop
          disabled={isLoading}
          isLoading={isLoading}
        >
          <PageHeader title="New Distribution Package" feature="distribution_packages" small />

          <div css={styles.content}>
            <InputGroup>
              <InputText model="name" label="Name" />
              <InputText model="tag" label="Tag" />
            </InputGroup>

            <Separator />

            <InputGroup>
              <InputText model="documentKey" label="Document Key" />
            </InputGroup>

            <InputGroup>
              <InputSearchableSelect
                label="Source Document Group"
                getMoreOptions={() => setGetMoreState(true)}
                hasMoreOptions={!!nextSDGPage}
                options={completeSDGList.map((current) => ({
                  label: current.name + ' - ' + current.tag,
                  value: current.id,
                  embedded: {
                    documentKey: current.documentKey,
                  },
                }))}
                loading={sourceDocumentGroupsList.pending > 0 || sourceDocumentGroupsNextPage.pending > 0}
                model="baseSourceDocumentGroupId"
                forceDisabled={!selectedDocumentKey}
                additionalFilters={[(current: SearchableDropdownOption) => current.embedded?.documentKey === selectedDocumentKey]}
              />
            </InputGroup>

            <Separator />

            <InputGroup>
              <InputSelect
                model="connectionId"
                label="Connection"
                disabled={!!connections?.pending || !connectionsList?.length || connections?.data?.count === 0}
              >
                {connectionsList.map((connection) => (
                  <option key={connection?.id} value={connection?.id} hidden={connection?.status !== 'connection.established'}>
                    {channels?.data?.[connection?.key]?.title || connection?.key}
                    {connection?.tag ? ` - ${connection?.tag}` : ''}
                  </option>
                ))}
              </InputSelect>

              <RawSchemaSelector
                label="Schema"
                schemas={schemasList}
                loading={schemas?.pending}
                connectionId={selectedConnectionId}
                model="channelSchemaId"
                forceDisabled={schemas?.pending || !schemasList || schemasList?.length === 0}
              />
            </InputGroup>

            <Separator />

            <InputGroup>
              <InputSelect
                model="baseSourceDocumentSchemaId"
                label="Source Document Schema"
                disabled={
                  !!sourceDocumentSchemas?.pending || !sourceDocumentSchemasList?.length || sourceDocumentSchemas?.data?.count === 0
                }
              >
                {sourceDocumentSchemasList.map((sourceDocumentSchema) => (
                  <option key={sourceDocumentSchema?.id} value={sourceDocumentSchema?.id}>
                    {sourceDocumentSchema?.name}
                  </option>
                ))}
              </InputSelect>
            </InputGroup>
          </div>

          <DialogActions>
            <DialogAction label="Create" />
            <DialogAction label="Cancel" to="/distribution-packages" />
          </DialogActions>
        </Overlay>
      </Form>
    </Portal>
  )
}

const styles = {
  root: {
    flexShrink: 0,
    display: 'flex',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
  },
}

export default NewDistributionPackage
