import React from 'react'

import isNaN from 'lodash/isNaN'

import { TOOLTIP_MAPPING_RULES } from '../../../theme/definitions/tooltips'

import Button from '../../Button'
import Form from '../../Form'
import Header from '../../Header'
import InfoTooltip from '../../InfoTooltip'
import Tooltip from '../../Tooltip'
import InputSearchableSelect from '../../InputSearchableSelect'

const DEFAULT_MODEL = {
  rule: {
    key: 'literal',
    options: {},
  },
}

const MappingRuleLiteral = ({ model, setModel, setView, close, documentSchemaProperty, create, executeDelete, enums = [] }) => {
  React.useEffect(() => {
    if (!model || model.rule?.key !== 'literal') {
      setModel({ ...DEFAULT_MODEL, rule: { targetAttributeName: documentSchemaProperty, key: 'literal' } }, true)
    }
  }, [])

  const onSubmit = (data) => {
    setModel({
      ...DEFAULT_MODEL,
      ...model,
      rule: { ...DEFAULT_MODEL.rule, targetAttributeName: documentSchemaProperty, options: { value: data.rule.options.value } },
    })

    const val = +data?.rule?.options?.value
    if (!isNaN(+val)) {
      setTimeout(
        () =>
          setModel({
            rule: {
              key: 'convert',
              targetAttributeName: documentSchemaProperty,
              options: {
                type: 'number',
                decimals: val % 1 > 0 ? 2 : 0,
              },
            },
          }),
        1000,
      )
    }

    close()
  }

  const verb = create ? 'Create a new' : 'Edit'

  return (
    <div css={styles.root}>
      <Header
        title={`${verb} Literal rule`}
        icon="rules"
        before={
          <>
            {create && (
              <Button icon="chevronRight" iconSize={17} onClick={() => setView('menu')} css={[styles.button, { marginRight: '0.5rem' }]} />
            )}
          </>
        }
        aside={
          <>
            {executeDelete && (
              <Tooltip message="Delete Rule" placement="top">
                <Button glyph="times" glyphSize={16} onClick={() => executeDelete()} css={[styles.button, { marginRight: '0.6rem' }]} />
              </Tooltip>
            )}
            <InfoTooltip message={TOOLTIP_MAPPING_RULES.literal} placement="right" />
          </>
        }
      />

      <Form onSubmit={onSubmit} defaultValues={model} css={styles.form}>
        <InputSearchableSelect
          label="Insert a Literal Value"
          model="rule.options.value"
          options={enums.map((current) => ({ label: current, value: current }))}
          allowManualEntry
        />

        <Button label="Apply Rule" loading={false} />
      </Form>
    </div>
  )
}

const styles = {
  root: {
    width: 'auto',
    height: 'auto',
  },
  form: {
    padding: '1rem',
  },
  button: {
    backgroundColor: 'transparent',
    border: 0,
    padding: 0,
  },
}

export default MappingRuleLiteral
