import React from 'react'
import { lighten } from 'polished'

import { COLORS, SHADOW } from '../../theme'

const Dialog: React.FC = ({ children, ...rest }) => {
  return (
    <div css={styles.root} {...rest}>
      {children}
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',

    fontSize: '0.7rem',
    letterSpacing: '-0.4px',
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.orchidPaleLight}`,
    lineHeight: '250%',
    borderRadius: 4,
    boxShadow: SHADOW(3, lighten(0.025, COLORS.gray40)),
  },
}

export default Dialog
