import React from 'react'

import { COLORS } from '../../../../theme'

import PageSubtitle from '../PageSubtitle'

import Info from '../../../Info'

export type Props = {
  allowCopy?: boolean
  id: string
  title?: string
  lastUpdated?: number
  attributesAvailable?: boolean
}

const SchemaSubtitle: React.FC<Props> = ({ allowCopy, id, lastUpdated, title, attributesAvailable }) => {
  return (
    <>
      <PageSubtitle allowCopy={allowCopy} id={id} lastUpdated={lastUpdated}>
        <Info
          label={'Attributes available: '}
          afterGlyph={attributesAvailable ? 'check' : 'times'}
          glyphColor={COLORS.white}
          color={COLORS.black40}
          glyphSize={18}
        />
      </PageSubtitle>
    </>
  )
}

export default SchemaSubtitle
