import React from 'react'
import filter from 'lodash/filter'
import useAxios from 'axios-hooks'

import { Schema } from '../../declarations'

import SchemaAttributeMappingInput from './SchemaAttributeMappingInput'
import Button from '../Button'
import List from '../List'
import { Dictionary } from 'lodash'
import { DocumentType } from '../../declarations/SourceDocument'

type Props = {
  rules: any
  schema: Schema
  distributionPackageId: string
  sourceDocumentSchemaId?: string
  sourceDocuments: DocumentType[]
  sourceDocumentAttributes: string[]
  disabled?: boolean
  documentKey?: string
  documentRulesGrouped?: Dictionary<any[]>
  sourceDocumentRulesGrouped?: Dictionary<any[]>
  setMainLoading?: Function
  feature?: string
}

const MappingForm: React.FC<Props> = ({
  rules,
  schema,
  distributionPackageId,
  sourceDocumentSchemaId,
  documentKey = '',
  sourceDocuments,
  sourceDocumentAttributes,
  setMainLoading = () => {},
  disabled,
  ...rest
}) => {
  //TODO @jbraccini: Change this implementation to a Stepper hook to decouple from mapping
  const [previewIndex, setPreviewIndex] = React.useState(0)
  const [previewDocument, setPreviewDocument] = React.useState(sourceDocuments?.[previewIndex])
  const [hasNextPreviewDocument, setHasNextPreviewDocument] = React.useState(previewIndex + 1 < sourceDocuments?.length)
  const [hasPrevPreviewDocument, setHasPrevPreviewDocument] = React.useState(previewIndex - 1 >= 0)

  const [schemaRequiredKeys, setSchemaRequiredKeys] = React.useState([])
  const [schemaNotRequiredKeys, setSchemaNotRequiredKeys] = React.useState<string[]>([])
  const [showNotRequired, setShowNotRequired] = React.useState(false)
  const [isShowNotRequiredStarted, setIsShowNotRequiredStarted] = React.useState(false)

  const [currentDocumentPreview, setCurrentDocumentPreview] = React.useState()

  const [{ data: documentPreview, loading: documentPreviewLoading }, getDocumentPreview] = useAxios(
    {
      url: `/distribution-packages/${distributionPackageId}/attribute-previews/${previewDocument?.[documentKey]}`,
      method: 'GET',
    },
    { manual: true },
  )

  React.useEffect(() => {
    if (isShowNotRequiredStarted) {
      setShowNotRequired(true)
      setIsShowNotRequiredStarted(false)
    }
  }, [isShowNotRequiredStarted])

  React.useEffect(() => {
    setMainLoading(documentPreviewLoading)
  }, [documentPreviewLoading])

  React.useEffect(() => {
    if (documentPreview?.document) setCurrentDocumentPreview(documentPreview.document)
  }, [documentPreview])

  React.useEffect(() => {
    getDocumentPreview()
  }, [previewDocument])

  React.useEffect(() => {
    setPreviewDocument(sourceDocuments?.[previewIndex])
    setHasNextPreviewDocument(previewIndex + 1 < sourceDocuments?.length)
    setHasPrevPreviewDocument(previewIndex - 1 >= 0)

    //eslint-disable-next-line
  }, [previewIndex])

  React.useEffect(() => {
    const properties = schema?.definitions?.document?.properties
    const propertiesKeys = Object.keys(properties || [])
    const required = schema?.definitions?.document?.required

    setSchemaRequiredKeys(required)
    setSchemaNotRequiredKeys(filter(propertiesKeys, (key) => !required?.includes(key)))
  }, [schema])

  if (!schema) return null

  const documentSchemaProperties = schema.definitions?.document?.properties
  const documentSchemaDefinitions = schema.definitions

  const nextPreviewDocument = () => {
    if (previewIndex + 1 < sourceDocuments?.length) setPreviewIndex(previewIndex + 1)
  }

  const prevPreviewDocument = () => {
    if (previewIndex - 1 >= 0) setPreviewIndex(previewIndex - 1)
  }

  const mappingFormRuleInputProps = {
    schema,
    nextPreviewDocument,
    prevPreviewDocument,
    hasNextPreviewDocument,
    hasPrevPreviewDocument,

    distributionPackageId,
    sourceDocumentSchemaId,
    sourceDocumentAttributes,
    previewDocument,
    currentDocumentPreview,
    setCurrentDocumentPreview,
    documentKey,
    documentPreview: documentPreview?.document,
    disabled,
    setMainLoading,

    documentSchemaDefinitions,
    ...rest,
  }

  return (
    <div css={styles.root}>
      <List>
        {schemaRequiredKeys &&
          schemaRequiredKeys.map((documentSchemaProperty) => (
            <SchemaAttributeMappingInput
              key={documentSchemaProperty}
              documentSchema={documentSchemaProperties}
              documentSchemaProperty={documentSchemaProperty}
              required
              {...mappingFormRuleInputProps}
            />
          ))}

        <Button
          label={`${showNotRequired ? 'Hide' : 'Show'} Additional Attributes`}
          onClick={showNotRequired ? () => setShowNotRequired(false) : () => setIsShowNotRequiredStarted(!isShowNotRequiredStarted)}
          css={{ maxWidth: '80%' }}
          loading={isShowNotRequiredStarted}
        />

        {schemaNotRequiredKeys && (
          <>
            {showNotRequired &&
              schemaNotRequiredKeys.map((documentSchemaProperty, index) => (
                <SchemaAttributeMappingInput
                  key={index}
                  documentSchema={documentSchemaProperties}
                  documentSchemaProperty={documentSchemaProperty}
                  css={{ marginTop: '2rem' }}
                  {...mappingFormRuleInputProps}
                />
              ))}
          </>
        )}
      </List>
    </div>
  )
}

const styles = {}

export default MappingForm
