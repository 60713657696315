import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getQuerySelector, getMutationSelector } from '@redux-requests/core'
import { COLORS } from '../../theme'

import Page from '../../components/layout/page/Page'
import PageAction from '../../components/layout/page/PageAction'

import CardSection from '../../components/CardSection'
import EditableSection from '../../components/EditableSection'
import EditableSectionContent from '../../components/EditableSectionContent'
import EditableFieldGroup from '../../components/EditableFieldGroup'
import CopyableField from '../../components/CopyableField'

import { webhookStatus } from '../../components/layout/page/subtitle/WebhooksSubtitle'

import useAPI, { getActionType, useAPIMutation } from '../../hooks/useAPI'
import WebhooksSubtitle from '../../components/layout/page/subtitle/WebhooksSubtitle'
import Confirm from '../../components/Confirm'

const WebhookPage: React.FC = () => {
  const { id } = useParams()
  const history = useHistory()
  const [deleteState, setDeleteState] = React.useState(false)
  const [isDeleteStarted, setIsDeleteStarted] = React.useState(false)

  const webhook = useSelector(getQuerySelector({ type: getActionType('WEBHOOK') }))
  const mutation = useSelector(getMutationSelector({ type: getActionType('WEBHOOK', 'DELETE') }))

  const isLoading = webhook.pending > 0
  const isDeleting = mutation?.pending > 0

  useAPI('WEBHOOK', `/web-hooks/${id}`, false, 'GET', false, undefined, null, 'webHooks')

  const handleDelete = () => setDeleteState(true)
  useAPIMutation('WEBHOOK', webhook?.data, !deleteState, 'DELETE')

  React.useEffect(() => {
    if (deleteState && isDeleting) setIsDeleteStarted(true)
    if (isDeleteStarted && !isDeleting) history.push('/webhooks')
  }, [deleteState, isDeleting])

  return (
    <Page
      feature="webhooks"
      title={webhook?.data?.url}
      loading={isLoading}
      working={isDeleting}
      subtitle={<WebhooksSubtitle allowCopy status={webhook?.data?.key} />}
      actions={
        <Confirm onYes={handleDelete} message={`Are you sure you want to delete this webhook?`}>
          <PageAction label="Delete Webhook" icon="thrash" reverse />
        </Confirm>
      }
    >
      <CardSection feature="webhooks" notFound={webhook?.error?.response?.status === 404} loading={isLoading || isDeleting}>
        <EditableSection>
          <EditableSectionContent>
            <EditableFieldGroup>
              <CopyableField css={{ wordBreak: 'break-word' }} label="Webhook URL" defaultValues={webhook?.data} model="url" allowCopy />

              <CopyableField
                label="Key"
                defaultValues={webhook?.data}
                model="key"
                colorIndicator={webhookStatus[webhook?.data?.key]?.color || COLORS.purpleTitle}
              />

              <CopyableField label="ID" defaultValues={webhook?.data} model="id" shortened allowCopy />

              <CopyableField label="Type" defaultValues={webhook?.data} model="type" />
            </EditableFieldGroup>
          </EditableSectionContent>
        </EditableSection>
      </CardSection>
    </Page>
  )
}

export default WebhookPage
