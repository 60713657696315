import React from 'react'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import isEmpty from 'lodash/isEmpty'
import isDefined from '@codewell/is-defined'

import List from '../../../components/List'
import SourceDocumentListItem from '../../../components/list/SourceDocumentListItem'

import useAPI, { getActionType } from '../../../hooks/useAPI'
import { SourceDocumentGroup } from '../../../declarations'

type Props = {
  sourceDocumentGroup: SourceDocumentGroup
}

const SourceDocumentsTabPage: React.FC<Props> = ({ sourceDocumentGroup }) => {
  const sourceDocuments = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENTS') }))

  const sourceDocumentsList = sourceDocuments?.data?._embedded?.sourceDocuments?.map((item) => item.document)

  useAPI(
    'SOURCE_DOCUMENTS',
    `/source-document-groups/${sourceDocumentGroup?.id}/source-documents`,
    isDefined(sourceDocuments?.data) || sourceDocuments?.pending > 0,
    'GET',
    true,
  )

  const isLoading = sourceDocuments?.pending > 0
  const isEmptyList = isEmpty(sourceDocumentsList) || sourceDocuments?.data?.count === 0

  return (
    <List feature="source_documents" loading={isLoading} empty={isEmptyList} gridGap="0" css={{ overflowX: 'auto' }}>
      {sourceDocumentsList &&
        sourceDocumentsList.map((sourceDocument) => (
          <SourceDocumentListItem
            key={sourceDocument?.id}
            documentKey={sourceDocumentGroup?.documentKey}
            sourceDocument={sourceDocument}
            to={`/source-document-groups/${sourceDocumentGroup?.id}/source-documents/${sourceDocument?.[sourceDocumentGroup?.documentKey]}`}
          />
        ))}
    </List>
  )
}

export default SourceDocumentsTabPage
