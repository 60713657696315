import React from 'react'
import { transparentize } from 'polished'

import { COLORS } from '../theme'

import Brand from './Brand'
import Container from './Container'

const CleanTopBar = () => {
  return (
    <Container fullWidth css={styles.root}>
      <div css={styles.container}>
        <Brand width={158} height={34} />
      </div>
    </Container>
  )
}

const styles = {
  root: {
    background: COLORS.white,
    borderBottom: `1px solid ${COLORS.orchidPale20}`,
    boxShadow: `0px 4px 5px ${transparentize(0.56, COLORS.orchidPaleLight)}`,
  },

  container: {
    padding: '2rem',
    width: '100%',
  },
}

export default CleanTopBar
