import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Spreadsheet from 'react-spreadsheet'
import chunk from 'lodash/chunk'
import filter from 'lodash/filter'

import { COLORS } from '../../theme'
import { SOURCE_DOCUMENT_UPLOAD_BATCH } from '../../utils/constants'
import { setSourceDocumentsCurrentUpload } from '../../actions/common'

import Button from '../Button'
import Overlay, { OverlayProps } from '../Overlay'
import Form from '../Form'
import Label from '../Label'
import InputSelect from '../InputSelect'
import Separator from '../Separator'
import { RootState } from '../../setup/storeConfig'

const rowHasError = (value) => value === 0 || value === null

const getSheetErrors = (sheet, documentKey) => {
  if (!sheet) return
  return filter(sheet, (row) => rowHasError(row[documentKey]))
}

const getValidSheet = (sheet, documentKey) => {
  if (!sheet) return
  return filter(sheet, (row) => !rowHasError(row[documentKey]))
}

type Props = {
  id?: string
  feature?: string
  documentKey: string
  onClose?: Function
} & OverlayProps

const UploadSourceDocumentsOverlay: React.FC<Props> = ({ id, feature, documentKey, ...rest }) => {
  const { onClose } = rest
  const dispatch = useDispatch()

  const spreadsheet = useSelector((state: RootState) => state?.common?.uploader?.spreadsheet)
  const [form] = React.useState({ page: spreadsheet?.sheetNames?.[0] })
  const [page, setPage] = React.useState(spreadsheet?.sheets?.[spreadsheet?.sheetNames?.[0]])
  const [sheet, setSheet] = React.useState(getValidSheet(page?.sheet, documentKey))
  const [sheetErrors, setSheetErrors] = React.useState(getSheetErrors(page?.sheet, documentKey) || [])
  const [hasDocumentKey, setHasDocumentKey] = React.useState(false)

  React.useEffect(() => {
    setHasDocumentKey(page?.headers?.includes(documentKey))
    setSheet(getValidSheet(page?.sheet, documentKey))
    setSheetErrors(getSheetErrors(page?.sheet, documentKey) || [])
  }, [page])

  const onFormChange = (data) => {
    setPage(spreadsheet?.sheets?.[data?.page])
  }

  const handleSubmit = () => {
    dispatch(setSourceDocumentsCurrentUpload(chunk(sheet, SOURCE_DOCUMENT_UPLOAD_BATCH), id, feature))
    onClose()
  }

  return (
    <Overlay position="center" {...rest} maxWidth="70">
      <div css={{ marginTop: '1rem', padding: '2rem', display: 'grid', gridGap: '1rem', overflowY: 'auto' }}>
        <Form onChange={onFormChange} defaultValues={form}>
          <InputSelect model="page" label="Which sheet do you want to use?" hideEmptyOption>
            {spreadsheet?.sheetNames?.map((name) => (
              <option value={name}>{name}</option>
            ))}
          </InputSelect>
        </Form>

        {sheet && (
          <>
            <Label label="Here is a preview of the first 30 documents" />
            <div css={styles.contentPan}>
              <Spreadsheet
                css={{ minWidth: '100%' }}
                columnLabels={page?.headers}
                data={sheet.slice(0, 30).map((document) => page?.headers.map((key) => ({ value: document[key] })))}
              />
            </div>
          </>
        )}

        {sheetErrors?.length > 0 && (
          <>
            <Label label="These Source Documents have errors and won't be imported" color={COLORS.red} marginTop="1rem" />
            <div css={[styles.contentPan, { opacity: '0.4' }]}>
              <Spreadsheet
                css={{ minWidth: '100%' }}
                columnLabels={page.headers}
                data={sheetErrors?.map?.((document) => page?.headers.map((key) => ({ value: document[key] }))) || []}
              />
            </div>
          </>
        )}

        <Separator />

        <Button
          label={
            hasDocumentKey ? (
              'Import Valid Source Documents'
            ) : (
              <p>
                This spreadsheet does not have a Source Document Key '<span css={{ textTransform: 'none' }}>{documentKey}</span>'
              </p>
            )
          }
          disabled={!hasDocumentKey}
          onClick={handleSubmit}
          css={{ textTransform: 'uppercase' }}
        />
      </div>
    </Overlay>
  )
}

const styles = {
  contentPan: {
    display: 'flex',
    justifyContent: 'flex-start',
    maxHeight: 200,
    overflow: 'auto',
    border: `1px solid ${COLORS.gray40}`,
    borderRadius: 4,
  },
}

export default UploadSourceDocumentsOverlay
