import React from 'react'

import { COLORS } from '../theme'

type Props = {
  label: string
  to?: any
}

const Label: React.FC<Props> = ({ label, to, ...rest }) => {
  return (
    <label to={to} css={{ ...styles.root, ...rest }}>
      {label}
    </label>
  )
}

const styles = {
  root: {
    color: COLORS.purple,
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: '-0.6px',
  },
}

export default Label
