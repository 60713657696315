import React from 'react'
import clsx from 'clsx'
import get from 'lodash/get'
import { COLORS } from '../theme'
import Button from './Button'
import Icon from './Icon'
import Glyph from './Glyph'
import { idShorthand } from '../utils/functions'
import useClipboard from 'react-use-clipboard'
import isDefined from '@codewell/is-defined'
import isEmpty from 'lodash/isEmpty'

import FormatDate, { DATE_FORMATS } from './FormatDate'
import { Glyph as GlyphType, Icon as IconType } from '../declarations'

type Props = {
  className?: string
  label?: string
  defaultValues?: object
  model: any
  colorIndicator?: string
  icon?: IconType
  iconSize?: number
  glyph?: GlyphType
  glyphColor?: string
  glyphSize?: number
  allowCopy?: boolean
  isDate?: boolean
  shortened?: boolean
  disabled?: boolean
} & React.ReactNode

const CopyableField: React.FC<Props> = ({
  className = '',
  label,
  defaultValues,
  model,
  colorIndicator,
  icon,
  iconSize,
  glyph,
  glyphColor,
  glyphSize,
  allowCopy,
  shortened,
  isDate,
  disabled,
}) => {
  const value = React.useMemo(() => get(defaultValues, model, ''), [defaultValues, model])
  const [copied, setCopied] = useClipboard(value || '', { successDuration: 2000 })

  const hasValue = isDefined(value) && !isEmpty(value)

  const rootClassNames = clsx({
    [className]: className,
    'is-disabled': disabled,
    'is-value': !allowCopy,
  })

  const classNames = clsx({
    'is-copyable': allowCopy,
    'is-disabled': disabled,
  })

  return (
    <div css={styles.root} className={rootClassNames}>
      <section css={styles.dataContainer}>
        <label css={styles.label} className={classNames}>
          {label}
        </label>
        <div css={styles.data}>
          {icon && <Icon icon={icon} size={iconSize} css={{ marginRight: '0.5rem' }} />}
          {glyph && <Glyph glyph={glyph} size={glyphSize} color={glyphColor} css={{ marginRight: '0.5rem' }} />}
          {colorIndicator && hasValue && <div css={styles.colorIndicator({ color: colorIndicator })} className={classNames} />}
          <span css={styles.value} className={classNames}>
            {!isDate && <>{hasValue ? (shortened ? idShorthand(value) : value) : '-'}</>}
            {isDate && <FormatDate date={value} format={DATE_FORMATS.system} />}
          </span>
          {allowCopy && (
            <Button
              role="button"
              glyph={copied ? 'check' : 'clipboard'}
              glyphSize={24}
              glyphColor={disabled ? COLORS.gray40 : COLORS.orchid}
              onClick={setCopied}
              css={styles.copy}
            />
          )}
        </div>
      </section>
    </div>
  )
}

const styles = {
  root: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem',
    minWidth: '330px',

    '&:hover': {
      outline: `1px solid ${COLORS.orchidPaleLight}`,
      borderRadius: '4px',
      boxShadow: '0px 3px 0px rgb(229 229 229 / 18%)',
    },

    '&.is-value': {
      '&:hover': {
        outline: 0,
        borderRadius: 0,
        boxShadow: 'none',
      },
    },

    '&.is-disabled': {
      '&:hover': {
        outline: 0,
        borderRadius: 0,
        boxShadow: 'none',
      },
    },
  },

  label: {
    color: COLORS.orchidPale,
    fontSize: '0.9rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.01em',
    lineHeight: '30px',
    textTransform: 'uppercase',
    pointerEvents: 'none',

    '&.is-disabled': {
      color: COLORS.gray80,
    },
  },

  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  data: {
    display: 'flex',
    alignItems: 'center',
  },

  colorIndicator: ({ color }) => ({
    backgroundColor: color,
    borderRadius: '50%',
    height: 15,
    marginRight: '0.5rem',
    minWidth: 15,

    '&.is-disabled': {
      backgroundColor: COLORS.gray80,
    },
  }),

  value: {
    alignItems: 'center',
    color: COLORS.orchidPale,
    display: 'flex',
    fontFamily: 'Amiko',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '-0.01em',
    lineHeight: '27px',
    marginTop: 5,
    marginBottom: 5,

    '&.is-copyable': {
      color: COLORS.orchid,
    },
    '&.is-disabled': {
      color: COLORS.gray80,
    },
  },
  copy: {
    background: 'none',
    border: 'none',
  },
}

export default CopyableField
