import React from 'react'

import ListItem from '../ListItem'

import { COLORS } from '../../theme'

export type Props = {
  documentKey: string
  sourceDocument: any
  action: string
}

const ErrorTranslatedDocumentListItem: React.FC<Props> = ({ errorDocument, ...rest }) => {
  return <ListItem label={errorDocument?.key} icon="documentError" {...rest} css={styles.root} />
}

const styles = {
  root: {
    '&.active': {
      background: COLORS.grayLight,
    },
  },
}

export default ErrorTranslatedDocumentListItem
