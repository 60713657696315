import React from 'react'

import { COLORS, NEW_COLORS } from '../../theme'
import useAPI from '../../hooks/useAPI'
import useFirstRender from '../../hooks/useFirstRender'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import { CircleSpinner } from 'react-spinners-kit'
import Icon from '../Icon'
import Glyph from '../Glyph'
import { darken } from 'polished'

const ExpandableSchemaItem = ({
  label,
  requestPath,
  connectionId,
  onSelect,
  handleIsExpanded,
  rootCategory,
  expandedTree = {},
  selectedId,
  level,
  handleIsWorking,
  working,
}) => {
  const ref = React.useRef()
  const entity = `SCHEMAS_${requestPath}`
  const [isExpanded, setIsExpanded] = React.useState(expandedTree[requestPath] || false)
  const firstRender = useFirstRender()

  const subCategoryList = useSelector(getQuerySelector({ type: `GET_${entity}` }))

  const schemas = subCategoryList?.data?._embedded?.schemas

  useAPI(
    entity,
    `/connections/${connectionId}/schemas?pathBeginsWith=${requestPath?.replaceAll?.('&', '%26')}`,
    !isExpanded || !connectionId || schemas,
    'GET',
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    [isExpanded],
  )

  const handleLabelClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (!working) setIsExpanded(!isExpanded)
  }

  const categories =
    schemas?.reduce((list, current) => {
      const title = current?.title || ''
      const subPath = title.slice(requestPath.length) || ''
      const categoriesList = subPath?.split?.('>')?.filter((current) => current?.trim?.()?.length > 0) || []
      const category = categoriesList[0]?.trim?.() || ''
      const subCategoryPath = category ? requestPath + ' > ' + category : requestPath
      const exists = !!list.find((current) => {
        return current.category === category
      })
      if (!exists) {
        list.push({
          category: category || title,
          hasSubCategory: subCategoryPath < title,
          subCategoryPath,
          id: current.id,
        })
      }
      return list
    }, []) || []

  React.useEffect(() => {
    if (!firstRender && isExpanded) {
      handleIsExpanded?.({
        rootCategory,
        id: requestPath,
        isOpen: isExpanded,
        ref,
      })
    }
  }, [schemas, isExpanded])

  React.useEffect(() => {
    setIsExpanded(expandedTree[requestPath])
  }, [expandedTree[requestPath]])

  React.useEffect(() => {
    handleIsWorking(subCategoryList?.pending > 0)
  }, [subCategoryList.pending])

  const renderItems = () => {
    const parsedItems = categories.reduce(
      (items, current, index) => {
        if (current.hasSubCategory) {
          items.categories.push(
            <li key={`Schema_Category_${current?.category}_${index}`}>
              <ExpandableSchemaItem
                label={current.category}
                connectionId={connectionId}
                requestPath={current.subCategoryPath}
                onSelect={onSelect}
                handleIsExpanded={handleIsExpanded}
                rootCategory={rootCategory}
                expandedTree={expandedTree}
                selectedId={selectedId}
                level={level + 1}
                handleIsWorking={handleIsWorking}
                working={working}
              />
            </li>,
          )
        } else {
          items.finals.push(
            <li key={`Schema_${requestPath}_${current.category}`}>
              <div
                onClick={() => !working && onSelect({ value: current.id, label: current.category, path: requestPath })}
                css={styles.schema(level + 1)}
                ref={current.id === selectedId ? ref : null}
              >
                {current.category}
                {current.id !== selectedId && <div css={styles.circle} className={'SchemaSelectorDot'} />}
                {current.id === selectedId && <Glyph glyph="check" size="18" className={'SchemaSelectorCheck'} />}
              </div>
            </li>,
          )
        }

        return items
      },
      { categories: [], finals: [] },
    )
    return [...parsedItems.categories, ...parsedItems.finals]
  }

  return (
    <div css={styles.root} key={`${requestPath}_schemas`} onClick={(e) => e.stopPropagation()}>
      {label && (
        <div css={styles.label(level)} onClick={handleLabelClick}>
          {label}
          {subCategoryList?.pending > 0 || (isExpanded && !categories?.length) ? (
            <CircleSpinner size={15} color={COLORS.orchidPale40} />
          ) : (
            <Icon icon={'chevron'} size="18" css={styles.chevron(isExpanded)} />
          )}
        </div>
      )}
      {isExpanded && categories?.length > 0 && <ul css={styles.list}>{renderItems()}</ul>}
    </div>
  )
}

const styles = {
  root: {},
  label: (level) => ({
    alignItems: 'center',
    backgroundColor: NEW_COLORS.purplePaleLight,
    borderBottom: `1px solid ${COLORS.orchidPale40}`,
    color: COLORS.purple,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    height: '40px',
    justifyContent: 'space-between',
    lineHeight: '19px',
    paddingLeft: 20 + level * 20,
    paddingRight: '10px',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: darken(0.1, NEW_COLORS.purplePaleLight),
    },
  }),

  chevron: (open) => {
    return {
      transition: 'transform .3s ease',
      transform: `rotate(${open ? '90' : '0'}deg)`,
    }
  },

  list: {
    listStyle: 'none',
    margin: 0,
    paddingLeft: 0,
  },

  schema: (level) => ({
    alignItems: 'center',
    backgroundColor: NEW_COLORS.purplePaleLightest,
    borderBottom: `1px solid ${COLORS.orchidPale40}`,
    color: COLORS.purple,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    height: '40px',
    justifyContent: 'space-between',
    lineHeight: '19px',
    paddingLeft: 20 + level * 20,
    paddingRight: '10px',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: darken(0.1, NEW_COLORS.purplePaleLightest),
    },
    '&:hover .SchemaSelectorDot': {
      display: 'block',
    },
    '&:hover .SchemaSelectorCheck': {
      color: `${NEW_COLORS.purplePale} !important`,
    },
  }),

  circle: {
    backgroundColor: NEW_COLORS.purplePale,
    borderRadius: '50%',
    display: 'none',
    height: '6px',
    margin: '6px',
    width: '6px',
  },
}

export default ExpandableSchemaItem
