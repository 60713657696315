import React from 'react'

import EditableFieldInputSelect from './EditableFieldInputSelect'

type Props = {
  className?: string
  defaultValues?: object
  model: string
  sourceDocumentSchema: any
  sourceDocumentSchemas: any
  disabled?: boolean
  loading?: boolean
  setMutationData?: Function
}

const EditableSourceDocumentSchemaSelector: React.FC<Props> = ({ sourceDocumentSchemas, sourceDocumentSchema, defaultValues, ...rest }) => {
  const options = React.useMemo(
    () => [
      <option key={`source_document_schema_none`} value={''}>
        None
      </option>,
      ...sourceDocumentSchemas?.map?.((schema) => {
        const { id, name } = schema
        return (
          <option key={`source_document_schema_${name}`} value={id}>
            {name}
          </option>
        )
      }),
    ],
    [sourceDocumentSchemas],
  )
  const labelValue = (sourceDocumentSchemas?.data?.count === 0 ? 'There are no Source Document Schemas' : sourceDocumentSchema?.name) || '-'

  return (
    <EditableFieldInputSelect
      label="Source Document Schema"
      labelValue={labelValue}
      defaultValues={defaultValues}
      options={options}
      {...rest}
    />
  )
}

export default EditableSourceDocumentSchemaSelector
