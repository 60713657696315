import { DateTime } from 'luxon'
import isUndefined from 'lodash/isUndefined'
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import isNumber from 'lodash/isNumber'
import toString from 'lodash/toString'
import * as XLSX from 'xlsx'

import { DEFAULT_EMPTY_VALUE, TIMEZONE } from './constants'

/*
  -----------------
  GENERAL FUNCTIONS
  -----------------
*/
export const isPast = (date, timezone = TIMEZONE, defaultValue = DEFAULT_EMPTY_VALUE) => {
  if (!date) return defaultValue

  if (typeof date === 'string') date = DateTime.fromISO(date).setZone(timezone)
  const now = DateTime.local().setZone(timezone)

  if (date <= now) return true
  else return false
}

export const arrayToMap = (value, key = 'id') => {
  if (!value) return []
  if (!Array.isArray(value)) return value

  let map = {}
  for (let i = 0; i < value.length; i++) map[get(value[i], key)] = value[i]

  return map
}

export const isDefined = (value) => !isUndefined(value) && value !== null

export const randomID = () => {
  return Math.random().toString(16).slice(2)
}

export const stripTags = (str = '') => str.replace(/<[^>]+>/g, '')

export const isVenzeeUndefined = (prop) => prop === 'undefined'

export const idShorthand = (id) => `${id?.substring(0, 4)} . . . ${id?.substring(id?.length - 4, id?.length)}`

export const ellipsis = (str = '', maxChar = 10, ellipsisStr = '. . .') => {
  const resultStr = toString(str)
  if (resultStr.length < maxChar) return resultStr

  return `${resultStr.substring(0, maxChar)} ${ellipsisStr}`
}

export const schemaCategoryEllipsis = (str, maxChar) => {
  if (!str) return

  const splitted = str.split(' > ')

  if (splitted.length === 1) return splitted[0]
  // return splitted.map((level) => ellipsis(level, maxChar)).join(' > ')

  return `${ellipsis(splitted[0], maxChar)} > ${ellipsis(splitted[splitted.length - 1], maxChar * 2)}`
}

export const getReference = ($ref) => $ref?.split('/').pop()

export const getInputSchema = (property, schema, definitions) => {
  let result = schema?.[property]

  if (result?.$ref) result = { ...definitions?.[getReference(result.$ref)] }

  if (result?.allOf) {
    const allOf = [...result.allOf]
    let extendedSchema = {}

    forEach(allOf, (props) => {
      if (!props.$ref) extendedSchema = { ...extendedSchema, ...props }
      if (props.$ref) extendedSchema = { ...extendedSchema, ...definitions?.[getReference(props.$ref)] }
    })

    result = extendedSchema
  }

  return result
}

export const stringToColor = (str: string) => {
  if (!str) return '#000'
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

export const nicePercentage = (value) => {
  if (!value) return 0

  if (Number.isInteger(value)) return Math.floor(value)
  return parseFloat(value)
}

export const readSpreadsheet = async (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)

    fileReader.onload = (e) => {
      const bufferArray = e?.target?.result

      const spreadsheet = XLSX.read(bufferArray, { type: 'buffer' })

      let sheets = {}
      spreadsheet.SheetNames.forEach((sheetName) => {
        const parsedSheet = XLSX.utils.sheet_to_json<object>(spreadsheet.Sheets[sheetName])

        sheets[sheetName] = { headers: parsedSheet[0] && Object.keys(parsedSheet[0]), sheet: parsedSheet }
      })

      resolve({
        sheetNames: spreadsheet.SheetNames,
        sheets,
      })
    }

    fileReader.onerror = (error) => reject(error)
  })

export const getSourceDocumentsAttributes = (sourceDocuments) => {
  if (!sourceDocuments) return {}

  const result = {}

  sourceDocuments.forEach((sourceDocument) => {
    const documentAttributes = Object.keys(sourceDocument)

    documentAttributes.forEach((documentAttribute) => {
      if (isUndefined(result[documentAttribute])) {
        result[documentAttribute] = 1
      } else {
        result[documentAttribute]++
      }
    })
  })

  return result
}

export const getValidSelector = (selector, sliceEnd) => {
  let result: string = selector
    .replaceAll(' ', '')
    .replaceAll(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
    .slice(0, sliceEnd || -1)
  if (isNumber(+result.charAt(0))) result = `x${result}`
  return result
}
