import React from 'react'
import clsx from 'clsx'
import { useMedia } from 'use-media'
import { NavLink } from 'react-router-dom'

import Icon from '../Icon'

import { COLORS } from '../../theme'

import withTabsContext from '../../hocs/withTabsContext'

const Tab = ({ currentTab, onSelect, onSelected, name, disabled, feature, label, to }) => {
  const small = useMedia({ maxWidth: 1600 })

  const classNames = clsx({
    'is-small': small,
  })
  const classNameDisabled = clsx({ 'is-disabled': disabled })

  if (to) {
    const isLinkActive = (_, { search, pathname }) => {
      let active = false

      if (typeof to === 'string' && !search) {
        active = pathname.includes(to) || pathname.includes(to.pathname)
      } else if (to.hasOwnProperty('search')) {
        if (to.search === null) active = search === ''
        else active = search === to.search
      }

      return active
    }

    const active = window.location.pathname.includes(to) || window.location.pathname.includes(to.pathname)
    const showLabel = !small || active

    return (
      <NavLink
        css={styles.root}
        activeClassName="is-active"
        disabled={disabled}
        isActive={isLinkActive}
        to={to}
        className={classNameDisabled}
      >
        {feature && <Icon feature={feature} size={22} css={{ marginRight: '0.5rem' }} />}

        {showLabel && <span>{label}</span>}
      </NavLink>
    )
  }

  const handleClick = () => {
    if (onSelected) onSelected()
    if (onSelect) onSelect(name)
  }

  return (
    <button type="button" css={styles.root} className={classNames} onClick={handleClick}>
      {label}
    </button>
  )
}

const styles = {
  root: {
    position: 'relative',
    // marginRight: '2rem',
    height: 50,

    fontSize: '1rem',
    fontWeight: 500,
    color: COLORS.orchidPale,

    border: 'none',
    background: 'none',
    outline: 'none',
    cursor: 'pointer',

    textTransform: 'uppercase',
    textDecoration: 'none',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    padding: '0 20px 0 20px',

    '@media (max-width: 1895px) and (min-width: 1600px)': {
      '& span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 120,
      },
    },

    '&.is-active': {
      color: COLORS.purple,

      '&::after': {
        content: '""',
        position: 'absolute',

        height: 3,
        left: -2,
        right: -2,
        bottom: -2,

        borderRadius: 1,
        background: COLORS.purple,
      },
    },

    '&.is-disabled': {
      color: COLORS.orchidPale20,
      pointerEvents: 'none',
    },
  },
}

export default withTabsContext(Tab)
