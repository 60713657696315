import React from 'react'
import { NavLink } from 'react-router-dom'
import { lighten } from 'polished'
import { CircleSpinner } from 'react-spinners-kit'
import clsx from 'clsx'

import InfoTag from './InfoTag'

import { COLORS } from '../theme'
import { idShorthand } from '../utils/functions'

import Icon from './Icon'

type Props = {
  label: string
  icon?: string
  to?: string
  id?: string
  className?: string
  disabled?: boolean
  hideIcon?: boolean
  hideChevron?: boolean
  loading?: boolean
  customLoader?: any
  tag?: string
} & React.ReactNode

const DefaultLoader: React.FC = () => <CircleSpinner size={25} color={COLORS.purplePale60} />

const ListItem: React.FC<Props> = ({
  className,
  label,
  icon,
  children,
  to,
  id,
  loading = false,
  disabled = false,
  hideIcon = false,
  hideChevron = false,
  customLoader,
  tag,
}) => {
  const classNames = clsx({
    [className]: className,
    'is-disabled': disabled,
    'is-hiding-icon': hideIcon,
    'is-loading': loading,
  })

  const Loader = customLoader ? () => customLoader : DefaultLoader
  return (
    <NavLink css={styles.root} to={to} className={classNames}>
      {loading && (
        <div>
          <Loader />
        </div>
      )}

      <div>
        <div css={styles.content}>
          <div css={styles.label}>
            <span>
              {label} {id && <span css={styles.id}>{idShorthand(id)}</span>}
            </span>
            {tag && <InfoTag label={tag} />}
          </div>
          <div css={styles.pills}>{children}</div>
        </div>
      </div>
      {!hideChevron && <Icon icon="chevron" size={30} css={{ height: '100%' }} />}
    </NavLink>
  )
}

const styles = {
  root: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto 40px',

    padding: '15px 20px 15px 20px',

    // height: 76,
    borderBottom: `1px solid ${COLORS.orchidPaleLight}`,
    textDecoration: 'none',

    color: COLORS.purpleTitle,
    backgroundColor: COLORS.white,

    '&.is-loading': {
      gridTemplateColumns: '40px auto 40px',
      minHeight: 'fit-content',
      backgroundColor: COLORS.yellowLightest,
    },

    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },

    '&:last-child': {
      borderBottom: 0,
    },

    '&:hover': {
      backgroundColor: lighten(0.025, COLORS.purplePaleLightest),
    },

    '&.is-disabled': {
      pointerEvents: 'none',
      opacity: 0.6,
    },

    '&.is-hiding-icon': {
      gridTemplateColumns: 'auto 40px',
    },
  },

  id: {
    fontSize: 13,
    color: COLORS.purpleTitleShaded,
    marginLeft: 5,
  },

  content: {
    display: 'grid',
    gridGap: 5,
  },

  label: {
    textTransform: 'uppercase',
    letterSpacing: '-1px',
    fontWeight: 500,
    fontSize: 18,

    display: 'grid',
    gridGap: 15,
    gridAutoFlow: 'column',
    width: 'fit-content',
    justifyContent: 'center',
  },

  pills: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '1rem',
  },
}

export default ListItem
