import React from 'react'
import ReactJson, { ReactJsonViewProps } from 'react-json-view'

type Props = {
  label?: string
} & ReactJsonViewProps

const Log: React.FC<Props> = ({ label, ...rest }) => {
  return (
    <div css={styles.root}>
      {label && <div css={styles.header}>{label}</div>}

      <ReactJson {...rest} />
    </div>
  )
}

const styles = {
  root: {
    display: 'grid',
    gridGap: '1rem',
  },

  header: {
    textTransform: 'uppercase',
  },
}

export default Log
