import React from 'react'

import ListItem from '../ListItem'
import Icon from '../Icon'

type Props = {
  release: any
}

const ReleaseListItem: React.FC<Props> = ({ release, ...rest }) => {
  const { id } = release

  return <ListItem label={id} icon="releases" customLoader={<Icon icon="cog" spin />} {...rest} />
}
export default ReleaseListItem
