import React from 'react'

const TimesGlyph = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.15213 5.15162C5.37716 4.92666 5.68233 4.80028 6.00052 4.80028C6.31872 4.80028 6.62389 4.92666 6.84892 5.15162L12.0005 10.3032L17.1521 5.15162C17.2628 5.03701 17.3952 4.94559 17.5416 4.8827C17.688 4.81981 17.8455 4.78671 18.0048 4.78532C18.1642 4.78394 18.3222 4.8143 18.4697 4.87464C18.6171 4.93497 18.7511 5.02408 18.8638 5.13675C18.9765 5.24942 19.0656 5.3834 19.1259 5.53088C19.1862 5.67835 19.2166 5.83637 19.2152 5.99571C19.2138 6.15504 19.1807 6.3125 19.1178 6.45891C19.055 6.60531 18.9635 6.73773 18.8489 6.84842L13.6973 12L18.8489 17.1516C19.0675 17.3779 19.1885 17.6811 19.1857 17.9957C19.183 18.3103 19.0568 18.6113 18.8343 18.8338C18.6118 19.0563 18.3108 19.1825 17.9962 19.1852C17.6816 19.188 17.3784 19.067 17.1521 18.8484L12.0005 13.6968L6.84892 18.8484C6.6226 19.067 6.31948 19.188 6.00484 19.1852C5.69021 19.1825 5.38923 19.0563 5.16674 18.8338C4.94425 18.6113 4.81805 18.3103 4.81532 17.9957C4.81258 17.6811 4.93354 17.3779 5.15213 17.1516L10.3037 12L5.15213 6.84842C4.92716 6.62339 4.80078 6.31822 4.80078 6.00002C4.80078 5.68183 4.92716 5.37666 5.15213 5.15162Z"
    />
  </svg>
)

export default TimesGlyph
