import React from 'react'

type Props = {
  className?: string
}

const EditableSectionContent: React.FC<Props> = ({ children, className }) => {
  return (
    <div css={styles.root} className={className}>
      {children}
    </div>
  )
}

const styles = {
  root: {},
}

export default EditableSectionContent
