import React from 'react'

import { COLORS } from '../../theme'

const DialogActions: React.FC = ({ children }) => {
  return <div css={styles.root}>{children}</div>
}

const styles = {
  root: {
    display: 'flex',
    borderTop: `1px solid ${COLORS.grayLight}`,

    '& > *': {
      flex: 1,
    },
  },
}

export default DialogActions
