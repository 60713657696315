import clsx from 'clsx'
import React from 'react'
import { COLORS } from '../../src/theme'
import { Schema } from '../declarations'
import EditableField from './EditableField'

import RawSchemaSelector from './selectors/RawSchemaSelector'

type Props = {
  className?: string
  defaultValues?: object
  model: string
  label?: string
  schemas?: Schema[]
  schema?: Schema
  loading?: boolean
  forceDisabled?: boolean
  disabled?: boolean
  connectionId?: string
  setMutationData: Function
  resolver: any
}

const EditableRawSchemaSelector: React.FC<Props> = ({
  label = 'Schema',
  schemas,
  loading,
  schema,
  connectionId,
  forceDisabled,
  defaultValues,
  model,
  disabled,
  ...rest
}) => {
  const classNames = clsx({
    'is-disabled': forceDisabled || disabled,
  })
  const hasConnection = !!connectionId && connectionId !== 'undefined'

  const parts = schema?.title?.split('>')
  const selectedSchemaName = parts?.pop?.()?.trim()
  const selectedSchemaPath = parts?.join?.('>')
  return (
    <EditableField
      label={label}
      model={model}
      id={`id_${model}`}
      defaultValues={defaultValues}
      labelValue={
        (
          <span>
            {
              <div css={styles.pathLabel} className={classNames}>
                {selectedSchemaPath}
              </div>
            }
            {selectedSchemaName}
          </span>
        ) || `Please select a ${hasConnection ? 'schema' : 'connection'}`
      }
      escapedIds={['RawSchemaSelectorList']}
      editComponent={
        <RawSchemaSelector
          schemas={schemas}
          loading={loading}
          connectionId={connectionId}
          labelValue={
            <span>
              {
                <div css={styles.pathLabel} className={classNames}>
                  {selectedSchemaPath}
                </div>
              }
              {selectedSchemaName}
            </span>
          }
          model={model}
          forceDisabled={forceDisabled}
        />
      }
      disabled={forceDisabled || disabled}
      {...rest}
    />
  )
}

const styles = {
  root: {},

  pathLabel: {
    color: COLORS.orchidPale80,
    fontSize: 13,
    textTransform: 'uppercase',
    lineHeight: '14,67px',
    '&.is-disabled': {
      color: COLORS.gray80,
    },
  },
}

export default EditableRawSchemaSelector
