import React from 'react'

type Props = {
  className?: string
  fullWidth?: boolean
} & React.ReactNode

const Container: React.FC<Props> = ({ children, fullWidth, className, id }) => {
  return (
    <div css={styles.root} className={className} {...{ id }}>
      <div css={styles.wrapped(fullWidth)}>{children}</div>
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },

  wrapped: (fullWidth) => ({
    display: 'flex',
    alignItems: 'center',

    width: fullWidth ? '100%' : 'calc(100% - 6rem)',
    maxWidth: fullWidth ? 'auto' : '1400px',
  }),
}

export default Container
