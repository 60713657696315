import React from 'react'

import ListItem from '../ListItem'
import Info from '../Info'
import InfoId from '../InfoId'

import { COLORS, ICONS } from '../../theme'

type Props = {
  documentRule: any
}

export const COLOR_TYPES = {
  copy: COLORS.blue80,
  literal: COLORS.green80,
  convert: COLORS.purple80,
}

const DocumentRuleListItem: React.FC<Props> = ({ documentRule, ...rest }) => {
  return (
    <ListItem label={documentRule?.rule?.targetAttributeName} icon="sourceDocumentSchemas" {...rest}>
      <InfoId id={documentRule?.id} tooltip="Id" color={COLORS.orchidPale} />

      {documentRule?.rule?.key === 'copy' && (
        <Info label={documentRule?.rule?.options?.source?.attributeName} color={COLOR_TYPES.copy} tooltip="Source Document Attribute" />
      )}

      {documentRule?.rule?.key === 'literal' && (
        <Info label={documentRule?.rule?.options?.value} color={COLOR_TYPES.literal} tooltip="Literal Value" />
      )}

      {documentRule?.rule?.key === 'convert' && (
        <Info label={documentRule?.rule?.options?.type} color={COLOR_TYPES.convert} tooltip="Convert To" />
      )}

      <Info
        icon={ICONS.sourceDocumentSchemas}
        label={documentRule?.rule?.key}
        color={COLOR_TYPES[documentRule?.rule?.key] || COLORS.default}
        tooltip="Rule"
      />
    </ListItem>
  )
}

export default DocumentRuleListItem
