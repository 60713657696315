/* eslint-disable no-undef */
import React, { useEffect } from 'react'

const ScrollSpy = ({ className = 'active', offsetTop = 0, offsetLeft = 0, duration = 1000, children }) => {
  useEffect(() => {
    const sourceElements = []
    const targetElements = []

    const throttle = (fn, wait = 100) => {
      let timer
      let time = Date.now()

      return (params) => {
        clearTimeout(timer)

        if (time + wait - Date.now() < 0) {
          fn(params)
          time = Date.now()
        } else {
          timer = setTimeout(fn, wait / 5)
        }
      }
    }

    const onScrollHandler = throttle(() => {
      sourceElements.map((source, i) => {
        const target = targetElements[i]
        const { y } = target?.getBoundingClientRect()

        const visibleVertical = y > -125 && y < window.innerHeight - 200

        if (visibleVertical) {
          source.classList.add(className)
        } else {
          source.classList.remove(className)
        }

        return true
      })
    })

    children?.map((el) => {
      const href = el.props?.href
      const self = el.ref?.current

      if (!self || !href || href.charAt(0) !== '#') {
        return false
      }

      const targetElement = href === '#' ? document.body : document.querySelector(href)

      if (targetElement) {
        // self.onclick = (e) => onClickHandler(e, targetElement.offsetTop - offsetTop, targetElement.offsetLeft - offsetLeft, duration)
        targetElements.push(targetElement)
        sourceElements.push(self)
      }

      return true
    })

    if (targetElements.length) {
      const ScrollEvent = new Event('scroll')
      document.querySelector('#page')?.addEventListener('scroll', onScrollHandler, { passive: true })
      document.querySelector('#page')?.dispatchEvent(ScrollEvent)
    }

    return () => {
      document.querySelector('#page')?.removeEventListener('scroll', onScrollHandler)
    }
  }, [children, className, duration, offsetTop, offsetLeft])

  return <>{children}</>
}

export default ScrollSpy
