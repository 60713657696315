import React from 'react'

import EditableField from './EditableField'
import InputText from './InputText'

type Props = {
  className?: string
  label?: string
  labelValue?: string
  defaultValues?: object
  url?: string
  model: any
  id?: string
  disabled?: boolean
  resolver?: any
  colorIndicator?: string
  icon?: string
  iconSize?: number
  glyph?: string
  glyphColor?: string
  glyphSize?: number
  setMutationData?: Function
} & React.ReactNode

const EditableFieldInputText: React.FC<Props> = ({ model, ...rest }) => {
  return <EditableField id={`id_${model}`} model={model} editComponent={<InputText css={styles.root} />} {...rest} />
}

const styles = {
  root: {
    maxHeight: 'unset',
    outline: 0,
  },
}

export default EditableFieldInputText
