import React from 'react'

import { COLORS } from '../theme'

import Icon from './Icon'
import Glyph from './Glyph'
import Tooltip from './Tooltip'
import Ellipsis from './typography/Ellipsis'

export type Props = {
  label?: string | React.FC | React.FunctionComponentElement<React.HTMLAttributes<HTMLElement>>
  className?: string
  icon?: string
  glyph?: string
  afterIcon?: string
  afterGlyph?: string
  iconSize?: number | string
  glyphSize?: number | string
  glyphColor?: any
  tooltipPlacement?: string
  tooltip?: string
  seal?: boolean
  spin?: boolean
}

const Warning: React.FC<Props> = ({
  icon,
  glyph,
  afterIcon,
  afterGlyph,
  iconSize = 20,
  glyphSize,
  glyphColor,
  label = '',
  tooltipPlacement = 'top',
  tooltip = '',
  className,
  seal,
  spin,
}) => (
  <Tooltip message={tooltip} placement={tooltipPlacement}>
    <div css={styles.root} className={className}>
      {icon && <Icon icon={icon} size={seal ? iconSize * 1.2 : iconSize} css={{ marginRight: '0.2rem' }} spin={spin} />}
      {glyph && <Glyph glyph={glyph} size={seal ? glyphSize * 1.2 : glyphSize} color={glyphColor} spin={spin} />}
      <Ellipsis css={{ margin: '0 0.25rem' }}>{label}</Ellipsis>
      {afterIcon && <Icon icon={afterIcon} size={seal ? iconSize * 1.2 : iconSize} css={{ marginLeft: '0.2rem' }} />}
      {afterGlyph && <Glyph glyph={afterGlyph} size={seal ? glyphSize * 1.2 : glyphSize} color={glyphColor} />}
    </div>
  </Tooltip>
)

const styles: any = {
  root: {
    alignItems: 'center',
    backgroundColor: COLORS.yellowLight,
    border: `1px solid ${COLORS.yellow80}`,
    borderRadius: 8,
    boxShadow: '8px 8px 0px rgba(255, 213, 76, 0.5)',
    color: COLORS.yellow,
    display: 'flex',
    fontSize: 14,
    fontWeight: 500,
    height: 'fit-content',
    justifyContent: 'center',
    padding: 10,
    textTransform: 'uppercase',
    width: '100%',
  },
}

export default Warning
