import produce from 'immer'

import set from 'lodash/set'

import { ApiEntity, ApiMethod, ENTITY, METHOD } from '../../actions/data'

import {
  updateDistributionPackage,
  deleteDistributionPackage,
  deleteDistributionPackageSourceDocuments,
  createDistributionPackage,
} from './distributionPackages'
import { updateConnection, deleteConnection, createConnection } from './connections'
import { deleteWebhook, createWebhook } from './webhooks'
import { createRelease } from './releases'
import { createStagedRelease } from './stagedReleases'
import {
  updateSourceDocumentSchema,
  deleteSourceDocumentSchema,
  createSourceDocumentSchema,
  createSourceDocumentSchemaRule,
} from './sourceDocumentSchemas'
import {
  deleteSourceDocumentGroup,
  createSourceDocumentGroup,
  updateSourceDocumentGroup,
  deleteSourceDocumentGroupSourceDocuments,
} from './sourceDocumentGroups'

const getOp = (op, path, value) => ({ op, path, value })

export const parseJsonPatch = (data) => {
  const added = [getOp('add', '/tag', data?.tag)]

  if (data?.accessToken && data?.accessToken !== '') {
    added.push(getOp('add', '/accessToken', data?.accessToken))
  }

  return added
}

export const parseJsonPatchResponse = (response) => {
  const parsedValues = response?._embedded?.body?.reduce((values, value) => {
    const result = { ...values }
    const path = (value.path[0] === '/' ? value.path.slice(1) : value.path).replace('/', '.')
    set(result, path, value.value)
    return result
  }, {})
  return parsedValues
}

export const parseDistributionPackagePayload = (payload) => {
  if (payload?.tag === '') payload.tag = null
  if (payload?.baseSourceDocumentSchemaId === '') payload.baseSourceDocumentSchemaId = null
  if (payload?.channelSchemaId === 'undefined') delete payload.channelSchemaId
  if (payload?.connectionId === 'undefined') delete payload.connectionId
  return payload
}

export const normalize = (data) =>
  produce(data, (draft) => {
    delete draft.id
    delete draft._links
    delete draft._embedded
    delete draft.lastUpdated
    delete draft.documentKey
  })

const MUTATIONS = {
  [`${METHOD.PATCH}_${ENTITY.DISTRIBUTION_PACKAGE}`]: updateDistributionPackage,
  [`${METHOD.DELETE}_${ENTITY.DISTRIBUTION_PACKAGE}`]: deleteDistributionPackage,
  [`${METHOD.DELETE}_${ENTITY.DISTRIBUTION_PACKAGE_SOURCE_DOCUMENTS}`]: deleteDistributionPackageSourceDocuments,
  [`${METHOD.POST}_${ENTITY.DISTRIBUTION_PACKAGE}`]: createDistributionPackage,
  [`${METHOD.PATCH}_${ENTITY.CONNECTION}`]: updateConnection,
  [`${METHOD.POST}_${ENTITY.CONNECTION}`]: createConnection,
  [`${METHOD.DELETE}_${ENTITY.CONNECTION}`]: deleteConnection,
  [`${METHOD.DELETE}_${ENTITY.WEBHOOK}`]: deleteWebhook,
  [`${METHOD.POST}_${ENTITY.WEBHOOK}`]: createWebhook,
  [`${METHOD.POST}_${ENTITY.RELEASE}`]: createRelease,
  [`${METHOD.POST}_${ENTITY.STAGED_RELEASE}`]: createStagedRelease,
  [`${METHOD.PATCH}_${ENTITY.SOURCE_DOCUMENT_SCHEMA}`]: updateSourceDocumentSchema,
  [`${METHOD.DELETE}_${ENTITY.SOURCE_DOCUMENT_SCHEMA}`]: deleteSourceDocumentSchema,
  [`${METHOD.POST}_${ENTITY.SOURCE_DOCUMENT_SCHEMA}`]: createSourceDocumentSchema,
  [`${METHOD.POST}_${ENTITY.SOURCE_DOCUMENT_SCHEMA_RULE}`]: createSourceDocumentSchemaRule,
  [`${METHOD.DELETE}_${ENTITY.SOURCE_DOCUMENT_GROUP}`]: deleteSourceDocumentGroup,
  [`${METHOD.POST}_${ENTITY.SOURCE_DOCUMENT_GROUP}`]: createSourceDocumentGroup,
  [`${METHOD.PATCH}_${ENTITY.SOURCE_DOCUMENT_GROUP}`]: updateSourceDocumentGroup,
  [`${METHOD.DELETE}_${ENTITY.SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS}`]: deleteSourceDocumentGroupSourceDocuments,
}

export const getMutation = (entity: ApiEntity, method: ApiMethod = 'PATCH') => {
  const mutation = MUTATIONS[`${method}_${entity}`]

  if (!mutation) throw new Error('No mutation found')

  return mutation
}
