import React from 'react'
import clsx from 'clsx'
import { keyframes } from '@emotion/react'

import { CircleSpinner } from 'react-spinners-kit'

import { ICONS, FEATURES, COLORS } from '../theme'

type Props = {
  icon?: string
  size?: number | string
  feature?: string
  loading?: boolean
  spin?: boolean
} & React.HTMLAttributes<HTMLImageElement>

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Icon: React.FC<Props> = ({ icon = 'default', feature, color, size = 24, loading, spin, className }) => {
  let iconObj: any = {}
  const featureIcon = FEATURES[feature]?.icon

  const classNames = clsx({
    [className]: className,
    spin: spin,
  })

  if (featureIcon) iconObj = ICONS[featureIcon]
  else if (typeof icon === 'string') iconObj = ICONS[icon]
  else if (typeof icon === 'object') iconObj = icon

  const styles: any = {
    width: size,
    height: size,
    display: 'inline-block',
    verticalAlign: 'middle',
    userSelect: 'none',
    pointerEvents: 'none',
    flex: '0 0 auto',
    fill: color ? color : iconObj?.color,

    '&.spin': {
      animation: `${rotate} 1s linear infinite`,
    },
  }

  return (
    <>
      {!loading && <img src={iconObj?.file} css={styles} alt={iconObj?.alt || 'Icon'} width={size} height={size} className={classNames} />}
      {loading && (
        <div css={{ width: size, height: size, display: 'flex', flex: '0 0 auto', justifyContent: 'center', alignItems: 'center' }}>
          <CircleSpinner size={size * (5 / 8)} color={COLORS.purplePale60} />
        </div>
      )}
    </>
  )
}

export default Icon
