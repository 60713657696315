import COLORS from './colors'

import CheckGlyph from '../../components/glyphs/CheckGlyph'
import ClipboardGlyph from '../../components/glyphs/ClipboardGlyph'
import ExclamationGlyph from '../../components/glyphs/ExclamationGlyph'
import TimesGlyph from '../../components/glyphs/TimesGlyph'
import SearchGlyph from '../../components/glyphs/SearchGlyph'
import LightningGlyph from '../../components/glyphs/LightningGlyph'
import EditGlyph from '../../components/glyphs/EditGlyph'
import PlusGlyph from '../../components/glyphs/PlusGlyph'
import ClockGlyph from '../../components/glyphs/ClockGlyph'

export const getGlyph = (glyph: string) => {
  if (glyph in GLYPHS) return GLYPHS[glyph]
  return GLYPHS.default
}

const GLYPHS = {
  default: {
    component: CheckGlyph,
    defaultColor: COLORS.default,
  },

  check: {
    component: CheckGlyph,
    defaultColor: COLORS.green,
  },

  clipboard: {
    component: ClipboardGlyph,
    defaultColor: COLORS.green,
  },

  exclamation: {
    component: ExclamationGlyph,
    defaultColor: COLORS.yellow,
  },

  times: {
    component: TimesGlyph,
    defaultColor: COLORS.red,
  },

  search: {
    component: SearchGlyph,
    defaultColor: COLORS.purplePale,
  },

  lightning: {
    component: LightningGlyph,
    defaultColor: COLORS.purplePale,
  },

  edit: {
    component: EditGlyph,
    defaultColor: COLORS.purplePale,
  },

  plus: {
    component: PlusGlyph,
    defaultColor: COLORS.blue,
  },

  clock: {
    component: ClockGlyph,
    defaultColor: COLORS.purplePale,
  },
}

export const glyphsList = Object.keys(GLYPHS)

export default GLYPHS
