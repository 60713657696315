import React from 'react'
import { AnySchema } from 'yup'

import EditableField from './EditableField'
import SearchableDropdown from './SearchableDropdown'

type Props = {
  className?: string
  label?: string
  defaultValues?: object
  model: any
  id?: string
  disabled?: boolean
  resolver?: AnySchema
  options: Array<any>
  labelValue?: string
  additionalFilters?: Function[]
  hasMoreOptions?: boolean
  getMoreOptions?: Function
  loading?: boolean
}

const EditableFieldInputSearchableSelect: React.FC<Props> = ({
  model,
  options,
  additionalFilters,
  hasMoreOptions,
  getMoreOptions,
  loading,
  labelValue,
  defaultValues,
  ...rest
}) => {
  return (
    <EditableField
      model={model}
      id={`id_${model}`}
      labelValue={labelValue}
      defaultValues={defaultValues}
      editComponent={
        <SearchableDropdown
          css={styles.root}
          options={options}
          additionalFilters={additionalFilters}
          getMoreOptions={getMoreOptions}
          hasMoreOptions={hasMoreOptions}
          loading={loading}
          isForm
        />
      }
      escapedIds={['SearchableDropdownLabel', 'SearchableDropdownList', 'SearchableDropdownSearch']}
      {...rest}
    />
  )
}

const styles = {
  root: {
    maxHeight: 'unset',
    outline: 0,
  },
}

export default EditableFieldInputSearchableSelect
