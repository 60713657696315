import React from 'react'
import useAxios from 'axios-hooks'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getMutationSelector } from '@redux-requests/core'
import { produce } from 'immer'
import isDefined from '@codewell/is-defined'

import Overlay from '../../Overlay'
import Portal from '../../Portal'
import PageHeader from '../../layout/page/PageHeader'
import Form from '../../Form'
import InputGroup from '../../InputGroup'
import InputText from '../../InputText'

import DialogActions from '../../dialog/DialogActions'
import DialogAction from '../../dialog/DialogAction'

import { useAPIMutation, getActionType } from '../../../hooks/useAPI'

import { clearNewSourceDocumentSchema } from '../../../actions/common'
import { RootState } from '../../../setup/storeConfig'

const normalize = (data) => {
  if (!data) return

  return produce(data, (draft) => {
    return draft
  })
}

const NewSourceDocumentSchema: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [sourceDocumentSchema, setSourceDocumentSchema] = React.useState()
  const [sourceDocumentSchemaLoading, setSourceDocumentSchemaLoading] = React.useState(false)

  const mutation = useSelector(getMutationSelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMA', 'POST') }))
  const newSourceDocumentSchema = useSelector((state: RootState) => state?.common?.sourceDocumentSchema)

  const validation = React.useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(),
        tag: Yup.string(),
      }),
    [],
  )

  useAPIMutation('SOURCE_DOCUMENT_SCHEMA', sourceDocumentSchema, !isDefined(sourceDocumentSchema), 'POST')

  const [{ data: sourceDocumentSchemaCreated, error }, getSourceDocumentSchemaCreated] = useAxios(
    {
      url: `/source-document-schemas/${newSourceDocumentSchema?.data?.id}`,
      method: 'GET',
    },
    { manual: true },
  )

  React.useEffect(() => {
    if (error) {
      try {
        getSourceDocumentSchemaCreated()
      } catch (error) {}
    }
  }, [error])

  React.useEffect(() => {
    if (sourceDocumentSchemaCreated?.id) {
      setSourceDocumentSchemaLoading(false)
      history.push(`/source-document-schemas/${sourceDocumentSchemaCreated?.id}`)
    }
  }, [sourceDocumentSchemaCreated])

  React.useEffect(() => {
    if (newSourceDocumentSchema?.data?.id) {
      setSourceDocumentSchemaLoading(true)
      getSourceDocumentSchemaCreated()
    }
  }, [newSourceDocumentSchema])

  React.useEffect(
    () => () => {
      dispatch(clearNewSourceDocumentSchema())
    },
    [],
  )

  const isLoading = mutation?.pending > 0 || sourceDocumentSchemaLoading

  return (
    <Portal type="overlay">
      <Form onSubmit={(data) => setSourceDocumentSchema(normalize(data))} resolver={validation} disabled={isLoading}>
        <Overlay
          onClose={() => history.push('/source-document-schemas')}
          css={styles.root}
          position="right"
          fullheight
          showBackdrop
          closeOnBackdrop
          disabled={isLoading}
          isLoading={isLoading}
        >
          <PageHeader
            title="New Source Document Schema"
            feature="source_document_schemas"
            hideBackButton
            css={{ top: 0, flexShrink: 0 }}
            small
          />

          <div css={styles.content}>
            <InputGroup>
              <InputText model="name" label="Name" />
              <InputText model="tag" label="Tag" />
            </InputGroup>
          </div>

          <DialogActions>
            <DialogAction label="Create" />
            <DialogAction label="Cancel" to="/source-document-schemas" />
          </DialogActions>
        </Overlay>
      </Form>
    </Portal>
  )
}

const styles: any = {
  root: {
    flexShrink: 0,
    display: 'flex',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
  },
}

export default NewSourceDocumentSchema
