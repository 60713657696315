import React from 'react'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'

import Info, { Props as InfoProps } from './Info'

export type Props = {
  to: string
  disabled?: boolean
} & InfoProps

const InfoLink: React.FC<Props> = ({ to, disabled, className, ...rest }) => {
  const classNames = clsx({
    [className]: className,
    'is-disabled': disabled,
  })

  return (
    <NavLink css={styles.root} to={to} className={classNames} onClick={(e) => disabled && e.preventDefault()}>
      <Info {...rest} />
    </NavLink>
  )
}

const styles = {
  root: {
    textDecoration: 'none',

    '&.is-disabled': {
      cursor: 'not-allowed',
      opacity: 0.75,
    },

    '&:hover': {
      opacity: 0.75,
    },
  },
}

export default InfoLink
