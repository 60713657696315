import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getQuerySelector, resetRequests } from '@redux-requests/core'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import reverse from 'lodash/reverse'

import Page from '../../components/layout/page/Page'
import CardSection from '../../components/CardSection'
import ListLoader from '../../components/ListLoader'
import Pagination from '../../components/Pagination'

import ReleaseListItem from '../../components/list/ReleaseListItem'
import ReleaseSubtitle from '../../components/layout/page/subtitle/ReleaseSubtitle'
import PageAction from '../../components/layout/page/PageAction'

import NewRelease from '../../components/overlays/new/NewRelease'

import useAPI, { getActionType } from '../../hooks/useAPI'
import { Release } from '../../declarations/Release'

const ReleasesPage: React.FC = () => {
  const dispatch = useDispatch()
  const releases = useSelector(getQuerySelector({ type: getActionType('RELEASES') }))
  const distributionPackages = useSelector(getQuerySelector({ type: getActionType('DISTRIBUTION_PACKAGES') }))

  const releasesList = releases?.data?._embedded?.releases || []

  const [getMoreState, setGetMoreState] = React.useState(false)
  const [releasesPages, setReleasesPages] = React.useState<Release[]>([])
  const releaseNextPage = useSelector(getQuerySelector({ type: getActionType('RELEASES_PAGES') }))
  const nextReleasePage = (releaseNextPage?.data ? releaseNextPage?.data?._links?.next?.href : releases?.data?._links?.next.href) || ''

  useAPI('RELEASES', '/releases?offset=0&limit=20', false, 'GET', true)
  useAPI('DISTRIBUTION_PACKAGES', '/distribution-packages')
  useAPI(
    'RELEASES_PAGES',
    nextReleasePage,
    !nextReleasePage || !getMoreState || releaseNextPage?.pending > 0,
    'GET',
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    [getMoreState],
  )

  React.useEffect(() => {
    if (getMoreState) setGetMoreState(false)
  }, [getMoreState])

  React.useEffect(
    () => () => {
      dispatch(resetRequests([getActionType('RELEASES'), getActionType('DISTRIBUTION_PACKAGES')]))
    },
    [],
  )

  React.useEffect(() => {
    if (releaseNextPage?.data) setReleasesPages([...releasesPages, ...(releaseNextPage?.data?._embedded?.releases || [])])
  }, [releaseNextPage?.data?._embedded?.releases])

  const isLoading = releases?.pending > 0 || distributionPackages?.pending > 0
  const isEmptyList = (isEmpty(releasesList) || releases?.data?.count === 0) && !isLoading

  const completeReleaseList = [...releasesList, ...releasesPages]

  const items = reverse(sortBy(completeReleaseList, 'lastUpdated')).map((release) => (
    <ReleaseListItem
      key={release.id}
      release={release}
      tag={distributionPackages?.data?.[release?.distributionPackageId]?.tag}
      to={`/releases/${release.id}`}
      loading={release?.status === 'releasing'}
    >
      <ReleaseSubtitle
        allowCopy={false}
        distributionPackageId={release?.distributionPackageId}
        lastUpdated={release?.lastUpdated}
        distributionPackageTitle={distributionPackages?.data?.[release?.distributionPackageId]?.name}
        status={release?.status}
        isList
      />
    </ReleaseListItem>
  ))

  return (
    <Page feature="releases" title="Releases" hideBackButton actions={<PageAction to="/releases/new" label="Create New" icon="releases" />}>
      <CardSection
        loading={isLoading}
        feature="releases"
        empty={isEmptyList}
        emptyMessage="There are no Releases created yet"
        customLoader={() => <ListLoader />}
      >
        {releasesList && (
          <Pagination
            next={() => setGetMoreState(true)}
            dataLength={items.length}
            loader={<ListLoader />}
            hasMore={!!nextReleasePage}
            scrollableTarget="page"
          >
            {items}
          </Pagination>
        )}
      </CardSection>

      <Switch>
        <Route exact path="/releases/new" component={NewRelease} />
      </Switch>
    </Page>
  )
}

export default ReleasesPage
