import React from 'react'
import { createPortal } from 'react-dom'

import usePortal from '../hooks/usePortal'

const Portal = ({ children, type = 'default' }) => {
  const portal = usePortal(type)
  return children && portal ? createPortal(<>{children}</>, portal) : null
}

export default Portal
