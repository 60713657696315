import React from 'react'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getQuerySelector, getMutationSelector } from '@redux-requests/core'
import { produce } from 'immer'
import isDefined from '@codewell/is-defined'
import toArray from 'lodash/toArray'
import pick from 'lodash/pick'
import clone from 'lodash/clone'
import { notify } from '../../../modules/notifications'

import Overlay from '../../Overlay'
import Portal from '../../Portal'
import PageHeader from '../../layout/page/PageHeader'
import Form from '../../Form'
import InputGroup from '../../InputGroup'
import InputText from '../../InputText'
import InputSelect from '../../InputSelect'
import Separator from '../../Separator'
import ErrorList from '../../ErrorList'

import DialogActions from '../../dialog/DialogActions'
import DialogAction from '../../dialog/DialogAction'

import useAPI, { getActionType, useAPIMutation } from '../../../hooks/useAPI'

const CONNECTION_TYPES = {
  SingleUserPerConnection: 'SingleUserPerConnection',
  AmazonSellerCentral: 'AmazonSellerCentral',
  NewUserPerConnection: 'NewUserPerConnection',
  Shopify: 'Shopify',
}

const CONNECTION_TYPE_FIELDS = {
  [CONNECTION_TYPES.SingleUserPerConnection]: ['key', 'tag', 'password', 'username'],
  [CONNECTION_TYPES.NewUserPerConnection]: ['key', 'tag'],
  [CONNECTION_TYPES.AmazonSellerCentral]: ['key', 'sellerId', 'tag'],
  [CONNECTION_TYPES.Shopify]: ['key', 'shopUrl', 'tag'],
}

const normalize = (data, type, channelKey) => {
  if (!data) return

  return produce(data, (draft) => {
    let paramProperties = {}
    const clonedDraft = clone(draft)

    clonedDraft.key = channelKey

    switch (type) {
      case CONNECTION_TYPES.SingleUserPerConnection:
        paramProperties = pick(clonedDraft, CONNECTION_TYPE_FIELDS.SingleUserPerConnection)
        break
      case CONNECTION_TYPES.NewUserPerConnection:
        paramProperties = pick(clonedDraft, CONNECTION_TYPE_FIELDS.NewUserPerConnection)
        break
      case CONNECTION_TYPES.AmazonSellerCentral:
        paramProperties = pick(clonedDraft, CONNECTION_TYPE_FIELDS.AmazonSellerCentral)
        break
      case CONNECTION_TYPES.Shopify:
        paramProperties = pick(clonedDraft, CONNECTION_TYPE_FIELDS.Shopify)
        break
    }

    if (paramProperties?.tag === '') delete paramProperties.tag

    return paramProperties
  })
}

const NewConnection: React.FC = () => {
  const history = useHistory()

  const [connection, setConnection] = React.useState()
  const [selectedChannelKey, setSelectedChannelKey] = React.useState()
  const [resetSaveValues, setResetSaveValues] = React.useState()

  const mutation = useSelector(getMutationSelector({ type: getActionType('CONNECTION', 'POST') }))

  const channels = useSelector(getQuerySelector({ type: getActionType('CHANNELS') }))
  const selectedChannel = useSelector(getQuerySelector({ type: getActionType('CHANNEL') }))

  const channelsList = toArray(channels?.data)

  const validation = React.useMemo(() => {
    let dynamicConditions = {}
    if (selectedChannel?.data) {
      Object.keys(selectedChannel?.data?.connectionSchema?.properties).forEach((propertyKey) => {
        const property = selectedChannel?.data?.connectionSchema?.properties[propertyKey]

        if (!property?.type) return false

        if (property?.pattern) {
          dynamicConditions[propertyKey] = Yup.string().required().matches(new RegExp(property?.pattern))
          return false
        }

        dynamicConditions[propertyKey] = Yup.string().required()
      })
    }

    const conditions = {
      key: Yup.string().required(),

      ...dynamicConditions,
    }

    return Yup.object().shape(conditions)
  }, [selectedChannel?.data])

  const handleConnectionSuccess = (response) => {
    notify('CONNECTION', 'CREATED', response.data)
    history.push(`/connections/${response?.data?.id}/general`)

    return response
  }

  useAPI('CHANNELS', '/channels', isDefined(channels?.data), 'GET', false, 'key')
  useAPI('CHANNEL', `/channels/${selectedChannelKey}`, !selectedChannelKey, 'GET', false, 'key')
  useAPIMutation('CONNECTION', connection, !isDefined(connection), 'POST', handleConnectionSuccess)

  const isLoading = mutation?.pending > 0 || channels?.pending > 0 || selectedChannel?.pending > 0

  const handleSelectChannel = (e) => {
    const { value } = e.target
    setSelectedChannelKey(value)
    setResetSaveValues({ key: value })
  }

  return (
    <Portal type="overlay">
      <Form
        onSubmit={(data) => setConnection(normalize(data, selectedChannel?.data?.type, selectedChannel?.data?.key))}
        resolver={validation}
        disabled={isLoading}
        shouldReset={!!resetSaveValues}
        saveValues={resetSaveValues}
        resetCallback={() => setResetSaveValues(undefined)}
      >
        <Overlay
          onClose={() => history.push('/connections')}
          css={styles.root}
          position="right"
          fullheight
          showBackdrop
          closeOnBackdrop
          disabled={isLoading}
          isLoading={isLoading}
        >
          <PageHeader title="New Connection" feature="connections" small />

          <div css={styles.content}>
            <InputGroup>
              <InputSelect
                model="key"
                label="Channel"
                disabled={channels?.pending || !channelsList?.length || channels?.data?.count === 0}
                onChange={handleSelectChannel}
              >
                {channelsList.map((channel) => (
                  <option key={channel?.key} value={channel?.key}>
                    {channel?.title}
                  </option>
                ))}
              </InputSelect>

              {isDefined(selectedChannel?.data) &&
                Object.keys(selectedChannel?.data?.connectionSchema?.properties)
                  .map((propertyKey) => ({ ...selectedChannel?.data?.connectionSchema?.properties?.[propertyKey], propertyKey }))
                  .map((property) => {
                    switch (property.type) {
                      case undefined:
                        return null
                      default:
                        return (
                          <>
                            <InputText model={property.propertyKey} label={property.propertyKey} />
                            {property.description && <div>{property.description}</div>}
                          </>
                        )
                    }
                  })}
            </InputGroup>
            <Separator />

            <InputGroup>
              <InputText model="tag" label="Tag" />
            </InputGroup>

            {mutation?.error?.response && (
              <InputGroup>
                <ErrorList errors={mutation.error?.response?.data} />
              </InputGroup>
            )}
          </div>

          <DialogActions>
            <DialogAction label="Create" />
            <DialogAction label="Cancel" to="/connections" />
          </DialogActions>
        </Overlay>
      </Form>
    </Portal>
  )
}

const styles: any = {
  root: {
    display: 'flex',
    flexShrink: 0,
  },
  content: {
    flex: 1,
    overflowY: 'auto',
  },
}

export default NewConnection
