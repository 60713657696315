import React from 'react'
import clsx from 'clsx'
import { darken, transparentize } from 'polished'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { AutoSizer, List as VirtualizedList } from 'react-virtualized'
import { COLORS, NEW_COLORS } from '../theme'
import Card from './Card'
import Icon from './Icon'
import SummonModal from './SummonModal'
import Glyph from './Glyph'
import { CircleSpinner } from 'react-spinners-kit'
import withFormContext from '../hocs/withFormContext'
import get from 'lodash/get'
import Tooltip from './Tooltip'

export type Option = {
  label: string
  value: string | number
  embedded?: { [key: string]: any }
}

export type Props = {
  model?: string
  options?: Option[]
  valueLabel?: string
  defaultValue?: number | string
  disabled?: boolean
  forceDisabled?: boolean
  additionalFilters?: Array<(p1: Option) => boolean>
  onChange?: Function
  loading?: boolean
  hasMoreOptions?: boolean
  getMoreOptions?: Function
  isForm?: boolean
  register?: Function
  getValues?: Function
  allowManualEntry?: boolean
}

const ItemLabel: React.FC<{ text: string; parentWidth: number | undefined }> = ({ text, parentWidth }) => {
  const labelRef: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null)
  const [textWidth, setTextWidth] = React.useState(0)
  React.useEffect(() => {
    setTextWidth(labelRef?.current?.offsetWidth || 0)
  }, [labelRef?.current])
  return <span ref={labelRef}>{parentWidth && textWidth > parentWidth - 90 ? <Tooltip message={text}>{text}</Tooltip> : text}</span>
}

const SearchableDropdown: React.FC<Props> = ({
  model,
  valueLabel = '',
  forceDisabled,
  options = [],
  disabled,
  defaultValue,
  additionalFilters = [],
  onChange = () => {},
  loading,
  hasMoreOptions,
  getMoreOptions,
  isForm,
  register,
  getValues,
  allowManualEntry,
}) => {
  const [open, setOpen] = React.useState(false)
  const [send, setSend] = React.useState(false)
  const [search, setSearch] = React.useState<string>('')
  const classNames = clsx({
    'is-disabled': forceDisabled || disabled,
    'is-list-hidden': options.length === 0,
  })

  const overscanRowCount = 5

  const registered = register?.(model)
  const selectedValue = defaultValue || get(getValues?.(), model || '')
  const filters = [(current: Option) => current?.label?.toLowerCase?.().includes(search.toLowerCase()), ...additionalFilters]
  const filteredList = filters.reduce((list = [], currentFilter) => {
    return list.filter(currentFilter)
  }, options)

  const handleSelect = (e) => {
    if (isForm) {
      registered.onChange({
        target: {
          name: model,
          value: e,
          type: 'select',
        },
      })
    } else {
      onChange(e)
    }
    setOpen(false)
  }

  const handleBlur = (e) => {
    let isPrevented = false
    if (e?.type === 'click') {
      isPrevented = !!e.path?.find?.((current) =>
        ['SearchableDropdownLabel', 'SearchableDropdownList', 'SearchableDropdownSearch'].includes(current.id),
      )
    }
    if (!isPrevented) {
      if (allowManualEntry) setSend(true)
      setOpen(false)
    }
  }
  const ref: React.MutableRefObject<HTMLInputElement | null> = useDetectClickOutside({ onTriggered: handleBlur })
  const focusRef: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null)

  const handleOpen = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(!open)
  }

  const handleInputKey = (e) => {
    if (allowManualEntry && e.keyCode === 13) {
      e.preventDefault()
      handleSelect(search)
    }
    if (!allowManualEntry && e.keyCode === 13) {
      e.preventDefault()
      handleSelect(filteredList[0]?.value)
    }
  }

  if (open && filteredList.length === 0 && !loading && hasMoreOptions) getMoreOptions?.()

  React.useEffect(() => {
    if (focusRef.current && open) focusRef.current.focus()
    if (!open)
      setSearch(
        valueLabel || options.find((current) => current.value === selectedValue)?.label || selectedValue?.label || selectedValue || '',
      )
  }, [focusRef.current, open])

  React.useEffect(() => {
    // This is because useDetectClickOutside in version 1.1.1 doesnt have peer ddependencies
    // and uses the initial state instead of the current
    if (send) {
      handleSelect(search)
      setSend(false)
    }
  }, [send])

  return (
    <div css={styles.root} className={classNames} key={model} onClick={(e) => e.stopPropagation()}>
      <div ref={ref}>
        <SummonModal
          open={open}
          placement="bottom-start"
          cta={
            <div onClick={handleOpen} css={styles.input} className={classNames}>
              {!open && (
                <span css={styles.valueLabel} id="SearchableDropdownLabel">
                  {valueLabel || options.find((current) => current.value === selectedValue)?.label || selectedValue}
                </span>
              )}
              {open && (
                <input
                  ref={focusRef}
                  defaultValue={search}
                  id="SearchableDropdownSearch"
                  placeholder={allowManualEntry ? '' : 'Search...'}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => setSearch(e.target.value)}
                  css={styles.search}
                  onKeyDown={handleInputKey}
                />
              )}
              {options.length > 0 && <Icon icon={'chevron'} size="16" css={styles.chevron(open)} />}
            </div>
          }
        >
          <Card css={styles.modal({ width: ref?.current?.offsetWidth })} className={classNames}>
            <div css={styles.listContainer({ width: ref?.current?.offsetWidth })} id="SearchableDropdownList">
              <AutoSizer disableHeight>
                {({ width }) => (
                  <VirtualizedList
                    ref="List"
                    css={styles.list}
                    height={205}
                    width={width}
                    overscanRowCount={overscanRowCount}
                    rowCount={filteredList?.length}
                    rowHeight={41}
                    rowRenderer={({ index, key, style }) => {
                      const current = filteredList?.[index] || {}

                      return (
                        <div style={style} key={key}>
                          <div css={styles.item({ maxWidth: ref?.current?.offsetWidth })} onClick={() => handleSelect(current.value)}>
                            <ItemLabel text={current.label} parentWidth={ref?.current?.offsetWidth} />
                            {current.value !== selectedValue && <div css={styles.circle} className={'SearchableSelectorDot'} />}
                            {current.value === selectedValue && <Glyph glyph="check" size={18} className={'SearchableSelectorCheck'} />}
                          </div>
                        </div>
                      )
                    }}
                    onRowsRendered={({ overscanStartIndex }) => {
                      const isLastItemRendered =
                        overscanStartIndex >= filteredList?.length - (overscanRowCount + 2) && hasMoreOptions && !loading
                      const isFilteredAndHavesfewItems =
                        (!!search || !!additionalFilters.length) && filteredList.length <= 5 && hasMoreOptions

                      if (isLastItemRendered || isFilteredAndHavesfewItems) {
                        getMoreOptions?.()
                      }
                    }}
                  />
                )}
              </AutoSizer>
              {loading && (
                <div css={styles.listSpinnerContainer}>
                  <CircleSpinner size={30} color={COLORS.purplePale60} />
                </div>
              )}
            </div>
          </Card>
        </SummonModal>
      </div>
    </div>
  )
}

const styles: any = {
  root: {
    cursor: 'pointer',
    display: 'grid',
    gap: '0.5rem',

    '&.is-disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },

  list: {
    width: '100%',
    scrollbarWidth: 'none',
  },

  modal: ({ width }) => ({
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: `
      0px 1px 1px ${transparentize(0.5, COLORS.purplePale40)},
      0px 2px 2px ${transparentize(0.6, COLORS.purplePale40)}
    `,
    height: 205,
    boxSizing: 'border-box',
    width: width || 300,
    '&.is-list-hidden': {
      border: 'none',
      height: '0',
      boxShadow: 'none',
    },
  }),

  listContainer: ({ width }) => ({
    height: 205,
    width: width || 493,
  }),

  label: {
    color: COLORS.purple,
    fontSize: 12,
    letterSpacing: '-0.6px',
    textTransform: 'uppercase',
  },

  input: {
    alignItems: 'center',
    border: `1px solid ${COLORS.purplePale40}`,
    borderRadius: 4,
    boxShadow: `
      0px 1px 1px ${transparentize(0.5, COLORS.purplePale40)},
      0px 2px 2px ${transparentize(0.6, COLORS.purplePale40)}
    `,
    color: COLORS.purple,
    display: 'flex',
    fontFamily: 'Amiko',
    fontSize: '18px',
    justifyContent: 'space-between',
    lineHeight: '24px',
    outlineColor: COLORS.blue40,
    padding: '0.5rem 10px 0.5rem 1rem',

    '&:focus': {},

    '&.is-disabled': {},
  },

  valueLabel: {
    minHeight: '26px',
  },

  search: {
    alignItems: 'center',
    border: 'none',
    color: '#5B2C84',
    display: 'flex',
    fontFamily: 'Amiko',
    fontSize: '18px',
    justifyContent: 'space-between',
    lineHeight: '24,01px',
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },

  chevron: (open) => {
    return {
      transition: 'transform .3s ease',
      transform: `rotate(${open ? '90' : '0'}deg)`,
    }
  },

  item: ({ maxWidth }) => ({
    alignItems: 'center',
    backgroundColor: NEW_COLORS.purplePaleLightest,
    borderBottom: `1px solid ${COLORS.orchidPale40}`,
    color: COLORS.purple,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 14,
    fontWeight: 600,
    height: '40px',
    justifyContent: 'space-between',
    lineHeight: '19px',
    paddingLeft: 20,
    paddingRight: '10px',
    textTransform: 'uppercase',
    '& > span': {
      maxWidth: maxWidth ? maxWidth - 80 : 'initial',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&:hover': {
      backgroundColor: darken(0.1, NEW_COLORS.purplePaleLightest),
    },
    '&:hover .SearchableSelectorDot': {
      display: 'block',
    },
    '&:hover .SearchableSelectorCheck': {
      path: {
        fill: `${NEW_COLORS.purplePale} !important`,
      },
    },
  }),

  circle: {
    backgroundColor: NEW_COLORS.purplePale,
    borderRadius: '50%',
    display: 'none',
    height: '6px',
    margin: '6px',
    width: '6px',
  },

  listSpinnerContainer: {
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}

export default withFormContext(SearchableDropdown)
