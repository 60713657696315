import React from 'react'

import { TOOLTIP_MAPPING_RULES } from '../../../theme/definitions/tooltips'

import Button from '../../Button'
import Form from '../../Form'
import InputText from '../../InputText'
import InputSelect from '../../InputSelect'
import Header from '../../Header'
import InfoTooltip from '../../InfoTooltip'
import Tooltip from '../../Tooltip'
import InputSearchableSelect from '../../InputSearchableSelect'

const DEFAULT_MODEL = {
  rule: {
    key: 'replace',
    options: {
      replaceWith: {
        value: '',
      },
    },
  },
}

const MappingRuleReplace = ({ model, setModel, setView, close, documentSchemaProperty, create, executeDelete, enums = [] }) => {
  React.useEffect(() => {
    if (!model || model.rule?.key !== 'replace') {
      setModel({ ...DEFAULT_MODEL, rule: { targetAttributeName: documentSchemaProperty, key: 'replace' } }, true)
    }
  }, [])

  const onSubmit = (data) => {
    setModel({
      ...DEFAULT_MODEL,
      ...model,
      rule: {
        ...DEFAULT_MODEL.rule,
        targetAttributeName: documentSchemaProperty,
        options: {
          searchFor: data.rule.options.searchFor,
          ...(data.rule.options.searchFlags !== '' && { searchFlags: data.rule.options.searchFlags }),
          replaceWith: { value: data.rule.options.replaceWith.value },
        },
      },
    })

    close()
  }

  const verb = create ? 'Create a new' : 'Edit'

  return (
    <div css={styles.root}>
      <Header
        title={`${verb} Replace rule`}
        icon="rules"
        before={
          <>
            {create && (
              <Button icon="chevronRight" iconSize={17} onClick={() => setView('menu')} css={[styles.button, { marginRight: '0.5rem' }]} />
            )}
          </>
        }
        aside={
          <>
            {executeDelete && (
              <Tooltip message="Delete Rule" placement="top">
                <Button glyph="times" glyphSize={16} onClick={() => executeDelete()} css={[styles.button, { marginRight: '0.6rem' }]} />
              </Tooltip>
            )}
            <InfoTooltip message={TOOLTIP_MAPPING_RULES.replace} placement="right" />
          </>
        }
      />

      <Form onSubmit={onSubmit} defaultValues={model} css={styles.form}>
        <InputText model="rule.options.searchFor" label="Insert a string to find" required />
        <InputSearchableSelect
          label="Insert a string to replace"
          model="rule.options.replaceWith.value"
          options={enums.map((current) => ({ label: current, value: current }))}
          allowManualEntry
        />

        <InputSelect model="rule.options.searchFlags" label="Regular Expression Flag">
          <option value="g">Global</option>
          <option value="i">Ignore Case</option>
          <option value="m">Multi Line</option>
          <option value="s">Single Line</option>
          <option value="u">Unicode</option>
          <option value="e">Exact</option>
        </InputSelect>

        <Button label="Apply Rule" loading={false} />
      </Form>
    </div>
  )
}

const styles = {
  root: {
    width: 'auto',
    height: 'auto',
  },
  form: {
    padding: '1rem',
  },
  button: {
    backgroundColor: 'transparent',
    border: 0,
    padding: 0,
  },
}

export default MappingRuleReplace
