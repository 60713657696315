import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { lighten } from 'polished'
import { ToastContainer } from 'react-toastify'
import jwt_decode from 'jwt-decode'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import ProductFruits from 'react-product-fruits'
import { RouteComponentProps } from 'react-router'

import 'react-toastify/dist/ReactToastify.css'

import { store } from '../setup/store'
import { setUser } from '../actions/common'
import { connect } from '../modules/websocket'

import { initialize, setup } from '../modules/init'
import { COLORS } from '../theme'

import ApplicationWrapper from '../components/layout/ApplicationWrapper'
import MainNavigationVertical from '../components/MainNavigationVertical'
import Footer from '../components/Footer'
import SourceDocumentsUploader from '../components/SourceDocumentsUploader'

import ConnectionsPage from './connections/connections'
import ConnectionPage from './connections/connection'

import ChannelsPage from './channels/channels'
import ChannelPage from './channels/channel'

import ReleasesPage from './releases/releases'
import ReleasePage from './releases/release'

import StagedReleasesPage from './staged-releases/staged-releases'
import StagedReleasePage from './staged-releases/staged-release'

import WebhooksPage from './webhooks/webhooks'
import WebhookPage from './webhooks/webhook'

import SourceDocumentSchemaPage from './source-document-schemas/source-document-schema'
import SourceDocumentSchemasPage from './source-document-schemas/source-document-schemas'
import SourceDocumentSchemasEditMappingRulesPage from './source-document-schemas/mapping-rules/edit/edit-mapping-rules'

import DistributionPackagesPage from './distribution-packages/distribution-packages'
import DistributionPackagePage from './distribution-packages/distribution-package'
import DistributionPackageEditMappingRulesPage from './distribution-packages/mapping-rules/edit/edit-mapping-rules'

import SourceDocumentGroupsPage from './source-document-groups/source-document-groups'
import SourceDocumentGroupPage from './source-document-groups/source-document-group'

import NotificationToast from '../components/NotificationToast'
import { useSelector } from 'react-redux'
import { RootState } from '../setup/storeConfig'

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 8000,
  transition: transitions.FADE,
}

type Props = {
  user: {
    client_id: string
    [key: string]: any
  }
} & RouteComponentProps

const PFWraper = () => {
  const client_id = useSelector((state: RootState) => state?.common?.global?.user?.client_id)
  const [userInfo, setUserInfo] = React.useState({
    username: client_id,
  })

  React.useEffect(() => {
    setUserInfo({
      username: client_id,
    })
  }, [client_id])

  return <ProductFruits projectCode={process.env.REACT_APP_PRODUCT_FRUITS} language="en" {...userInfo} />
}

class VenzeeStudioEntry extends React.Component<Props> {
  timeout = null
  state = {
    $mounted: false,
  }

  componentDidMount = async () => {
    const token = localStorage.getItem('vz.at')

    if (!token) {
      this.props.history.push('/login', {
        type: 'no_token',
        message: 'Please enter your credentials to login to your account',
      })
      return
    }

    await initialize()
    await setup()

    store.dispatch(setUser(jwt_decode(token)))
    connect(token)

    this.setState({ $mounted: true })
  }

  componentWillUnmount = () => {
    if (this.timeout) clearTimeout(this.timeout)
  }

  render() {
    return (
      <>
        {this.state.$mounted && (
          <>
            <Helmet>
              <title>Venzee Studio</title>
            </Helmet>

            <AlertProvider template={NotificationToast} {...options}>
              <ApplicationWrapper>
                <PFWraper />
                <MainNavigationVertical />
                <div navigationDummyBecauseFixedNavigation></div>

                <div css={styles.content}>
                  <Switch>
                    <Route exact path="/connections(|/new)" component={ConnectionsPage} />
                    <Route path="/connections/:id" component={ConnectionPage} />

                    <Route exact path="/distribution-packages(|/new)" component={DistributionPackagesPage} />
                    <Route exact path="/distribution-packages/:id/mapping-rules/edit" component={DistributionPackageEditMappingRulesPage} />
                    <Route path="/distribution-packages/:id" component={DistributionPackagePage} />

                    <Route exact path="/channels" component={ChannelsPage} />
                    <Route path="/channels/:id" component={ChannelPage} />

                    <Route exact path="/releases(|/new)" component={ReleasesPage} />
                    <Route path="/releases/:id" component={ReleasePage} />

                    <Route exact path="/staged-releases(|/new)" component={StagedReleasesPage} />
                    <Route path="/staged-releases/:id" component={StagedReleasePage} />

                    <Route exact path="/webhooks(|/new)" component={WebhooksPage} />
                    <Route path="/webhooks/:id" component={WebhookPage} />

                    <Route exact path="/source-document-schemas(|/new)" component={SourceDocumentSchemasPage} />
                    <Route
                      exact
                      path="/source-document-schemas/:id/mapping-rules/edit"
                      component={SourceDocumentSchemasEditMappingRulesPage}
                    />
                    <Route path="/source-document-schemas/:id" component={SourceDocumentSchemaPage} />
                    <Route exact path="/source-document-groups(|/new)" component={SourceDocumentGroupsPage} />
                    <Route path="/source-document-groups/:id" component={SourceDocumentGroupPage} />

                    <Redirect from="/" to="/distribution-packages" />
                  </Switch>

                  <Footer />
                </div>

                <div>
                  <div id="portal-default"></div>
                  <div id="portal-tooltip"></div>
                  <div id="portal-modals"></div>
                  <div id="portal-uploader"></div>
                  <div id="portal-overlay"></div>
                  <div id="portal-dialog"></div>
                </div>

                <SourceDocumentsUploader />
                <ToastContainer />
              </ApplicationWrapper>
            </AlertProvider>
          </>
        )}
      </>
    )
  }
}

const styles = {
  content: {
    // backgroundColor: COLORS.navbar,
    minHeight: '100vh',
    // paddingRight: '2rem',
  },
  contentHeaderBackground: {
    position: 'absolute',
    height: 200,
    backgroundColor: lighten(0.005, COLORS.purplePaleLight),
    borderBottom: `1px solid ${COLORS.purplePale40}`,
  },
}

export default VenzeeStudioEntry
