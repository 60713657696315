import React from 'react'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import toArray from 'lodash/toArray'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'

import Page from '../../components/layout/page/Page'
import CardSection from '../../components/CardSection'
import ListItemGroup from '../../components/ListItemGroup'

import ChannelListItem from '../../components/list/ChannelListItem'

import useAPI, { getActionType } from '../../hooks/useAPI'

const ChannelsPage: React.FC = () => {
  const channels = useSelector(getQuerySelector({ type: getActionType('CHANNELS') }))
  const channelsList = toArray(channels?.data)

  const channelsGroupedByCategory = groupBy(channelsList, 'category')

  useAPI('CHANNELS', '/channels', false, 'GET', false, 'key')

  const isLoading = channels?.pending > 0

  return (
    <Page feature="channel" title="Channels" actions={<></>}>
      <CardSection loading={isLoading}>
        {channels &&
          Object.keys(channelsGroupedByCategory).map((category) => {
            const categoryChannels = channelsGroupedByCategory[category]
            return (
              <ListItemGroup label={category} icon="channels">
                {sortBy(categoryChannels, 'key').map((channel) => (
                  <ChannelListItem key={channel.key} channel={channel} to={`/channels/${channel.key}`} hideIcon hideChevron />
                ))}
              </ListItemGroup>
            )
          })}
      </CardSection>
    </Page>
  )
}

export default ChannelsPage
