import React from 'react'
import isNaN from 'lodash/isNaN'

import { TOOLTIP_MAPPING_RULES } from '../../../theme/definitions/tooltips'

import Button from '../../Button'
import Form from '../../Form'
import InputText from '../../InputText'
import InputSelect from '../../InputSelect'
import Header from '../../Header'
import InfoTooltip from '../../InfoTooltip'
import Tooltip from '../../Tooltip'

const DEFAULT_MODEL = {
  rule: {
    key: 'convert',
    options: {},
  },
}

const MappingRuleConvert = ({ model, setModel, setView, close, documentSchemaProperty, create, executeDelete }) => {
  const [showDecimals, setShowDecimals] = React.useState(false)

  React.useEffect(() => {
    if (!model || model.rule?.key !== 'convert') {
      setModel({ ...DEFAULT_MODEL, rule: { targetAttributeName: documentSchemaProperty, key: 'convert' } }, true)
    }
  }, [])

  const onSubmit = (data) => {
    const value = parseInt(data?.rule?.options?.decimals)

    setModel({
      ...DEFAULT_MODEL,
      rule: {
        ...DEFAULT_MODEL.rule,
        targetAttributeName: documentSchemaProperty,
        options: {
          type: data.rule.options.type,
          ...(data.rule.options.type === 'number' && !isNaN(value) && { decimals: value }),
        },
      },
    })

    close()
  }

  const handleFormChange = (form) => {
    const currentType = form?.rule?.options?.type
    setShowDecimals(currentType === 'number')
  }

  const verb = create ? 'Create a new' : 'Edit'

  return (
    <div css={styles.root}>
      <Header
        title={`${verb} Convert rule`}
        icon="rules"
        before={
          <>
            {create && (
              <Button icon="chevronRight" iconSize={17} onClick={() => setView('menu')} css={[styles.button, { marginRight: '0.5rem' }]} />
            )}
          </>
        }
        aside={
          <>
            {executeDelete && (
              <Tooltip message="Delete Rule" placement="top">
                <Button glyph="times" glyphSize={16} onClick={() => executeDelete()} css={[styles.button, { marginRight: '0.6rem' }]} />
              </Tooltip>
            )}
            <InfoTooltip message={TOOLTIP_MAPPING_RULES.convert} placement="right" />
          </>
        }
      />

      <Form onSubmit={onSubmit} defaultValues={model} css={styles.form} onChange={handleFormChange}>
        <InputSelect model="rule.options.type" label="Select a type to convert data into">
          <option value="string">String</option>
          <option value="number">Number</option>
          <option value="boolean">Boolean</option>
        </InputSelect>

        {showDecimals && <InputText model="rule.options.decimals" label="Decimals" type="number" max="50" required />}

        <Button label="Apply Rule" loading={false} />
      </Form>
    </div>
  )
}

const styles = {
  root: {
    width: 'auto',
    height: 'auto',
  },
  form: {
    padding: '1rem',
  },
  button: {
    backgroundColor: 'transparent',
    border: 0,
    padding: 0,
  },
}

export default MappingRuleConvert
