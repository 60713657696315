import React from 'react'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'

import { getActionType } from '../../hooks/useAPI'
import { FEATURES } from '../../theme'

import PageHeader from '../layout/page/PageHeader'
import Log from '../Log'
import Overlay from '../Overlay'
import InfoLink from '../InfoLink'

const UploadSourceDocumentsErrorsOverlay = ({ ...rest }) => {
  const errors = useSelector((state) => state.common?.uploader?.errors)
  const feature = useSelector((state) => state.common?.uploader?.feature)
  const distributionPackage = useSelector(getQuerySelector({ type: getActionType('DISTRIBUTION_PACKAGE') }))
  const sourceDocumentGroup = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUP') }))

  return (
    <Overlay position="center" maxWidth="80" fullheight closeOnBackdrop showBackdrop {...rest}>
      <PageHeader
        title={`Errors Importing Source Documents`}
        feature="source_documents"
        hideBackButton
        css={{ top: 0, flexShrink: 0 }}
        fullWidth
      >
        {feature === 'distribution_package' && (
          <InfoLink
            icon="distributionPackages"
            to=""
            label={distributionPackage?.data?.name}
            color={FEATURES.distribution_packages.color}
            tooltip="Distribution Package"
          />
        )}
        {feature === 'source_document_group' && (
          <InfoLink
            icon="sourceDocumentsGroup"
            to="/source-document-groups"
            label={sourceDocumentGroup?.data?.name}
            color={FEATURES.source_document_group.color}
            tooltip="Source Document Group"
          />
        )}
      </PageHeader>
      <div css={{ padding: '2rem', overflowY: 'auto', display: 'grid', gridGap: '2rem' }}>
        {errors.map((error, index) => (
          <Log
            key={`Upload_SOurce_document_upload_error_${index}`}
            label={error.message}
            src={{ ...error.config, data: JSON.parse(error.config.data) }}
            collapsed
          />
        ))}
      </div>
    </Overlay>
  )
}

export default UploadSourceDocumentsErrorsOverlay
