import React from 'react'
import { darken, transparentize, tint } from 'polished'
import clsx from 'clsx'

import { nicePercentage } from '../utils/functions'
import { COLORS, SHADOW } from '../theme'

const ProgressBar = ({ color = 'blue', height = 3, width = '100%', showPercentage, percent = 0, className }) => {
  // change colors based on status
  let setColor = COLORS[color]
  let percentage = parseFloat(percent).toFixed(2)
  if (percentage > 100) setColor = COLORS.red
  else if (percentage <= 0) setColor = COLORS.gray40

  // for styling
  if (percentage >= 100) percentage = 100
  else if (percentage <= 0) percentage = 0

  const classes = clsx({ [className]: className, 'with-percentage': showPercentage })

  return (
    <div css={styles} className={classes}>
      <div css={[styles.bar, { width, height }]}>
        <div
          css={[
            styles.fill,
            {
              width: `${Math.floor(percentage)}%`,
              background: tint(0.25, setColor),
              boxShadow: SHADOW(1, transparentize(0.8, setColor)),
            },
          ]}
        />
      </div>
      {showPercentage && <div css={[styles.percentage, { color: darken(0.05, setColor) }]}>{nicePercentage(percentage)}%</div>}
    </div>
  )
}

const styles = {
  '&.with-percentage': {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },

  bar: {
    background: COLORS.gray40,
    borderRadius: 100,
  },

  fill: {
    borderRadius: 100,
    height: '100%',
    transition: 'width 400ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  percentage: {
    marginLeft: 8,
    fontSize: '0.9rem',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
}

export default ProgressBar
