import React from 'react'
import produce from 'immer'
import clsx from 'clsx'
import toString from 'lodash/toString'
import set from 'lodash/set'

import { COLORS } from '../../../theme'
import { TOOLTIP_MAPPING_RULES } from '../../../theme/definitions/tooltips'

import Button from '../../Button'
import List from '../../List'
import InfoTooltip from '../..//InfoTooltip'
import Ellipsis from '../../typography/Ellipsis'
import Header from '../../Header'
import Icon from '../../Icon'
import Tooltip from '../../Tooltip'
import { DocumentType } from '../../../declarations/SourceDocument'

type Props = {
  model: any
  setModel: Function
  setView: Function
  previewDocument: DocumentType
  documentSchemaProperty: string
  sourceDocumentAttributes: any
  prevPreviewDocument: Function
  nextPreviewDocument: Function
  hasPrevPreviewDocument: boolean
  hasNextPreviewDocument: boolean
  create?: boolean
  close: Function
  executeDelete?: Function
  schemaAttributeType?: string
}

type MappingRuleCopyItemProps = {
  onSelect: Function
  attribute?: string
  schemaAttributeType?: string
  currentPreview?: string
  currentPreviewType?: string
  selected?: boolean
  className?: string
}

const DEFAULT_MODEL = {
  rule: {
    key: 'copy',
    options: {
      source: {},
    },
  },
}

const isTyped = (typeA, typeB) => {
  if (typeA === typeB) return true
  if (typeA === 'integer' && typeB === 'number') return true
  return false
}

const MappingRuleCopyItem: React.FC<MappingRuleCopyItemProps> = ({
  onSelect,
  attribute,
  schemaAttributeType,
  currentPreview,
  currentPreviewType,
  selected,
  className = '',
}) => {
  const typeChecked = isTyped(schemaAttributeType, currentPreviewType)

  const classNames = clsx({
    [className]: className,
    'is-selected': selected,
    'is-type': typeChecked,
  })

  return (
    <div css={styles.attribute} onClick={() => onSelect(attribute)} className={classNames}>
      <div css={styles.attributeHeader}>
        {selected && <Icon icon="check" size={13} css={{ marginRight: '0.3rem' }} />}
        {typeChecked && <Icon icon={selected ? 'typeLight' : 'type'} size={12} css={{ marginRight: '0.3rem' }} />}
        <div>{attribute}</div>
      </div>
      {currentPreview && <Ellipsis css={styles.attributePreview}>{currentPreview}</Ellipsis>}
    </div>
  )
}

const MappingRuleCopy: React.FC<Props> = ({
  model,
  setModel,
  setView,
  close,
  documentSchemaProperty,
  sourceDocumentAttributes,
  previewDocument,
  prevPreviewDocument,
  hasPrevPreviewDocument,
  nextPreviewDocument,
  hasNextPreviewDocument,
  schemaAttributeType,
  executeDelete,
  create,
}) => {
  const newCopyRule = { ...DEFAULT_MODEL, rule: { targetAttributeName: documentSchemaProperty, key: 'copy' } }

  const onSelect = (attribute) => {
    const newModel = produce(model || newCopyRule, (draft) => set(draft, 'rule.options.source.attributeName', attribute))

    setModel(newModel)
    close()
  }

  const verb = create ? 'Create a new' : 'Edit'

  return (
    <div css={styles.root}>
      <Header
        title={`${verb} Copy rule`}
        icon="rules"
        before={
          <>
            {create && (
              <Button icon="chevronRight" iconSize={17} onClick={() => setView('menu')} css={[styles.button, { marginRight: '0.5rem' }]} />
            )}
          </>
        }
        aside={
          <>
            {executeDelete && (
              <Tooltip message="Delete Rule" placement="top">
                <Button glyph="times" glyphSize={16} onClick={() => executeDelete()} css={[styles.button, { marginRight: '0.6rem' }]} />
              </Tooltip>
            )}
            <Button
              icon="chevronRight"
              iconSize={16}
              onClick={() => prevPreviewDocument()}
              disabled={!hasPrevPreviewDocument}
              css={styles.button}
            />
            <Button
              icon="chevron"
              iconSize={16}
              onClick={() => nextPreviewDocument()}
              disabled={!hasNextPreviewDocument}
              css={[styles.button, { marginRight: '0.5rem' }]}
            />
            <InfoTooltip message={TOOLTIP_MAPPING_RULES.copy} placement="right" />
          </>
        }
      />

      <List css={styles.content}>
        {sourceDocumentAttributes?.map((attribute) => {
          const currentPreview = toString(previewDocument?.[attribute])
          const currentPreviewtype = typeof previewDocument?.[attribute]

          return (
            <MappingRuleCopyItem
              key={attribute}
              attribute={attribute}
              currentPreview={currentPreview}
              currentPreviewType={currentPreviewtype}
              onSelect={onSelect}
              selected={model?.rule?.options?.source?.attributeName === attribute}
              schemaAttributeType={schemaAttributeType}
            />
          )
        })}
      </List>
    </div>
  )
}

const styles: any = {
  root: {},

  title: {
    fontSize: 15,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '-0.5px',
    color: COLORS.purpleTitle,
  },

  button: {
    backgroundColor: 'transparent',
    border: 0,
    padding: 0,
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${COLORS.orchidPaleLight}`,
    borderBottom: `1px solid ${COLORS.orchidPaleLight}`,
    padding: '0 .75rem',
    height: 40,
  },

  headerToolbar: {
    display: 'flex',
    alignItems: 'center',
  },

  headerTitle: {
    display: 'flex',
    alignItems: 'center',
  },

  content: {
    maxHeight: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0.5rem',
    gridGap: '0.25rem',
    color: COLORS.orchidPale60,
    fontWeight: 'bold',
    fontSize: 13,
    width: '100%',
    paddingBottom: '0.5rem',
  },

  attribute: {
    display: 'flex',
    alignItems: 'center',
    width: 578,
    padding: '0.4rem 0.3rem',

    cursor: 'pointer',
    lineHeight: '100%',

    '&.is-type': {
      color: COLORS.orchidPale,
    },

    '&:hover, &.is-selected': {
      backgroundColor: COLORS.blue20,
      color: COLORS.white,
      borderRadius: 4,
      '& > *': {
        fontWeight: 'bold!important',
      },
    },

    '&.is-selected': {
      backgroundColor: COLORS.blue40,
    },
  },

  attributeHeader: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.5rem',
  },

  attributePreview: {
    flex: 1,
    fontFamily: 'Courier New',
    fontWeight: 'normal',
  },

  back: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '2rem',
    cursor: 'pointer',
  },
}

export default MappingRuleCopy
