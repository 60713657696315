import React from 'react'

import ListItem from '../ListItem'
import SourceDocumentGroupSubtitle from '../layout/page/subtitle/SourceDocumentGroupSubtitle'

type Props = {
  sourceDocumentGroup: any
}

const SourceDocumentGroupListItem: React.FC<Props> = ({ sourceDocumentGroup, ...rest }) => {
  const { lastUpdated, documentKey, id, tag, name } = sourceDocumentGroup

  return (
    <ListItem label={name} id={id} tag={tag} icon="sourceDocumentsGroup" {...rest}>
      <SourceDocumentGroupSubtitle allowCopy={false} name={name} documentKey={documentKey} lastUpdated={lastUpdated} />
    </ListItem>
  )
}

export default SourceDocumentGroupListItem
