import React from 'react'

const Ellipsis = ({ children, className = '' }) => (
  <span css={styles} className={className}>
    {children}
  </span>
)

const styles: any = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}

export default Ellipsis
