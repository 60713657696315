import React from 'react'
import pluralize from 'pluralize'

import Icon from './Icon'

import { getFeature } from '../theme'
import { Feature } from '../declarations'

type Props = {
  feature?: Feature
}

const Status404: React.FC<Props> = ({ feature }) => {
  const foundFeature = getFeature(feature)

  const entity = pluralize.singular(foundFeature?.title)

  return (
    <div css={styles.root}>
      <div css={styles.emptyMessage}>
        <Icon icon="404" size="60%" css={{ marginBottom: '1rem', minWidth: 500, minHeight: 500, maxWidth: 650, maxHeight: 650 }} />
        <div css={styles.header}>We tried really hard, but couldn't find this {entity}.</div>
      </div>
    </div>
  )
}

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: '12fr 5fr',
    gridGap: '1rem',
  },
  header: {
    fontSize: 27,
    letterSpacing: '-2px',
    // fontWeight: 'bold',
    // textTransform: 'uppercase',
    maxWidth: 500,
  },
  emptyMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // maxWidth: 300,
    textAlign: 'center',
  },
}

export default Status404
