import React from 'react'
import clsx from 'clsx'
import { transparentize } from 'polished'
import isDefined from '@codewell/is-defined'

import Status404 from '../../Status404'

import { COLORS } from '../../../theme'
import { Feature } from '../../../declarations'

import PageHeader from './PageHeader'

type Props = {
  title: string
  feature: Feature
  subtitle?: string | React.ReactElement
  actions?: any
  sticky?: boolean
  loading?: boolean
  working?: boolean
  hideBackButton?: boolean
  status?: any
  tag?: string
  notFound?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const Page: React.FC<Props> = ({
  children,
  sticky,
  subtitle,
  actions,
  feature,
  title,
  loading,
  working,
  tag,
  status,
  hideBackButton,
  avoidMargins,
  notFound,
  className,
}) => {
  const contentClassNames = clsx({
    [className]: className,
    'with-actions': isDefined(actions),
  })

  const avoidMarginsClassName = clsx({ 'avoid-margins': avoidMargins })
  return (
    <div css={styles.root} className={avoidMarginsClassName}>
      {!notFound && (
        <>
          <PageHeader
            title={title}
            feature={feature}
            children={subtitle}
            loading={loading}
            working={working}
            tag={tag}
            hideBackButton={hideBackButton}
            status={status}
          />

          <div css={styles.containerRoot} className={contentClassNames}>
            <div css={{ width: '100%' }}>
              <div css={styles.pad}>{children}</div>
            </div>

            {actions && (
              <div css={styles.actions} className={avoidMarginsClassName}>
                {actions}
              </div>
            )}
          </div>
        </>
      )}

      {notFound && <Status404 feature={feature} />}
    </div>
  )
}

const styles: any = {
  root: {
    marginTop: '3rem',
    paddingRight: '2rem',
    minHeight: 'calc(100vh - 10rem)',
    '&.avoid-margins': {
      paddingRight: 0,
    },

    '@media (max-width: 1550px)': {
      paddingRight: 0,
    },
  },

  actions: {
    display: 'grid',
    gridGap: '1rem',
    height: 'fit-content',

    position: 'sticky',
    top: '1rem',

    '&.avoid-margins': {
      top: 0,
    },
  },

  containerRoot: {
    marginBottom: '3rem',
    overflowX: 'auto',
    width: 'calc(100vw - 390px)',

    '&.with-actions': {
      display: 'grid',
      gridTemplateColumns: '12fr 5fr',
      gridGap: '1rem',
    },
  },

  pad: {
    // padding: '0 1rem',
  },

  container: {
    marginBottom: '2rem',

    display: 'flex',
    alignItems: 'center',
  },

  header: {
    position: 'sticky',
    top: 50,
    height: 70,
    backgroundColor: COLORS.navbar,
    borderBottom: `1px solid ${COLORS.orchidPale20}`,
    boxShadow: `0px 4px 5px ${transparentize(0.56, COLORS.orchidPaleLight)}`,
    zIndex: 3,
    textTransform: 'uppercase',
    letterSpacing: '-1px',
  },
}

export default Page
