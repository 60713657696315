import React from 'react'

import Icon from './Icon'

import { COLORS } from '../theme'

const EditableFieldGroupHeader = ({ title, feature }) => {
  return (
    <div css={styles.root}>
      {feature && <Icon feature={feature} css={{ marginRight: '1rem' }} />}
      <div css={styles.title}>{title}</div>
    </div>
  )
}

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '20px 30px',
    backgroundColor: COLORS.white,
    borderTop: `1px solid ${COLORS.purplePale60}`,
  },

  title: {
    fontSize: 17,
    color: COLORS.purpleTitle,
    textTransform: 'uppercase',
    letterSpacing: '-0.7px',
  },
}

export default EditableFieldGroupHeader
