import React from 'react'

const CheckGlyph = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 24" {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0484 6.35183C20.2734 6.57687 20.3997 6.88203 20.3997 7.20023C20.3997 7.51843 20.2734 7.8236 20.0484 8.04863L10.4484 17.6486C10.2234 17.8736 9.9182 18 9.6 18C9.2818 18 8.97663 17.8736 8.7516 17.6486L3.9516 12.8486C3.73301 12.6223 3.61206 12.3192 3.61479 12.0046C3.61753 11.6899 3.74373 11.3889 3.96622 11.1665C4.18871 10.944 4.48968 10.8178 4.80432 10.815C5.11895 10.8123 5.42208 10.9332 5.6484 11.1518L9.6 15.1034L18.3516 6.35183C18.5766 6.12687 18.8818 6.00049 19.2 6.00049C19.5182 6.00049 19.8234 6.12687 20.0484 6.35183Z"
    />
  </svg>
)

export default CheckGlyph
