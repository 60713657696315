import React from 'react'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getMutationSelector, resetRequests } from '@redux-requests/core'
import isDefined from '@codewell/is-defined'

import Overlay from '../../Overlay'
import Portal from '../../Portal'
import PageHeader from '../../layout/page/PageHeader'
import Form from '../../Form'
import InputGroup from '../../InputGroup'
import InputText from '../../InputText'

import DialogActions from '../../dialog/DialogActions'
import DialogAction from '../../dialog/DialogAction'

import { useAPIMutation, getActionType } from '../../../hooks/useAPI'

import InputSearchableSelect from '../../InputSearchableSelect'
import InputSelect from '../../InputSelect'

const NewSourceDocumentSchemaRule: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch()

  const [rules, setRules] = React.useState<object[] | null>()
  const [selectedKey, setSelectedKey] = React.useState('')
  const [convertType, setConvertType] = React.useState('')
  const [mutationHasStarted, setMutationHasStarted] = React.useState(false)

  const mutation = useSelector(getMutationSelector({ type: getActionType('SOURCE_DOCUMENT_SCHEMA_RULE', 'POST') }))

  const validation = React.useMemo(
    () =>
      Yup.object().shape({
        targetAttributeName: Yup.string().required(),
        key: Yup.string().required(),
        options: Yup.object().required(),
      }),
    [],
  )

  const isLoading = mutation?.pending > 0

  useAPIMutation('SOURCE_DOCUMENT_SCHEMA_RULE', { id, rules }, !isDefined(rules) || mutationHasStarted, 'POST')

  React.useEffect(() => {
    if (mutation.pending === 0 && mutationHasStarted) {
      setMutationHasStarted(false)
      setRules(null)
      history.push(`/source-document-schemas/${id}/general`)
    }
  }, [mutation.pending])

  React.useEffect(
    () => () => {
      dispatch(resetRequests([getActionType('DOCUMENT_RULES')]))
    },
    [],
  )

  const renderForm = () => {
    let result
    switch (selectedKey) {
      case 'replace':
        result = (
          <>
            <InputText model="options.searchFor" label="Insert a string to find" />
            <InputText model="options.replaceWith.value" label="Insert a string to replace" />
          </>
        )
        break
      case 'convert':
        result = (
          <>
            <InputSelect model="options.type" label="Select a type to convert data into" hideEmptyOption>
              <option selected disabled>
                Please select a conversion type
              </option>
              <option value="number">Number</option>
              <option value="string">String</option>
              <option value="boolean">Boolean</option>
            </InputSelect>
            {convertType === 'number' && <InputText model="options.decimals" label="Decimals" />}
          </>
        )
        break
      case 'literal':
        result = (
          <>
            <InputText model="options.value" label="Insert a literal value" />
          </>
        )
        break

      case 'copy':
        result = (
          <>
            <InputText model="options.source.attributeName" label="Insert an attribute name" />
          </>
        )
        break
      default:
        break
    }
    return result
  }

  return (
    <Portal type="overlay">
      <Form
        onSubmit={(data) => {
          setRules([data])
          setMutationHasStarted(true)
        }}
        resolver={validation}
        disabled={isLoading}
        onChange={({ key, options }) => {
          setSelectedKey(key)
          if (key === 'convert') {
            setConvertType(options?.type)
          } else {
            setConvertType('')
          }
        }}
      >
        <Overlay
          onClose={() => history.push(`/source-document-schemas/${id}/general`)}
          css={styles.root}
          position="right"
          fullheight
          showBackdrop
          closeOnBackdrop
          disabled={isLoading}
          isLoading={isLoading}
        >
          <PageHeader title="New Mapping Rule" feature="source_document_schemas" hideBackButton css={{ top: 0, flexShrink: 0 }} small />

          <div css={styles.content}>
            <InputGroup>
              <InputText model="targetAttributeName" label="Target Attribute" />
              <InputSearchableSelect
                label="Type"
                model="key"
                options={[
                  { label: 'Copy', value: 'copy' },
                  { label: 'Literal', value: 'literal' },
                  { label: 'Replace', value: 'replace' },
                  { label: 'Convert', value: 'convert' },
                ]}
                defaultValue={selectedKey}
              />
              {renderForm()}
            </InputGroup>
          </div>

          <DialogActions>
            <DialogAction label="Create" />
            <DialogAction label="Cancel" to={`/source-document-schemas/${id}/general`} />
          </DialogActions>
        </Overlay>
      </Form>
    </Portal>
  )
}

const styles: any = {
  root: {
    flexShrink: 0,
    display: 'flex',
  },
  content: {
    flex: 1,
    overflowY: 'auto',
  },
}

export default NewSourceDocumentSchemaRule
