import {
  SET_USER,
  SET_SPREADSHEET,
  SET_SOURCE_DOCUMENTS_CURRENT_UPLOAD,
  UPDATE_SOURCE_DOCUMENTS_CURRENT_UPLOAD,
  ERROR_SOURCE_DOCUMENTS_CURRENT_UPLOAD,
  RESET_SOURCE_DOCUMENTS_CURRENT_UPLOAD,
  SET_SOURCE_DOCUMENTS_LOADER_SHOW,
  SET_SOURCE_DOCUMENTS_LOADER_UPLOAD,
  SET_NEW_CONNECTION,
  CLEAR_NEW_CONNECTION,
  SET_NEW_WEBHOOK,
  CLEAR_NEW_WEBHOOK,
  SET_DELETED_CONNECTION,
  CLEAR_DELETED_CONNECTION,
  SET_NEW_SOURCE_DOCUMENT_GROUP,
  CLEAR_NEW_SOURCE_DOCUMENT_GROUP,
  SET_NEW_SOURCE_DOCUMENT_SCHEMA,
  CLEAR_NEW_SOURCE_DOCUMENT_SCHEMA,
  ADD_NOTIFICATION,
} from '../actions/common'

export const INITIAL_STATE = {
  global: {},
  uploader: {
    show: false,
    upload: false,
    errors: [],
  },
  notifications: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        global: {
          ...state.global,
          user: action.user,
        },
      }

    case SET_SPREADSHEET:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          spreadsheet: action.meta,
        },
      }

    case SET_SOURCE_DOCUMENTS_CURRENT_UPLOAD:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          upload: true,
          show: true,
          totalChunks: action.sourceDocuments.length,
          id: action.id,
          feature: action.feature,
          sourceDocumentsCurrentUpload: [...action.sourceDocuments],
          errors: [],
        },
      }
    case UPDATE_SOURCE_DOCUMENTS_CURRENT_UPLOAD:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          sourceDocumentsCurrentUpload: [...action.sourceDocuments],
        },
      }

    case ERROR_SOURCE_DOCUMENTS_CURRENT_UPLOAD:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          errors: [...state.uploader.errors, action.error],
        },
      }

    case SET_SOURCE_DOCUMENTS_LOADER_SHOW:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          show: action.show,
        },
      }

    case SET_SOURCE_DOCUMENTS_LOADER_UPLOAD:
      return {
        ...state,
        uploader: {
          ...state.uploader,
          upload: action.upload,
        },
      }

    case SET_NEW_CONNECTION:
      return {
        ...state,
        connection: action.connection,
      }

    case CLEAR_NEW_CONNECTION:
      return {
        ...state,
        connection: null,
      }

    case SET_NEW_WEBHOOK:
      return {
        ...state,
        webhook: action.webhook,
      }

    case CLEAR_NEW_WEBHOOK:
      return {
        ...state,
        webhook: null,
      }

    case SET_NEW_SOURCE_DOCUMENT_GROUP:
      return {
        ...state,
        sourceDocumentGroup: action.sourceDocumentGroup,
      }

    case CLEAR_NEW_SOURCE_DOCUMENT_GROUP:
      return {
        ...state,
        sourceDocumentGroup: null,
      }

    case SET_NEW_SOURCE_DOCUMENT_SCHEMA:
      return {
        ...state,
        sourceDocumentSchema: action.sourceDocumentSchema,
      }

    case CLEAR_NEW_SOURCE_DOCUMENT_SCHEMA:
      return {
        ...state,
        sourceDocumentSchema: null,
      }

    case SET_DELETED_CONNECTION:
      return {
        ...state,
        deletedConnection: action.deletedConnection,
      }

    case CLEAR_DELETED_CONNECTION:
      return {
        ...state,
        deletedConnection: null,
      }

    case RESET_SOURCE_DOCUMENTS_CURRENT_UPLOAD:
      return { ...INITIAL_STATE }

    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.notification, ...state.notifications],
      }

    default:
      return state
  }
}

export default reducer
