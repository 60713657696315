import React from 'react'

import { COLORS } from '../../../theme'

import Button from '../../Button'

const PageAction = ({ ...rest }) => {
  return <Button css={styles.root} iconSize={27} {...rest} />
}

const styles = {
  root: {
    height: 'auto',
    backgroundColor: 'transparent',
    color: COLORS.purpleTitle,
    textTransform: 'uppercase',
    fontSize: 19,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    fontWeight: 500,

    padding: '1rem 1.5rem',
    borderRadius: 9,
    border: `1px solid rgba(0,0,0,0)`,

    '&:hover': {
      border: `1px solid ${COLORS.purpleTitleShaded}`,
      background: COLORS.yellowLightest,
      boxShadow: `8px 8px 0 ${COLORS.buttonShadow}`,
    },

    '@media (max-width: 1895px)': {
      fontSize: 17,
    },
  },
}

export default PageAction
