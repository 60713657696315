import React from 'react'
import clsx from 'clsx'
import { CircleSpinner } from 'react-spinners-kit'

import StatusEmpty from './StatusEmpty'

import { COLORS } from '../theme'
import { Feature } from '../declarations'

type Props = {
  horizontal?: boolean
  className?: string
  gridGap?: string | number
  loading?: boolean
  feature?: Feature
  empty?: boolean
  emptyMessage?: string
}

const List: React.FC<Props> = ({
  children,
  horizontal,
  className = '',
  loading = false,
  empty = false,
  emptyMessage = 'There is no data',
  feature,
  gridGap = '1rem',
  ...rest
}) => {
  const classes = clsx({
    [className]: className,
    'is-horizontal': horizontal,
    'is-empty': empty,
    'is-loading': loading,
  })

  const dynamicStyles = {
    gridGap,
    ...rest,
  }

  return (
    <>
      <div css={[styles.root, dynamicStyles]} className={classes}>
        {loading && (
          <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2rem' }}>
            <CircleSpinner size={25} color={COLORS.orchidPaleLight} />
          </div>
        )}
        {empty && !loading && <StatusEmpty feature={feature} />}
        {!empty && !loading && children}
      </div>
    </>
  )
}

const styles = {
  root: {
    display: 'grid',

    '&.is-loading': {
      minHeight: '20rem',
    },

    '&.is-horizontal': {
      gridAutoFlow: 'column',
    },

    '&.is-empty': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      minHeight: 500,
    },
  },

  emptyMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
}

export default List
