import React from 'react'

import { addNotification } from '../actions/common'
import { store } from '../setup/store'

import { FEATURES } from '../theme'

const ENTITIES = {
  DISTRIBUTION_PACKAGE: 'DISTRIBUTION_PACKAGE',
  CONNECTION: 'CONNECTION',
  SOURCE_DOCUMENT: 'SOURCE_DOCUMENT',
  RELEASE: 'RELEASE',
  STAGED_RELEASE: 'STAGED_RELEASE',
}

type Entity = keyof typeof ENTITIES

const ACTIONS = {
  CREATED: 'CREATED',
  DELETED: 'DELETED',
  MODIFIED: 'MODIFIED',
  UPDATED: 'UPDATED',
}

type Action = keyof typeof ACTIONS

const Notifications = {
  [ENTITIES.CONNECTION]: {
    feature: FEATURES.connection,
    actions: {
      [ACTIONS.CREATED]: {
        builder: (data) => (
          <span>
            A Connection to <strong>{data.key}</strong> has been created and will be available in any second
          </span>
        ),
      },
      [ACTIONS.UPDATED]: {
        builder: (data) => (
          <span>
            A Connection to <strong>{data.key}</strong> has been updated
          </span>
        ),
      },
    },
  },

  [ENTITIES.RELEASE]: {
    feature: FEATURES.releases,
    actions: {
      [ACTIONS.CREATED]: {
        icon: 'cog',
        spin: true,
        builder: (data) => `Releasing ${data._embedded.distributionPackage.name}`,
      },
      [ACTIONS.UPDATED]: {
        icon: 'success',
        builder: (data) => {
          const distributionPackage = data._embedded.distributionPackage
          const { errors, validDocuments } = data.summary

          return `${distributionPackage.name} has been released with ${validDocuments} valid documents and ${errors} errors`
        },
      },
    },
  },

  [ENTITIES.STAGED_RELEASE]: {
    feature: FEATURES.staged_releases,
    actions: {
      [ACTIONS.CREATED]: {
        icon: 'cog',
        spin: true,
        builder: (data) => (
          <span>
            Staging <strong>{data._embedded.distributionPackage.name}</strong>
          </span>
        ),
      },
      [ACTIONS.UPDATED]: {
        icon: 'success',
        builder: (data) => {
          const distributionPackage = data._embedded.distributionPackage
          const { errors, validDocuments } = data.summary

          return (
            <span>
              <strong>{distributionPackage.name}</strong> has been staged with <strong>{validDocuments} valid documents</strong> and
              <strong> {errors} errors</strong>
            </span>
          )
        },
      },
    },
  },
}

export const notify = (entity: Entity, action: Action, data?: any, message?: string = 'Empty Message', onClick?: any) => {
  const entityModel = Notifications[entity]
  const foundAction = entityModel?.actions?.[action]
  const toastMessage = foundAction ? foundAction.builder(data) : message

  store.dispatch(
    addNotification({
      icon: foundAction?.icon || entityModel?.feature?.icon,
      spin: foundAction?.spin,
      message: toastMessage,
      onClick,
    }),
  )
}

export const customNotify = (message: string, icon?: string, spin?: boolean, onClick?: any) => {
  store.dispatch(addNotification({ icon, message, spin, onClick }))
}
