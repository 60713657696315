import React from 'react'
import { SourceDocumentGroup } from '../declarations'

import EditableFieldInputSearchableSelect from './EditableFieldInputSearchableSelect'
import { Option } from './SearchableDropdown'

type Props = {
  label?: string
  labelValue?: string
  className?: string
  defaultValues?: object
  sourceDocumentGroups: SourceDocumentGroup[]
  documentKey?: string
  model: string
  setMutationData?: Function
  disabled?: boolean
  options?: Option[]
  getMoreOptions?: Function
  hasMoreOptions?: boolean
  loading?: boolean
} & React.ReactNode

const EditableSourceDocumentGroupsSelector: React.FC<Props> = ({
  label = 'Source Document Group',
  labelValue = '-',
  sourceDocumentGroups = [],
  documentKey,
  getMoreOptions,
  hasMoreOptions,
  defaultValues,
  loading,
  setMutationData,
  ...rest
}) => {
  const options = React.useMemo(
    () =>
      sourceDocumentGroups.map((current) => ({
        label: current.name + ' - ' + current.tag,
        value: current.id,
        embedded: {
          documentKey: current.documentKey,
        },
      })),
    [sourceDocumentGroups, documentKey],
  )

  return (
    <EditableFieldInputSearchableSelect
      model="baseSourceDocumentGroupId"
      labelValue={labelValue || '-'}
      label={label}
      getMoreOptions={getMoreOptions}
      hasMoreOptions={hasMoreOptions}
      options={options}
      forceDisabled={!documentKey}
      defaultValues={defaultValues}
      loading={loading}
      setMutationData={setMutationData}
      additionalFilters={[(current: Option) => current.embedded?.documentKey === documentKey]}
      {...rest}
    />
  )
}

export default EditableSourceDocumentGroupsSelector
