import { getActionType } from '../../hooks/useAPI'

export const createStagedRelease = (stagedRelease, onSuccess) => {
  return {
    type: getActionType('STAGED_RELEASE', 'POST'),
    request: {
      url: '/staged-releases',
      method: 'post',
      data: stagedRelease,
    },
    meta: {
      mutations: {
        [getActionType('STAGED_RELEASE', 'NEW')]: (data) => data,
        [getActionType('STAGED_RELEASES', 'GET')]: {
          updateData: (current, mutated) => {
            return { ...current, [mutated?.id]: mutated }
          },
        },
      },
      ...(onSuccess && { onSuccess }),
    },
  }
}
