import React from 'react'

import MappingRule from './MappingRule'
import MappingAddAnother from './MappingAddAnother'

const MappingRuleCloud = ({
  model,
  setModel,
  sourceDocumentRules,
  documentSchemaProperty,
  sourceDocumentAttributes,
  inputSchemaType,
  distributionPackageId,
  close,
  openSection,
  ...rest
}) => {
  return (
    <div css={styles.root}>
      {sourceDocumentRules?.map((rule) => (
        <MappingRule
          defaultModel={rule}
          model={model}
          documentSchemaProperty={documentSchemaProperty}
          sourceDocumentAttributes={sourceDocumentAttributes}
          schemaAttributeType={inputSchemaType}
          distributionPackageId={distributionPackageId}
          close={close}
          key={rule.id}
          sourceDocumentRules={sourceDocumentRules}
          isSourceDocument
          {...rest}
        />
      ))}

      {model?.map((rule) => (
        <MappingRule
          defaultModel={rule}
          model={model}
          setModel={setModel}
          documentSchemaProperty={documentSchemaProperty}
          sourceDocumentAttributes={sourceDocumentAttributes}
          schemaAttributeType={inputSchemaType}
          distributionPackageId={distributionPackageId}
          sourceDocumentRules={sourceDocumentRules}
          close={close}
          key={rule.id}
          {...rest}
        />
      ))}

      {model.length === 0 && sourceDocumentRules.length === 0 && <MappingAddAnother onClick={() => openSection('copy')} />}
    </div>
  )
}

const styles: any = {
  root: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '-0.4rem',
  },
}

export default MappingRuleCloud
