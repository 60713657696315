import COLORS from './colors'

export const getBrand = (brand) => {
  if (brand in BRAND) return BRAND[brand]
  return BRAND.default
}

const BRAND = {
  default: {
    file: require('../../assets/brand/venzee-logo-purple.svg'),
    color: COLORS.default,
    alt: 'Venzee Logo',
  },
  small: {
    file: require('../../assets/brand/venzee-logo-small.svg'),
    color: COLORS.default,
    alt: 'Venzee Logo',
  },
}

for (let brand in BRAND) {
  BRAND[brand].file = BRAND[brand].file.default || BRAND[brand].file
}

export const brandList = Object.keys(BRAND)

export default BRAND
