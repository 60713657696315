import React from 'react'
import { useSelector } from 'react-redux'
import { getQuerySelector } from '@redux-requests/core'
import isEmpty from 'lodash/isEmpty'
import isDefined from '@codewell/is-defined'

import List from '../../../components/List'
import SourceDocumentListItem from '../../../components/list/SourceDocumentListItem'
import Pagination from '../../../components/Pagination'
import ListLoader from '../../../components/ListLoader'

import useAPI, { getActionType } from '../../../hooks/useAPI'
import { DistributionPackage, SourceDocument } from '../../../declarations'

type Props = {
  distributionPackage: DistributionPackage
}
const SourceDocumentsTabPage: React.FC<Props> = ({ distributionPackage }) => {
  const DPKGSourceDocuments = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENTS') }))
  const SDGSourceDocuments = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS') }))

  const [getMoreState, setGetMoreState] = React.useState(false)
  const [DPKGSourceDocumentPages, setSourceDocumentPages] = React.useState<SourceDocument[]>([])
  const DPKGSourceDocumentNextPage = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENTS_PAGES') }))
  const nextDPKGSourceDocumentPage =
    (DPKGSourceDocumentNextPage?.data
      ? DPKGSourceDocumentNextPage?.data?._links?.next?.href
      : DPKGSourceDocuments?.data?._links?.next?.href) || ''

  const [SDGSourceDocumentsPages, setSDGSourceDocumentsPages] = React.useState<SourceDocument[]>([])
  const SDGSourceDocumentsNextPage = useSelector(getQuerySelector({ type: getActionType('SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS_PAGES') }))
  const nextSDGSourceDocumentPage =
    (SDGSourceDocumentsNextPage?.data
      ? SDGSourceDocumentsNextPage?.data?._links?.next?.href
      : SDGSourceDocuments?.data?._links?.next?.href) || ''

  React.useEffect(() => {
    if (getMoreState) setGetMoreState(false)
  }, [getMoreState])

  React.useEffect(() => {
    if (DPKGSourceDocumentNextPage?.data)
      setSourceDocumentPages([
        ...DPKGSourceDocumentPages,
        ...(DPKGSourceDocumentNextPage?.data?._embedded?.sourceDocuments?.map?.((item) => item.document) || []),
      ])
  }, [DPKGSourceDocumentNextPage?.data?._embedded?.sourceDocuments])

  React.useEffect(() => {
    if (SDGSourceDocumentsNextPage?.data)
      setSDGSourceDocumentsPages([
        ...SDGSourceDocumentsPages,
        ...(SDGSourceDocumentsNextPage?.data?._embedded?.sourceDocuments?.map?.((item) => item.document) || []),
      ])
  }, [SDGSourceDocumentsNextPage?.data?._embedded?.sourceDocuments])
  const DPKGSourceDocumentsList = DPKGSourceDocuments?.data?._embedded?.sourceDocuments?.map?.((item) => item.document) || []
  const SDGSourceDocumentsList = SDGSourceDocuments?.data?._embedded?.sourceDocuments?.map?.((item) => item.document) || []

  const completeDPKGSourceDocumentsList = [...DPKGSourceDocumentsList, ...DPKGSourceDocumentPages]
  const completeSDGSourceDocumentsList = [...SDGSourceDocumentsList, ...SDGSourceDocumentsPages]
  const finalSourceDocumentsList = [...completeDPKGSourceDocumentsList, ...completeSDGSourceDocumentsList]

  const baseSourceDocumentGroupId = distributionPackage?.baseSourceDocumentGroupId

  useAPI(
    'SOURCE_DOCUMENTS',
    `/distribution-packages/${distributionPackage?.id}/source-documents?offset=0&limit=10`,
    isDefined(DPKGSourceDocuments?.data) || DPKGSourceDocuments?.pending > 0,
    'GET',
    true,
  )
  useAPI(
    'SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS',
    `/source-document-groups/${baseSourceDocumentGroupId}/source-documents?offset=0&limit=10`,
    isDefined(SDGSourceDocuments.data) || !baseSourceDocumentGroupId || SDGSourceDocuments.pending > 0,
    'GET',
    true,
  )
  useAPI(
    'SOURCE_DOCUMENTS_PAGES',
    nextDPKGSourceDocumentPage,
    !nextDPKGSourceDocumentPage || !getMoreState || DPKGSourceDocumentNextPage?.pending > 0,
    'GET',
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    [getMoreState],
  )
  useAPI(
    'SOURCE_DOCUMENT_GROUP_SOURCE_DOCUMENTS_PAGES',
    nextSDGSourceDocumentPage,
    !nextSDGSourceDocumentPage || !getMoreState || SDGSourceDocumentsNextPage?.pending > 0,
    'GET',
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    [getMoreState],
  )
  const isLoading = DPKGSourceDocuments?.pending > 0 || SDGSourceDocuments.pending > 0
  const isEmptyList = isEmpty(finalSourceDocumentsList) || (DPKGSourceDocuments?.data?.count === 0 && SDGSourceDocuments?.data?.count === 0)

  const items = finalSourceDocumentsList.map((sourceDocument) => (
    <SourceDocumentListItem
      key={sourceDocument?.id}
      documentKey={distributionPackage?.documentKey}
      sourceDocument={sourceDocument}
      to={`/distribution-packages/${distributionPackage?.id}/source-documents/${sourceDocument?.[distributionPackage?.documentKey]}`}
    />
  ))
  return (
    <List feature="source_documents" loading={isLoading} empty={isEmptyList} gridGap="0" css={{ overflowX: 'auto' }}>
      {finalSourceDocumentsList.length > 0 && (
        <Pagination
          next={() => setGetMoreState(true)}
          dataLength={items.length}
          loader={<ListLoader />}
          hasMore={!!nextDPKGSourceDocumentPage || !!nextSDGSourceDocumentPage}
          scrollableTarget="page"
        >
          {items}
        </Pagination>
      )}
    </List>
  )
}

export default SourceDocumentsTabPage
