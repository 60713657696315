import { buildBaseUrl, setHeaders } from './axios'

export const initialize = async () => {
  // build urls for requests
  await buildBaseUrl()

  return true
}

export const setup = async () => {
  // set token
  const tokenStep = await setHeaders()
  if (!tokenStep) return window.location.replace('/login')
}
