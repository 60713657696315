import React from 'react'
import clsx from 'clsx'

import { COLORS } from '../../theme'

type Props = {
  children?: React.ReactElement[] | React.ReactElement
  className?: string
}

const TabList: React.FC<Props> = ({ className = '', children }) => {
  const classNames = clsx({
    [className]: className,
    'is-centered': className,
  })

  return (
    <div css={styles.root}>
      <div css={styles.list} className={classNames}>
        {children}
      </div>
    </div>
  )
}

const styles: any = {
  root: {
    width: '100%',
    backgroundColor: COLORS.white,
  },
  list: {
    display: 'flex',

    borderBottom: `1px solid ${COLORS.grayLight}`,

    '&.is-centered': {
      justifyContent: 'center',
    },
  },
}

export default TabList
