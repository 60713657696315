import React from 'react'

import Icon from './Icon'

import { getFeature } from '../theme'
import { Feature } from '../declarations'

type Props = {
  feature?: Feature
  message?: string
}

const StatusEmpty: React.FC<Props> = ({ feature, message }) => {
  const foundFeature = getFeature(feature)

  const entity = foundFeature?.title

  return (
    <div css={styles.emptyMessage}>
      <Icon icon={foundFeature?.icon} size={50} css={{ marginBottom: '1rem' }} />
      {!message && <div css={styles.header}>There are no {entity}</div>}
      {message && <div css={styles.header}>{message}</div>}
    </div>
  )
}

const styles = {
  root: {
    '&.is-empty': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      minHeight: '25rem',
    },
  },
  header: {
    fontSize: 17,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  emptyMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: 300,
    textAlign: 'center',
  },
}

export default StatusEmpty
