import React from 'react'

import { COLORS } from '../theme'

import Container from './Container'

const Footer: React.FC = () => (
  <Container fullWidth css={styles.root}>
    <div css={styles.nav}>
      <div></div>
      <div>© 2021 - Venzee. All rights reserved.</div>
    </div>
  </Container>
)

const styles = {
  root: {
    height: 80,
    display: 'grid',
    gridTemplateColumns: '12fr 5fr',
    gridGap: '1rem',
    paddingRight: '2rem',
    textTransform: 'uppercase',
    color: COLORS.gray80,
    // backgroundColor: COLORS.white,
    // borderTop: `1px solid ${COLORS.orchidPale20}`,
  },

  nav: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    // padding: '0 2rem',
  },
}

export default Footer
