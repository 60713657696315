import COLORS from './colors'

export const getIcon = (icon: string) => {
  if (icon in ICONS) return ICONS[icon]
  return ICONS.default
}

const ICONS = {
  default: {
    file: require('../../assets/icons/products.svg'),
    color: COLORS.default,
    alt: 'Products',
  },
  syndication: {
    file: require('../../assets/icons/syndication.svg'),
    color: COLORS.default,
    alt: 'Syndication',
  },
  products: {
    file: require('../../assets/icons/products.svg'),
    color: COLORS.default,
    alt: 'Products',
  },
  connections: {
    file: require('../../assets/icons/connections.svg'),
    color: COLORS.default,
    alt: 'Connections',
  },
  connection: {
    file: require('../../assets/icons/connection.svg'),
    color: COLORS.default,
    alt: 'Connection',
  },
  dashboard: {
    file: require('../../assets/icons/dashboard.svg'),
    color: COLORS.default,
    alt: 'Products',
  },
  chevron: {
    file: require('../../assets/icons/chevron.svg'),
    color: COLORS.default,
  },
  chevronRight: {
    file: require('../../assets/icons/chevron-right.svg'),
    color: COLORS.default,
  },
  exclamation: {
    file: require('../../assets/icons/exclamation.svg'),
    color: COLORS.yellow,
  },
  webhook: {
    file: require('../../assets/icons/webhook.svg'),
    color: COLORS.purple,
  },
  releases: {
    file: require('../../assets/icons/releases.svg'),
    color: COLORS.purple,
  },
  distributionPackages: {
    file: require('../../assets/icons/distribution-packages.svg'),
    color: COLORS.purple,
  },
  sourceDocumentSchemas: {
    file: require('../../assets/icons/source-document-schemas.svg'),
    color: COLORS.purple,
  },

  sourceDocuments: {
    file: require('../../assets/icons/file.svg'),
    color: COLORS.purple,
  },

  sourceDocumentsGroup: {
    file: require('../../assets/icons/fileGroup.svg'),
    color: COLORS.purpleTitle,
  },

  documentError: {
    file: require('../../assets/icons/document-error.svg'),
    color: COLORS.purple,
  },

  documentValid: {
    file: require('../../assets/icons/document-valid.svg'),
    color: COLORS.purple,
  },

  rules: {
    file: require('../../assets/icons/source-document-schemas.svg'),
    color: COLORS.purple,
  },
  channels: {
    file: require('../../assets/icons/channels.svg'),
    color: COLORS.purple,
  },
  check: {
    file: require('../../assets/icons/check.svg'),
    color: COLORS.white,
  },
  valid: {
    file: require('../../assets/icons/check-green.svg'),
    color: COLORS.white,
  },
  emptyContent: {
    file: require('../../assets/icons/empty-content.svg'),
    color: COLORS.white,
  },
  mappingRules: {
    file: require('../../assets/icons/distribution-packages-mapping-rules.svg'),
    color: COLORS.white,
  },
  info: {
    file: require('../../assets/icons/info.svg'),
    color: COLORS.white,
  },
  infoLight: {
    file: require('../../assets/icons/info-light.svg'),
    color: COLORS.white,
  },
  clipboardCopyInverted: {
    file: require('../../assets/icons/clipboard-copy-white.svg'),
    color: COLORS.white,
  },
  ruleAdd: {
    file: require('../../assets/icons/rule-add.svg'),
    color: COLORS.white,
  },
  type: {
    file: require('../../assets/icons/type.svg'),
    color: COLORS.white,
  },
  typeLight: {
    file: require('../../assets/icons/type-blue.svg'),
    color: COLORS.white,
  },
  general: {
    file: require('../../assets/icons/general.svg'),
    color: COLORS.white,
  },

  deleteDistributionPackage: {
    file: require('../../assets/icons/delete-distribution-package.svg'),
    color: COLORS.white,
  },

  deleteDocuments: {
    file: require('../../assets/icons/delete-documents.svg'),
    color: COLORS.white,
  },

  input: {
    file: require('../../assets/icons/input.svg'),
    color: COLORS.white,
  },

  requested: {
    file: require('../../assets/icons/requested.svg'),
    color: COLORS.white,
  },

  success: {
    file: require('../../assets/icons/success.svg'),
    color: COLORS.white,
  },

  cog: {
    file: require('../../assets/icons/cog.svg'),
    color: COLORS.white,
  },

  key: {
    file: require('../../assets/icons/key.svg'),
    color: COLORS.white,
  },

  thrash: {
    file: require('../../assets/icons/thrash.svg'),
    color: COLORS.white,
  },

  404: {
    file: require('../../assets/404.svg'),
  },
}

for (let icon in ICONS) {
  ICONS[icon].file = ICONS[icon].file.default || ICONS[icon].file
}

export const iconsList = Object.keys(ICONS)

export default ICONS
