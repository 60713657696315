import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getQuerySelector, resetRequests } from '@redux-requests/core'

import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import reverse from 'lodash/reverse'

import Page from '../../components/layout/page/Page'
import CardSection from '../../components/CardSection'
import PageAction from '../../components/layout/page/PageAction'
import NewConnection from '../../components/overlays/new/NewConnection'
import ListLoader from '../../components/ListLoader'
import Pagination from '../../components/Pagination'

import ConnectionListItem from '../../components/list/ConnectionListItem'

import useAPI, { getActionType } from '../../hooks/useAPI'

import { Connection } from '../../declarations'

const ConnectionsPage: React.FC = () => {
  const dispatch = useDispatch()
  const [getMoreState, setGetMoreState] = React.useState(false)
  const [connectionsPages, setConnectionsPages] = React.useState<Connection[]>([])

  const connections = useSelector(getQuerySelector({ type: getActionType('CONNECTIONS') }))
  const connectionsList = connections?.data?._embedded?.connections || []

  const connectionsNextPage = useSelector(getQuerySelector({ type: getActionType('CONNECTIONS_PAGES') }))

  const nextConnectionsPage =
    (connectionsNextPage?.data ? connectionsNextPage?.data?._links?.next?.href : connections?.data?._links?.next?.href) || ''

  useAPI('CONNECTIONS', '/connections?offset=0&limit=20', false, 'GET', true)
  useAPI(
    'CONNECTIONS_PAGES',
    nextConnectionsPage,
    !nextConnectionsPage || !getMoreState || connectionsNextPage?.pending > 0,
    'GET',
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    [getMoreState],
  )

  const isLoading = connections?.pending > 0
  const isEmptyList = (isEmpty(connectionsList) || connections?.data?.count === 0) && !isLoading

  React.useEffect(() => {
    dispatch(resetRequests([getActionType('CONNECTION')]))
  }, [])

  React.useEffect(() => {
    if (getMoreState) setGetMoreState(false)
  }, [getMoreState])

  React.useEffect(() => {
    if (connectionsNextPage?.data) setConnectionsPages([...connectionsPages, ...(connectionsNextPage?.data?._embedded?.connections || [])])
  }, [connectionsNextPage?.data?._embedded?.connections])

  const completeConnectionsList = [...connectionsList, ...connectionsPages]

  const items = reverse(sortBy(completeConnectionsList, 'lastUpdated')).map((connection) => (
    <ConnectionListItem
      key={connection?.id || connection?.connectionId}
      connection={connection}
      to={`/connections/${connection?.id || connection?.connectionId}`}
      loading={!connection?.status}
      disabled={!connection?.status}
    />
  ))

  return (
    <Page feature="connections" title="Connections" actions={<PageAction to="/connections/new" label="Create New" icon="connection" />}>
      <CardSection
        loading={isLoading}
        empty={isEmptyList}
        feature="connections"
        emptyMessage="There are no Connections created yet"
        customLoader={() => <ListLoader />}
      >
        {connectionsList && (
          <Pagination
            next={() => setGetMoreState(true)}
            dataLength={items.length}
            loader={<ListLoader />}
            hasMore={!!nextConnectionsPage}
            scrollableTarget="page"
          >
            {items}
          </Pagination>
        )}
      </CardSection>
      <Switch>
        <Route exact path="/connections/new" component={NewConnection} />
      </Switch>
    </Page>
  )
}

export default ConnectionsPage
