import React from 'react'
import moment from 'moment'
import { lighten } from 'polished'

import FormatDate, { Props as DateProps, DATE_FORMATS } from './FormatDate'
import { COLORS } from '../theme'

import Info, { Props as InfoProps } from './Info'
import Glyph from './Glyph'

export type Props = {
  onClick?: Function
} & InfoProps &
  DateProps

const InfoDate: React.FC<Props> = ({ color = 'transparent', label, date, ...rest }) => {
  const parsedDate = moment(date)
  const fullDate = parsedDate.format(DATE_FORMATS.full)
  return (
    <Info
      css={styles.root}
      color={color}
      tooltip={fullDate}
      label={
        <div css={styles.label}>
          <Glyph glyph="clock" size={16} css={{ marginRight: '0.4rem' }} />
          <div css={{ display: 'flex', alignItems: 'center' }}>
            {label} &nbsp;
            <FormatDate date={date} {...rest} />
          </div>
        </div>
      }
      {...rest}
    />
  )
}

const styles = {
  root: {
    color: lighten(0.2, COLORS.gray),
    padding: 0,
    fontWeight: 600,
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
}

export default InfoDate
