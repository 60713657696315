import React from 'react'

import { idShorthand } from '../utils/functions'

import InfoCopyClipboard from './InfoCopyClipboard'
import { Props as InfoProps } from './Info'

type Props = {
  id: string
} & InfoProps

const InfoId: React.FC<Props> = ({ id, ...rest }) => {
  return <InfoCopyClipboard label={idShorthand(id)} textToCopy={id} tooltip="Copy ID" {...rest} />
}

export default InfoId
