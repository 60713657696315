import React from 'react'

import { COLORS } from '../../theme'

const MappingAddAnother = (props) => {
  return (
    <div css={styles.root} {...props}>
      Copy Data From Your Documents
    </div>
  )
}

const styles = {
  root: {
    fontSize: '0.85rem',
    cursor: 'pointer',
    backgroundColor: COLORS.blue20,
    borderRadius: 3,
    height: 30,
    marginRight: '0.4rem',
    color: COLORS.blue80,
    display: 'flex',
    alignItems: 'center',
    lineHeight: 0.9,

    border: `2px dashed ${COLORS.blue80}`,
    opacity: 0.7,
    marginTop: '0.4rem',

    padding: '0 1rem',

    textTransform: 'uppercase',
  },
}

export default MappingAddAnother
