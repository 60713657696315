import React from 'react'
import useAxios from 'axios-hooks'
import clsx from 'clsx'
import { transparentize } from 'polished'
import isUndefined from 'lodash/isUndefined'
import { useDispatch } from 'react-redux'

import { COLORS, FEATURES } from '../../theme'
import { updateRule, deleteRule as deleteRuleAction } from '../../actions/mapping'

import SummonModal from '../SummonModal'
import Card from '../Card'
import MappingRuleSelector from './MappingRuleSelector'
import MappingRulePill from './rules/pills/MappingRulePill'
import Tooltip from '../Tooltip'

const MappingRule = ({
  defaultModel,
  distributionPackageId,
  currentDocumentPreview,
  setCurrentDocumentPreview,
  previewDocument,
  setPreviewLoading,
  documentKey,
  setMainLoading,
  model,
  setModel,
  removeRuleById,
  isSourceDocument,
  sourceDocumentRules,
  className,
  feature = 'distribution_package',
  sourceDocumentSchemaId,
  ...rest
}) => {
  const dispatch = useDispatch()
  const [rule, setRule] = React.useState(defaultModel)
  const [open, setOpen] = React.useState(false)
  const featureId = feature === 'distribution_package' ? distributionPackageId : sourceDocumentSchemaId

  // eslint-disable-next-line
  const [{ data, loading: rulePutLoading, error }, executePut] = useAxios(
    {
      url: `${FEATURES[feature].parentpath}/${featureId}/document-rules/${rule?.id}`,
      method: 'PUT',
    },
    { manual: true },
  )

  const [{ data: ruleDeleteData, loading: ruleDeleteLoading }, executeDelete] = useAxios(
    {
      url: `${FEATURES[feature].parentpath}/${featureId}/document-rules/${rule?.id}`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const [{ data: documentPreview, loading: documentPreviewLoading }, getDocumentPreview] = useAxios(
    {
      url: `${FEATURES[feature].parentpath}/${distributionPackageId}/attribute-previews/${previewDocument?.[documentKey]}`,
      method: 'GET',
    },
    { manual: true },
  )

  const loading = rulePutLoading || ruleDeleteLoading || documentPreviewLoading

  React.useEffect(() => {
    if (!isUndefined(ruleDeleteData)) {
      dispatch(deleteRuleAction(rule))
      removeRuleById(rule.id)
    }
  }, [ruleDeleteData])

  React.useEffect(() => {
    setPreviewLoading(documentPreviewLoading)
    // setMainLoading(documentPreviewLoading)
  }, [documentPreviewLoading])

  React.useEffect(() => {
    if (documentPreview?.document) setCurrentDocumentPreview(documentPreview.document)
  }, [documentPreview])

  React.useEffect(() => {
    if (data) {
      dispatch(updateRule(data))
      setRule(data)
      getDocumentPreview()
    }
  }, [data])

  const setRuleExternal = (newRule) => {
    setOpen(false)

    if (newRule) executePut({ data: newRule.rule })
  }

  const classNames = clsx({
    'is-open': open,
  })

  const deleteRule = () => {
    setOpen(false)
    executeDelete()
  }

  return (
    <SummonModal
      open={open}
      setOpen={setOpen}
      placement="bottom-start"
      disableDefaultClickBehavior={isSourceDocument}
      cta={
        <Tooltip message={isSourceDocument && 'Source Document Rules cannot be edited'} placement="top">
          <MappingRulePill rule={rule} loading={loading} isSourceDocument={isSourceDocument} className={classNames} />
        </Tooltip>
      }
      offset={[0, -3]}
    >
      <Card css={styles.modal}>
        <div onClick={(e) => e.stopPropagation()}>
          <MappingRuleSelector
            model={rule}
            setModel={setRuleExternal}
            defaultView={rule?.rule?.key}
            previewDocument={previewDocument}
            executeDelete={deleteRule}
            {...rest}
          />
        </div>
      </Card>
    </SummonModal>
  )
}

const styles = {
  modal: {
    border: `2px solid ${COLORS.yellow40}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,

    width: 590,
    minHeight: 'auto',

    boxShadow: `
      0px 1px 1px ${transparentize(0.5, COLORS.purplePale40)},
      0px 2px 2px ${transparentize(0.6, COLORS.purplePale40)}
    `,
  },
}

export default MappingRule
