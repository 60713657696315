import React from 'react'
import ScrollSpy from '../ScrollSpy'
import { lighten } from 'polished'

import { COLORS } from '../../theme'

import List from '../List'
import Header from '../Header'

import { getInputSchema, getValidSelector } from '../../utils/functions'

type Props = {
  schemaRequiredKeys: string[]
  schemaNotRequiredKeys: string[]
  showNotRequired: boolean
  documentSchemaProperties: any
  documentSchemaDefinitions?: object
}

const SchemaAttributeOverview: React.FC<Props> = ({
  schemaRequiredKeys,
  schemaNotRequiredKeys,
  showNotRequired,
  documentSchemaProperties,
  documentSchemaDefinitions,
}) => {
  return (
    <div css={styles.overview}>
      <Header title="Required Attributes" iconSize={18} css={styles.header} />

      {schemaRequiredKeys && (
        <List gridGap={0} css={{ minHeight: 'auto' }}>
          {schemaRequiredKeys.length > 0 && (
            <ScrollSpy>
              {schemaRequiredKeys.map((documentSchemaProperty) => (
                <a
                  href={`#${getValidSelector(documentSchemaProperty, 7)}`}
                  css={styles.overviewItem}
                  key={documentSchemaProperty}
                  ref={React.createRef()}
                >
                  {getInputSchema(documentSchemaProperty, documentSchemaProperties, documentSchemaDefinitions)?.title}
                </a>
              ))}
            </ScrollSpy>
          )}
        </List>
      )}

      {schemaNotRequiredKeys && showNotRequired && (
        <>
          <Header title="Additional Attributes" iconSize={18} css={[styles.header, { top: 45 }]} />
          <List gridGap={0} css={{ minHeight: 'auto' }}>
            {schemaNotRequiredKeys?.length > 0 && (
              <ScrollSpy>
                {schemaNotRequiredKeys.map((documentSchemaProperty) => (
                  <a
                    href={`#${getValidSelector(documentSchemaProperty, 7)}`}
                    css={styles.overviewItem}
                    className="not-required"
                    key={documentSchemaProperty}
                    ref={React.createRef()}
                  >
                    {getInputSchema(documentSchemaProperty, documentSchemaProperties, documentSchemaDefinitions)?.title}
                  </a>
                ))}
              </ScrollSpy>
            )}
          </List>
        </>
      )}
    </div>
  )
}

const styles: any = {
  overview: {
    position: 'sticky',
    top: 0,
    maxHeight: '100vh',
    overflowY: 'auto',
    width: 400,
  },
  overviewItem: {
    lineHeight: '12px',

    color: COLORS.orchidPale,
    fontSize: '1rem',
    textTransform: 'uppercase',
    textDecoration: 'none',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    opacity: '0.6',
    padding: '1rem',

    '&.not-required': {
      color: COLORS.purplePale,
    },

    '&.active': {
      color: COLORS.purpleTitle,
      opacity: 1,
      backgroundColor: lighten(0.03, COLORS.grayLight),
    },
  },

  header: {
    position: 'sticky',
    top: 0,
    zIndex: 10,

    fontSize: '1.1rem',

    height: 'fit-content',
    color: COLORS.purpleTitle,
    textTransform: 'uppercase',
    padding: '1rem 1.5rem',
    borderRadius: 9,

    display: 'flex',
    alignItems: 'center',

    border: `1px solid ${COLORS.purpleTitleShaded}`,
    background: COLORS.yellowLightest,
    boxShadow: `8px 8px 0 ${COLORS.buttonShadow}`,

    marginBottom: 40,
  },
}

export default SchemaAttributeOverview
