import React from 'react'
import axios from 'axios'
import { METHOD } from '../actions/data'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { handleRequests } from '@redux-requests/core'
import { createDriver } from '@redux-requests/axios'
import keyBy from 'lodash/keyBy'
import { customNotify } from '../modules/notifications'

import { history } from '../setup/store'
import common from '../reducers/common'
import mapping from '../reducers/mapping'

export type RootState = {
  requests?: { [key: string]: any }
  common?: { [key: string]: any }
  mapping?: { [key: string]: any }
  router?: { [key: string]: any }
}

const initialState = {}

class ResponseError extends Error {
  constructor(response) {
    super('ResponseError')

    this.name = 'ResponseError'
    this.response = response
  }
}

const onSuccess = (response, requestAction, store) => {
  const { key, mapKey = 'id', raw, request } = requestAction
  const { method } = request
  const hasEmbeddedData = Boolean(response.data?._embedded)
  const hasResults = Boolean(response.data?.count)
  const data = key && hasEmbeddedData ? response?.data?._embedded?.[key] : response?.data?._embedded || response.data

  switch (method?.toUpperCase()) {
    case METHOD.DELETE:
      customNotify('Deleted successfully', 'thrash')
      break
    case METHOD.PATCH:
      customNotify('Data Updated', 'success')
      break
  }

  if (raw || !hasResults) return response

  return { ...response, data: keyBy(hasEmbeddedData ? data : [data], mapKey) }
}

const onError = (error, requestAction, store) => {
  switch (error.response.status) {
    case 409:
      customNotify('This resource is being used. Please try again later')
      break
    case 401:
      history.push('/logout')
      break
  }

  throw new ResponseError(error.response)
}

const storeConfig = (history) => {
  const { requestsReducer, requestsMiddleware } = handleRequests({
    driver: createDriver(axios),
    onSuccess,
    onError,
    cache: true,
  })

  const middlewares = [routerMiddleware(history), ...requestsMiddleware]
  const enhancers = [applyMiddleware(...middlewares)]

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false })
      : compose

  const reducers = combineReducers({
    requests: requestsReducer,
    common: common,
    mapping: mapping,
    router: connectRouter(history),
  })

  const store = createStore(reducers, initialState, composeEnhancers(...enhancers))

  history.listen(() => window.updateAvailable && window.location.reload())

  return store
}

export const getAllAction = (name) => `api/GET_ALL_${name.toUpperCase()}`
export const getAction = (name) => `api/GET_${name.toUpperCase()}`
export const createAction = (name) => `api/CREATE_${name.toUpperCase()}`
export const updateAction = (name) => `api/UPDATE_${name.toUpperCase()}`
export const destroyAction = (name) => `api/DELETE_${name.toUpperCase()}`

export default storeConfig
